import faLang from "./entries/fa-IR";
import enLang from "./entries/en-US";
import {addLocaleData} from "react-intl";

const AppLocale = {
  fa: faLang,
  en: enLang,
  
};
addLocaleData(AppLocale.fa.data);
addLocaleData(AppLocale.en.data);

export default AppLocale;

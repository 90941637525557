import React, {Component} from 'react';
import {Button, Row, Col, Card, Input, Progress} from 'antd'
import {NotificationManager} from 'react-notifications'
import httpCaller from '../../../services/httpService'
import InputMask from 'react-input-mask';
import {withRouter} from 'react-router-dom'
import thunk from 'redux-thunk';

class EnterConfirmationCode extends Component
{
    constructor(props){
        
        super(props);
        let id = this.props.match.params["userId"];
        this.state ={
            Id : id,
            Code: '',
            counter: 0,
            Enable: 'false'
        }
        console.log(props);
    }

    componentDidMount(){
        if (this.props.location.state === undefined){
            this.props.history.push(`/NotFound`);
        }
        // else{
        //     console.log("from"+this.props.location.state.from)
        // }
    }

    handleCode = (e) => {
        this.setState({Code: e.target.value})
    }

    prepareData(){
        return{
            userId: parseInt(this.state.Id),
            code: this.state.Code,
        }
    }

    SaveConfimationCode = (e) => {
        if(this.state.Code.replace('—','').length < 6){
            NotificationManager.error('کد تایید به درستی وارد نشده است');
        }
        else{
            this.setState({counter:0})
            var Id = this.state.Id;
            httpCaller.Auth.validateConfirmationCode(this.prepareData(), () => {
                this.props.history.push(`/login/SetPasswordByUser/${Id}`)
            }, () => {
            })
        } 
    }

    ReSendConfimationCode = () => {
        this.setState({counter:0})
        httpCaller.Auth.sendSMS({id: parseInt(this.state.Id)}, () => {
        }, (error) => {
            NotificationManager.error(error);
        })
    }

    increaseTimer = () => {
        setTimeout(
            function () {
                this.setState({counter: (this.state.counter) + 1})
            }
                .bind(this),
            1000
        );
        // if(this.state.counter === 99)
        // {
        //     this.setState({Enable: 'true'})
        // }
        return this.state.counter
    }

    render(){
        return(
            <Row>
                <Col lg={6} md={6} sm={6} xs={12}></Col>
                <Col lg={12} md={12} sm={12} xs={24} >
                    <Card title="ثبت کد تایید" style={{textAlign:"center",marginTop:"20%"}} headStyle={{backgroundColor:"#520be1",color:"#f6f9fb"}} bodyStyle={{backgroundColor:"#e1e6f0"}}>
                        <Row>
                            <Col lg={6} md={6} sm={2} xs={24}></Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <br/>
                                <Input mask="999999" addonBefore="کد تایید" maskChar="—" placeholder="کد تایید" value={this.state.Code} onChange={this.handleCode}/>
                            </Col>
                            <Col lg={6} md={6} sm={2} xs={24}></Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" onClick = {this.SaveConfimationCode}>ثبت کد تایید</Button>
                                <Button type="primary" onClick = {this.ReSendConfimationCode}>ارسال مجدد کد تایید</Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Progress type="circle" percent={this.increaseTimer()} width={80} />
                            </Col>
                        </Row> 
                    </Card>
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}></Col>
                {/* <NotificationContainer/> */}
            </Row>            
        )
    }
}

export default withRouter(EnterConfirmationCode);
import React, { Component } from 'react';
import { Col, Switch, Row, Input, Form, Button, Card } from 'antd';

import { NotificationManager } from 'react-notifications'
import HttpService from "../../../services/httpService"
import { CheckPermissionHOC } from "../../../services/authorization"
class Shahkar extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.loadData(true);
  }

  loadData = (val) => {
    HttpService.Setting.getShahkar((data) => {
      this.setState({
        description: data.description,
        isActive: data.isActive
      });
    }, () => { }, val);
  }
  saveData = () => {
    if (this.state.description.trim() == '') {
      NotificationManager.error('ثبت توضیحات الزامی است.');
    }
    HttpService.Setting.saveShahkar({ isActive: this.state.isActive, description: this.state.description }, () => {
      NotificationManager.success('تغییرات ذخیره شده است.');
      this.loadData(false);// به منظور بررسی صحت عملبات در سرور
    });
  }

  handleDescription = (e) => {
    this.setState({ description: e.target.value })
  }
  handleActive = (val) => {
    this.setState({ isActive: val });
  }

  render() {
    return (
      <Card title='فعال/غیرفعالسازی شاهکار' headStyle={{ backgroundColor: "#f18805", color: "#f6f9fb" }}>
        <div className="gx-description">
          <Row>
            <Col xxl={8} sm={10} xs={24}>
              <label>{"استعلام از سرویس شاهکار "}</label>
              <Switch onChange={this.handleActive} checked={this.state.isActive} />
            </Col>
            <Col xxl={10} sm={14} xs={24}>
              <Form.Item
                label="توضیحات"
                labelCol={{ xs: 24, lg: 8, xl: 6 }}
                wrapperCol={{ xs: 24, lg: 16, xl: 18 }}
              >
                <Input.TextArea value={this.state.description} onChange={this.handleDescription} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="gx-card-list-footer">
          <Button type="primary" onClick={() => this.saveData()}>ذخیره</Button>
        </div>
      </Card>
    );
  }
}
export default CheckPermissionHOC(Shahkar, "SettingManagement_Shahlar");

import React, { Component } from 'react';
import { Row, Col, Button,Modal, Radio, Checkbox, Select, InputNumber, Card, Input, Form, Result } from 'antd';
import httpCaller from '../../services/httpService';
import numeral from 'numeral';
import { HasPermission } from '../../services/authorization';
import { NotificationManager } from 'react-notifications'
const { confirm } = Modal;

class NewExceptionalCurUseBySarafi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exceptionalCurUseBySarafiInfo: {
                id: this.props.location.state.id,
                sarafiId: this.props.location.state.sarafiId,
                currencyUseId: 0,
                description: '',
                buyCash: false,
                sellCash: false,
                buyHavaleh: false,
                sellHavaleh: false,
                validTransAndPaymentTypes: 0,
                allowedBuy: -1,
                descriptionAllowedBuy: '',

            },
            children: [],
            openPositionKind: 1
        }
    }

    componentDidMount = () => {
        this.FillCurrencyUse();
        console.log("componentDidMount.exceptionalCurUseBySarafiInfo.id", this.state.exceptionalCurUseBySarafiInfo.id);
        this.props.form.resetFields();
        if (this.state.exceptionalCurUseBySarafiInfo.id != null) {
            this.GetData(this.state.exceptionalCurUseBySarafiInfo.id);
        }
    }
    GetData = (id) => {
        httpCaller.Sana.GetExceptionalCurUseBySarafiById(id, (result) => {
            console.log("result", result);
            result.currencyUseId=result.currencyUseId.toString();
            this.props.form.setFieldsValue(result);

            this.setState({ exceptionalCurUseBySarafiInfo: result });
            if(result.allowedBuy!=-1 )
            {
                this.setState({openPositionKind:2})
            }
        }, () => { })
    }

    FillCurrencyUse = () => {
        httpCaller.Sana.GetCurrencyUses((data) => {
            this.setState({ children: data })
        }, (err) => {
        }, false);
    }

    handleChangeOpenPositionKind = (e) => {
        this.setState({
            openPositionKind: e.target.value
            // ,openPositionCeiling:0 
        });
            this.setState({
                exceptionalCurUseBySarafiInfo: {
                    ...this.state.exceptionalCurUseBySarafiInfo,
                    allowedBuy: e.target.value === 1 ? -1 : 1
                }
            })
    }

    handleCancelButtonClick = () => {
        this.props.history.push({ pathname: '/ExceptionalCurUseBySarafi', state: { sarafiId: this.state.exceptionalCurUseBySarafiInfo.sarafiId }, })
    }

    handleDelete = () => {
        confirm({
            title: 'آیا از حذف مطمئن هستید؟',
            content:
                'پس از حذف، دسترسی به این مستثنی وجود نخواهد داشت',
            onOk: () => {
                this.handleDeleteButtonClick();
            },
            onCancel: () => { },
        });
    }

    handleDeleteButtonClick = () => {
        var id = this.state.exceptionalCurUseBySarafiInfo.id;
        console.log(id);
        httpCaller.Sana.DeleteExceptionalCurUseBySarafi(id, () => {
            this.handleCancelButtonClick();
        }, () => { })
    }

    handleNewExceptionalCurUseBySarafi = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {



                var data = this.prepareData();
                console.log(data);

                // if (data.ValidTransAndPaymentTypes == "0") {
                //     NotificationManager.error(`لطفا یکی از موارد خرید یا فروش را انتخاب کنید`);
                //     return;
                // }
                 if (data.ValidTransAndPaymentTypes == "0" && data.allowedBuy==-1) {
                    NotificationManager.error(`نمی توان بدون تعیین مستثنی یا سقف خرید فرم را ثبت نمود`);
                    return;
                }
                if (this.state.openPositionKind === 2 && data.descriptionAllowedBuy === "") {
                    NotificationManager.error(`لطفا توضیحات سقف خرید را مشخص نمایید`);
                    return;

                }
                // if (data.Description == "") {
                //     NotificationManager.error('وارد کردن توضیحات الزامی است.');
                //     return;
                // }
                // else{
                httpCaller.Sana.InsertExceptionalCurUseBySarafi(data, () => {
                    NotificationManager.success(`مستثنی جدید با موفقیت افزوده شد`);
                    this.handleCancelButtonClick();
                })
                // }
            }
        });
    }

    prepareData = () => {
        var transactions = 0;
        if (this.state.exceptionalCurUseBySarafiInfo.buyCash) transactions = transactions + 1;
        if (this.state.exceptionalCurUseBySarafiInfo.sellCash) transactions = transactions + 2;
        if (this.state.exceptionalCurUseBySarafiInfo.buyHavaleh) transactions = transactions + 4;
        if (this.state.exceptionalCurUseBySarafiInfo.sellHavaleh) transactions = transactions + 8;

        return {
            id:this.state.exceptionalCurUseBySarafiInfo.id,
            SarafiId: this.state.exceptionalCurUseBySarafiInfo.sarafiId,
            CurrencyUseId:parseInt(this.state.exceptionalCurUseBySarafiInfo.currencyUseId),
            Description: this.state.exceptionalCurUseBySarafiInfo.description,
            ValidTransAndPaymentTypes: transactions.toString(),
            allowedBuy: this.state.exceptionalCurUseBySarafiInfo.allowedBuy,
            descriptionAllowedBuy: this.state.exceptionalCurUseBySarafiInfo.descriptionAllowedBuy,

        }
    }


    changeBuyCashStatus = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                buyCash: e.target.checked
            }
        })
    }

    calcTransactionTypes = (e) => {
        return (
            e.target.checked ?
                { ...this.state.exceptionalCurUseBySarafiInfo.ValidTransAndPaymentTypes } + e.target.value :
                { ...this.state.exceptionalCurUseBySarafiInfo.ValidTransAndPaymentTypes } - e.target.value
        )
    }

    changeSellCashStatus = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                sellCash: e.target.checked
            }
        })
    }

    changeBuyChequeStatus = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                buyHavaleh: e.target.checked
            }
        })
    }

    changeSellChequeStatus = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                sellHavaleh: e.target.checked
            }
        })
    }

    handleChange = (value) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                currencyUseId:parseInt(value) 
            }
        })
        console.log(this.state.exceptionalCurUseBySarafiInfo.currencyUseId);
    }

    handleChangeDesc = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                description: e.target.value
            }
        })
    }

    handleChangedescriptionAllowedBuy = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                descriptionAllowedBuy: e.target.value
            }
        })
    }

    handleChangeallowedBuy = (e) => {
        this.setState({
            exceptionalCurUseBySarafiInfo: {
                ...this.state.exceptionalCurUseBySarafiInfo,
                allowedBuy: e
            }
        })
    }


    render() {

        const { getFieldDecorator } = this.props.form;
        let exceptionalCurUseBySarafiUpdate = HasPermission("SanaManagement_ExceptionalCurUseBySarafi");
        return (
            <Card className="gx-card" title="اطلاعات مستثنی">
                <Row>
                    <Col span={14} lg={4} md={4} sm={4} xs={4}>
                        <Input addonBefore="کد صرافی" value={this.state.exceptionalCurUseBySarafiInfo.sarafiId} disabled={true} />
                    </Col>
                    <Col span={10}>

                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col lg={16} md={16} sm={16} xs={24}>
                        <Form.Item
                            label="سرفصل :"
                            labelCol={{ xs: 4, lg: 4, md: 4, xl: 4 }}
                            wrapperCol={{ xs: 20, lg: 20, md: 20, xl: 20 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide bank name!'
                                },
                            ]}
                        >
                            {getFieldDecorator('currencyUseId', {
                                rules: [{ required: true, message: ' سرفصل را وارد نمایید' }],
                            })(
                                <Select
                                    mode="single"
                                    style={{ width: '100%' }}
                                    placeholder="لطفا انتخاب کنید"
                                    onChange={this.handleChange}
                                >
                                    {
                                        this.state.children.map(child => <Select.Option key={child.id}>{child.title}</Select.Option>)
                                    }
                                </Select>
                            )}

                        </Form.Item>
                    </Col>

                </Row>
                <br />
                <Row>
                    <Col md={6} xs={12}>
                        <Checkbox value='1' checked={this.state.exceptionalCurUseBySarafiInfo.buyCash} onChange={this.changeBuyCashStatus}>خرید نقدی</Checkbox>
                    </Col>
                    <Col md={6} xs={12}>
                        <Checkbox value='2' checked={this.state.exceptionalCurUseBySarafiInfo.sellCash} onChange={this.changeSellCashStatus}>فروش نقدی</Checkbox>
                    </Col>
                    <Col md={6} xs={12}>
                        <Checkbox value='4' checked={this.state.exceptionalCurUseBySarafiInfo.buyHavaleh} onChange={this.changeBuyChequeStatus}>خرید حواله</Checkbox>
                    </Col>
                    <Col md={6} xs={12}>
                        <Checkbox value='8' checked={this.state.exceptionalCurUseBySarafiInfo.sellHavaleh} onChange={this.changeSellChequeStatus}>فروش حواله</Checkbox>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={6} xs={12}>

                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg={16} md={16} sm={16} xs={24}>
                        <Form.Item
                            label="توضیحات"
                            labelCol={{ xs: 24, lg: 4, md: 4, xl: 4 }}
                            wrapperCol={{ xs: 24, lg: 20, md: 20, xl: 20 }}
                        >
                            {getFieldDecorator('description', {
                                initialValue: this.state.description,
                                rules: [{ required: true, message: ' توضیحات را وارد نمایید' }],
                            })(
                                <Input.TextArea rows={2} onChange={this.handleChangeDesc} />
                            )}

                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col lg={16} md={16} sm={12} xs={24}>
                        <Form.Item
                            label="نوع سقف خرید"
                            labelCol={{ xs: 24, lg: 4, md: 4, xl: 4 }}
                            wrapperCol={{ xs: 24, lg: 12, md: 12, xl: 12 }}
                        >
                            <Radio.Group onChange={this.handleChangeOpenPositionKind} value={this.state.openPositionKind} buttonStyle="solid" >
                                <Radio.Button value={1}>بدون سقف</Radio.Button>
                                <Radio.Button value={2}>سقف ثابت</Radio.Button>
                            </Radio.Group>

                        </Form.Item>
                    </Col>
                </Row>
                {this.state.openPositionKind !== 2 ? "" :
                    <Row>
                        <Col lg={16} md={16} sm={12} xs={24}>
                            <Form.Item
                                label="سقف خرید"
                                labelCol={{ xs: 24, lg: 4, md: 4, xl: 4 }}
                                wrapperCol={{ xs: 24, lg: 12, md: 12, xl: 12 }}
                            >
                                {getFieldDecorator('allowedBuy', {
                                    initialValue: this.state.exceptionalCurUseBySarafiInfo.allowedBuy,
                                    rules: [{ required: true, message: 'سقف خرید را وارد نمایید' }],
                                })(
                                    <InputNumber min={1} addonBefore="سقف خرید" onChange={this.handleChangeallowedBuy} />
                                )}

                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col lg={16} md={16} sm={12} xs={24}>
                        <Form.Item
                            label="توضیحات سقف خرید"
                            labelCol={{ xs: 24, lg: 4, md: 4, xl: 4 }}
                            wrapperCol={{ xs: 24, lg: 20, md: 20, xl: 20 }}
                        >
                            {getFieldDecorator('descriptionAllowedBuy', {
                            })(
                                <Input.TextArea rows={2} onChange={this.handleChangedescriptionAllowedBuy} />
                            )}

                        </Form.Item>
                    </Col>

                </Row>
                <br />
                {exceptionalCurUseBySarafiUpdate && <Row>
                    <Col md={24} xs={24}>
                        <Button type="primary" htmlType="button" onClick={this.handleNewExceptionalCurUseBySarafi}>ثبت </Button>
                        {this.state.exceptionalCurUseBySarafiInfo.id == null ? "" :
                            <Button type="danger" htmlType="button" onClick={this.handleDelete}>حذف </Button>
                        }
                        <Button htmlType="button" onClick={this.handleCancelButtonClick}>انصراف</Button>
                    </Col>
                </Row>}
            </Card>
        )
    }
}

export default Form.create()(NewExceptionalCurUseBySarafi)

import React, { Component } from 'react';
import {Row,Col,Table, Button, Card,Select, Input,Form} from 'antd'
import httpCaller from '../../services/httpService'
import { CheckPermissionHOC } from '../../services/authorization';

class Event extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchResult:{},
            userName: "",
            category: "",
            message:""
        }
    }

    componentDidMount() {
        this.fetchData(1);
    }

    fetchData =  (pageIndex) => {
        var param = {
            pageIndex: pageIndex,
            userName: this.state.userName,
            category:this.state.category,
            message:this.state.message
        };
        httpCaller.System.GetEvents(param, (pagedEntity) => {
            this.setState({ searchResult: pagedEntity });
        });
    }

    doSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({userName:values.userNameTxt,category: values.categoryTxt,message:values.messageTxt}, ()=> this.fetchData(1))
            }
        });
    }
    handlePageIndexChanged = (pageIndex) => {
      this.fetchData(pageIndex);
    }
   
    loadDetail =(record)=>{
        let type= record.type.toLowerCase();
        return (<Row >
        <Col md={24}>
            <div >
                <label >جزئیات:</label>
                <div
                    style={{ textAlign: "left", height: "400px", maxWidth:"1000px", overflow: "scroll" }}>
                    {record.details}
                </div>
            </div>
        </Col>
     </Row>)
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const columns =  [
         {
            title: 'نام کاربر',
            dataIndex: 'userName',
        },
        {
            title: 'نوع',
            dataIndex: 'type',
        },
        {
            title: 'گروه',
            dataIndex: 'category',
        },
        {
            title: 'عنوان',
            dataIndex: 'message',
        }, {
            title: 'زمان',
            dataIndex: 'dateText',
        }
        , {
            title: 'IP',
            dataIndex: 'userIp',
        }]

        return (
            <React.Fragment>
                 <Card >
                    <Row>
                    
                        <Col  md={8} sm={12} xs={24}>
                            <Form.Item
                                label="نام کاربری"
                            >
                                {getFieldDecorator('userNameTxt',{initialValue: this.state.userName})(
                                    <Input/>
                                )}
                                
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Form.Item
                                label="گروه"
                            >
                                {getFieldDecorator('categoryTxt',{initialValue: this.state.category})(
                                    <Input />
                                )}
                                
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Form.Item
                                label="عنوان"
                            >
                                {getFieldDecorator('messageTxt',{initialValue: this.state.message})(
                                    <Input />
                                )}
                                
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="gx-card-list-footer">
                        <Button type="primary" onClick = {this.doSearch}>جستجو</Button>
                    </div>
                </Card>
                <Card>
                    <Table
                        className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageIndexChanged
                        }}
                        expandedRowRender={this.loadDetail}
                        expandRowByClick = {true}
                        rowKey="date"
                    >
                    </Table>
                </Card>

             
            </React.Fragment>

        );
    }
}

export default  CheckPermissionHOC(Form.create()(Event),"sysAdmin");
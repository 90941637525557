import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SimpleFieldSet extends Component {
    constructor(props) {
        super(props);

        // تا زمانی که کاربر expand
        //نکرده، نیاز به لود پنل نیست 
        this.isExpandedEver = false;
        this.state = {
            isExpand: !!this.props.isExpand,
        }
    }
    handleCollapse = (doExpand) => {
        this.setState({ isExpand: doExpand })
    }

    render() {
        if (this.state.isExpand) {
            this.isExpandedEver = true;
        }

        var panel = this.isExpandedEver ? (<fieldset  style={{padding:"10px", border: "1px solid #C0DAE7", display: (this.state.isExpand ? "inherit" : "none") }}>
            <legend  style={{padding:"0px 2px 0px 2px", fontSize: "14px", width: "auto", border: "none", textAlign: "right", fontWeight:"bold", color:"#038fde" }}>
                {
                    this.props.isCollapsible ? (<a onClick={() => this.handleCollapse(false)}>
                        <i className="icon icon-chevron-down" style={{paddingRight:"5px", fontSize: "1rem", cursor: "pointer" , fontWeight:"bold", color:"#038fde"}} />
                    </a>) : ""
                }

                <a style={{paddingRight:"5px"}} onClick={() => this.handleCollapse(false)}>{this.props.title}</a>
            </legend>
            {this.props.children}
        </fieldset>) : "";


        return (
            <React.Fragment>
                <div style={{ textAlign: "right", display: (this.state.isExpand ? "none" : "inherit") , fontWeight:"bold", color:"#038fde", marginTop: "5px", marginBottom: "5px"}}>
                    <a onClick={() => this.handleCollapse(true)}>
                        <i className="icon icon-chevron-left" style={{paddingRight:"5px", fontSize: "1rem", cursor: "pointer", fontWeight:"bold", color:"#038fde" }} />
                    </a>
                    <a style={{paddingRight:"5px"}} onClick={() => this.handleCollapse(true)}>{this.props.title}</a>
                </div>
                {panel}
            </React.Fragment>
        );
    }
}

SimpleFieldSet.propTypes = {
    title: PropTypes.string,
    isCollapsible: PropTypes.bool,
    isExpand: PropTypes.bool

};

export default SimpleFieldSet;
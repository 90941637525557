import React, { Component } from 'react';
import httpCaller from '../../../services/httpService'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import { Row, Col, Form, Input, Button, Card, Table, Select } from 'antd'
import DropDownCurrency from '../import/DropDownCurrency'
import { DatePicker } from "react-advance-jalaali-datepicker";
import {CheckPermissionHOC} from '../../../services/authorization'
import MaskedInput from 'antd-mask-input'

class FullSearchExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: null,
            fullSearchdFilterState: {
                demandId: 0,
                sarafiIdOrName: "",
                supplyId: 0,
                supplyNationalOrCodeId: "",
                dealingId: 0,

                cottageNumber: "",
                supplyFromDate: "",
                supplyToDate: "",
                dealingFromDate: "",
                dealingToDate: "",

                currencyType: "",
                currencyRateType:255,
            },
        }

        this.fullSearchFilterRef = {
            demandId: React.createRef(),
            sarafiIdOrName: React.createRef(),
            supplyId: React.createRef(),
            supplyNationalOrCodeId: React.createRef(),
            dealingId: React.createRef(),
            cottageNumber: React.createRef(),

            supplyFromDate: React.createRef(),
            supplyToDate: React.createRef(),
            dealingFromDate: React.createRef(),
            dealingToDate: React.createRef(),
            currencyType: React.createRef(),
        }
    }
    componentDidMount() {
        this.loadEntitiesBaseOnQuery();
    }

    loadEntitiesBaseOnQuery = () => {

        console.log("loadEntitiesBaseOnQuery", this.state );
        let searchParams = new URLSearchParams(this.props.location.search);
        let hasDemandId = searchParams.has("demandId");
        let hasSarId = searchParams.has("sarafiIdOrName");
        let hasSupplyid = searchParams.has("supplyId");
        let haSupplyNationalOrCodeId = searchParams.has("supplyNationalOrCodeId");
        let hasDealingId = searchParams.has("dealingId");
        let hasCottageNumber = searchParams.has("cottageNumber");

        let hasSupplyFromDate = searchParams.has("supplyFromDate");
        let hasSupplyToDate = searchParams.has("supplyToDate");
        let hasDealingFromDate = searchParams.has("dealingFromDate");
        let hasDealingToDate = searchParams.has("dealingToDate");
        let hasCurrencyType = searchParams.has("currencyType");

        console.log("loadEntitiesBaseOnQueryhasSupplyFromDate", hasSupplyFromDate );
        console.log("loadEntitiesBaseOnQueryhasdemandId", hasDemandId );
        if (hasDemandId) {
            this.fullSearchFilterRef.demandId.current.setValue(searchParams.get("demandId"));
        }

        if (hasSarId) { 
            this.fullSearchFilterRef.sarafiIdOrName.current.setValue(searchParams.get("sarafiIdOrName"));
        }
        if (hasSupplyid) {
            this.fullSearchFilterRef.supplyId.current.setValue(searchParams.get("supplyId"));
        }
        if (haSupplyNationalOrCodeId) {
            this.fullSearchFilterRef.supplyNationalOrCodeId.current.setValue(searchParams.get("supplyNationalOrCodeId"));
        }
        if (hasDealingId) {
            this.fullSearchFilterRef.dealingId.current.setValue(searchParams.get("dealingId"));
        }
        if (hasCottageNumber) {
            this.fullSearchFilterRef.cottageNumber.current.setValue(searchParams.get("cottageNumber"));
        }


        if (hasSupplyFromDate) {
            this.state.fullSearchdFilterState.supplyFromDate=searchParams.get("supplyFromDate")
           // this.fullSearchFilterRef.supplyFromDate.current.value = (searchParams.get("supplyFromDate")) ;
             }
        if (hasSupplyToDate) {
           // this.fullSearchFilterRef.supplyToDate.current.setValue(searchParams.get("supplyToDate"));
           this.state.fullSearchdFilterState.supplyToDate=searchParams.get("supplyToDate");
         // this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, supplyToDate: searchParams.get("supplyToDate") } });
        }
        if (hasDealingFromDate) {
            //this.fullSearchFilterRef.dealingFromDate.current.setValue(searchParams.get("dealingFromDate"));
            this.state.fullSearchdFilterState.dealingFromDate=searchParams.get("dealingFromDate");
          //  this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, dealingFromDate: searchParams.get("dealingFromDate") } });
        }
           
        if (hasDealingToDate) {
           // this.fullSearchFilterRef.dealingToDate.current.setValue(searchParams.get("dealingToDate"));
           // this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, dealingToDate: searchParams.get("dealingToDate") } });
           this.state.fullSearchdFilterState.dealingToDate=searchParams.get("dealingToDate");
        }
        if (hasCurrencyType) {
            this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, currencyType: searchParams.get("currencyType") } });
        }

        if (hasDemandId || hasSarId || hasSupplyid || haSupplyNationalOrCodeId || hasDealingId || hasCottageNumber
            || hasSupplyFromDate || hasSupplyToDate || hasDealingFromDate || hasDealingToDate) {
            setTimeout(() => {
                this.handleSearchButtonClick();
            }, 500);
        }
    }
    handleSupplyFromDate = (e) => {
        console.log("this.state:", this.state);
        this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, supplyFromDate: e.target.value } },
            () => { console.log("fullSearchdFilterState.supplyFromDate3:", this.state); });
    }
    handleSupplyToDate = (e) => {
        this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, supplyToDate: e.target.value } })
    }
    handleDealingFromDate = (e) => {
        this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, dealingFromDate: e.target.value } })
    }
    handleDealingToDate = (e) => {
        this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, dealingToDate: e.target.value } })
   }
    handleCurrencyType = (e) => {
        console.log("CurrencyTypeHanler", e)
        this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, currencyType: e } } 
            , console.log ("CurrencyTypeHanler ==>> this.state", this.state )
            );
    }
    handleChangeStatusRate = (val) => {
        this.setState({ fullSearchdFilterState: { ...this.state.fullSearchdFilterState, currencyRateType: val} } );
    }

    doSearch = (pageIndex) => {

        this.setQueryParameter();
console.log(" this.state.fullSearchdFilterState.dealingId", this.state );
        httpCaller.NimaExport.FullSearch({
            pageIndex, demandId: this.parseInt0( this.state.fullSearchdFilterState.demandId ), sarafiIdOrName: this.state.fullSearchdFilterState.sarafiIdOrName
            , supplyId:  this.parseInt0( this.state.fullSearchdFilterState.supplyId), supplyNationalOrCodeId: this.state.fullSearchdFilterState.supplyNationalOrCodeId
            , dealingId: this.parseInt0(  this.state.fullSearchdFilterState.dealingId ), cottageNumber: this.state.fullSearchdFilterState.cottageNumber
            , supplyFromDate: this.state.fullSearchdFilterState.supplyFromDate, supplyToDate: this.state.fullSearchdFilterState.supplyToDate
            , dealingFromDate: this.state.fullSearchdFilterState.dealingFromDate, dealingToDate: this.state.fullSearchdFilterState.dealingToDate
            , currencyType: this.parseInt0(  this.state.fullSearchdFilterState.currencyType )
            ,currencyRateType: this.parseInt0(  this.state.fullSearchdFilterState.currencyRateType )

        }, (data) => {
           
            this.setState({ searchResult: data });
        });
    }
    parseInt0 = ( paramValue)=>{
        if(paramValue === null || paramValue === ""){
            return 0;
        }
        else    {
            return parseInt(paramValue) ;
        }
    }
    setQueryParameter = () => {

        let pathname = this.props.location.pathname;
        let searchParams = new URLSearchParams();
        searchParams.set("type", 2);

        if (this.state.fullSearchdFilterState.demandId.trim() != "") {
            searchParams.set("demandId", this.state.fullSearchdFilterState.demandId);
        }

        if (this.state.fullSearchdFilterState.sarafiIdOrName.trim() != "") {
            searchParams.set("sarafiIdOrName", this.state.fullSearchdFilterState.sarafiIdOrName);
        }
        if (this.state.fullSearchdFilterState.supplyId.trim() != "") {
            searchParams.set("supplyId", this.state.fullSearchdFilterState.supplyId);
        }
        if (this.state.fullSearchdFilterState.supplyNationalOrCodeId.trim() != "") {
            searchParams.set("supplyNationalOrCodeId", this.state.fullSearchdFilterState.supplyNationalOrCodeId);
        }
        if (this.state.fullSearchdFilterState.dealingId.trim() != "") {
            searchParams.set("dealingId", this.state.fullSearchdFilterState.dealingId);
        }
        if (this.state.fullSearchdFilterState.cottageNumber.trim() != "") {
            searchParams.set("cottageNumber", this.state.fullSearchdFilterState.cottageNumber);
        }

        if (this.state.fullSearchdFilterState.supplyFromDate.trim() != "") {
            searchParams.set("supplyFromDate", this.state.fullSearchdFilterState.supplyFromDate);
        }
        if (this.state.fullSearchdFilterState.supplyToDate.trim() != "") {
            searchParams.set("supplyToDate", this.state.fullSearchdFilterState.supplyToDate);
        }
        if (this.state.fullSearchdFilterState.dealingFromDate.trim() != "") {
            searchParams.set("dealingFromDate", this.state.fullSearchdFilterState.dealingFromDate);
        }
        if (this.state.fullSearchdFilterState.dealingToDate.trim() != "") {
            searchParams.set("dealingToDate", this.state.fullSearchdFilterState.dealingToDate);
        }
        if (this.state.fullSearchdFilterState.currencyType.trim() != "") {
            searchParams.set("currencyType", this.state.fullSearchdFilterState.currencyType);
        }

        this.props.history.push({
            pathname: pathname,
            search: searchParams.toString()
        });
    }
    handleSearchButtonClick = () => {
        console.log("fullSearchdFilterState.supplyFromDate4:", this.state.fullSearchdFilterState.supplyFromDate);
        this.setState({
            fullSearchdFilterState: {
                ...this.state.fullSearchdFilterState, demandId:  this.fullSearchFilterRef.demandId.current.input.value,
                sarafiIdOrName: this.fullSearchFilterRef.sarafiIdOrName.current.input.value,
                supplyId: this.fullSearchFilterRef.supplyId.current.input.value,
                supplyNationalOrCodeId: this.fullSearchFilterRef.supplyNationalOrCodeId.current.input.value,
                dealingId: this.fullSearchFilterRef.dealingId.current.input.value,
                cottageNumber: this.fullSearchFilterRef.cottageNumber.current.input.value,

                //  supplyFromDate: this.fullSearchFilterRef.supplyFromDate.current.input.value ,
                //  supplyToDate: this.fullSearchFilterRef.supplyToDate.current.input.value ,
                //  dealingFromDate: this.fullSearchFilterRef.dealingFromDate.current.input.value ,
                //  dealingToDate: this.fullSearchFilterRef.dealingToDate.current.input.value 
                //  currencyType: this.fullSearchFilterRef.currencyType.current.input.value ,
            }
        }

            , () => {
                console.log("fullSearchdFilterState.supplyFromDate5:", this.state);
                this.doSearch(1);
            })
    }
    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }
    handlePageIndexChanged = (pageIndex) => {
        this.doSearch(pageIndex)
    }
    setRowClassName = (record) => {
        return record.supplyIsDeleted === true ? 'supplyIsDeletedStyle' : '';
      }

    handleShowSupply = (row) => {
        this.props.history.push(`/nima/supply/${row.supplyId}/${row.demandId}`)
    }
    DatePickerInput(props) {
        return <Input  {...props} style={{ textAlign: "center", backgroundColor: "white" }} />;
    }
    render() {
        const FormItem = Form.Item;
        const columns =
            [{
                title: 'کد عرضه',
                dataIndex: 'supplyId',
            } , {
                title: 'کد عرضه کننده',
                dataIndex: 'applicantNationalId',
            } , {
                title: 'نام عرضه کننده ',
                dataIndex: 'applicantDisplayName',
            }
            , {
                title: 'تاریخ عرضه',
                dataIndex: 'supplyRegisterDateStr',
            }
            , {
                title: 'کد تقاضا',
                dataIndex: 'demandId',
            }, {
                title: 'کد معامله',
                dataIndex: 'dealingId',
            },{
                title: 'کد صرافی',
                dataIndex: 'sarafiCode',
            }, {
                title: 'نام صرافی',
                dataIndex: 'sarafiName',
            }, {
                title: 'وضعیت معامله',
                dataIndex: 'dealingStatusStr',
            }
            , {
                title: 'مبلغ ارز',
                dataIndex: 'dealingAmount',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ${row.currencyName}`} />
            }
            , {
                title: 'تاریخ معامله',
                dataIndex: 'dealingCreateDateStr',
            }
        ];

        return (
            <React.Fragment>

                <Card className="gx-card">
                    <Form>
                        <Row>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="کد عرضه"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.fullSearchFilterRef.supplyId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="کد تقاضا"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.fullSearchFilterRef.demandId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="کد معامله"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.fullSearchFilterRef.dealingId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>


                        </Row>
                        <Row>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="نام/کد/شناسه"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input placeholder="نام/کد/شناسه ملی عرضه کننده" ref={this.fullSearchFilterRef.supplyNationalOrCodeId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label=" عرضه از تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
  <MaskedInput mask="1111/11/11"   id="supplyFromDate" name="supplyFromDate" placeholder="yyyy/mm/dd" style={{direction:'ltr', textAlign:'center'}} size="11"
  placeholder="1398/01/01"  onKeyUp={this.handleSearchInputKeyUp  } value={this.state.fullSearchdFilterState.supplyFromDate}    onChange={this.handleSupplyFromDate}  />

                                    {/* <DatePicker
                                        customClass="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleSupplyFromDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="supplyFromDate"
                                        // preSelected={ this.state.toDate}
                                        value={this.state.supplyFromDate}
                                        cancelOnBackgroundClick={false}
                                    /> */}

                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label=" عرضه تا تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
  <MaskedInput mask="1111/11/11"   id="supplyToDate" name="supplyToDate" placeholder="yyyy/mm/dd" style={{direction:'ltr', textAlign:'center'}} size="11"
  placeholder="1398/01/01"  onKeyUp={this.handleSearchInputKeyUp}   value={this.state.fullSearchdFilterState.supplyToDate}  onChange={this.handleSupplyToDate}  />

                                    {/* <DatePicker
                                        customClass="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleSupplyToDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="supplyToDate"
                                        // preSelected={ this.state.toDate}
                                        value={this.state.supplyToDate}
                                        cancelOnBackgroundClick={false}
                                    /> */}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="کد صرافی"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.fullSearchFilterRef.sarafiIdOrName} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="معامله از تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                      <MaskedInput mask="1111/11/11"   id="dealingFromDate" name="dealingFromDate" placeholder="yyyy/mm/dd" 
                                      style={{direction:'ltr', textAlign:'center'}} size="11"
                                        placeholder="1398/01/01"  onKeyUp={this.handleSearchInputKeyUp}    value={this.state.fullSearchdFilterState.dealingFromDate} 
                                         onChange={this.handleDealingFromDate}   />
                                    {/* <DatePicker
                                        customClass="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleDealingFromDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="dealingFromDate"
                                        // preSelected={ this.state.toDate}
                                        value={this.state.dealingFromDate}
                                        cancelOnBackgroundClick={false}
                                       /> */}
                                </FormItem>
                            </Col>

                            <Col md={8} sm={24}>
                                <FormItem
                                    label="معامله تا تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <MaskedInput mask="1111/11/11"   id="dealingToDate" name="dealingToDate" placeholder="yyyy/mm/dd" 
                                      style={{direction:'ltr', textAlign:'center'}} size="11"  value={this.state.fullSearchdFilterState.dealingToDate} 
                                        placeholder="1398/01/01"  onKeyUp={this.handleSearchInputKeyUp}     onChange={this.handleDealingToDate}    />
                                    {/* <DatePicker
                                        customClass="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleDealingToDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="dealingToDate"
                                        // preSelected={ this.state.toDate}
                                        value={this.state.dealingToDate}
                                        cancelOnBackgroundClick={false}
                                    /> */}
                                </FormItem>
                            </Col>


                        </Row>

                        <Row>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="شماره کوتاژ "
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.fullSearchFilterRef.cottageNumber} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>

                            <Col md={8} sm={24}>
                                <FormItem
                                    label="نوع ارز"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <DropDownCurrency onChange={this.handleCurrencyType} value={this.state.fullSearchdFilterState.currencyType} onKeyUp={this.handleSearchInputKeyUp} />

                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="نوع نرخ "
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Select defaultValue="255" onChange={this.handleChangeStatusRate} > 
                                        <Select.Option value="255">همه گزینه ها</Select.Option>
                                        <Select.Option value="0">مصوب</Select.Option>
                                        <Select.Option value="1">آزاد</Select.Option>
                                        <Select.Option value="2">تعیین شده توسط بانک مرکزی</Select.Option>
                                        <Select.Option value="9">تالار دوم</Select.Option>
                                    </Select> 
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>

                            <Button type="primary" htmlType="button" onClick={() => this.handleSearchButtonClick()}>
                                جستجو
                        </Button>
                        </Row>
                    </Form>
                </Card>

                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageIndexChanged
                        }}
                        onRowClick={this.handleShowSupply}
                        rowClassName={this.setRowClassName}
                    />
                </Card>)}
            </React.Fragment>
        );
    }
}

export default CheckPermissionHOC(FullSearchExport,'NimaExport_ViewDealing');

import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import RequestDashboard from '../dashboard/RequestDashboard'
import SupplyDashboard from './SupplyDashboard'
import {Card, Col, Row} from 'antd'
import {CheckPermissionHOC} from 'services/authorization'

const Dashboard = ({match}) => (
  
  <React.Fragment>
      <Row>
        <Col md={12} xs={12}>
          <RequestDashboard></RequestDashboard>
        </Col>
          <Col md={12} xs={12}>
            <SupplyDashboard></SupplyDashboard>
          </Col>
      </Row>
  </React.Fragment>
);

export default CheckPermissionHOC(Dashboard,"Dashboard_Nima");

import React, { Component, createRef } from 'react';
import DisplayFormData from '../../utility/DisplayFormData'
import httpService from '../../../services/httpService'
import SimpleFieldSet from '../../utility/SimpleFieldSet'
import PaymentComponent from '../import/PaymentComponent'
import PaymentIdentifier from '../import/PaymentIdentifier'
import numeral from 'numeral'
import { Row, Col, Button,Modal} from 'antd'
import {NotificationManager} from 'react-notifications'
import {HasPermission} from '../../../services/authorization'
import { isNumber } from 'lodash';


class Demand extends Component {
    constructor(props) {
        super(props);
        this.demandId = props.demandId;
        this.state = {
            entity: {
                destinationAccount:{}
            },
            modalDescription:"",
            modalVisible:false,
            modalConfirmAction:null,
            modalTitle:""
        }
    }
    componentDidMount() {
        this.loadPageEntity(true);
    }

    loadPageEntity = (val) => {
        httpService.NimaExport.GetDemandInfo({ demandId: this.demandId }, (data)=>{
            this.setState({ entity: data }, ()=>{
                this.props.updateDealing(this.state.entity)
            });
        },(error)=>{}, val);
    }

    handelReturnFromFinal = () => {
            httpService.NimaExport.ReturnFromFinal({dealingId:this.state.entity.dealingId, description : this.state.modalDescription }, this.showSuccessAndReloadEntity)
    }
    handelCancelDealing = () => {
            httpService.NimaExport.CancelDealing({ dealingId:this.state.entity.dealingId, description : this.state.modalDescription }, this.showSuccessAndReloadEntity)
    }
    handelFinalDealing = () => {
            httpService.NimaExport.FinalDealing({ dealingId:this.state.entity.dealingId, description : this.state.modalDescription }, this.showSuccessAndReloadEntity)
    }
    handelConfirmSupplierCanceling = () => {
                httpService.NimaExport.ReplySupplierCanceling({ confirm: true, dealingId :this.state.entity.dealingId, description : this.state.modalDescription }, this.showSuccessAndReloadEntity)
    }
    handelRejectSupplierCanceling = () => {
        httpService.NimaExport.ReplySupplierCanceling({ confirm : false, dealingId :this.state.entity.dealingId, description : this.state.modalDescription }, this.showSuccessAndReloadEntity)
    }
   
    showSuccessAndReloadEntity = () => {
        NotificationManager.success('عملیات با موفقیت انجام شده است.');
        this.setState({modalVisible:false});
        setTimeout(() => {
            this.loadPageEntity(false);
        }, 3000);

    }

    showModal = (title, action) =>{

        this.setState({modalVisible:true, modalDescription:"", modalTitle:title, modalConfirmAction:action});
    }
    handleConfirmModal =()=>{
        var desc= this.state.modalDescription.trim();
        if(!desc){
            NotificationManager.error("توضیحات الزامی است.")
        }else{
            this.state.modalConfirmAction();
        }
    }
    handleDescChange= (e)=>{
        this.setState({modalDescription: e.target.value})
    }

    render() {
        let entity = this.state.entity;

        let hasDealing = !!entity.dealingId;
        
        let isHsabtasvieh =(!!entity.paymentTerms &&  entity.paymentTerms == 3) ;
        let canReturnFromFinal = HasPermission("NimaExport_ReturnFromFinalDealing") && entity.dealingStatus == 6;
        let canCancelDealing = HasPermission("NimaExport_CancelDealing") && ([1, 2, 3, 4, 5].indexOf(entity.dealingStatus) > 0);
        let canFinalDealing = HasPermission("NimaExport_FinalDealing") && entity.dealingStatus == 4;
        let canReplySupplierCanceling = HasPermission("NimaExport_ReplySupplierCanceling") && entity.dealingStatus == 8;

        return (
            <React.Fragment>
                <Row>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="کد تقاضا" value={entity.demandId}></DisplayFormData>
                    </Col>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="مبلغ ارز" value={numeral(entity.demandAmountMax).format('0,0')}></DisplayFormData>
                    </Col>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="حداقل مبلغ ارز" value={numeral(entity.demandAmountMin).format('0,0')}></DisplayFormData>
                    </Col>
                </Row>
               
                
                    { hasDealing && (<React.Fragment>
                    <br />
                    <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="کد معامله" value={entity.dealingId}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="مبلغ ارز تایید شده" value={numeral(entity.dealingAmount).format('0,0')}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="وضعیت" value={entity.dealingStatusText}></DisplayFormData>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تاریخ صدور حواله" value={entity.transferenceDate}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="شماره پیگیری سنا" value={entity.sanaTrackingCode}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تاریخ ایجاد معامله" value={entity.dealingCreateDate}></DisplayFormData>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                        <Col md={8} xs={24}>
                                <DisplayFormData lable="جمع کل (ریال)" value={numeral(entity.totalAmount).format('0,0')}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تاریخ مختومه " value={entity.dealingCloseDate}></DisplayFormData>
                            </Col>
                            {(entity.paymentTerms != null && entity.paymentTerms > 0) && <Col md={8} xs={24}>
                                <DisplayFormData lable="نحوه تسویه ریالی" value={entity.paymentTermsStr}></DisplayFormData>
                            </Col>}
                        </Row>
                       
                    </React.Fragment>)}
                 <br />
                 {HasPermission("Nima_ViewExchangeAccount") &&(<SimpleFieldSet title="مشخصات حساب مقصد" isCollapsible>
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="کشور" value={entity.destinationAccount.destinationCountry}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="بانک" value={entity.destinationAccount.destinationBank}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="کد سوئیفت" value={entity.destinationAccount.swift}></DisplayFormData>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="سایر کدها" value={entity.destinationAccount.otherPaymentCode}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="نوع کد" value={entity.destinationAccount.codeDescription}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="شماره حساب" value={entity.destinationAccount.destinationAccount}></DisplayFormData>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="IBAN" value={entity.destinationAccount.iban}></DisplayFormData>
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="صاحب حساب" value={entity.destinationAccount.accountOwner}></DisplayFormData>
                            </Col>
                        </Row>
                        <br />
                    </SimpleFieldSet>)
                }
                {
                    hasDealing &&
                    (<React.Fragment>
                        <br />
                        <SimpleFieldSet title="مشخصات حساب ریالی عرضه کننده" isCollapsible>
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="بانک" value={entity.supplierBankName}></DisplayFormData>
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="شماره حساب" value={entity.supplierAccountNumber}></DisplayFormData>
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="شماره شبا" value={entity.supplierIBAN}></DisplayFormData>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="صاحب حساب" value={entity.supplierAcountOwner}></DisplayFormData>
                                </Col>
                            </Row>
                        </SimpleFieldSet>

                        <br />
                       {!isHsabtasvieh && 
                       (
                        <SimpleFieldSet title="پرداختها" isCollapsible>
                            <Row  style={{ display: this.CanShowPayment }}>
                                <Col sm={24} style={{ fontSize: "14px" }}>
                                    <PaymentComponent currentEntity="dealing" entityId={entity.dealingId} showPayment={this.CanShowPayment}></PaymentComponent>
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </SimpleFieldSet> 
                       )
                       } 
                        {isHsabtasvieh && 
                            (
                             <SimpleFieldSet title="واریزهای حساب تسویه" isCollapsible>
                                <Row  style={{ display: this.CanShowPayment }}>
                                    <Col sm={24} style={{ fontSize: "14px" }}>
                                        <PaymentIdentifier currentEntity="dealing" entityId={entity.dealingId} showPayment={this.CanShowPayment}></PaymentIdentifier>
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>
                            </SimpleFieldSet> 
                            )
                        }
                        <br />

                        
                        </React.Fragment>)

                }

            <div style={{marginTop:"20px"}}>
                { canReturnFromFinal && (<Button type="primary" style={{marginRight:"20px"}}  onClick={()=> this.showModal("بازگشت از مختومه", this.handelReturnFromFinal)}>{"بازگشت از مختومه"}</Button>)}
                { canCancelDealing && (<Button type="primary" style={{marginRight:"20px"}}  onClick={()=> this.showModal("انصراف از معامله", this.handelCancelDealing)} >{"انصراف از معامله"}</Button>)}
                { canFinalDealing && (<Button type="primary" style={{marginRight:"20px"}}  onClick={()=> this.showModal("تایید و خاتمه معامله", this.handelFinalDealing)}>{"تایید و خاتمه معامله"}</Button>)}
                { canReplySupplierCanceling && (<Button type="primary" style={{marginRight:"20px"}}  onClick={()=>this.showModal("تایید  انصراف عرضه کننده", this.handelConfirmSupplierCanceling)}>{"تایید  انصراف عرضه کننده"}</Button>)}
                { canReplySupplierCanceling && (<Button type="primary" style={{marginRight:"20px"}}  onClick={()=>this.showModal("رد انصراف عرضه کننده", this.handelRejectSupplierCanceling)}>{"رد انصراف عرضه کننده"}</Button>)}
            </div>
               
            <Modal
                visible={this.state.modalVisible}
                title={this.state.modalTitle}
                onOk={this.state.modalConfirmAction}
                onCancel={()=>{this.setState({modalVisible:false})}}
                footer={[
                    <Button key="submit" type="primary"  onClick={this.handleConfirmModal}>
                        ارسال
                    </Button>,
                    <Button key="back" onClick={()=>{this.setState({modalVisible:false})}}>
                        بازگشت
                    </Button>
                ]}
                >
                <Row style={{textAlign:"center"}}>
                        <Col span={24}>
                            <textarea id = "txtDesc" placeholder="توضیحات" onChange={this.handleDescChange} value={this.state.modalDescription} style={{width:"80%", height:"100%", border:"1px groove black", borderRadius:"7px", padding:"5px"}} />
                        </Col>
                </Row>
        </Modal>
            </React.Fragment>
        );
    }
}

export default Demand;

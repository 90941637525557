import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Modal, Button, Card, Row, Col, Divider, Input } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
import { connect } from 'react-redux';
//import '../../../css/Style.css';
import NumberFormat from 'react-number-format';
import { DatePicker } from "react-advance-jalaali-datepicker";
 import BuyFromCbiModal from './BuyFromCbiModal';
 import BuyInNimaModal from './BuyInNimaModal';
 import BuyInSanaModal from './BuyInSanaModal';
 import SaleInNimaModal from './SaleInNimaModal';
 import SaleInSanaModal from './SaleInSanaModal';
import SaleToCbiModal from './SaleToCbiModal';
import HelpCalcBalanceModal from './HelpCalcBalanceModal';
import {CheckPermissionHOC , HasPermission} from '../../../services/authorization';
import {FilterFilled} from '@ant-design/icons'

class ExchangePosition extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fromDate: '1397/05/15',
            toDate: '',
            Stock: 0,
            SumSale: 0,
            SumBuy: 0,
            BuySana: 0,
            BuyNima: 0,
            SaleSana: 0,
            SaleNima: 0,
            NotMaped: 0,
            SaleBank: 0,
            BuyBank: 0,
            StockePercent: 0
        };
        this.state.idsarafi = this.props.exchangeId;
        console.log(this.state.idsarafi);
    }

    handledata = () => {
        const successHandler = (result) => {
            this.setState({
                Stock: result.stock,
                SumSale: result.sumSale,
                SumBuy: result.sumBuy,
                BuySana: result.buySana,
                BuyNima: result.buyNima,
                SaleSana: result.saleSana,
                SaleNima: result.saleNima,
                NotMaped: result.notMaped,
                SaleBank: result.saleBank,
                BuyBank: result.buyBank,
                StockePercent: result.stockePercent,

            });
        };
        const rejectedHandler = (ar) => {
        };

        httpCaller.Sarafi.GetBalanceSarafPosition(this.state.idsarafi, this.state.fromDate, this.state.toDate, successHandler, rejectedHandler);
    }

    handelFromDate = (unix, formatted) => {
        this.setState({ fromDate: formatted })
    }

    handelToDate = (unix, formatted) => {
        this.setState({ toDate: formatted })
    }

    handelBtnFilter = () => {
        this.handledata();
    }

    componentDidMount() {

        this.handledata();
    }

    DatePickerInput(props) {
        return <Input {...props} style={{textAlign:"center"}} />;
    }


    render() {
        if(!HasPermission ("ExchangeManagement_ViewBalance")) return null;
        return (
            <Row>
                <Col span={24}>
                <Card title={<span>آمار کل صرافی / بانک: {this.props.exchangeId}</span>}>
                    <Row>
                        <Col lg={2} md={2} sm={12} xs={12}>
                            <label>از تاریخ</label>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} >
                            <DatePicker
                                customClass ="customDatePicker"
                                inputComponent={this.DatePickerInput}
                                placeholder="انتخاب تاریخ"
                                format="jYYYY/jMM/jDD"
                                onChange={this.handelFromDate}
                                id="datefrom"
                                preSelected={this.state.fromDate}
                                value={this.state.fromDate}
                            />
                        </Col>
                        <Col lg={2} md={2} sm={8} xs={24}>
                        </Col>
                        <Col lg={2} md={2} sm={12} xs={12}>
                            <label>تا تاریخ</label>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <DatePicker
                                customClass ="customDatePicker"
                                inputComponent={this.DatePickerInput}
                                placeholder="انتخاب تاریخ"
                                format="jYYYY/jMM/jDD"
                                onChange={this.handelToDate}
                                id="dateto"
                                value={this.state.toDate}
                            />
                        </Col>
                        <Col lg={4} md={4} sm={8} xs={24}>
                            <Button type="primary" icon="s" onClick={this.handelBtnFilter}>اعمال فیلتر<FilterFilled /></Button>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24}>
                            <Divider type="horizontal"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="مجموع فروش" style={{textAlign:"center"}} headStyle={{backgroundColor:"#d9edf7",color:"#31708f"}} bodyStyle={{backgroundColor:"#eff7fd"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.SumSale} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="تراز" style={{textAlign:"center"}} headStyle={{backgroundColor:"#267704",color:"#dff0d8"}} bodyStyle={{backgroundColor:"#f5fff1"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.Stock} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <span >درصد تراز: </span>
                                <NumberFormat value={this.state.StockePercent} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="مجموع خرید" style={{textAlign:"center"}} headStyle={{backgroundColor:"#fcf8e3",color:"#8a6d3b"}} bodyStyle={{backgroundColor:"#fffcf2"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.SumBuy} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="میزان فروش در نیما به واردکنندگان" style={{textAlign:"center"}} headStyle={{backgroundColor:"#d9edf7",color:"#31708f"}} bodyStyle={{backgroundColor:"#eff7fd"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.SaleNima} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <SaleInNimaModal  exchangeId={this.state.idsarafi} fromDate={this.state.fromDate} toDate= {this.state.toDate} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="سناهای محاسبه نشده در تراز" style={{textAlign:"center"}} headStyle={{backgroundColor:"#267704",color:"#dff0d8"}} bodyStyle={{backgroundColor:"#f5fff1"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.NotMaped} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br />
                                <br />
                                <a target="_blank" href={httpCaller.Sarafi.GetPositionFileDownloadLink({idsarafi:this.state.idsarafi,fromDate: this.state.fromDate, toDate: this.state.toDate})}>
                                    <i class="fas fa-file-excel exsana"></i> دریافت فایل
                                </a>
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="میزان خرید ارز در نیما از صادرکنندگان" style={{textAlign:"center"}} headStyle={{backgroundColor:"#fcf8e3",color:"#8a6d3b"}} bodyStyle={{backgroundColor:"#fffcf2"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.BuyNima} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <BuyInNimaModal exchangeId={this.state.idsarafi} fromDate={this.state.fromDate} toDate= {this.state.toDate} />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="میزان فروش به صرافی ها/بانک ها در سنا" style={{textAlign:"center"}} headStyle={{backgroundColor:"#d9edf7",color:"#31708f"}} bodyStyle={{backgroundColor:"#eff7fd"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.SaleSana} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <SaleInSanaModal exchangeId={this.state.idsarafi} fromDate={this.state.fromDate} toDate= {this.state.toDate} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="میزان خرید ارز از صرافی ها / بانک ها در سنا" style={{textAlign:"center"}} headStyle={{backgroundColor:"#fcf8e3",color:"#8a6d3b"}} bodyStyle={{backgroundColor:"#fffcf2"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.BuySana} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <BuyInSanaModal exchangeId={this.state.idsarafi} fromDate={this.state.fromDate} toDate= {this.state.toDate}/>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="فروش ارز به بانک مرکزی" style={{textAlign:"center"}} headStyle={{backgroundColor:"#d9edf7",color:"#31708f"}} bodyStyle={{backgroundColor:"#eff7fd"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.SaleBank} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <SaleToCbiModal exchangeId={this.state.idsarafi.code} fromDate={this.state.fromDate} toDate= {this.state.toDate} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card size="small" title="خرید ارز از بانک مرکزی" style={{textAlign:"center"}} headStyle={{backgroundColor:"#fcf8e3",color:"#8a6d3b"}} bodyStyle={{backgroundColor:"#fffcf2"}}>
                                <span >مبلغ به یورو: </span>
                                <NumberFormat value={this.state.BuyBank} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'€'} />
                                <br/>
                                <br/>
                                <BuyFromCbiModal exchangeId={this.state.idsarafi} fromDate={this.state.fromDate} toDate= {this.state.toDate} />
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                        <Col md={8} sm={12} xs={24}></Col>
                        <Col md={8} sm={12} xs={24}>
                            <HelpCalcBalanceModal/>
                        </Col>
                        <Col md={8} sm={12} xs={24}></Col>
                    </Row>
                </Card>
                </Col>
            </Row>
        );
    }
}

//Commented By Dajer
//CheckPermissionHOC(ExchangePosition , "ExchangeManagement_ViewBalance")  ;

export default  ExchangePosition ;
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Alert, Table, Col, Row, Modal,Button } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
// import NotFound from '../Not-Found';
// import Modal from 'react-bootstrap/Modal';

class BuyInNimaModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
           list:[],
        }
    }

    HandelHideModal = () => { this.setState({ modalShow: false }); }
    HandelShowModal = () => {
        this.setState({ modalShow: true });
        this.handledata();
        this.populateColumns();
    }
  
    handledata = () => {
        const successHandler = (result) => {
            var items = [...result];
            this.setState({ list: items });
            console.log(this.state.list);
        };
        const rejectedHandler = (ar) => {
        };
        httpCaller.Sarafi.GetNimaSarafPositionBuy(this.props.exchangeId, this.props.fromDate, this.props.toDate, successHandler, rejectedHandler);
    }

    populateColumns = () => {
        this.setState({columns : [{
            title: 'ارز / مرحله معامله',
            dataIndex: 'currencyName',
          }, {
            title: 'آماده',
            dataIndex: 'amadehMoamele',
          }, {
            title: 'منتظر صدور حواله',
            dataIndex: 'montazerSdorehHavaleh',
          }, {
            title: 'آماده واریز',
            dataIndex: 'amadehVariz',
          }, {
            title: 'آماده تایید',
            dataIndex: 'amadeTayedSarafi',
          }, {
            title: 'منتظر تایید',
            dataIndex: 'montzerTayedArzeh',
          },{
            title: 'مختومه',
            dataIndex: 'makhtomeh',
          },{
            title: 'انصراف عرضه کننده',
            dataIndex: 'enserafeArzeKonnde',
          },{
            title: 'انصراف صراف',
            dataIndex: 'enserafeSaraf',
          },{
            title: 'مجموع',
            dataIndex: 'totalAmount',
          },{
            title: 'مجموع به یورو',
            dataIndex: 'totalEuro',
          }]});
    }

    render() {

        return (

            <Row>
                <Col>
                    <a onClick={this.HandelShowModal} style={{paddingRight:"10px"}}>مشاهده جزئیات</a>
                    <Modal
                        width="200"
                        title="میزان خرید ارز در نیما از صادرکنندگان"
                        visible={this.state.modalShow}
                        onCancel={this.HandelHideModal}
                        footer={[
                          <Button key="back" onClick={this.HandelHideModal}>بازگشت</Button>,
                        ]}
                        >
                        <Row>
                            <Col span={24}>
                                <Table className="gx-table-responsive"
                                    columns={this.state.columns}
                                    dataSource={this.state.list}
                                    bordered
                                />
                            </Col>
                        </Row>        
                    </Modal>
                </Col>
            </Row>
        );
    }
}

export default BuyInNimaModal;

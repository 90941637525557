import React, { Component } from 'react';
import { Col, Row, Button, Modal, Card,Tooltip,Form , Input,Checkbox } from 'antd';



import { connect } from 'react-redux';
import httpCaller from '../../services/httpService';

import DisplayFormData from '../utility/DisplayFormData';
import {NotificationContainer, NotificationManager} from "react-notifications";
import { withRouter } from 'react-router-dom';
import { HasPermission, CheckPermissionHOC } from '../../services/authorization'

class CreateAppUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            UserType: "کاربر",
            UserTypeVal: 2,
            UserName: '',
            Password: '',
            DefaultPassword: '',
            NationalId: '',
            Name: '',
            Family: '',
            DisplayName: '',
            Email: '',
            Desc: '',
            SarafiId: '',
            Tel: '',
            Mobile: '',
            AuthenticatedByLDAP: true,
        }
    }

    componentDidMount() {
        this.setState({ DefaultPassword: 'System@@min1' })
    }

    handleUserName = (e) => {
        this.setState({ UserName: e.target.value })
    }

    handleNationalId = (e) => {
        const reg = /^[0-9\b]+$/;
        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({ NationalId: e.target.value })
        }
    }

    handleName = (e) => {
        this.setState({ Name: e.target.value })
    }

    handleFamily = (e) => {
        this.setState({ Family: e.target.value })
    }

    handleDisplayName = (e) => {
        this.setState({ DisplayName: e.target.value })
    }

    leaveEmailInput = (e) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(e.target.value)) {
            this.setState({ Email: e.target.value })
        }
    }
    handleEmail = (e) => {
        this.setState({ Email: e.target.value })
    }

    handleTel = (e) => {

        const reg = /^[0-9\b]+$/;
        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({ Tel: e.target.value })
        }
    }

    handleMobile = (e) => {

        const reg = /^[0-9\b]+$/;
        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({ Mobile: e.target.value })
        }
    }

    handleDescription = (e) => {
        this.setState({ Desc: e.target.value })
    }
    handleLdap =(e)=>{
        this.setState({ AuthenticatedByLDAP: e.target.checked })
    }
    prepareRequest() {
        return {
            id: -1,
            userName: this.state.UserName,
            userType: this.state.UserTypeVal,
            password: this.state.DefaultPassword,
            nationalId: this.state.NationalId,
            firstName: this.state.Name,
            lastName: this.state.Family,
            displayName: this.state.DisplayName,
            phoneNumber: this.state.Tel,
            mobileNumber: this.state.Mobile,
            email: this.state.Email,
            sarafiId: 0,
            description: this.state.Desc,
            isActive: false,
            authenticatedByLDAP: this.state.AuthenticatedByLDAP
        }
    }

    submit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const successHandler = (result) => {
                    var alertmessage = '';
                    if (result.length > 0) {
                        result.forEach(element => {
                            alertmessage += element.description + " ** "
                        });
                        NotificationManager.warning(alertmessage);
                    }
                    else {
                        NotificationManager.success("ایجاد کاربر با موفقیت انجام شد");
                    }
                }
    
                const rejectHandler = (ar) => {
                    NotificationManager.error("خطا در ایجاد کاربر جدید. لطفا با مدیر سامانه تماس بگیرید.")
                };
                const requestdata = this.prepareRequest();
                httpCaller.AppUser.CreateAppUser(requestdata, successHandler, rejectHandler);
            }
        });
    }

    render() {
        let canCreateUser = HasPermission("AccountManagement_AddUser");
        const { TextArea } = Input;
        const formItemLayout = {
            labelCol: {
                md : { span: 6},
                xs : {span: 24},
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
              md: { span: 12 },
              lg: { span: 12 },
            },
          };
        const FormItem = Form.Item;
        const { getFieldDecorator } = this.props.form;

        return (
            <React.Fragment>
                <Row style={{textAlign:"center"}}>
                    <Col span={2} />
                    <Col span={20}>
                        <Card title = "کاربر جدید" style={{textAlign:"center"}} headStyle={{backgroundColor:"#520be1",color:"#f6f9fb"}} bodyStyle={{backgroundColor:"#e1e6f0"}}> 
                            <Row>
                                <Col span={4} />
                                <Col span={18}>
                                    <Form onSubmit={this.submit}>
                                        <FormItem
                                            {...formItemLayout}
                                            label="نام کاربری">
                                                {getFieldDecorator('username', {
                                                rules: [{ required: true, message: 'لطفا نام کاربری خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.UserName} onChange={this.handleUserName} />
                                                )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="شناسه ملی">
                                                {getFieldDecorator('nationalId', {
                                                rules: [{ required: true, message: 'لطفا شناسه ملی خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.NationalId} onChange={this.handleNationalId} maxLength='10' />
                                                )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="نام">
                                                {getFieldDecorator('name', {
                                                rules: [{ required: true, message: 'لطفا نام خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.Name} onChange={this.handleName} />
                                                )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="نام خانوادگی">
                                                {getFieldDecorator('family', {
                                                rules: [{ required: true, message: 'لطفا نام خانوادگی خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.Family} onChange={this.handleFamily} />
                                                )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="نام نمایشی">
                                                {getFieldDecorator('displayname', {
                                                rules: [{ required: true, message: 'لطفا نام نمایشی خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.DisplayName} onChange={this.handleDisplayName} />
                                                )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="پست الکترونیک">
                                                {getFieldDecorator('email', {
                                                rules: [{ required: true, message: 'لطفا پست الکترونیک خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.Email} onBlur={this.leaveEmailInput} onChange={this.handleEmail} />
                                                )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="شماره همراه">
                                                {getFieldDecorator('mobile', {
                                                rules: [{ required: true, message: 'لطفا شماره همراه خود را وارد کنید!' }],
                                                })(
                                                    <Input value={this.state.Mobile} onChange={this.handleMobile} />
                                                )}
                                        </FormItem>
                                        <FormItem
                                           {...formItemLayout}
                                            label="شماره تماس">
                                                <Input value={this.state.Tel} onChange={this.handleTel} />
                                        </FormItem>
                                        <FormItem   
                                            {...formItemLayout}>
                                            <Checkbox onChange={this.handleLdap} checked={this.state.AuthenticatedByLDAP}>احراز هویت با Active Directory(برای کاربران بانک)</Checkbox>
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="توضیحات">
                                                <TextArea rows={4} placeholder="توضیحات" value={this.state.Desc} onChange={this.handleDescription} />
                                        </FormItem>
                                    </Form>
                                </Col>
                                <Col span={2} />
                            </Row> 
                            <Row>
                                <Col md={6} xs={24}></Col>
                                {canCreateUser && <Col md={6} xs={24}>
                                    <Button type="primary" htmlType="button" onClick={this.submit}>
                                        ایجاد کاربر
                                    </Button>
                                </Col>}
                                <Col md={6} xs={24}>
                                    <Button type="primary" htmlType="button" >انصراف</Button>
                                </Col>
                                <Col md={6} xs={24}></Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={2} />
                </Row>
            </React.Fragment>
        )
    }
}

// const mapStatetoProps = (state) => {
//     return {

//         changeui: state.UiReducer.changeui
//     }
// }
// export default connect(mapStatetoProps)(CreateAppUser)
const CreateAppUserForm = Form.create()(CreateAppUser);
export default  CheckPermissionHOC(withRouter(CreateAppUserForm), "AccountManagement_AddUser");


import React from "react";
import {Route, Switch} from "react-router-dom";

import Main from "./main/index";
import Exchange from './Exchange/index';
import Login from './Login/index';
import Nima from './nima/index';
import RoleList from './role/RoleList';
import Role from './role/Role'
import Event from './system/Event'
import Users from './user/Users'
import ViewCbiUsers from "./user/ViewCbiUsers";
import ViewUser from './user/ViewUser'
import CreateAppUser from './user/CreateAppUser'
import Errorpage from './Erorr'
import HesabTasvieh from './HesabTasvieh/SearchDigitalChequePayments'
import Invalidpayments from './HesabTasvieh/Invalidpayments'
import DownloadFile from './utility/DownloadFile'
import CurrencyUse from './Sana/CurrencyUseList'
import ExceptionalCurUseBySarafi from './Sana/ExceptionalCurUseBySarafiList'
import SellFromResourceBank from './Sana/SellFromResourceBank'
import NewCurrencyUse from './Sana/NewCurrencyUse'
import NewExceptionalCurUseBySarafi from './Sana/NewExceptionalCurUseBySarafi'
import SanaSetting from './setting/SanaSetting'
import SanaCalendar from './setting/Calendar'
import RateRangeSetting from './setting/CurrencyCeilingRateList'
import NewRateRangeSetting from './setting/CurrencyCeilingRate'
import CheckSanaRialiPayments from './Reports/checkSanaRialiPayments'
import StatisticsRialiPayments from './Reports/statisticsRialiPayments'
import RialiPayment from './Reports/RialiPayment'
import ShowExchangePosition from './Reports/ShowExchangePosition'
import CoinDealing from './Sana/CoinDealing'
import PositionCorrection from './Sana/PositionCorrection'
import RialiPaymentsValidityReport from './Reports/RialiPaymentsValidityReport'
 

const App = ({match}) => (
  
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main}/>
      <Route path={`${match.url}exchange`} component={Exchange} />
      <Route path={`${match.url}nima`} component={Nima} />
      <Route path={`${match.url}login`} component={Login} />
      <Route path={`${match.url}setting/sana`} component={SanaSetting} />
      <Route path={`${match.url}setting/calendar`} component={SanaCalendar} />
      <Route path={`${match.url}setting/CurrencyCeilingRateList`} component={RateRangeSetting} />
      <Route path={`${match.url}setting/CurrencyCeilingRate`} component={NewRateRangeSetting} />
      <Route path={`${match.url}role/:roleId`} component={Role} />
      <Route path={`${match.url}role`} component={RoleList} />
      <Route exact path={`${match.url}event`} component={Event} />
      <Route exact path={`${match.url}user`} component={Users} />
      <Route exact path={`${match.url}ViewCbiUsers`} component={ViewCbiUsers} />
      <Route exact path={`${match.url}user/view/:userId`} component={ViewUser} />
      <Route exact path={`${match.url}user/new`} component={CreateAppUser} />
      <Route exact path={`${match.url}error`} component={Errorpage} />
      <Route exact path={`${match.url}hesabTasvieh`} component={HesabTasvieh} />
      <Route exact path={`${match.url}invalidpayments`} component={Invalidpayments} />
      <Route exact path={`${match.url}docs`} component={DownloadFile} />
      <Route exact path={`${match.url}currencyUse`} component={CurrencyUse} />
      <Route exact path={`${match.url}ExceptionalCurUseBySarafi`} component={ExceptionalCurUseBySarafi} />
      <Route exact path={`${match.url}SellFromResourceBank`} component={SellFromResourceBank} />
      <Route exact path={`${match.url}NewCurrencyUse`} component={NewCurrencyUse} />
      <Route exact path={`${match.url}NewExceptionalCurUseBySarafi`} component={NewExceptionalCurUseBySarafi} />
      <Route exact path={`${match.url}sana/CoinDealing`} component={CoinDealing} />
      <Route exact path={`${match.url}Reports/CheckSanaRialiPayments`} component={CheckSanaRialiPayments} /> 
      <Route exact path={`${match.url}Reports/statisticsRialiPayments`} component={StatisticsRialiPayments} /> 
      <Route exact path={`${match.url}Reports/RialiPayment`} component={RialiPayment} /> 
      <Route exact path={`${match.url}Reports/ShowExchangePosition`} component={ShowExchangePosition} /> 
      <Route exact path={`${match.url}PositionCorrection`} component={PositionCorrection} /> 
      <Route exact path={`${match.url}Reports/RialiPaymentsValidity`} component={RialiPaymentsValidityReport} /> 
    </Switch>
  </div>
   
);

export default App;

import React, { Component } from 'react';
import Profile from "./ProfileBox"
import { withRouter } from 'react-router-dom'
import { Row, Col, Tabs, Button, Modal, Input, Card } from 'antd'
import { HasPermission } from '../../services/authorization';
import UserRoles from './UserRoles';

import httpCaller from '../../services/httpService';

import { NotificationManager } from 'react-notifications';


class ViewUser extends Component {


    constructor(props) {
        super(props);
        this.state = {
            UserType: "کاربر",
            id: this.props.match.params["userId"],
            
        }
        this.profileBoxRef = {
            inputNewPassword: React.createRef(),

        }
    }

    GatherData =()=> {
        return ({
            id: parseInt(this.state.id),
            password: this.profileBoxRef.inputNewPassword.current.input.value
        })
    }

    handleReturnButton = () => {
        this.props.history.push('/user');
    }

    ConfirmGeneratePassword = () => {
        let re = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        if (re.test(this.profileBoxRef.inputNewPassword.current.input.value)) {
            var data = this.GatherData();
            httpCaller.Auth.SavePassword(JSON.stringify(data),
                (result) => {
                    NotificationManager.success("عملیات رمز عبور با موفقیت انجام شد.")
                },
                () => {
                    NotificationManager.error("عملیات رمز عبور با شکست مواجه شد، در صورت بروز مجدد خطا با مدیر سامانه تماس حاصل نمایید.")
                });
        }
        else {
            this.setState({ samplePasswordShow: true });
            NotificationManager.error("فرمت پسورد باید ترکیب حروف، علائم، عدد و حداقل هشت کارکتر باشد");
        }

    }
    handelRegisternputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.ConfirmGeneratePassword();
        }
    }

    render() {
        const TabPane = Tabs.TabPane;

        let canEditUser = HasPermission("AccountManagement_UpdateUser")
        let canDeleteUser = HasPermission("AccountManagement_DeleteUser");
        let canResetPassword = HasPermission("AccountManagement_ResetPassword");

        return (
            <React.Fragment>

                <Tabs type="card">
                    <TabPane tab={canEditUser ? "ویرایش کاربر" : "مشاهده اطلاعات کاربر"} key="1">
                        <Profile history={this.props.history} UserId={this.state.id} />
                    </TabPane>
                    <TabPane tab="نقشهای کاربر" key="2">
                        <UserRoles UserId={this.state.id} />
                    </TabPane>
                    <TabPane tab="بازیابی رمز عبور" key="3">

                        <Card  style={{ textAlign: "center" }}>

                            <Row style={{ textAlign: "center" }}>
                                <Col span={6}>
                                    {this.state.samplePasswordShow && <label >برای مثال: Ali@1234</label>}
                                  </Col>
                               <Col  span={18}></Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={6}>
                                    <Input id="txtNewPassword" onKeyUp={this.handelRegisternputKeyUp}
                                        ref={this.profileBoxRef.inputNewPassword} placeholder="رمز عبور جدید" style={{ textAlign: "center" }} />
                                </Col>
                                <Col span={6}>
                                    <Button type="primary" onClick={this.ConfirmGeneratePassword}>تایید</Button> 
                                    {/* <Button type="default" onClick={this.hideModal}>انصراف</Button> */}
                                </Col>
                                <Col span={12}></Col>
                            </Row>
               

                        </Card>
                    </TabPane>
                </Tabs>

                <br />
            </React.Fragment>
        );
    }
}


export default withRouter(ViewUser);
const navLinks = [
    {
        id: 10, textKey: 'sidebar.dashboard', icon: 'icon icon-exchange', subMenus: [
            {
                id: 100,
                textKey: 'sidebar.dashboard.nima',
                link: '/main/dashboard/nima',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima'
            }
            ,
            {
                id: 110,
                textKey: 'sidebar.dashboard.nimaTotalReport',
                link: '/main/dashboard/nimaTotalReport',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } ,
            {
                id: 111,
                textKey: 'sidebar.dashboard.currencySupplyToday',
                link: '/main/dashboard/currencySupplyToday',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } ,
             
        ]
    },
    {
        id: 12, textKey: 'sidebar.reports', icon: 'icon icon-exchange', subMenus: [
            {
                id: 120,
                textKey: 'sidebar.reports.rialiPaymentStatistics',
                link: '/Reports/StatisticsRialiPayments',
                icon: 'icon icon-exchange',
                permissionKey:'ReportsManagement_SanaRialiValidation'
            },
            {
                id: 121,
                textKey: 'sidebar.reports.RialiPaymentsValidityReport',
                link: '/Reports/RialiPaymentsValidity',
                icon: 'icon icon-exchange',
                permissionKey:'ReportsManagement_SanaRialiValidation'
            }, 
            {
                id: 122,
                textKey: 'sidebar.reports.rialiPayment',
                link: '/Reports/CheckSanaRialiPayments',
                icon: 'icon icon-exchange',
                permissionKey:'ReportsManagement_SanaRialiValidation'
            },      
            {
                id: 123,
                textKey: 'sidebar.reports.RialiPaymentIncomplete',
                link: '/Reports/RialiPayment',
                icon: 'icon icon-exchange',
                permissionKey:'ReportsManagement_SanaRialiValidation'
            }  ,
            {
                id: 124,
                textKey: 'sidebar.reports.ShowExchangePosition',
                link: '/Reports/ShowExchangePosition',
                icon: 'icon icon-exchange',
                permissionKey:'ReportsManagement_ShowExchangePosition'
            } 
        ]
    },
    
    {
         id:15, textKey: 'sidebar.nima', icon: 'icon icon-exchange', subMenus: [
            {
                id:150,
                textKey: 'sidebar.request',
                link: '/nima/import',
                icon: 'icon icon-exchange',
                permissionKey:'RequestManagement_ViewDetail'
            },
            {
                id:151,
                textKey: 'sidebar.supply',
                link: '/nima/export',
                icon: 'icon icon-exchange',
                permissionKey:'NimaExport_ViewDealing'
            },
            {
                id:152,
                textKey: 'sidebar.applicant',
                link: '/nima/merchant',
                icon: 'icon icon-exchange',
                permissionKey:'Merchant_permission' 
            },
            {
                id:153,
                textKey: 'sidebar.BlackList',
                link: '/nima/BlackList',
                icon: 'icon icon-exchange',
                permissionKey:'BlackList_permission' 
            }
    
        ]
    },
    {
        id: 20, textKey: 'sidebar.sana', icon: 'icon icon-exchange', subMenus: [
            {
                id: 200,
                textKey: 'sidebar.exchange',
                link: '/exchange/sarafi',
                icon: 'icon icon-exchange',
                permissionKey:'ExchangeManagement_view'
            },
            {
                id: 201,
                textKey: 'sidebar.SanaCurrencyUse',
                link: '/currencyUse',
                icon: 'icon icon-exchange',
                permissionKey:'SanaManagement_Edit'
            },
            {
                id: 204,
                textKey: 'sidebar.SanaExceptionalCurUseBySarafi',
                link: '/ExceptionalCurUseBySarafi',
                icon: 'icon icon-exchange',
                permissionKey:'SanaManagement_ExceptionalCurUseBySarafi'
            },            
            {
                id: 202,
                textKey: 'sidebar.sana.SellFromResource',
                link: '/SellFromResourceBank',
                icon: 'icon icon-exchange',
                permissionKey:'SanaManagement_SellFromResourceBank'
            },
            {
                id: 203,
                textKey: 'sidebar.sana.PositionCorrection',
                link: '/PositionCorrection',
                icon: 'icon icon-exchange',
                permissionKey:'ExchangeManagement_PositionCorrection'
            }
        ]
    },
    {
        id: 21, textKey: 'sidebar.coinDealing', icon: 'icon icon-exchange', subMenus: [
            {
                id: 210,
                textKey: 'sidebar.coinDealing.report',
                link: '/sana/CoinDealing',
                icon: 'icon icon-exchange',
                permissionKey:'CoinDealing'
            },
        ]
    },
    {
        id: 22, textKey: 'sidebar.setting', icon: 'icon icon-exchange', subMenus: [
            {
                id: 220,
                textKey: 'sidebar.setting.sana',
                link: '/setting/sana',
                icon: 'icon icon-exchange',
                permissionKey:'SettingManagement'
            },
            {
                id: 221,
                textKey: 'sidebar.setting.calendar',
                link: '/setting/calendar',
                icon: 'icon icon-exchange',
                permissionKey:'SettingManagement'
            },
            
            {
                id:222,
                textKey: 'sidebar.CurrencyCeilingRateList',
                link: '/setting/currencyceilingratelist',
                icon: 'icon icon-exchange',
                permissionKey:'SettingManagement_RateRange' 
            }
        ]
    },
    {
        id:25, textKey: 'sidebar.usersManagement', icon: 'icon icon-exchange', subMenus: [
            {
                id:250,
                textKey: 'sidebar.users',
                link: '/user',
                icon: 'icon icon-exchange',
                permissionKey:'AccountManagement_SearchUser'
            },
            {
                id:251,
                textKey: 'sidebar.newuser',
                link: '/user/new',
                icon: 'icon icon-exchange',
                permissionKey:'AccountManagement_AddUser'
            },
            {
                id:252,
                textKey: 'sidebar.role',
                link: '/role',
                icon: 'icon icon-exchange',
                permissionKey:'RoleManagement_ViewRole'
            },
            {
                id:253,
                textKey: 'sidebar.viewcbiusers',
                link: '/ViewCbiUsers',
                icon: 'icon icon-exchange',
                permissionKey:'AccountManagement_ViewCbiUsers'
            },
            

        ]
    },
    {
        id:30, textKey: 'sidebar.system', icon: 'icon icon-exchange', subMenus: [
            {
                id:300,
                textKey: 'sidebar.event',
                link: '/event',
                icon: 'icon icon-exchange',
                permissionKey:'sysAdmin'
            },
        ]
    },
    {
        id: 35, textKey: 'sidebar.Tasvieh', icon: 'icon icon-exchange', subMenus: [
            {
                id: 350,
                textKey: 'sidebar.payments',
                link: '/hesabTasvieh',
                icon: 'icon icon-exchange',
                permissionKey:'DigitalCheque_ViewRole'

            },
            {
                id: 351,
                textKey: 'sidebar.invalidpayments',
                link: '/invalidpayments',
                icon: 'icon icon-exchange',
                permissionKey:'Invalidpayments_ViewRole'

            }
        ]
    }
    ,
    // {
    //     id: 40, textKey: 'sidebar.Help', icon: 'icon icon-exchange', subMenus:[
    //         {
    //             id: 400,
    //             textKey: 'sidebar.HelpDocs',
    //             link: '/docs',
    //             icon: 'icon icon-exchange',
    //         }
    //     ]
    // }

];

const getNavLinks = () => {
    console.log("getNavLinks",navLinks);
    return [...navLinks];
};

export default getNavLinks;

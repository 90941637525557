import React, {Component} from 'react';
import {Card, Row, Col, Checkbox, Select, Form, Button, Table } from 'antd';
import httpCaller from '../../services/httpService';
import CurrencyUseDetail from './CurrencyUseDetail';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import numeral from 'numeral';

class CurrencyUseList extends Component{
    constructor(props){
        super(props)
        this.state = {
            Buy: false,
            Sale: false,
            Cash: false,
            Havale: false,
            AllowedCeiling: -1,
            CurrencyUseIds: [],
            children: [],
            TransactionTypes:[],
            searchResult: null,
            pageSize: 10,
            pageIndex: 1,
        }
    }


    componentDidMount = () => {
        this.FillCurrencyUse();
        this.Search(1, true);
    }

    onChange = (checkedValues) => {

        this.setState({TransactionTypes: checkedValues});
    }

    handleChange = (value) => {
        
        this.setState({CurrencyUseIds: value})
    }

    FillCurrencyUse = () => {
        httpCaller.Sana.GetCurrencyUses((data)=>{
            this.setState({children: data})}, (err) => {
            }, false);
    }

    handleSearchButtonClick = () => {
        this.Search(1, true);
    }

    handlePageChange = (page) => {
        this.Search(page, true);
    };

    Search = (pageIdx, isSOCLog) => {
        this.setState({pageIndex: pageIdx});
        var data = this.prepareData(pageIdx);
        httpCaller.Sana.SearchCurrencyUse(data,(result)=>{
            this.setState({searchResult: result});
        },()=>{},isSOCLog)
    }

    prepareData = (pageIdx) => {
        return {
            currencyUseIds: this.state.CurrencyUseIds.join(),
            ValidTransactionAndPaymentTypes: this.state.TransactionTypes.join(),
            pageIndex: parseInt(pageIdx),
            pageSize: parseInt(this.state.pageSize),
        }
    }

    render(){
        const columns = [{
            title:'کد سرفصل',
            dataIndex:'id',
        },
        {
            title:'عنوان سرفصل',
            dataIndex:'title',
        },
        {
            title:' شماره تعرفه',
            dataIndex:'tarefeCode',
        },
        {
            title:'شامل محدودیت خرید حداکثر یک سرفصل در سال',
            dataIndex:'hasLimitedInBuyCount',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        
        {
            title:'سقف مجاز در سال (یورو)',
            dataIndex:'allowedCeiling',
            render: (text) => (
                numeral(text).format(0,0)
            )
        },
        {
            title:'خرید نقدی',
            dataIndex:'buyCash',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        {
            title:'فروش نقدی',
            dataIndex:'sellCash',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        {
            title:'خرید حواله',
            dataIndex:'buyHavaleh',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        {
            title:'فروش حواله',
            dataIndex:'sellHavaleh',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        {
            title:'استعلام از ناخدا',
            dataIndex:'requiredCert',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        }];

        const FormItem = Form.Item;

        const plainOptions = [
            {label: 'خرید نقدی', value: 1},
            {label: 'فروش نقدی', value: 2},
            {label: 'خرید حواله', value: 4},
            {label: 'فروش حواله', value: 8}];

        const CheckboxGroup = Checkbox.Group;
        return(
            <Row>
                <Col md={24} xs={24}>
                    <Card>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="سرفصل"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="لطفا انتخاب کنید"
                                        onChange={this.handleChange}
                                    >
                                    {
                                        this.state.children.map(child => <Select.Option key={child.id}>{child.title}</Select.Option>)
                                    }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <CheckboxGroup options={plainOptions} onChange={this.onChange}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={24}>
                                <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                    جستجو
                                </Button>
                                <Button type="primary" icon="add" htmlType="button" onClick={() => this.props.history.push('/NewCurrencyUse')}>
                                    ایجاد سرفصل جدید
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                    {this.state.searchResult && (<Card>
                        <Table className="gx-table-responsive withPointer"
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.searchResult.items}
                            bordered
                            expandedRowRender={(row)=><CurrencyUseDetail action = {this.Search} currencyUseId = {row.id}></CurrencyUseDetail>}
                            expandRowByClick = {true}
                            pagination={{
                                total: this.state.searchResult.totalCount,
                                pageSize: this.state.searchResult.pageSize,
                                current: this.state.searchResult.pageIndex,
                                onChange: (index) =>  this.handlePageChange(index)
                            }}
                        />
                    </Card>)}
                </Col>
            </Row>
        )
    }
}

export default CurrencyUseList

import React, { Component } from 'react';
import { Row, Col, Alert, Input, Button, Card, Table, data, Select, option, Switch } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../services/httpService';
//import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
//import ExchangeDetail from './/ExchangeDetail';
// import NotFound from '../Not-Found';
import { HasPermission } from '../../services/authorization';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


class Calendar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      year: 1400,

      month: 2,
      list: [],
      columns: [],

    }
  }

  componentDidMount() {
    //str.substring(0, 4) + "\\"+ str.substring(4, 6) + "\\"+ str.substring(6, 8)
    this.setState({
      columns: [{
        title: 'روز کاری',
        dataIndex: 'date1',
        render: (text) => (
          <span style={{ fontSize: '15px' }}>{text.substring(0, 4) + "/" + text.substring(4, 6) + "/" + text.substring(6, 8)}</span>)
      }, {
        title: ' روز هفته',
        dataIndex: 'weekDay',
      }, {
        title: 'تعطیلی',
        dataIndex: 'holiday',
        render: (text, record) => (<Switch checked={record.holiday} onChange={(e) => this.handelChange(record, e)} />),
        // render: (text) => ( 
        //   <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
      }, {
        title: 'توضیحات',
        dataIndex: 'Dsc',
      }
      ]
    });

  }

  componentWillMount() {
    //window.location.href
    this.handledata();
  }
  handelChange = (record, e) => {
    // console.log( "handelClick e:" ,a);
    // console.log( "handelClick e:" ,e);

    const successHandler = (result) => {
      this.setState({ list: result })
    };
    const rejectedHandler = (ar) => {
    };
    console.log("cal server")
    httpCaller.Setting.ChangeHoliday(record.date1, e, successHandler, rejectedHandler);
  }

  handleChangeYear = (e) => {
    console.log("handleChangeYear e:", e)
    this.setState({ year: e });
  }
  handleChangeMonth = (e) => {
    this.setState({ month: e });
  }

  handledata = () => {
    const successHandler = (result) => {
      this.setState({ list: result })
    };
    const rejectedHandler = (ar) => {
    };
    httpCaller.Setting.GetCalendar(this.state.year, this.state.month, successHandler, rejectedHandler);
  }

  handleSearchInputKeyUp = (e) => {
    if (e.key === "Enter") {
      this.handledata();
    }
  }

  // handelEditMode = (e) => {
  // console.log("handelEditMode e:",e)
  // }

  render() {
    if (!HasPermission("SettingManagement_Calendar")) return null;

    return (
      <React.Fragment>

        <Row justify="start">
          <Col xxl={1} xl={3} lg={4} md={4} sm={6} xs={24}>
            <label>سال:</label>
          </Col>
          <Col xxl={4} xl={6} lg={8} md={16} sm={16} xs={24}>
            <Select defaultValue="1400" onChange={this.handleChangeYear} style={{ width: 120 }} onKeyUp={this.handleSearchInputKeyUp} >
              <Select.Option value="1398">1398</Select.Option>
              <Select.Option value="1399">1399</Select.Option>
              <Select.Option value="1400">1400</Select.Option>
              <Select.Option value="1401">1401</Select.Option>
            </Select>

          </Col>
          <Col xxl={1} xl={3} lg={4} md={4} sm={6} xs={24}>
            <label>ماه:</label>
          </Col>
          <Col xxl={4} xl={6} lg={8} md={16} sm={16} xs={24}>
            <Select defaultValue="2" onChange={this.handleChangeMonth} style={{ width: 120 }} onKeyUp={this.handleSearchInputKeyUp} >
              <Select.Option value="1">فروردین</Select.Option>
              <Select.Option value="2">اردیبهشت</Select.Option>
              <Select.Option value="3">خرداد</Select.Option>
              <Select.Option value="4">تیر</Select.Option>
              <Select.Option value="5">مرداد</Select.Option>
              <Select.Option value="6">شهریور</Select.Option>
              <Select.Option value="7">مهر</Select.Option>
              <Select.Option value="8">آبان</Select.Option>
              <Select.Option value="9">آذر</Select.Option>
              <Select.Option value="10">دی</Select.Option>
              <Select.Option value="11">بهمن</Select.Option>
              <Select.Option value="12">اسفند</Select.Option>
            </Select>

          </Col>
          <Col>
            <Button type="primary" icon="search" htmlType="button" onClick={() => this.handledata()}>
              جستجو
            </Button>
          </Col>
        </Row>

        <Row >
          <Col span={24}>
            <Card>
              <Table className="gx-table-responsive withPointer"
                // onRow={(record) => {
                //   return {
                //     onClick : () => {this.handelEditMode(record.day)}
                //   };
                // }}
                stripped
                columns={this.state.columns}
                dataSource={this.state.list}
                bordered
                pagination={false}
                title={() => 'تقویم روزهای کاری'}
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default withRouter(Calendar)



import React,{Component} from 'react';
import { Card, Row, Col, Form, Divider, Select, Button, Table, TreeSelect, Input, Alert } from 'antd';
import MaskedInput from 'antd-mask-input';
import httpCaller from '../../services/httpService';
import NumberFormat from 'react-number-format';
import moment from 'moment-jalaali'
import numeral from 'numeral'
import { FileExcelTwoTone, SearchOutlined } from '@ant-design/icons';
import { trimStart } from "lodash";
import { CheckPermissionHOC } from '../../services/authorization';
//import { DatePicker } from "jalali-react-datepicker";



class CoinDealing extends Component{
    constructor(props){
        super(props);
        this.state={
            defaultFromDate:'',
            defaultToDate:'',
            fromDate:'',
            toDate:'',
            NId:'',
            trackingCode:'',
            children:[],
            srchResult: { items: [] },
            pageSize: 10,
            pageIndex: 1,
        }
    }

    componentDidMount() {
        var nd = new Date();
        var to = moment.jConvert.toJalaali(nd.getFullYear(), nd.getMonth() + 1 , nd.getDate());
        to = to.jy+'/'+ ('0' + to.jm).slice(-2)+'/'+('0' + to.jd).slice(-2)
        this.setState({toDate: to});

        var from = moment.jConvert.toJalaali(nd.getFullYear(), nd.getMonth() + 1 , nd.getDate()-7);
        from = from.jy+'/'+ ('0' + from.jm).slice(-2)+'/'+('0' + from.jd).slice(-2)
        this.setState({fromDate: from});
        
        this.InitiateData(from, to);

        httpCaller.Sarafi.GetAllSarafies("", (data) => {
            this.setState({ children: data })
        }, (err) => { }, false);

        
        
    }

    InitiateData = (from, to) => {
        
        var param = {
            pageIndex: 1,
            fromDate: from,
            toDate: to,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
            nationalId: this.state.NId,
            trackingCode:this.state.trackingCode,
        };
        console.log("INSIDE",param)
        httpCaller.CoinDealing.GetCoinDealings(param,(res)=>
        {
            console.log(param)
            this.setState({srchResult:res});
        },(err)=>{}, false)

    }

    handleTreeSelect = (value) => {
        this.setState({ selectedSarafies: value })
    }

    handleSearchButtonClick = () => {
        this.fetchData(1);
    }

    handlePageChange = (page) => {
        this.fetchData(page);
    };

    fetchData = (pageIndex) => {
        var param = {
            pageIndex: pageIndex,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
            nationalId: this.state.NId,
            trackingCode:this.state.trackingCode,
        };
        httpCaller.CoinDealing.GetCoinDealings(param,(res)=>
        {
            console.log(res)
            this.setState({srchResult:res});
        },(err)=>{})
    }

    handleFromDate = (e) => {
        this.setState({ fromDate: e.target.value })
    }

    handleToDate = (e) => {
        this.setState({ toDate: e.target.value })
    }

    handleSrchTree = (val) => {
        httpCaller.Sarafi.GetAllSarafies(val, (data) => {
            this.setState({ children: data })
        }, (err) => { }, false);
    }

    handleNIdChange = (e) => {
        this.setState({NId:e.target.value});
    }
    
    handleTrackingCodeChange = (e) => {
        this.setState({trackingCode:e.target.value});
    }
    handleExportExcel = () => {
        var param = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
            nationalId: this.state.NId,
            trackingCode:this.state.trackingCode,
        };
        var nd = new Date();
        var jt = moment.jConvert.toJalaali(nd.getFullYear(), nd.getMonth() + 1, nd.getDate());
        var fileName = `CoinDealing_${jt.jy}${('0'+jt.jm).slice(-2)}${('0'+jt.jd).slice(-2)}.xls`
        httpCaller.CoinDealing.GetCoinDealingsExcel(fileName, param);
    }

    setRowClassName = (record) => {
        return record.statusStr !== 'معتبر' ? 'Ebtali' : '';
    }

    render(){
        const FormItem = Form.Item;

        const columns =
            [{
                title: 'کد  صرافی',
                dataIndex: 'sarId',
                width: '100px',
            }
            , {
                title: 'نام صرافی',
                dataIndex: 'sarName',
                width: '200px',
            }
            , {
                title: 'کد پیگیری',
                dataIndex: 'trackingCode',
                width: '150px',
            }
            , {
                title: 'کد ملی/شناسه ملی مشتری',
                dataIndex: 'customer.nationalCode',
                width: '200px', 
            }
            , {
                title: 'نام مشتری/نام شرکت',
                dataIndex: 'customer.firstName',
                width: '200px',
               
            }
            , {
                title: 'شماره همراه مشتری/نماینده مشتری',
                dataIndex: 'mobileNumber',
                width: '200px',             
            }
            , {
                title: 'وضعیت معامله',
                dataIndex: 'statusStr',
                width: '150px',
            }
            , {
                title: 'تاریخ معامله',
                dataIndex: 'persianDealingDate',
                width: '150px',             
            }
            , {
                title: 'کاربر ثبت کننده معامله',
                dataIndex: 'userName',               
                width: '150px',
            }
            , {
                title: 'نوع تراکنش',
                dataIndex: 'transTypeStr',
                width: '100px',
            }
            , {
                title: ' اقلام معامله',
                children: [
                    {
                        title: 'تمام سکه',
                        children:[
                            {
                                title:'تعداد',
                                dataIndex: 'totalCoins.countCoin'
                            }
                            ,
                            {
                                title:'نرخ(ریال)',
                                dataIndex: 'totalCoins.rateCoin',
                                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                            }
                        ]
                    },
                    {
                        title: 'نیم سکه',
                        children:[
                            {
                                title:'تعداد',
                                dataIndex: 'totalCoins.countCoinHalf'
                            }
                            ,
                            {
                                title:'نرخ(ریال)',
                                dataIndex: 'totalCoins.rateCoinHalf',
                                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                            }
                        ]
                    },
                    {
                        title: 'ربع سکه',
                        children:[
                            {
                                title:'تعداد',
                                dataIndex: 'totalCoins.countCoinQuarter'
                            }
                            ,
                            {
                                title:'نرخ(ریال)',
                                dataIndex: 'totalCoins.rateCoinQuarter',
                                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                            }
                        ]
                    },
                    {
                        title: 'سکه گرمی',
                        children:[
                            {
                                title:'تعداد',
                                dataIndex: 'totalCoins.countCoinGram'
                            }
                            ,
                            {
                                title:'نرخ(ریال)',
                                dataIndex: 'totalCoins.rateCoinGram',
                                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                            }
                        ]
                    },
                    {
                        title: 'سکه بهارآزادی',
                        children:[
                            {
                                title:'تعداد',
                                dataIndex: 'totalCoins.countCoinBahar'
                            }
                            ,
                            {
                                title:'نرخ(ریال)',
                                dataIndex: 'totalCoins.rateCoinBahar',
                                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                            }
                        ]
                    },
                ],
            },
        ];



        return (
            <React.Fragment>
                <Row >
                    <Col sm={11}>
                        <div style={{ fontSize: '1.6rem', fontWeight: 'Bold' }}>مشاهده معاملات سکه ثبت شده در سنا</div>
                    </Col>
                </Row>
                <Divider style={{ backgroundColor: '#c5c5c5', height: '2px' }}></Divider>
                <Card className="gx-card">

                    <Row>
                        <Col md={8} sm={24}>
                            <FormItem
                                label="از تاریخ"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <MaskedInput mask="1111/11/11" id="fromDate" name="fromDate" style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                                    placeholder={this.state.defaultFromDate} value={this.state.fromDate} onChange={this.handleFromDate} />
                                    {/* <DatePicker timePicker={false} onClickSubmitButton={this.handleFromDate} className='ant-input jalaliDatePicker'/> */}
                            </FormItem>
                        </Col>
                        <Col md={9} sm={24}>
                            <FormItem
                                label=" تا تاریخ"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <MaskedInput mask="1111/11/11" id="toDate" name="toDate" style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                                    placeholder={this.state.defaultToDate} value={this.state.toDate} onChange={this.handleToDate} />
                                    {/* <DatePicker timePicker={false} onClickSubmitButton={this.handleToDate} className='ant-input jalaliDatePicker'/> */}
                            </FormItem>
                        </Col>
                        <Col md={7} sm={24}>
                            <FormItem
                                label="کد پیگیری"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                           <Input  value={this.state.trackingCode} onChange={this.handleTrackingCodeChange} />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} sm={24}>
                            <FormItem
                                label="صرافی"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <TreeSelect
                                    showSearch
                                    allowClear
                                    multiple
                                    treeNodeFilterProp='title'
                                    treeCheckable
                                    dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                    placeholder="لطفا انتخاب کنید"
                                    style={{ width: "100%" }}
                                    onChange ={this.handleTreeSelect}                                    
                                >
                                    {
                                        this.state.children.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                    }

                                </TreeSelect>
       
                            </FormItem>
                        </Col>
                        <Col md={9} sm={24}>
                            <FormItem
                                label="کد/شناسه ملی مشتری"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <Input  value={this.state.NId} onChange={this.handleNIdChange} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={24}>
                            <Button type="primary" htmlType="button" onClick={() => this.handleSearchButtonClick()}>
                                جستجو
                            </Button>
                            <Button htmlType="button" type="default" style={{ borderColor: "green", color: "green" }} onClick={() => this.handleExportExcel()}
                                icon={<FileExcelTwoTone twoToneColor="#52c41a" />}>
                                انتقال به فایل اکسل
                            </Button>
                        </Col>
                    </Row>
   
                </Card>
                <Card>

                    <br />
                    <Table className="gx-table-responsive"
                        columns={columns}
                        dataSource={this.state.srchResult.items}
                        bordered
                        expandRowByClick={false}
                        rowKey="sanaTrackingCode"
                        rowClassName={this.setRowClassName}
                        pagination={{
                            total: this.state.srchResult.totalCount,
                            pageSize: this.state.srchResult.pageSize,
                            current: this.state.srchResult.pageIndex,
                            onChange: (index) => this.handlePageChange(index)
                        }}
                    />
                </Card>
            </React.Fragment>
        )
    }
}

export default CheckPermissionHOC(CoinDealing, 'CoinDealing_View');

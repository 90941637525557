import React,{Component} from 'react';
import {withRouter} from 'react-router-dom';
import httpCaller from '../../../services/httpService';
import UnSelectedOffer from './UnSelectedOffer';
import {HasPermission} from '../../../services/authorization';
import {Card,Table,Button} from 'antd'
import {css} from '@emotion/core'
import {CheckOutlined} from '@ant-design/icons'

class OtherOffersList extends Component{

    constructor(props)
    {
        super(props);
        this.state = {
            RequestId: this.props.reqId,
            OfferId: this.props.offerId,
            data : [],
        }
    }

    componentDidMount = () => {
        if (!HasPermission("RequestManagement_ViewDetail")) return null;
        httpCaller.NimaImport.GetOffers(parseInt(this.state.RequestId), (result) => {
                this.setState({data: result})
                if (result.length > 0)
                {
                    this.props.getOtherDataProp('true');
                    var str = this.props.searchType;
                    if (str.includes('offer'))
                    {
                        var searchedOfferId = this.props.offerId;
                         result.map(item => {
                             var code = item.offerId
                             if(code.toString() === searchedOfferId.toString()){
                                 this.props.SetActive('2'); 
                             }                               
                         })
                    }
                }
                else
                {
                    this.props.getOtherDataProp('false');
                }
            }, () => {
        });
    }

    ReturnBack = () => {
        this.props.history.push(`/nima/import`);
    }

    render(){

        const columns =  [
            // {
            //     title:"",
            //     dataIndex:'',
            //     render: (text, row)=> (row.offerId == this.state.offerId ? row.style.backgroundColor='gold' : ''),
            // },
            {
                title: 'کد پیشنهاد',
                dataIndex: 'offerId',
            }, {
                title: 'تاریخ پیشنهاد',
                dataIndex: 'offerRegisterDateStr',
            }, {
                title: 'مهلت اعتبار پیشنهاد',
                dataIndex: 'offerValidityDateStr',
            }, {
                title: 'قیمت واحد ارز (ریال)',
                dataIndex: 'currencyUnitPrice',
            }, {
                title: 'قیمت کل (ریال)',
                dataIndex: 'totalPrice',
            }]


        const selectedOffer = css({
            backgroundColor: 'gold'
        })

        const tableCSS = css({
            margin: '40px 120px',
            backgroundColor: 'white',
            '& table': {
              borderCollapse: 'collapse'
            },
            '& thead > tr > th': {
              backgroundColor: 'darkblue',
              color: 'white',
            },
            '& thead > tr': {
              borderWidth: '2px',
              borderColor: 'yellow',
              borderStyle: 'solid'
            }
          });

        return(
            <Card>
            <Table
                rowKey="offerId"
                className="gx-table-responsive withPointer"
                rowClassName={(row, index) => parseInt(row.offerId).toString().trim() === parseInt(this.props.offerId).toString().trim() ? "desiredEntity" : ''}
                columns={columns}
                dataSource={this.state.data}
                bordered
                pagination= {false}
                expandedRowRender={(row)=><UnSelectedOffer CurrentOfferId = {row.offerId}></UnSelectedOffer>}
                expandRowByClick = {true}
                defaultExpandedRowKeys ={this.props.offerId}
            >
            </Table>
            <br/>
        </Card>
           
        )
    }
}

export default withRouter(OtherOffersList);
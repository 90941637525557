import React, {Component} from 'react';
import {Radio, Table, Card, Row, Col, Divider} from "antd";
import Widget from "components/Widget/index";
import IconWithTextCard from "components/dashboard/IconWithTextCard";
import { PieChart, Pie, Cell, Tooltip,ResponsiveContainer,LabelList,Legend, Label } from 'recharts';
import numeral from 'numeral';
import httpService from 'services/httpService';


class RequestDashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentData:{
                loader: false,
                RequestCount: 0,
                RequestEuroAmount: 0,
                OfferCount: 0,
                OfferEuroAmount: 0
            },
            offerData: [],
            offerStatusData: [],
        }
    }

    componentDidMount = () => {
        
        this.LoadCurrentData();
        this.LoadOfferStatusData(0);
        setTimeout(() => {
            this.LoadOfferData(0);
        }, 1000);
    }

    LoadCurrentData = () => {
        httpService.Dashboard.GetDashboardImportDaily((data)=> {
            this.setState({
                currentData:{
                    RequestCount : data.totalRequestCount,
                    RequestEuroAmount : data.totalRequestAmount,
                    OfferCount : data.totalOfferCount,
                    OfferEuroAmount : data.totalOfferAmount
                }
            })
        })
    }

    LoadOfferStatusData = (year)=>{
        httpService.Dashboard.GetDashboardOfferByStatus({year},(data)=>{
            let result=[];
            result.push({status:'مختومه', count:data.closedCount, amount:data.closedAmount})
            result.push({status:'منتظر تأييد نهايی بازرگان', count:data.waitingToFinalConfirmCount, amount:data.waitingToFinalConfirmAmount})
            result.push({status:'منتظر تایید حواله', count:data.waitingToApproveTransferCount, amount:data.waitingToApproveTransferAmount})
            result.push({status:'منتظر صدور حواله', count:data.waitingToTransferExchangeCount, amount:data.waitingToTransferExchangeAmount})
            result.push({status:'منتظر پرداخت ریال', count:data.waitingToPayRialsCount, amount:data.waitingToPayRialsAmount})
            result.push({status:'منتظر تایید پیشنهاد', count:data.readyToConfirmCount, amount:data.readyToConfirmAmount})
            result.push({status:'انصراف متقاضی', count:data.cancelByApplicantCount, amount:data.cancelByApplicantAmount})
            result.push({status:'انصراف صراف', count:data.cancelBySarafCount, amount:data.cancelBySarafAmount})
            result.push({status:'منتظر انتخاب متقاضی', count:data.waitingToSelectCount, amount:data.waitingToSelectAmount})
            result.push({status:'لغو شده', count:data.cancelledCount, amount:data.cancelledAmount})
            result.push({status:'لغو شده توسط درخواست', count:data.cancelledByRequestCount, amount:data.cancelledByRequestAmount})
            result.push({status:'تعلیق پیشنهاد', count:data.otherSelectedCount, amount:data.otherSelectedAmount})
            result.push({status:'پیشنهاد رد شده', count:data.rejectedCount, amount:data.rejectedAmount})
            result.push({status:'منتظر انتخاب متقاضی(منقضی شده)', count:data.waitingToSelectExpiredCount, amount:data.waitingToSelectExpiredAmount})
            
            this.setState({ offerStatusData:result})
        });
    }

    LoadOfferData = (year) => {
        httpService.Dashboard.GetDashboardOfferTotal({year}, (data) => {
            let result = [];
            result.push({name:'پیشنهادات مختومه', count:data.offerInClosedCount, amount:data.offerInClosedAmount})
            result.push({name:'پیشنهادات در جریان', count:data.offerInDealingCount, amount:data.offerInDealingAmount})
            result.push({name:'پیشنهادات لغو شده', count:data.offerInCancelledCount, amount:data.offerInCancelledAmount})

            this.setState({ offerData:result})
        })
    }

    handleFilterChange = (type,e) => {
        let year= e.target.value;
        if(type == 0)
        {
            this.LoadOfferStatusData(year);
        }
        else
        {
            this.LoadOfferData(year)
        }
    }
    
    render(){
        const {loader, news} = this.state;
        const columns = [
            {
                title: "وضعیت پیشنهاد",
                dataIndex: "status"
            },
            {
                title: "تعداد",
                dataIndex: "count",
                render: (text, row, index) => numeral(text).format('0,0')
            },
            {
                title: "مبلغ به یورو",
                dataIndex: "amount",
                render: (text, row, index) => numeral(text).format('0,0')
            },
        ]

        const IconWithTextCardSubTitle = (
            <React.Fragment>
                <Row>
                    <Col sm={12}> تعداد درخواست ها: {this.state.currentData.RequestCount}</Col>
                    <Col sm={12}> مبلغ درخواست ها: {numeral(this.state.currentData.RequestEuroAmount).format('0,0')} یورو</Col>
                </Row>
                <Row>
                    <Col sm={12}>تعداد پیشنهاد ها: {this.state.currentData.OfferCount}</Col>
                    <Col sm={12}>مبلغ پیشنهاد ها: {numeral(this.state.currentData.OfferEuroAmount).format('0,0')} یورو</Col>
                </Row>
            </React.Fragment>
        )

        const COLORS = ['#5797fc', '#FA8C16', '#f5222d', '#d9d9d9'];
        
        const RADIAN = Math.PI / 180;

        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
          }) => {
            console.log("handling label?");
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
          
            return (
              <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(2)}%`}
              </text>
            );
          };
        
        const CustomTooltip = ({ active, payload, label }) => {
            if (active) {
              return (
                <div className="custom-tooltip">
                  <p className="label">{`${numeral(payload[0].value).format('0,0')} :تعداد`}</p>
                  <p className="label">{`${numeral(payload[0].payload.payload.amount).format('0,0')} :مبلغ به یورو`}</p>
                </div>
              );
            }
          
            return null;
          };

        return (
            <React.Fragment>
                <Card>
                    <IconWithTextCard cardColor="orange" icon="diamond" title="اطلاعات امروز" subTitle={IconWithTextCardSubTitle} />
                    <Widget>
                        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
                            <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2">وضعیت پیشنهادها</h2>
                            <div className="gx-mx-sm-2">
                                <Radio.Group className="gx-radio-group-link gx-radio-group-link-news" defaultValue={0}
                                        onChange={(e)=> this.handleFilterChange(0,e)}>
                                    <Radio.Button value={0} className="gx-mb-1">همه</Radio.Button>
                                    <Radio.Button value={1400} className="gx-mb-1">1400</Radio.Button>
                                    <Radio.Button value={1399} className="gx-mb-1">1399</Radio.Button>
                                    <Radio.Button value={1398} className="gx-mb-1">1398</Radio.Button>
                                    <Radio.Button value={1397} className="gx-mb-1">1397</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                        <Table
                            className="gx-table-no-bordered"
                            columns={columns}
                            dataSource={this.state.offerStatusData}
                            pagination= {false}
                        />
                    </Widget>
                    <Widget>
                        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
                                <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2"></h2>
                                <div className="gx-mx-sm-2">
                                <Radio.Group className="gx-radio-group-link gx-radio-group-link-news" defaultValue={0}
                                     onChange={(e)=> this.handleFilterChange(1,e)}>
                                    <Radio.Button value={0} className="gx-mb-1">همه</Radio.Button>
                                    <Radio.Button value={1400} className="gx-mb-1">1400</Radio.Button>
                                    <Radio.Button value={1399} className="gx-mb-1">1399</Radio.Button>
                                    <Radio.Button value={1398} className="gx-mb-1">1398</Radio.Button>
                                    <Radio.Button value={1397} className="gx-mb-1">1397</Radio.Button>
                                </Radio.Group>
                                </div>
                               
                        </div>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Tooltip content={CustomTooltip} />
                                <Legend paylodUniqBy />
                                <Pie
                                data={this.state.offerData} 
                                dataKey="count"
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                fill="#8884d8"
                                label={renderCustomizedLabel}
                                >
                                <LabelList dataKey="value" position="inside" />
                                {
                                    this.state.offerData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Widget>
                </Card>
            </React.Fragment>
        );
    }
}

export default RequestDashboard
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Table, Button, Modal, Row, Col } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
// import NotFound from '../Not-Found';
// import Modal from 'react-bootstrap/Modal';

class SaleInNimaModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            list:[],
        }
    }
    HandelHideModal = () => { this.setState({ modalShow: false }); }
    HandelShowModal = () => {
        this.setState({ modalShow: true });
        this.handledata();
        this.populateColumns();
    }

    populateColumns = () => {
        this.setState({columns : [{
            title: 'ارز / مرحله معامله',
            dataIndex: 'currencyName',
          }, {
            title: 'منتظر پرداخت',
            dataIndex: 'montazrePardakht',
          }, {
            title: 'آماده صدور',
            dataIndex: 'amadehSdorehHavale',
          }, {
            title: 'آماده تایید',
            dataIndex: 'amadehTaydeHavale',
          }, {
            title: 'منتظر تایید نهایی',
            dataIndex: 'montzerTayedeNahaii',
          }, {
            title: 'مختومه',
            dataIndex: 'makhtomeh',
          },{
            title: 'انصراف متقاضی',
            dataIndex: 'enserafeMotagazi',
          },{
            title: 'انصراف صراف',
            dataIndex: 'enserafeSarf',
          },{
            title: 'مجموع',
            dataIndex: 'totalAmount',
          },{
            title: 'مجموع به یورو',
            dataIndex: 'totalEuro',
          }]});
    }

    handledata = () => {
        const successHandler = (result) => {
            var items = [...result];
            this.setState({ list: items });
        };
        const rejectedHandler = (ar) => {
        };
        httpCaller.Sarafi.GetNimaSarafPositionSell(this.props.exchangeId, this.props.fromDate, this.props.toDate, successHandler, rejectedHandler);
    }
    

    render() {

        return (

                <Row>
                    <Col style={{width:"300px"}}>
                        <a onClick={this.HandelShowModal} style={{paddingRight:"10px"}}>مشاهده جزئیات</a>
                <Modal
                    width="200"
                    title="میزان فروش در نیما به واردکنندگان"
                    visible={this.state.modalShow}
                    onCancel={this.HandelHideModal}
                    footer={[
                      <Button key="back" onClick={this.HandelHideModal}>بازگشت</Button>,
                    ]}
                    >
                    <Row>
                        <Col span={24}>
                            <Table className="gx-table-responsive"
                                columns={this.state.columns}
                                dataSource={this.state.list}
                                bordered
                            />
                        </Col>
                    </Row>
                    
                </Modal>
                </Col>
                </Row>
        );
    }
}

export default SaleInNimaModal;
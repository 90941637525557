import React, {Component} from 'react';
import {Card, Row, Col, Checkbox, Input, Button, Form} from 'antd';
import httpCaller from '../../services/httpService';
import numeral from 'numeral';
import {HasPermission} from '../../services/authorization';
  
class CurrencyUseDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentId : this.props.currencyUseId,
            currencyUseInfo : {
                id: 0,
                title: '',
                buyCash: false,
                sellCash: false,
                buyHavaleh: false,
                sellHavaleh: false,
                isShownInUI: false,
                Required: '',
                allowedCeiling: '',
                isAdminAllowToEdit: false,
                validTransactionAndPaymentTypes: 0,
                requiredCert: false,
                hasLimitedInBuyCount:false,
                description:'',
                tarefeCode:''
            },
            isReadOnlyTarf:true,
        }
    }


    componentDidMount = () => {

        this.GetData(this.state.currentId, true);
    }

    GetData = (value, isSOCLog) => {
        httpCaller.Sana.GetCurrencyUseById(parseInt(value), (result) => {
            this.setState({currencyUseInfo: result});
            this.setState({isReadOnlyTarf:  result.tarefeCode == null ? false : true});
        }, (err) => { }, false, isSOCLog)
    }

    handleUpdateButtonClick = () => {
        var data = this.prepareData();
        httpCaller.Sana.UpdateCurrencyUse(data, () => {
            this.GetData(this.state.currentId, false);
            // this.props.action(1)
        }, () => {})
    }
   
    prepareData = () => {
        var transactions = 0;
        if (this.state.currencyUseInfo.buyCash) transactions = transactions + 1;
        if (this.state.currencyUseInfo.sellCash) transactions = transactions + 2;
        if (this.state.currencyUseInfo.buyHavaleh) transactions = transactions + 4;
        if (this.state.currencyUseInfo.sellHavaleh) transactions = transactions + 8;
        console.log(this.state.currencyUseInfo.allowedCeiling)
        return{
            id: this.state.currencyUseInfo.id,
            hasLimitedInBuyCount: this.state.currencyUseInfo.hasLimitedInBuyCount,
            allowedCeiling:parseFloat(this.state.currencyUseInfo.allowedCeiling=="" ? "-1" : this.state.currencyUseInfo.allowedCeiling ),
            isShownInUi: this.state.currencyUseInfo.isShownInUI,
            required: this.state.currencyUseInfo.required,
            title: this.state.currencyUseInfo.title,
            validTransactionAndPaymentTypes: transactions.toString(),
            requiredCert: this.state.currencyUseInfo.requiredCert,
            description: this.state.currencyUseInfo.description,
            tarefeCode:this.state.currencyUseInfo.tarefeCode
        }
    }

    handleCancelButtonClick = () => {
        this.GetData(this.state.currentId)
    }

    handelChangeTitle = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                title: e.target.value
            }
        })
    };

    handelChangeTarefeCode = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                tarefeCode: e.target.value
            }
        })
    };

    handelChangeEvidence = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                required: e.target.value
            }
        })
    }

    changeBuyCashStatus = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                buyCash: e.target.checked
            }
        })
    }

    calcTransactionTypes = (e) => {
        return(
        e.target.checked ? 
                    {...this.state.currencyUseInfo.validTransactionAndPaymentTypes} + e.target.value :
                    {...this.state.currencyUseInfo.validTransactionAndPaymentTypes} - e.target.value
                )
    }

    changeSellCashStatus = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                sellCash: e.target.checked
            }
        })
    }

    changeBuyChequeStatus = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                buyHavaleh: e.target.checked
            }
        })
    }

    changeSellChequeStatus = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                sellHavaleh: e.target.checked
            }
        })
    }

    changeValidDealStatus = (e) => {

        this.setState({TransactionTypes: e.target.checked})
    }

    changeShowInUI = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                isShownInUI: e.target.checked
            }
        })
    }

    handelChangeCeiling = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                allowedCeiling: e.target.value
            }
        })
    }
    changeRequiredCert = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                requiredCert: e.target.checked
            }
        })
    }
    changeHasLimitedInBuyCount = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                hasLimitedInBuyCount: e.target.checked
            }
        })
    }

    handleChangeDesc = (e) => {
        this.setState({
            currencyUseInfo:{
                ...this.state.currencyUseInfo, 
                description: e.target.value
            }
        })
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        let currencyUseUpdate = HasPermission("SanaManagement_Edit");
        return(
            <Row> 
                <Col md={24} xs={24}>
                    <Card className="gx-card">
                        <Row>
                            <Col span={4}>
                                <Input addonBefore="کد سرفصل" value={this.state.currencyUseInfo.id} readOnly/>
                            </Col>
                            <Col span={12}>
                                <Input addonBefore="عنوان سرفصل" value={this.state.currencyUseInfo.title} onChange={this.handelChangeTitle}/>
                            </Col>
                            <Col span={8}>
                             {/* <Input readOnly={this.state.isReadOnlyTarf} addonBefore=" شماره تعرفه" value={this.state.currencyUseInfo.tarefeCode} onChange={this.handelChangeTarefeCode}/> */}
                             <Input addonBefore=" شماره تعرفه" value={this.state.currencyUseInfo.tarefeCode} onChange={this.handelChangeTarefeCode}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={24}>
                                <Input  addonBefore="مدارک موردنیاز جهت ارائه" value={this.state.currencyUseInfo.required} onChange={this.handelChangeEvidence}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12} xs={24}>
                                <Input addonBefore="سقف مجاز در سال" addonAfter="یورو" value={this.state.currencyUseInfo.allowedCeiling} 
                                        onChange={this.handelChangeCeiling}/>
                            </Col>
                            <Col md={12} xs={24}>
                                <Checkbox checked = {this.state.currencyUseInfo.isShownInUI} onChange={this.changeShowInUI}>نمایش در لیست سرفصل های سنا</Checkbox>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col md={6} xs={12}>
                                <Checkbox value='1' checked={this.state.currencyUseInfo.buyCash} onChange={this.changeBuyCashStatus}>خرید نقدی</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox value='2' checked={this.state.currencyUseInfo.sellCash} onChange={this.changeSellCashStatus}>فروش نقدی</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox value='4' checked={this.state.currencyUseInfo.buyHavaleh} onChange={this.changeBuyChequeStatus}>خرید حواله</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox value='8' checked={this.state.currencyUseInfo.sellHavaleh} onChange={this.changeSellChequeStatus}>فروش حواله</Checkbox>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6} xs={12}>
                                <Checkbox checked={this.state.currencyUseInfo.requiredCert} onChange={this.changeRequiredCert}>استعلام از سامانه ناخدا</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox checked={this.state.currencyUseInfo.hasLimitedInBuyCount} onChange={this.changeHasLimitedInBuyCount}>شامل محدودیت خرید حداکثر یک سرفصل در سال</Checkbox>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={16} md={16} sm={16} xs={24}>
                                <Form.Item
                                label="توضیحات"
                                labelCol={{ xs: 24, lg: 4, md:4, xl: 4 }}
                                wrapperCol={{ xs: 24, lg: 16, md:12, xl: 12 }}
                            >
                                {getFieldDecorator('Description', {
                                initialValue: this.state.currencyUseInfo.description,
                                })(
                                    <Input.TextArea placeholder="توضیحات" style={{width:'100%'}} rows={4} onChange={this.handleChangeDesc} />
                                )}
                                
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        {this.state.currencyUseInfo.isAdminAllowToEdit && currencyUseUpdate && <Row>
                            <Col md={24} xs={24}>
                                <Button type="primary" htmlType="button" onClick={this.handleUpdateButtonClick}>بروزرسانی</Button>
                                <Button htmlType="button" onClick={this.handleCancelButtonClick}>انصراف</Button>
                            </Col>
                        </Row>}
                    </Card>
                </Col>
            </Row>
        )
    }
}
export default Form.create()(CurrencyUseDetail)

import React, { Component } from 'react';
import { Col, Row, Card } from 'antd';
import httpCaller from "../../../services/httpService";
import PaymentComponent from './PaymentComponent';
import { withRouter } from 'react-router-dom';
import DisplayFormData from '../../utility/DisplayFormData';
import SimpleFieldSet from '../../utility/SimpleFieldSet';
import {HasPermission} from '../../../services/authorization';
import numeral from 'numeral';


class UnSelectedoffer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            CurrentOffer: [],
            CurrentOfferId: this.props.CurrentOfferId,
            Request: '',
            hasPayment: 'none',
        }
    }

    componentDidMount = () => {
        httpCaller.NimaImport.GetOfferById(parseInt(this.state.CurrentOfferId), (result) => {
            this.setState({ CurrentOffer: result });
            this.setState({ Request: result.request });
        }, () => {
        }, false);
    }

    CanShowPayment = (val) => {
        this.setState({ hasPayment: val })
        return val;
    }
    
    render(){
        return(
            <React.Fragment>
                    <Row>
                        <Col md={16} xs={24}>
                            <DisplayFormData lable="نام صرافی" value={this.state.CurrentOffer.sarafiName} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد پیشنهاد" value={this.state.CurrentOffer.offerId} />
                        </Col>
                        <Col md={16} xs={24}>
                            <DisplayFormData lable="وضعیت پیشنهاد" value={this.state.CurrentOffer.offerStatusDesc} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ پیشنهاد" value={this.state.CurrentOffer.offerRegisterDateStr} />
                        </Col>
                        <Col md={16} xs={24}>
                            <DisplayFormData lable="مهلت اعتبار پیشنهاد" value={this.state.CurrentOffer.offerValidityDateStr} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="حداکثر زمان صدور حواله" value={this.state.CurrentOffer.transferDelayTime} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="آخرین تغییر" value={this.state.CurrentOffer.lastModifiedDateStr} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کاربر ثبت کننده" value={this.state.CurrentOffer.registeredBy} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={24} xs={24}>
                            <DisplayFormData lable="توضیحات" value={this.state.CurrentOffer.description} />
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="قیمت واحد ارز" value={numeral(this.state.CurrentOffer.currencyUnitPrice).format(0,0)} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کارمزد ارزی" value={numeral(this.state.CurrentOffer.currencyWage).format(0,0)} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کارمزد ریالی" value={numeral(this.state.CurrentOffer.irrwage).format(0,0)} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="قیمت کل" value={numeral(this.state.CurrentOffer.totalPrice).format(0,0)} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ صدور حواله" value={this.state.CurrentOffer.transferExchangeDateStr} />
                        </Col>
                       <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ تایید حواله" value={this.state.CurrentOffer.approveTransferDateStr} />
                        </Col>
                    
                        {(this.state.CurrentOffer.sanaTrackingCode != null && this.state.CurrentOffer.sanaTrackingCode != '') &&
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد پیگیری سنا" value={this.state.CurrentOffer.sanaTrackingCode} />
                        </Col>
                        }
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <SimpleFieldSet title='پرداختها' isCollapsible style={{display:this.state.hasPayment}}> 
                        <Row>
                            <Col span={24}>
                                <PaymentComponent currentEntity = "offer" entityId = {this.state.CurrentOfferId} showPayment = {this.CanShowPayment}></PaymentComponent>
                            </Col>
                        </Row>
                    </SimpleFieldSet>
            </React.Fragment>
        )
    }
}

export default withRouter(UnSelectedoffer)
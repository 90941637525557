import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link } from "react-router-dom";
import { Alert, Button, Card, Col, Switch, Row, Input, Radio, InputNumber } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService'
import { connect } from 'react-redux';
// import NotFound from '../Not-Found';
// import Switch from "react-switch";
import { HasPermission } from '../../../services/authorization';


class ExchangeTaahod extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props.exchangeId);
        this.state = {
            sarafi: { traceCD: "sdsd" },

            Mode: "view",
            taahodCeiling: 0,
            taahodKind: 3,
        };

        this.handleEdiButton = this.handleEdiButton.bind(this);
        this.handleSaveButton = this.handleSaveButton.bind(this);
    }

    handleChangeTaahodCeiling = (value) => {
        console.log("value:", value)
        console.log("value:", value)
        this.setState({ taahodCeiling: value });
    }
    handleChangeTaahodKind = (e) => {
        this.setState({ taahodKind: e.target.value });
    }

    handleEdiButton = (e) => {
        this.setState({ Mode: "edit" });
    }
    handleCancelButton = (e) => {
        this.setState({ Mode: "view" });
        this.handledata(false);
    }
    handleSaveButton = (e) => {
        this.setState({ Mode: "view" });

        httpCaller.Sarafi.updateTaahod({ ...this.state }, () => {
            this.props.history.push('/ExchangeDetail')
        }, () => { })
    }

    handledata = (val) => {
        const successHandler = (result) => {
            this.setState(
                {
                    sarafi: result,
                    exchangeId: this.props.exchangeId,
                    taahodCeiling: result.taahodCeiling,
                    taahodKind: result.taahodKind
                });
        };
        const rejectedHandler = (ar) => {
        };

        httpCaller.Sarafi.getSarafTahood(this.props.exchangeId, successHandler, rejectedHandler, val);
    }

    getButton = () => {
        if (!HasPermission("ExchangeManagement_TaahodCategory")) {
            return null
        };
        return (
            <Row>
                <Col lg={4} md={4} sm={12} xs={24}>
                    <Button type="primary" style={{ display: this.state.Mode === "edit" ? "block" : "none" }} onClick={this.handleSaveButton}>ثبت</Button>
                    <Button type="primary" style={{ display: this.state.Mode === "view" ? "block" : "none" }} onClick={this.handleEdiButton}>ویرایش تعهدات</Button>
                </Col>
                <Col lg={4} md={4} sm={12} xs={24}>
                    <Button type="primary" style={{ display: this.state.Mode === "edit" ? "block" : "none" }} onClick={this.handleCancelButton}>انصراف</Button>
                </Col>
            </Row>
        );
    }
    componentDidMount() {

        this.handledata(true);

    }


    render() {
        return (
            <Row>
                <Col span={24}>
                    <Card title="نوع کنترل سقف تعهد" style={{ paddingRight: "10px" }}>
                        
                            <Row >
                                <label>نوع تعهد: </label>
                                {/* <Switch onChange={this.handleChangeTaahodKind} disabled={this.state.Mode === "view" ? true : false} checked={this.state.taahodKind} /> */}
                                <Radio.Group onChange={this.handleChangeTaahodKind} value={this.state.taahodKind} disabled={this.state.Mode === "view" ? true : false} buttonStyle="solid" size={'large'} >
                                    <Radio.Button value={1}>بدون سقف</Radio.Button>
                                    <Radio.Button value={2}>سقف ثابت</Radio.Button>
                                    <Radio.Button value={3}>سقف محاسبه ای</Radio.Button>
                                </Radio.Group>
                            </Row>
                            {/* <Col lg={4} md={4} sm={8} xs={24}>
                            </Col> */}
                            <Row>         
                            <br />
                            </Row>
                            {this.state.taahodKind !== 2 ? "" :
                             <Row >
                       
                                    <label>سقف تعهد: </label>
                              
                                    <InputNumber onChange={this.handleChangeTaahodCeiling} readOnly={this.state.Mode === "view" ? true : false} value={this.state.taahodCeiling}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/(,*)/g, '')}
                                        style={{ width: '22%' }} />
                                        <span> ریال </span>
                                    
                            </Row>}
                        
                        <br />
                        <br />
                        {this.getButton()}
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ExchangeTaahod;

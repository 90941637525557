import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Alert, Col, Row, Button, Modal } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
// import NotFound from '../Not-Found';
// import Modal from 'react-bootstrap/Modal';

class HelpCalcBalanceModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            list: [],
        }
    }
    HandelHideModal = () => { this.setState({ modalShow: false }); }
    HandelShowModal = () => { this.setState({ modalShow: true });  }
    info = () => {
        Modal.info({
            width:"200",
            title:"راهنما",
            
          content: (
            
                    <Row>
                        <Col span={24}>
                        <p >
                        <strong>خرید در سامانه نیما :</strong>
                        شامل کلیه معاملات خرید ارز در سامانه نیما که در وضعیتی غیر از وضعیت «لغو شده» قرار دارند.
                
                    </p>
                    <br/>
                    <p >
                        <strong>فروش در سامانه نیما :</strong>
                        شامل کلیه معاملات فروش ارز در سامانه نیما که در وضعیت هایی غیر از وضعیت های «منتظر انتخاب متقاضی»، «تعلیق شده»، «آماده تایید پیشنهاد»،«پیشنهاد رد شده»، «لغو شده»، «لغو درخواست» قرار دارند.
                
                    </p>
                    <br/>
                    <p >
                                        <strong>خرید و فروش ارز در سامانه سنا :</strong>
                                        این قسمت مربوط به آمار خرید و فروش های شما با صرافی ها یا بانک هاست که شناسه ملی آن ها در رسید سنای معاملاتتان ثبت شده است.
                با توجه به این که کاربران سامانه سنا موظف هستند در کلیه معاملاتشان رسید سنا ثبت کنند مکانیزمی که جهت تطبیق رسیدهای سنای معاملات شبکه صرافی ها و بانک ها با یکدیگر در نظر گرفته شده است به صورت زیر است :
                1-	در صورتی که شما به صرافی یا بانکی ارز فروخته و رسید سنای آن را ثبت کرده باشید، صرافی یا بانک طرف مقابل هم باید رسید سنایی دقیقا به همان مبلغ و نوع ارز ثبت کرده باشد با این تفاوت که اگر شما فیلد «نوع عملیات» را "فروش" انتخاب کرده باشید طرف مقابل این فیلد را «خرید» انتخاب کرده باشد.
                تذکر : در صورتی که شما مثلا مبلغ 1000 دلار به صرافی یا بانکی فروخته و یک رسید سنا به همین مبلغ و نوع ارز ثبت کرده باشید ولی طرف مقابل این تراکنش را در قالب دو رسید سنای 500 دلاری ثبت کرده باشد، سامانه قادر به تشخیص انطباق این رسیدهای سنا به منظور محاسبه در تراز و پوزیشن نبوده و لازم است که رسیدسنا مطابق با ساختار مورد پذیرش سامانه ویرایش شود.
                
                    </p>
                    <br/>
                                    <p >
                                        <strong>خرید و فروش به بانک مرکزی :</strong>
                                        این قسمت مربوط به آمار خرید و فروش های شما با بانک مرکزی است که توسط اداره مربوطه در سامانه «سیب» (متعلق به بانک مرکزی) به ثبت رسیده باشد.
                
                    </p>
                    <br/>
                                    <p >
                                        <strong>تراز : </strong>
                                        حاصل تفاضل مجموع ارز خریداری شده از مجموع ارز فروخته شده شماست.
                
                    </p>
                    <br/>
                                    <p >
                                        <strong>درصد تراز : </strong>
                                        درصد تراز عبارت است از نسبت مجموع فروش ارز به مجموع خرید ارز
                    </p>
                        </Col>
                    </Row>
                    
                
          ),
          onOk() {
          },
        });
      }

    render() {
        
        return (
            <React.Fragment>
                <Row>
                    <Col  md={8} sm={12} xs={24}>
                        <Button shape="round" onClick={this.info} style={{backgroundColor:"lightBlue", borderColor:"blue", color:"#4345bb"}}>راهنمای نحوه محاسبه تراز و ساير جزئيات</Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default HelpCalcBalanceModal;
import React, { Component } from 'react';
import { Radio, Table, Card, Row, Col} from "antd";
import numeral from 'numeral'
import IconWithTextCard from "components/dashboard/IconWithTextCard";
import Widget from "components/Widget";
import { PieChart, Pie, Cell, Tooltip,ResponsiveContainer,LabelList,Legend } from 'recharts';
import httpService from 'services/httpService'

class SupplyDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            todayData: {
                supplyCount: 0,
                supplyEuroAmount: 0,
                demandCount: 0,
                demandEuroAmount: 0,
                dealingCount: 0,
                dealingEuroAmount: 0,
            },
            dealingData:[],
            dealingStatusData:[]
        }
    }
    componentDidMount(){

        this.LoadTodayData();
        this.LoadDealingStatusData(0);
        setTimeout(() => {
            this.LoadDealingData(0);
        }, 1000);
    }

    LoadTodayData = ()=>{
        httpService.Dashboard.GetTodayExportData((data)=>{
            this.setState({
                todayData:{
                    supplyCount : data.supplyCount,
                    supplyEuroAmount : data.supplyEuroAmount,
                    demandCount : data.demandCount,
                    demandEuroAmount : data.demandEuroAmount,
                    dealingCount : data.dealingCount,
                    dealingEuroAmount : data.dealingEuroAmount,   
                }
            })
        });
    }

    LoadDealingData = (year)=>{
        let a=[];
        httpService.Dashboard.GetDealingData({year},(data)=>{

            a.push({name:'معاملات مختومه', count:data.closedCount, amount:data.closedAmount})
            a.push({name:'معاملات در جریان', count:data.inProcessCount, amount:data.inProcessAmount})
            a.push({name:'معاملات لغو شده', count:data.canceledCount, amount:data.canceledAmount})

            this.setState({ dealingData:a})
        });
    }

    LoadDealingStatusData = (year)=>{
        httpService.Dashboard.GetDealingStatusData({year},(data)=>{
            let a=[];
            a.push({status:'مختومه', count:data.closedCount, amount:data.closedAmount})
            a.push({status:'منتظر تأیید عرضه کننده', count:data.supplierConfirmCount, amount:data.supplierConfirmAmount})
            a.push({status:'آماده تأیید صرافی', count:data.exchnageConfirmCount, amount:data.exchnageConfirmAmount})
            a.push({status:'آماده واریز ریال', count:data.depositCount, amount:data.depositAmount})
            a.push({status:'منتظر صدور حواله', count:data.waitChequeCount, amount:data.waitChequeAmount})
            a.push({status:'آماده معامله', count:data.openCount, amount:data.openAmount})
            a.push({status:'انصراف عرضه کننده از معامله', count:data.supplierCancelCount, amount:data.supplierCancelAmount})
            a.push({status:'انصراف صراف از معامله', count:data.exchangeCancelCount, amount:data.exchangeCancelAmount})
            a.push({status:'لغو شده', count:data.canceledCount, amount:data.canceledAmount})
            
            this.setState({ dealingStatusData:a})
        });
    }
    handleFilterChange = (type,e) => {
        let year= e.target.value;
        if(type == 0)
        {
            this.LoadDealingStatusData(year);
        }
        else
        {
            this.LoadDealingData(year)
        }
    }
    
    //handleFilterChange2
    render() {
        const columns = [
            {
                title: "وضعیت",
                dataIndex: "status"
            },
            {
                title: "تعداد",
                dataIndex: "count",
                render: (text, row, index) => numeral(text).format('0,0')
                
            },
            {
                title: "مبلغ به یورو",
                dataIndex: "amount",
                render: (text, row, index) => numeral(text).format('0,0')
            },
        ]

        const todayDate = (
            <React.Fragment>
                <Row>
                    <Col sm={12}> تعداد عرضه ها: {this.state.todayData.supplyCount}</Col>
                    <Col sm={12}> مبلغ عرضه ها: {numeral(this.state.todayData.supplyEuroAmount).format('0,0')} یورو</Col>
                </Row>
                <Row>
                    <Col sm={12}> تعداد تقاضا ها: {this.state.todayData.demandCount}</Col>
                    <Col sm={12}> مبلغ تقاضا ها: {numeral(this.state.todayData.demandEuroAmount).format('0,0')} یورو</Col>
                </Row>
                <Row>
                    <Col sm={12}> تعداد معاملات: {this.state.todayData.dealingCount}</Col>
                    <Col sm={12}> مبلغ معاملات: {numeral(this.state.todayData.dealingEuroAmount).format('0,0')} یورو</Col>
                </Row>
            </React.Fragment>

        )
        const COLORS = ['#5797fc', '#FA8C16', '#f5222d', '#d9d9d9'];

        const RADIAN = Math.PI / 180;

        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
          }) => {
            console.log("handling label????");
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
          
            return (
              <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(2)}%`}
              </text>
            );
          };
       
        const CustomTooltip = ({ active, payload, label }) => {
            if (active) {
              return (
                <div className="custom-tooltip">
                  <p className="label">{`${numeral(payload[0].payload.payload.count).format('0,0')} :تعداد`}</p>
                  <p className="label">{`${numeral(payload[0].payload.payload.amount).format('0,0')} :مبلغ به یورو`}</p>
                </div>
              );
            }
          
            return null;
          };

        return (
            <React.Fragment>
                <Card>
                    <IconWithTextCard cardColor="teal" icon="diamond" title="اطلاعات امروز" subTitle={todayDate} />
                    <Widget>
                        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
                            <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2">وضعیت معاملات</h2>
                            <div className="gx-mx-sm-2">
                                <Radio.Group className="gx-radio-group-link gx-radio-group-link-news" defaultValue={0}
                                    onChange={(e)=> this.handleFilterChange(0,e)}>
                                    <Radio.Button value={0} className="gx-mb-1">همه</Radio.Button>
                                    <Radio.Button value={1400} className="gx-mb-1">1400</Radio.Button>
                                    <Radio.Button value={1399} className="gx-mb-1">1399</Radio.Button>
                                    <Radio.Button value={1398} className="gx-mb-1">1398</Radio.Button>
                                    <Radio.Button value={1397} className="gx-mb-1">1397</Radio.Button>
                                </Radio.Group>
                            </div>
                           
                        </div>
                        <Table
                            className="gx-table-no-bordered"
                            columns={columns}
                            dataSource={this.state.dealingStatusData}
                            pagination={false}
                        ></Table>

                    </Widget>
                    <Widget>
                        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
                                <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2"></h2>
                                <div className="gx-mx-sm-2">
                                <Radio.Group className="gx-radio-group-link gx-radio-group-link-news" defaultValue={0}
                                     onChange={(e)=> this.handleFilterChange(1,e)}>
                                    <Radio.Button value={0} className="gx-mb-1">همه</Radio.Button>
                                    <Radio.Button value={1400} className="gx-mb-1">1400</Radio.Button>
                                    <Radio.Button value={1399} className="gx-mb-1">1399</Radio.Button>
                                    <Radio.Button value={1398} className="gx-mb-1">1398</Radio.Button>
                                    <Radio.Button value={1397} className="gx-mb-1">1397</Radio.Button>
                                </Radio.Group>
                                </div>
                        </div>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Tooltip content={CustomTooltip} />
                                <Legend paylodUniqBy />
                                <Pie
                                data={this.state.dealingData} 
                                dataKey="count"
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                fill="#8884d8"
                                label={renderCustomizedLabel}
                                >
                                <LabelList dataKey="value" position="inside" />
                                {
                                    this.state.dealingData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                                }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Widget>
                    
                </Card>
            </React.Fragment>

        );
    }
}


export default SupplyDashboard;
import React, { Component } from 'react';
import { Col, Row, Button, Modal, Card, Table } from 'antd';
import {NotificationManager} from 'react-notifications';
import PaymentComponent from './PaymentComponent';
import httpCaller from "../../../services/httpService";
import {withRouter} from 'react-router-dom';
import DisplayFormData from '../../utility/DisplayFormData';
import SimpleFieldSet from '../../utility/SimpleFieldSet';
import {HasPermission} from '../../../services/authorization';
import numeral from 'numeral';

class SelectedOffer extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.reqId)
        this.state = {
            RequestId: this.props.reqId,
            offerId: this.props.offerId,
            SelectedOffer: '',
            Request: '',
            OtherOffers: [],
            ShowActionBtn: 'none',
            ActionBtnCaption: '',
            CurrentOfferId: -1,
            setShow : false,
            show: false,
            desc: '',
            documents:[]
        }
    }

    LoadInfo = (val) => {
        
        httpCaller.NimaImport.GetSelectedOffer(parseInt(this.state.RequestId), (result) => {
            this.setState({ SelectedOffer: result })
            var id = this.state.SelectedOffer.offerId
            this.setState({ CurrentOfferId: id })
            this.getTransferDocuments(this.state.CurrentOfferId);
            if (id > 0) {
                this.props.getDataProp('true');
                if ([8,16,32,64].includes(result.offerStatus)) {
                    if (HasPermission("RequestManagement_CancelOffer"))
                    {
                        this.setState({ ShowActionBtn: 'block' })
                        this.setState({ ActionBtnCaption: 'انصراف از معامله' })
                    }
                }
                else if (result.offerStatus === 128) {
                    if (HasPermission("RequestManagement_ReturnFromMakhtoome"))
                    {
                        this.setState({ ShowActionBtn: 'block' })
                        this.setState({ ActionBtnCaption: 'بازگشت از مختومه' })
                    }
                } 
                else if (result.offerStatus === 512) {
                    if (HasPermission("RequestManagement_ConfirmApplicantCancel"))
                    {
                        this.setState({ ShowActionBtn: 'block' })
                        this.setState({ ActionBtnCaption: 'تایید/رد انصراف متقاضی' })
                    }
                }
                else
                {
                    this.setState({ ShowActionBtn: 'none' })
                }
                this.setState({ Request: result.request });
            }
            else {
                this.setState({ Request: '' })
                this.props.getDataProp('false');
            }
        }, () => {
        }, val);
    }

    componentDidMount = () => {
        this.LoadInfo(true);
    }

    CanShowPayment = (val) => {
        this.setState({setShow: val})
        return val;
    }

    openModal = () => {
       this.setState({show: true});
    }

    handleAction = () => {
        
        if(this.state.desc.trim() !== "")
        {
            if ([8,16,32,64].includes(this.state.SelectedOffer.offerStatus)) 
            {
                var param = {
                    offerId: parseInt(this.state.CurrentOfferId),
                    desc: this.state.desc
                };

                httpCaller.NimaImport.CancelOffer(param,() => {
                    NotificationManager.info(`عملیات درخواست انصراف با موفقیت انجام شد`);
                    this.LoadInfo(false);
                    this.handleClose();
                }, () => {

                })
            }
            else if (this.state.SelectedOffer.offerStatus === 512) 
            {
                var param = {
                    offerId: parseInt(this.state.CurrentOfferId),
                    desc: this.state.desc,
                    isConfirmed: true,
                };

                httpCaller.NimaImport.ConfirmApplicantCancel(param,() => {
                    NotificationManager.info(`عملیات تایید انصراف متقاضی با موفقیت انجام شد`);
                    this.LoadInfo(false);
                    this.handleClose();
                }, () => {

                })
            }
            else if(this.state.SelectedOffer.offerStatus === 128)
            {
                var param = {
                    offerId: parseInt(this.state.CurrentOfferId),
                    desc: this.state.desc
                };

                httpCaller.NimaImport.ReturnFromMakhtoome(param,() => {
                    NotificationManager.info(`عملیات بازگشت از مختومه با موفقیت انجام شد`);
                    this.LoadInfo(false);
                    this.handleClose();
                }, () => {

                })
            }

        }
        else
        {
            NotificationManager.error("وارد کردن توضیحات الزامی است");
        }
    }

    handleClose = () => {
        if (this.state.SelectedOffer.offerStatus === 512) 
        {
            if(this.state.desc !== "")
            {
                var param = {
                    offerId: parseInt(this.state.CurrentOfferId),
                    desc: this.state.desc,
                    isConfirmed: false,
                };

                httpCaller.NimaImport.ConfirmApplicantCancel(param,() => {
                    NotificationManager.info(`عملیات رد انصراف متقاضی با موفقیت انجام شد`);
                    this.LoadInfo(false);
                    this.handleClose();
                }, () => {
                }, false)
            }
            else
            {
                NotificationManager.error("وارد کردن توضیحات الزامی است");
            }
        }
        else
        {
            this.setState({show: false});
        }
    }

    handleShow = () => {
        this.setState({show: true});
    }

    handleDescChange = (e) => {
        this.setState({desc: e.target.value})
    }

    handleModal = (title) => {
        return(
            <Modal
                    width="50%"
                    title={title}
                    visible={this.state.show}
                    onOk={this.handleAction}
                    onCancel={this.handleClose}
                    footer = {[
                        <Button type="primary" onClick={this.handleAction}>تایید</Button>,
                        <Button type="default" onClick={this.handleClose}>{this.state.SelectedOffer.offerStatus === 512?'رد تایید':'بازگشت'}</Button>
                    ]}
                    >
                    <Row style={{textAlign:"center"}}>
                        <Col span={24}>
                            <textarea id = "txtDesc" placeholder="توضیحات" onChange={this.handleDescChange} value={this.state.desc} style={{width:"80%", height:"100%", border:"1px groove black", borderRadius:"7px", padding:"5px"}} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            
                        </Col>
                    </Row>
                    </Modal>
        )
    }

    ReturnBack = () => {
        this.props.history.push(`/nima/import`);
    }

    getTransferDocuments = (offerId) => {

        let canDownloadDocs =  HasPermission("RequestManagement_DownloadDocuments");
        if(canDownloadDocs){
            httpCaller.NimaImport.GetOfferDocuments(offerId, (result) =>{
                this.setState({documents: result})
            },()=>{}, false);
        }
      
    }
    
    downloadFile = (Id) => {
        var data = {
            id: Id
        }
        httpCaller.NimaImport.DownloadFile(data, (response) =>
        {
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'file.pdf');
            // document.body.appendChild(link);
            // link.click();
        },()=>{}, false);
    }

    render(){
        const columns = [{
            title: 'کد',
            dataIndex: 'id',
        }, {
            title: 'نام فایل',
            dataIndex: 'fileName',
            render: (text,row) => { return(<a  target="_blank" href={httpCaller.NimaImport.GetOfferFileDownloadLink({id:row.id})}>{text}</a>)}
        }, {
            title: 'سایز فایل',
            dataIndex: 'fileSize',
        }, {
            title: 'ارسال کننده',
            dataIndex: 'insertedBy',
        }, {
            title: 'تاریخ ارسال',
            dataIndex: 'insertedDate',
        }, {
            title: 'شرح',
            dataIndex: 'description',
        }];

        let canDownloadDocs =  HasPermission("RequestManagement_DownloadDocuments");
        return(
            <React.Fragment>
                <Card>
                    <Row>
                        <Col md={16} xs={24}>
                            <DisplayFormData lable="نام صرافی" value={this.state.SelectedOffer.sarafiName} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد پیشنهاد" value={this.state.SelectedOffer.offerId} />
                        </Col>
                        <Col md={16} xs={24}>
                            <DisplayFormData lable="وضعیت پیشنهاد" value={this.state.SelectedOffer.offerStatusDesc} />
                        </Col>    
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ پیشنهاد" value={this.state.SelectedOffer.offerRegisterDateStr} />
                        </Col>
                        <Col md={16} xs={24}>
                            <DisplayFormData lable="مهلت اعتبار پیشنهاد" value={this.state.SelectedOffer.offerValidityDateStr} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col md={8} xs={24}>
                            <DisplayFormData lable="حداکثر زمان صدور حواله" value={this.state.SelectedOffer.transferDelayTime} />
                        </Col>
                        <Col md={8} xs={24}>    
                            <DisplayFormData lable="زمان آخرین تغییر" value={this.state.SelectedOffer.lastModifiedDateStr} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کاربر ثبت کننده" value={this.state.SelectedOffer.registeredBy} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={24} xs={24}>
                            <DisplayFormData lable="توضیحات" value={this.state.SelectedOffer.description} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="قیمت واحد ارز" value={numeral(this.state.SelectedOffer.currencyUnitPrice).format(0,0)} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کارمزد ارزی" value={numeral(this.state.SelectedOffer.currencyWage).format(0,0)} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کارمزد ریالی" value={numeral(this.state.SelectedOffer.irrwage).format(0,0)} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="قیمت کل" value={numeral(this.state.SelectedOffer.totalPrice).format(0,0)} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ صدور حواله" value={this.state.SelectedOffer.transferExchangeDateStr} />
                        </Col>
                       <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ تایید حواله" value={this.state.SelectedOffer.approveTransferDateStr} />
                        </Col>
                    
                        {(this.state.SelectedOffer.sanaTrackingCode != null && this.state.SelectedOffer.sanaTrackingCode != '') &&
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد پیگیری سنا" value={this.state.SelectedOffer.sanaTrackingCode} />
                        </Col>
                        }
                    </Row>
                    <br/>
                    {this.state.SelectedOffer.offerStatus === 128 && 
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ مختومه" value={this.state.SelectedOffer.closedDateStr} />
                        </Col>
                    </Row>
                    }
                    <br/>
                    <br/>
                    {canDownloadDocs && this.state.documents.length > 0 &&
                    <SimpleFieldSet title="لیست مستندات حواله" isCollapsible>
                        <Row>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    dataSource = {this.state.documents}
                                    bordered
                                    pagination={false}
                                    />
                            </Col>
                        </Row>
                    </SimpleFieldSet>
                    }
                    <br/>
                    <br/>
                    <SimpleFieldSet title='پرداختها' isCollapsible> 
                        <Row>
                            <Col span={24}>
                                <PaymentComponent currentEntity = "offer" entityId = {this.state.CurrentOfferId} showPayment = {this.CanShowPayment}></PaymentComponent>
                            </Col>
                        </Row>
                    </SimpleFieldSet>
                    <br />
                    <br />
                    <Row>
                        <Col span={6}></Col>
                        <Col md={6} xs={24}>
                            <Button type="primary" style={{ margin: "15px", display: this.state.ShowActionBtn }} onClick={this.openModal}>{this.state.ActionBtnCaption}</Button>
                            {this.handleModal(`${this.state.ActionBtnCaption} برای کد پیشنهاد ${this.state.CurrentOfferId}`)}
                            
                        </Col>
                        <Col md={12} xs={24}>
                        </Col>
                    </Row>
                    </Card>
            </React.Fragment>
        )
    }
}

export default withRouter(SelectedOffer);

import React, { Component } from 'react';
import httpCaller from '../../services/httpService'
import { NotificationManager } from 'react-notifications'
import { Row, Col, Card,Radio, Table, Switch, Select, Input, Button, Form,Checkbox } from 'antd'
import { HasPermission,CheckPermissionHOC } from '../../services/authorization'
import UserRolesPermission from './UserRolesPermission';

class ViewCbiUsers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userKind:"SanaAdminPanel",
            searchResult: {},
            searchType: "0",
            searchValue: ""
        }
        this.searchValueRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData(1);
    }

    fetchData = (pageIndex) => {
        var param = {
            pageIndex: pageIndex,
            pageSize: 10,
            searchType: this.state.searchType ,
            searchContain: this.state.searchValue ,
            userKind:this.state.userKind
            
        };
        httpCaller.User.GetCbiUsers(param, (pagedEntity) => {
            this.setState({ searchResult: pagedEntity });
        });

    }

    handleSearchTypeChanged = (value) => {
        if(this.searchValueRef.current){
            this.searchValueRef.current.input.value = "";
        }

        this.setState({ searchType: value });
    }
    handleSearchTextChanged = (value) => {
        this.setState({ searchValue: this.searchValueRef.current.input.value });
    }
    handleUserTypeChanged = (value) => {
         this.setState({ searchValue: value });
    }
    handleSearch = (e) => {

        this.fetchData(1);
    }
    handleShowUser = (user) => {
        if (!HasPermission("SysAdmin") && user.isDeleted) {
            NotificationManager.error("کاربر در سامانه حذف شده است.")
            return;
        }

        this.props.history.push(`/user/view/${user.id}`);
    }

    handleChangeuserKind = (e) => {
        this.setState({
            userKind: e.target.value
            // ,openPositionCeiling:0 
        });
        // this.fetchData(1);
         var param = {
            pageIndex: 1,
            pageSize: 10,
            searchType: this.state.searchType ,
            searchContain: this.state.searchValue ,
            userKind:e.target.value
            
        };
        httpCaller.User.GetCbiUsers(param, (pagedEntity) => {
            this.setState({ searchResult: pagedEntity });
        });
    }

    Search = (pageIdx, isSOCLog) => {
        // this.setState({pageIndex: pageIdx});
        // var data = this.prepareData(pageIdx);
        // httpCaller.Sana.SearchCurrencyUse(data,(result)=>{
        //     this.setState({searchResult: result});
        // },()=>{},isSOCLog)
        console.log("Search");
    }

    render() {
        let isSysAdmin = HasPermission("SysAdmin");

        const Option = Select.Option;
        const columns = [
            {
                hidden:true,
                title:'کد کاربر',
                dataIndex:'id',
            },
            {
                title: "نام کاربری",
                dataIndex: "userName",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: "نام",
                dataIndex: "firstName",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: "نام خانوادگی",
                dataIndex: "lastName",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            // {
            //     title: "تایید شده",
            //     dataIndex: "isActive",
            //     render: (text, row) => (<Switch checked={row.isActive} disabled={true} />),
            // },
            {
                hidden: this.state.userKind=="SanaAdminPanel" ? true : false ,
                title: "نوع کاربری",
                dataIndex: "userTypeText",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: "فعال",
                dataIndex: "isEnable",
                render: (text, row) => (<Switch checked={row.isEnable} disabled={true} />),
            }
        ]
        return (

            <React.Fragment>
                <Card>
                    <Row>
                    <Col sm={2} lg={2} xl={2} xxl={2}></Col>
                    <Col sm={20} lg={8} xl={8} xxl={8}>
                         <Form.Item
                            label="جستجوی "
                            // labelCol={{ xs: 24, lg: 4, md: 4, xl: 4 }}
                            // wrapperCol={{ xs: 24, lg: 12, md: 12, xl: 12 }}
                        >
                           <Radio.Group onChange={this.handleChangeuserKind} value={this.state.userKind} buttonStyle="solid" >
                                <Radio.Button value="SanaAdminPanel">کاربران مدیریت سنا </Radio.Button>
                                <Radio.Button value="Sana2">کاربران سامانه سنا 2 </Radio.Button>
                            </Radio.Group>

                        </Form.Item>
                    </Col>
                        <Col sm={24} lg={14} xl={14} xxl={12}>
                            <Input.Group compact className="gx-mb-3">
                                <Select  defaultValue="0" style={{ width: '30%' }} onChange={this.handleSearchTypeChanged} >
                                    <Option value="0" >همه ی موارد</Option>
                                    {/* <Option value="1" >کد یا نام بانک/صرافی</Option> */}
                                    <Option value="2" >نام کاربری</Option>
                                    <Option value="3" >نام و نام خانوادگی</Option>
                                    {/* <Option value="4" >نوع کاربر</Option> */}
                                </Select>
                                {
                                    (() => {
                                        if (this.state.searchType == "4") {
                                            return <Select  style={{ width: '40%' }} defaultValue="" onChange={this.handleUserTypeChanged}>
                                                <Option value="">[انتخاب کنید]</Option>
                                                <Option value="CBIAdmin">کاربر ارشد</Option>
                                                <Option value="CBIUser">کاربر</Option>
                                                <Option value="SarafiAdmin">مدیر صرافی</Option>
                                                <Option value="SarafiUser">کاربر صرافی</Option>
                                                <Option value="NezaratOstan">ناظر استانی</Option>
                                            </Select>
                                        }
                                        else {
                                            return (<Input style={{ width: '40%' }} ref={this.searchValueRef}  placeholder="جستجو" onChange={this.handleSearchTextChanged} />)
                                        }
                                    })()
                                }
                                <Button type="primary" icon="search" onClick={this.handleSearch} >جستجو</Button>
                            </Input.Group>
                        </Col>
                       
                    </Row>
                </Card>
                {this.state.searchResult.items && <Card>
                    <Table className="gx-table-responsive withPointer" size='small'
                        rowKey="id"
                        columns={columns.filter(item => !item.hidden)}
                        dataSource={this.state.searchResult.items}
                        bordered
                        //expandedRowRender={(row)=><UserRolesPermission action = {this.Search} userId = {row.id}></UserRolesPermission>}
                        expandedRowRender={this.state.userKind=="SanaAdminPanel" ? (row)=><UserRolesPermission action = {this.Search} userId = {row.id}></UserRolesPermission> : false}
                        expandRowByClick = {true}
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: (index) => this.fetchData(index),
                        }}
                        // onRowClick= {(row) => !isSysAdmin && row.isDeleted ? {} : this.handleShowUser(row)}
                    />

                </Card>
                }
            </React.Fragment>
        );
    }
}

export default  CheckPermissionHOC(ViewCbiUsers,'AccountManagement_ViewCbiUsers');

import React, {Component} from 'react';
import {Button, Row, Col, Card, Input} from 'antd'
import {NotificationManager} from 'react-notifications'
import httpCaller from '../../../services/httpService'
import IntlMessages from "util/IntlMessages";
import { withRouter } from 'react-router-dom';
import {RedoOutlined} from '@ant-design/icons'

class SendConfirmationCode extends Component
{
    constructor(props){
        super(props);
        this.state = {
            UserName: '',
            UserId: 0,
            CaptchaText: '',
            ActualCaptchaText: '',
        }
        //this.SendConfimationCode = this.SendConfimationCode.bind(this);
    }

    componentDidMount(){
        this.RefreshCaptcha();
    }

    RefreshCaptcha = () => {
        this.setState({CaptchaText:""});
        httpCaller.Auth.loadCaptcha((result)=>{
            //document.getElementById("imgCaptcha").src = "data:image/jpeg;base64," + result;
        }, (error) => {
            this.setState({ActualCaptchaText: error.Value})
            document.getElementById("imgCaptcha").src = "data:image/jpeg;base64," + error.Key;
        }, false,false)
    }

    handleUserName = (e) => {
        this.setState({UserName: e.target.value})
    }

    captchaInfo(){
        console.log(this.state.CaptchaText);
        return{
            CaptchaInput: this.state.CaptchaText
        }
    }

    prepareData(){
        return{
            userName: this.state.UserName
        }
    }

    SendConfimationCode = () => {
        if(this.state.UserName === '' || this.state.CaptchaText === ''){
            NotificationManager.error("لطفا همه فیلدها را تکمیل نمایید");
            return;
        }
        if(this.state.ActualCaptchaText !== this.state.CaptchaText){
            NotificationManager.error("لطفا مقدار فیلد امنیتی را صحیح وارد نمایید.");
            return;
        }
       
        var data = {
            userName : this.state.UserName
        }
        
        httpCaller.Auth.getIdentityUserByUserName(data,(result)=>{
            this.setState({UserId: result.id});
            let userId = this.state.UserId
            
            httpCaller.Auth.sendSMS({id: this.state.UserId}, () => {
                this.props.history.push({
                    pathname: `/login/EnterConfirmationCode/${userId}`,
                    state: {
                        from: this.props.location.pathname
                    }
                })
            }, (errorMsg) => {
            })
        }, (errorMsg)=>{
        }, false)

    }

    handleCaptchaText = (e) => {
        this.setState({CaptchaText: e.target.value})
    }

    render(){
        return(
            <Row>
                <Col lg={6} md={4} sm={6} xs={12}></Col>
                <Col lg={12} md={16} sm={12} xs={24} >
                    <Card title="ارسال کد تایید" style={{textAlign:"center",marginTop:"20%"}} headStyle={{backgroundColor:"#520be1",color:"#f6f9fb"}} bodyStyle={{backgroundColor:"#e1e6f0"}}>
                        <Row>
                            <Col lg={6} md={6} sm={2} xs={24}></Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                
                                <Input addonBefore="نام کاربری" value={this.state.UserName} onChange={this.handleUserName}/>
                            </Col>
                            <Col lg={6} md={6} sm={2} xs={12}></Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col lg={6} md={6} sm={2} xs={24}></Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <img id="imgCaptcha" src="" style={{textAlign:"center", width: "80%", borderRadius:"10px", border:"2px solid #d9edf7", marginLeft:"10px"}}/>
                                <Button type="primary" shape="circle" onClick={this.RefreshCaptcha}><RedoOutlined /></Button>
                            </Col>
                            <Col lg={6} md={6} sm={2} xs={24}></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={6} md={4} sm={2} xs={12}></Col>
                            <Col lg={12} md={16} sm={24} xs={24}>
                                <Input placeholder="حاصل جمع"  type="text" onChange={this.handleCaptchaText}/>
                            </Col>
                            <Col lg={6} md={4} sm={2} xs={12}></Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col md={4} xs={12}></Col>
                            <Col md={16} xs={24}>
                                <Button type="primary" onClick = {this.SendConfimationCode}>ارسال کد تایید</Button>
                                <Button type="default" onClick = {() => {this.props.history.push(`/login`)}}>بازگشت</Button>
                            </Col>
                            <Col md={4} xs={12}></Col>
                        </Row> 
                    </Card>
                </Col>
                <Col lg={6} md={4} sm={6} xs={12}></Col>
            </Row>
        )
    }
}

export default withRouter(SendConfirmationCode);

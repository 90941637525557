import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import avatar4 from '../../assets/images/placeholder.jpg';
import {Logout} from '../../services/authorization'
import {userSignOutSuccess} from '../../appRedux/actions/Auth'
import { withRouter } from 'react-router-dom';
import {GetAuthUserInfo} from '../../services/authorization'

class UserInfo extends Component {

  
  signOut = ()=>{
    Logout();
    alert()
    setTimeout(() => {
      window.location.reload(true);  // برا دریافت آخرین نسخه از سرور
    }, 1000);
    
  }
  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>حساب من</li>
        <li onClick={() => this.signOut()}>خروج
        </li>
      </ul>
    );

   var userInfo=GetAuthUserInfo();
    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={avatar4}
                className="gx-avatar gx-pointer" alt=""/>
       <span className="gx-avatar-name" style={{fontSize:"smaller"}}>{userInfo.fullName}<i
            className="icon icon-chevron-down gx-fs-xxs gx-mr-2"/></span>
      </Popover>
    )

  }
}

export default withRouter(connect(null, {userSignOutSuccess})(UserInfo));

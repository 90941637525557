import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { Result, Button } from 'antd';

class Errorpage extends Component {

  render() {
    return (
        <Result
        status="500"
        title="خطایی رخ داده است لطفا با ادمین سامانه تماس بگیرید"
        subTitle={"شماره پیگیری خطا :"  + sessionStorage.getItem("incidentId")}
      //  extra={<Button type="primary">Back Home</Button>}
      />

    );
  } 
}

export default Errorpage;
import React, { Component } from 'react';
import { Row, Col, Tooltip, Table, Card, Button, Spin, Switch, Modal, Select, Input } from 'antd';
import { NotificationManager } from 'react-notifications'
import httpService from '../../services/httpService'
import { CloseOutlined} from '@ant-design/icons'


class RoleMember extends Component {
    constructor(props) {
        super(props);
        this.callUserTimeoutHandler = null;
        this.state = {
            searchResult: {},
            filteredUser: [],
            userSearchKey: "",
            selectedUser: null,
        }

        this.timeout = null;
        this.currentSearchValue = ""
    }

    componentDidMount() {
        this.fetchData(1);

    }
    fetchData = (pageIndex) => {

        var data = {
            roleId: this.props.roleId,
            pageSize: 10,
            pageIndex: pageIndex
        };
        httpService.Auth.getUsersInRole(data, (pagedEntity) => {
            this.setState({ searchResult: pagedEntity });
            this.props.setUsers(pagedEntity.items);

        });
    }
   
    handleAddUserToRole = () => {
        
        if (this.state.selectedUser > 0) {
            httpService.Auth.addUserToRole({ roleId: this.props.roleId, userId: this.state.selectedUser }, () => {
              
                let userText= this.state.filteredUser.filter(a=> a.value= this.state.selectedUser)[0].text;
                NotificationManager.success(`${userText} به نقش اضافه شده است .`)
                this.setState({selectedUser: null});
                this.fetchData(1);
            })
        }
    }
    handleRemoveUserFromRole = () => {
        this.setState({ modalVisible: false })
        httpService.Auth.removeUserFromRole({ roleId: this.props.roleId, userId: this.state.userIdToDelete }, () => {
            NotificationManager.success(`${this.state.userNametoDelete} از نقش حذف شده است .`)
            this.fetchData(1);
        });
    }
    handlePageIndexChanged = (pageIndex) => {
        this.fetchData(pageIndex);
    }
    
    showModalForDeleteUserFromRole = (userId, userName) => {
        this.setState({ modalVisible: true, userIdToDelete: userId, userNametoDelete: userName });
    }


    fetchSelectUserDate = (searchKey) => {
        httpService.User.GetUsers({ pageIndex: 1, pageSize: 5, userName: searchKey },
            (data) => {

                let mapedData = data.items.map(a => { return { value: a.id, text: `${a.userName}(${a.firstName} ${a.lastName})` } });
                this.setState({ filteredUser: mapedData, });
            });
    }

    handleSearch = searchKey => {
        if (!searchKey) {
            this.setState({ data: [] });
            return;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.currentSearchValue = searchKey;
        this.timeout = setTimeout(()=> {this.fetchSelectUserDate(searchKey)},600);
    }


    handleChange = (selectedUser) => {
        this.setState({ selectedUser });
    };
    render() {

        const columns = [
            {
                title: 'نام کاربری',
                dataIndex: 'userName',
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: 'نام',
                dataIndex: 'firstName',
                render: (text, row) => (row.isDeleted ? <del>{row.firstName + " " + row.lastName}</del> : (row.firstName + " " + row.lastName))
            },
            {
                title: 'فعال',
                dataIndex: 'isEnable',
                render: (text, row) => (<Switch checked={row.isEnable} disabled={true} />),

            },
            {
                title: 'حذف کاربر',
                dataIndex: 'action',
                render: (text, row) => (<Tooltip placeholder="top" title="حذف کاربر از نقش">
                    <a onClick={() => this.showModalForDeleteUserFromRole(row.userId, row.userName)}>
                        {<CloseOutlined style={{ color: "red", fontSize: "2rem", cursor: "pointer" }} />}
                    </a>
                </Tooltip>)
            }
        ]
        const options = this.state.filteredUser.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);
        return (
            <React.Fragment>
                <Card>
                    <Row>
                        <Col md={12}>
                            <Input.Group compact className="gx-mb-3">
                                <Select
                                    showSearch
                                    value={this.state.selectedUser}
                                    placeholder={"PlaceHolder"}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    style={{ width: 400 }}
                                    filterOption={false}
                                    onSearch={this.handleSearch}
                                    onChange={this.handleChange}
                                    notFoundContent={null}
                                >
                                    {options}
                                </Select>
                                <Button type="primary" onClick={this.handleAddUserToRole} >{"اضافه به نقش"}</Button>
                            </Input.Group>
                        </Col>
                        <Col md={12}>

                        </Col>
                    </Row>
                    <Table className="gx-table-responsive"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageIndexChanged
                        }}
                    />
                </Card>
                <Modal
                    visible={this.state.modalVisible}
                    footer={[
                        <Button key="submit" type="danger" onClick={this.handleRemoveUserFromRole}>
                            حذف کاربر از نقش
                    </Button>,
                        <Button key="back" onClick={() => { this.setState({ modalVisible: false }) }}>
                            بازگشت
                    </Button>
                    ]}
                >
                    <Row >
                        <Col span={24} >
                            <span>{"   کاربر  "}</span>
                            <span ><b>{this.state.userNametoDelete}</b></span>
                            <span>{"  از نقش  حذف شود؟ "}</span>
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        );
    }
}

export default RoleMember;
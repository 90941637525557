import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import httpCaller from '../../../services/httpService';
import NumberFormat from 'react-number-format';
import { Row, Col, Form, Input, Button, Card, Table } from 'antd'
import {NotificationManager} from 'react-notifications'

class SupplyCottages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,

        };

    }

    componentDidMount = () => {
        this.doSearch(1);
    }
    


    doSearch = () => {
        httpCaller.NimaExport.GetSupplyCottages(this.props.supplyId, (data)=>{
            console.log("GetSupplyCottages : " , data );
            this.setState({searchResult:data})
        });
    }

    render() {
        const FormItem = Form.Item;
        const columns= [{
            title: 'شناسه کوتاژ',
            dataIndex: 'id',
        }, {
            title: 'کد گمرکی',
            dataIndex: 'customsCode',
        }, {
            title: 'کد کوتاژ',
            dataIndex: 'cottageNumber',
        }, {
            title: 'تاریخ ارزیابی',
            dataIndex: 'evaluationDateStr',
        }, {
            title: 'تاریخ سررسید',
            dataIndex: 'maturityDateStr',
            
        }, {
            title: 'تاریخ ثبت پیشنهاد',
            dataIndex: 'currencyTypeStr',
        }, {
            title: 'تاریخ ثبت پیشنهاد',
            dataIndex: 'deadline',
        }
    
    ];
        return (
            <React.Fragment>
                {this.state.searchResult && (<Card title="کوتاژهای صادراتی" > 
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult}
                        bordered
                        pagination={false}
                        // onRowClick = {this.handleTableRowClicked}
                    />
                </Card>)}
            </React.Fragment>

        );
    }
}

export default withRouter(SupplyCottages);

import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Button, Row, Col, Card, Divider, Input } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
import { connect } from 'react-redux';
// import NotFound from '../Not-Found';
import NumberFormat from 'react-number-format';
import ExchangeLicense from './ExchangeLicense';
import ExchangeTaahod from './ExchangeTaahod';
import ExchangeOpenPosition from './ExchangeOpenPosition';
   

class ExchangeInfo extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      sarafi: { traceCD: "sdsd" },
      idsarafi: 16
    };
    
    this.state.idsarafi = this.props.exchangeId;
  }

  handledata = () => {

    const successHandler = (result) => {
      console.log("successHandler 2");
      console.log(this.state.idsarafi);

      this.setState({ sarafi: result })

      // if (result === null || result === 'undefined') {
      //   toast.warn('دادهای یافت نشد')
      // }
      // else {
      //   //this.setState({ sarafi: result })
      //   sarafi=result;
      //   // show date
      // }
    };
    const rejectedHandler = (ar) => {
    };

    httpCaller.Sarafi.GetSarafDetails(this.state.idsarafi, successHandler, rejectedHandler);
  }


  componentDidMount() {

    this.handledata();

  }

  backBorwser = () => {
    window.history.back();
  }


  render() {

    return (

      <Row>
        <Col span={24}>
          <Card title="مشخصات صرافی">
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Input addonBefore="کد صرافی" value={ this.state.idsarafi} />
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Input addonBefore="نام صرافی" value={this.state.sarafi.sarName} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="شماره مجوز" value={this.state.sarafi.lastLicenseNo} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="تاریخ مجوز" value={this.state.sarafi.lastLicenseDT} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="نوع صرافی" value={this.state.sarafi.sarrafiKindDesc} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="تاریخ ثبت" value={this.state.sarafi.regDT} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="پایان اعتبار مجوز" value={this.state.sarafi.lastLicenseExpDT} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="تلفن" value={this.state.sarafi.wTel} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="کد پستی" value={this.state.sarafi.zipCD} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="شماره ثبت" value={this.state.sarafi.regNo} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="شماره ملی صرافی" value={this.state.sarafi.nationID} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="مجوز" value={this.state.sarafi.licenseSTName} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="وضیعت مجوز" value={this.state.sarafi.lastLicenseTypeName} />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="نام استان" value={this.state.sarafi.provinceName} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Input addonBefore="نام شهر" value={this.state.sarafi.cityName} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col span={24}>
                  <Input addonBefore="آدرس" value={this.state.sarafi.addr} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col span={24}>
                <ExchangeLicense exchangeId={this.state.idsarafi} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                <ExchangeTaahod exchangeId={this.state.idsarafi} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                <ExchangeOpenPosition exchangeId={this.state.idsarafi} />
                </Col>
              </Row>
              
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withRouter(ExchangeInfo);

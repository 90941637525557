import React, {Component} from 'react'
import {Card, Table} from 'antd';
import httpService from '../../../services/httpService';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

class ExchangeLicenseHistory extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            ExchangeId: this.props.exchangeId,
            ExchangeName: '',
            history: [],
        }
    }

    componentDidMount = () => {
        httpService.Sarafi.GetSarafDetails(this.state.ExchangeId, (result) => { this.setState({ExchangeName: result.sarName})});

        httpService.Sarafi.GetSarafiLicenseHistory(this.state.ExchangeId, 
            (result) => {
                this.setState({history: result})
            });
    }
    

    componentDidUpdate = () => {
        console.log("componentDidUpdate");
    }

    render()
    {
        let columns = [{
            title : 'کاربر تغییر دهنده',
            dataIndex: 'userName',
        },
        {
            title : 'تاریخ تغییر',
            dataIndex: 'changeDate',
        },
        {
            title : 'مجوز خرید',
            dataIndex: 'allowBuy',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        {
            title : 'مجوز فروش',
            dataIndex: 'allowSell',
            render: (text) => (
                <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
        },
        {
            title : 'توضیحات',
            dataIndex: 'desc',
        }]

        return(
            <Card title={<span>تاریخچه تغییر مجوزهای {this.state.ExchangeName}</span>}>
                <Table className="gx-table-responsive withPointer"
                stripped
                columns={columns}
                dataSource={this.state.history}
                bordered
              />
            </Card>
        )
    }
}

export default ExchangeLicenseHistory

import React, { Component } from 'react';
import { Radio, Table, Card, Row, Col, Divider, Button, Tooltip, Collapse } from "antd";
import numeral from 'numeral';
import {Link} from "react-router-dom";

import { PrinterOutlined, DownloadOutlined, SearchOutlined, CaretRightOutlined } from '@ant-design/icons'
import httpService from '../../../services/httpService'
// import { Space } from 'antd';
const { Panel } = Collapse;
class Rpt1NimaExport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplies: [],
            demands: [],
            dealings: []
        }


    }
    componentDidMount() {
        httpService.Dashboard.GetRpt1NimaExport(this.props.curCode, (data) => {
            this.setState({
                supplies: data.supplies,
                demands: data.demands,
                dealings: data.dealings,
            });
        })

    }
    callback = (key) => {

    }

    backBorwser = () => {
        window.history.back();
      }

    render() {
        const supplyColumns = [
            {
                title: 'عرضه کننده',
                dataIndex: 'supplier',

                sorter: (a, b) => a.supplier.length - b.supplier.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'مبلغ',
                dataIndex: 'amount',

                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.amount - b.amount,
                // key: 'age',
                // sorter: (a, b) => a.age - b.age,
                // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            },
            {
                title: 'معادل یورو',
                dataIndex: 'euroAmount',

                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.euroAmount - b.euroAmount,
                // key: 'age',
                // sorter: (a, b) => a.age - b.age,
                // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            },
            {
                title: 'نوع ارز',
                dataIndex: 'currencyName',

                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            }];
        const demandColumns = [
            {
                title: 'صرافی/بانک',
                dataIndex: 'sarafi',
                sorter: (a, b) => a.sarafi.length - b.sarafi.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'عرضه کننده',
                dataIndex: 'supplier',
                sorter: (a, b) => a.supplier.length - b.supplier.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'مبلغ',
                dataIndex: 'amount',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'نرخ واحد',
                dataIndex: 'unitPrice',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.unitPrice - b.unitPrice,
            },
            {
                title: 'نوع ارز',
                dataIndex: 'currencyName',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'معادل یورویی',
                dataIndex: 'euroAmount',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.euroAmount - b.euroAmount,
            }];

        const dealingColumns = [
            {
                title: 'صرافی/بانک',
                dataIndex: 'sarafi',
                sorter: (a, b) => a.sarafi.length - b.sarafi.length,
                sortDirections: ['descend', 'ascend'],

            },
            {
                title: 'عرضه کننده',
                dataIndex: 'supplier',
                sorter: (a, b) => a.supplier.length - b.supplier.length,
                sortDirections: ['descend', 'ascend'],

            },
            {
                title: 'مبلغ',
                dataIndex: 'amount',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.amount - b.amount,

            },
            {
                title: 'نرخ واحد',
                dataIndex: 'unitPrice',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.unitPrice - b.unitPrice,
            },
            {
                title: 'نوع ارز',
                dataIndex: 'currencyName',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'معادل یورویی',
                dataIndex: 'euroAmount',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.euroAmount - b.euroAmount,
            }];

        return (
            <React.Fragment>

                <br />
                <Row>
                    <Col md={4}  sm={10} >
                        <Button type="primary" icon="download" onClick={() => window.open(httpService.Dashboard.ExportExcelRpt1(this.props.curCode), '_blank')}  >{`دریافت فایل (${this.props.curName})`}</Button>
                    </Col>
                    <Col md={16}  sm={2}>
                    </Col>
                    <Col md={4}  sm={10}>
                    <Button style={{float:'left'}} type="default" onClick={this.backBorwser}>بازگشت</Button>
                    </Col>
                </Row>

                <Row>
                    <Col   style={{minWidth:"400px"}} xxl={7} xl={12}>

                        <Collapse onChange={this.callback} defaultActiveKey="1" expandIconPosition={"left"} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 180} />}>
                            <Panel header={`عرضه (تعداد رکورد:${this.state.supplies.length})`} key="1" style={{ backgroundColor: '#b5d1ff' }} >
                                <Table className="gx-table-responsive"
                                    pagination={false}
                                    columns={supplyColumns}
                                    dataSource={this.state.supplies}
                                    onChange={this.handleChange} />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col  style={{minWidth:"600px"}} xxl={9} xl={12}>
                        <Collapse onChange={this.callback} defaultActiveKey="1" expandIconPosition={"left"} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 180} />}>
    

                            <Panel header={`تقاضای صرافی/بانک (تعداد رکورد:${this.state.demands.length})`} key="1" style={{ backgroundColor: '#fa8c16' }}>
                                <Table className="gx-table-responsive"
                                    pagination={false}
                                    columns={demandColumns}
                                    dataSource={this.state.demands}
                                    onChange={this.handleChange} />
                            </Panel>

 
                        </Collapse>

                    </Col>

                    <Col style={{minWidth:"600px"}} xxl={8} xl={12}>
                        <Collapse onChange={this.callback} defaultActiveKey="1" expandIconPosition={"left"} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 180} />}>


                            <Panel header={`معامله (تعداد رکورد:${this.state.dealings.length})`} key="1" style={{ backgroundColor: '#20c997' }} >
                                <Table className="gx-table-responsive"
                                    pagination={false}
                                    columns={dealingColumns}
                                    dataSource={this.state.dealings}
                                    onChange={this.handleChange} />

                            </Panel>
                        </Collapse>

                    </Col>

                </Row>




            </React.Fragment>
        );
    }
}

export default Rpt1NimaExport;
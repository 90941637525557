import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Report1 from "./dashboard/Report1";

import DashboardExportTotalReport1 from "./dashboard/DashboardExportTotalReport";
import currencySupplyToday from "./dashboard/CurrencySupplyToday";





const Main = ({match}) => (
    <Switch>
        <Route exact path={`${match.url}/dashboard`} component={Dashboard}/>
        <Route exact path={`${match.url}/dashboard/nima`} component={Dashboard}/>
        <Route exact path={`${match.url}/dashboard/nimadetails/:curCode?`} component={Report1}/>
        <Route exact path={`${match.url}/dashboard/nimaTotalReport`} component={DashboardExportTotalReport1}/>
        <Route exact path={`${match.url}/dashboard/currencySupplyToday`} component={currencySupplyToday}/>



        
    </Switch>
);

export default Main;

import React, { Component } from 'react';
import { Tabs } from 'antd'
import SearchRequest from './SearchRequest'
import SearchOffer from './SearchOffer';
import { HasPermission } from '../../../services/authorization';

class SearchContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            SearchType: "request"
        };
    }


    loadSearchRequest = () => {
        this.setState({ SearchType: "request" })
    }

    loadSearchOffer = () => {
        this.setState({ SearchType: "offer" })
    }

    componentDidMount() {
    }

    render() {
        
        let searchParams = new URLSearchParams(this.props.location.search); 
        var selectedTab = 1
        if(searchParams.has("type")){
            selectedTab = searchParams.get("type")
        }

        const TabPane = Tabs.TabPane;

        if (!HasPermission("RequestManagement_ViewDetail")) return null;
        return (

            <div  >
                <Tabs  type="card" defaultActiveKey={selectedTab}>
                    <TabPane tab="جستجوی درخواست" key="1">
                        <SearchRequest />
                    </TabPane>
                    <TabPane tab="جستجوی پیشنهاد" key="2">
                        <SearchOffer />
                    </TabPane>
                </Tabs>
            </div>

        );
    }
}

export default SearchContainer;

import React, { Component } from 'react';
import { Row, Col, Card, Button, Input, Modal, Form} from 'antd'
import { withRouter } from 'react-router-dom';

import { HasPermission } from '../../services/authorization'
import HttpService from '../../services/httpService'

class RoleDefine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                id: this.props.roleId,
                name: '',
                description: '',
            },
            showModal: false,
        }
    }
    componentDidMount() {
        if (this.state.model.id > 0) {
            HttpService.Auth.getRole({ id: this.state.model.id }, (data) => {
                this.setState({ model: { ...this.state.model, ...data } })
            });
        }
    }
    handleRoleName = (e) => {
        this.setState({ model: { ...this.state.model, name: e.target.value } })
    }
    handleRolDescription = (e) => {
        this.setState({ model: { ...this.state.model, description: e.target.value } })
    }
    submitForm = (e, a) => {
        HttpService.Auth.saveRole({ ...this.state.model }, () => {
            this.props.history.push('/role')
        })
    }
    cancelForm = () => {
        this.props.history.push('/role');
    }

    handleDeleteFromForm = () => {
        if (this.props.hasUsers) {
            this.setShowModel(true);
        } else {
            this.deleteRole();
        }

    }
    handleDeleteFromModal = () => {
        this.setShowModel(false);
        this.deleteRole();
    }
    deleteRole = () => {
        HttpService.Auth.deleteRole({ roleId: this.state.model.id }, () => {
            this.props.history.push('/role')
        })
    }
    setShowModel = (isShow) => {
        this.setState({ showModal: isShow });
    }
    render() {

        const FormItem =  Form.Item;
        return (
            <React.Fragment>
                <Card className="gx-card" title="اطلاعات نقش">
                    <Row>
                        <Col lg={12} sm={24}>
                            <FormItem
                                label="نام نقش"
                                labelCol={{ xs: 24, lg: 8 , xl:6}}
                                wrapperCol={{ xs: 24, lg: 16 , xl:18 }}
                            >
                              <Input value={this.state.model.name} onChange={this.handleRoleName}  />
                            </FormItem>
                        </Col>
                        <Col lg={12} sm={24}>
                            <FormItem
                                label="توضیحات"
                                labelCol={{ xs: 24, lg: 8 , xl:6}}
                                wrapperCol={{ xs: 24, lg: 16 , xl:18 }}
                            >
                                <Input.TextArea  value={this.state.model.description}  onChange={this.handleRolDescription}/>
                            </FormItem>
                        </Col>

                    </Row>

                    <Row style={{paddingRight:"20px"}}>
                           
                        {HasPermission("RoleManagement_CreateRole") && <Button onClick={this.submitForm} type="primary" style={{margin:"10px"}}> {this.state.model.id > 0 ? "بروز رسانی" : "ایجاد نقش"}</Button>}
                        <Button onClick={this.cancelForm}  style={{margin:"10px"}} >انصراف</Button>
                        {(HasPermission("RoleManagement_DeleteRole") && (this.state.model.id > 0)) &&  <Button onClick={this.handleDeleteFromForm} type="danger"  style={{margin:"10px"}}>حذف</Button>}

                    </Row>
            </Card>
             <DeleteRoleModal showModal = {this.state.showModal} handleDeleteFromModal={this.handleDeleteFromModal} setShowModel={this.setShowModel}/>
            </React.Fragment>
        );
    }
}

let DeleteRoleModal = (props) => {

    return (
        <Modal
          
          visible={props.showModal}
          title=""
          type="error"
          footer={[
            <Button key="back" type="danger" onClick={props.handleDeleteFromModal}>حذف</Button>,
            <Button key="submit" type="default"  onClick={() => props.setShowModel(false)}> انصراف</Button>,
          ]}
        >
           <Row>
                <Col md={2}> </Col>
                <Col md={20}>
                برخی از کاربران عضو نقش می باشند. در صورت حذف امکان بازیابی وجود ندارد.
                </Col>
                <Col md={2}> </Col>
            </Row>
        </Modal>
    )
}
export default withRouter(RoleDefine);


import React, {Component} from 'react';
import {Card, Input, Row, Col, Form, Button, InputNumber} from 'antd';
import httpService from '../../../services/httpService';
import { NotificationManager } from 'react-notifications';
import {CheckPermissionHOC} from "../../../services/authorization"

class PolicyCoefficients extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            policyCoeff_m: '',
            policyCoeff_nCapital: '',
            policyCoeff_N: '',
            description: '',
        }
    }

    componentDidMount = () => {
        httpService.Setting.GetPolicyCoefficients((result)=> {
            this.setState({
                policyCoeff_N : result.policyCoeff_N,
                policyCoeff_m : result.policyCoeff_m,
                policyCoeff_nCapital : result.policyCoeff_nCapital,
                description : result.description,
            });

        })
    }

    submit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                httpService.Setting.SavePolicyCoefficients(values, ()=>{
                    NotificationManager.success('تغییرات با موفقیت ثبت شد.')
                },()=>{})
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Card title='تعیین ضرایب سیاستی محاسبه تعهدات صرافی' headStyle={{backgroundColor:"#f18805",color:"#f6f9fb"}}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="ضریب سیاستی سرمایه ثبتی"
                            labelCol={{ xs: 24, lg: 8, md:8, xl: 6 }}
                            wrapperCol={{ xs: 24, lg: 16, md:6, xl: 8 }}
                        >
                            {getFieldDecorator('PolicyCoeff_nCapital', {
                            initialValue: this.state.policyCoeff_nCapital,
                            rules: [{ required: true, message: 'لطفا ضریب سیاستی سرمایه ثبتی را وارد نمایید!' }],
                            })(
                                <InputNumber />
                            )}
                            
                        </Form.Item>
                        <Form.Item
                            label="ضریب سیاستی تعهدات"
                            labelCol={{ xs: 24, lg: 8, md:8, xl: 6 }}
                            wrapperCol={{ xs: 24, lg: 16, md:6, xl: 8 }}
                        >
                            {getFieldDecorator('PolicyCoeff_N', {
                            initialValue: this.state.policyCoeff_N,
                            rules: [{ required: true, message: 'لطفا ضریب سیاستی تعهدات را وارد نمایید!' }],
                            })(
                                <InputNumber />
                            )}
                            
                        </Form.Item>
                        <Form.Item
                            label="ضریب سیاستی فروش قطعی"
                            labelCol={{ xs: 24, lg: 8, md:8, xl: 6 }}
                            wrapperCol={{ xs: 24, lg: 16, md:6, xl: 8 }}
                        >
                            {getFieldDecorator('PolicyCoeff_m', {
                            initialValue: this.state.policyCoeff_m,
                            rules: [{ required: true, message: 'لطفا ضریب سیاستی فروش قطعی را وارد نمایید!' }],
                            })(
                                <InputNumber />
                            )}
                            
                        </Form.Item>
                        <Form.Item
                            label="توضیحات"
                            labelCol={{ xs: 24, lg: 8, md:8, xl: 6 }}
                            wrapperCol={{ xs: 24, lg: 16, md:12, xl: 12 }}
                        >
                            {getFieldDecorator('Description', {
                            initialValue: this.state.description,
                            rules: [{ required: true, message: 'لطفا توضیحات را وارد نمایید!' }],
                            })(
                                <Input.TextArea />
                            )}
                            
                        </Form.Item>
                    </Col>
                </Row>
                <div className="gx-card-list-footer">
                    <Button type="primary" onClick = {this.submit}>ذخیره</Button>
                </div>
            </Card>
        );
    }
}

export default  CheckPermissionHOC(Form.create()(PolicyCoefficients),"SettingManagement_PolicyCoef");
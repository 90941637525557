import React, { Component } from 'react';
import {Row, Col, Card, Button, Input, Table, Form,Divider, Select, option } from 'antd';
import { withRouter } from "react-router-dom";
import httpCaller from '../../../services/httpService';
import { DatePicker } from "react-advance-jalaali-datepicker";
import {CheckPermissionHOC} from '../../../services/authorization';
//import jalaliConvert from '../dashboard/jalaliConvert.'; // /../services/authorization';

class CurrencySupplyToday extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,
                toDate: this.getPersianDate("y/m/d")
        };
       
    }

    componentDidMount = () => {
       this.setState({toDate: this.getPersianDate("y/m/d")})
    }

    getPersianDate = (format) => { 
        let week = new Array("يكشنبه", "دوشنبه", "سه شنبه", "چهارشنبه", "پنج شنبه", "جمعه", "شنبه")
        let months = new Array("فروردين", "ارديبهشت", "خرداد", "تير", "مرداد", "شهريور", "مهر", "آبان", "آذر", "دي", "بهمن", "اسفند");
        let today = new Date();
        let d = today.getDay();
        let day = today.getDate();
        let month = today.getMonth() + 1;
        let year = today.getYear();
        let tempp=0;
        year = (window.navigator.userAgent.indexOf('MSIE') > 0) ? year : 1900 + year;
        if (year == 0) {
            year = 2000;
        }
        if (year < 100) {
            year += 1900;
        }
      let  y = 1;
      let  i = 0;
        for (i = 0; i < 3000; i += 4) {
            if (year == i) {
                y = 2;
            }
        }
        for (i = 1; i < 3000; i += 4) {
            if (year == i) {
                y = 3;
            }
        }
        if (y == 1) {
            year -= ((month < 3) || ((month == 3) && (day < 21))) ? 622 : 621;
            switch (month) {
                case 1:
                  {
                    tempp=  ((day < 21) ? (month = 10, day += 10) : (month = 11, day -= 20));
                    break;
                  } 
                case 2:
                    {
                        tempp=  (day < 20) ? (month = 11, day += 11) : (month = 12, day -= 19);
                        break;
                    }
                case 3:
                    tempp= (day < 21) ? (month = 12, day += 9) : (month = 1, day -= 20);
                    break;
                case 4:
                    tempp=  (day < 21) ? (month = 1, day += 11) : (month = 2, day -= 20);
                    break;
                case 5:
                case 6:
                    tempp=  (day < 22) ? (month -= 3, day += 10) : (month -= 2, day -= 21);
                    break;
                case 7:
                case 8:
                case 9:
                    tempp=   (day < 23) ? (month -= 3, day += 9) : (month -= 2, day -= 22);
                    break;
                case 10:
                    tempp= (day < 23) ? (month = 7, day += 8) : (month = 8, day -= 22);
                    break;
                case 11:
                case 12:
                    tempp=   (day < 22) ? (month -= 3, day += 9) : (month -= 2, day -= 21);
                    break;
                default:
                    break;
            }
        }
        if (y == 2) {
            year -= ((month < 3) || ((month == 3) && (day < 20))) ? 622 : 621;
            switch (month) {
                case 1:
                    tempp=  (day < 21) ? (month = 10, day += 10) : (month = 11, day -= 20);
                    break;
                case 2:
                    tempp= (day < 20) ? (month = 11, day += 11) : (month = 12, day -= 19);
                    break;
                case 3:
                    tempp=  (day < 20) ? (month = 12, day += 10) : (month = 1, day -= 19);
                    break;
                case 4:
                    tempp=  (day < 20) ? (month = 1, day += 12) : (month = 2, day -= 19);
                    break;
                case 5:
                    tempp=  (day < 21) ? (month = 2, day += 11) : (month = 3, day -= 20);
                    break;
                case 6:
                    tempp=  (day < 21) ? (month = 3, day += 11) : (month = 4, day -= 20);
                    break;
                case 7:
                    tempp=   (day < 22) ? (month = 4, day += 10) : (month = 5, day -= 21);
                    break;
                case 8:
                    tempp=  (day < 22) ? (month = 5, day += 10) : (month = 6, day -= 21);
                    break;
                case 9:
                    tempp=  (day < 22) ? (month = 6, day += 10) : (month = 7, day -= 21);
                    break;
                case 10:
                    tempp=  (day < 22) ? (month = 7, day += 9) : (month = 8, day -= 21);
                    break;
                case 11:
                    tempp=   (day < 21) ? (month = 8, day += 10) : (month = 9, day -= 20);
                    break;
                case 12:
                    tempp=  (day < 21) ? (month = 9, day += 10) : (month = 10, day -= 20);
                    break;
                default:
                    break;
            }
        }
        if (y == 3) {
            year -= ((month < 3) || ((month == 3) && (day < 21))) ? 622 : 621;
            switch (month) {
                case 1:
                    tempp=   (day < 20) ? (month = 10, day += 11) : (month = 11, day -= 19);
                    break;
                case 2:
                    tempp=  (day < 19) ? (month = 11, day += 12) : (month = 12, day -= 18);
                    break;
                case 3:
                    tempp=   (day < 21) ? (month = 12, day += 10) : (month = 1, day -= 20);
                    break;
                case 4:
                    tempp=   (day < 21) ? (month = 1, day += 11) : (month = 2, day -= 20);
                    break;
                case 5:
                case 6:
                    tempp= (day < 22) ? (month -= 3, day += 10) : (month -= 2, day -= 21);
                    break;
                case 7:
                case 8:
                case 9:
                    tempp=  (day < 23) ? (month -= 3, day += 9) : (month -= 2, day -= 22);
                    break;
                case 10:
                    tempp= (day < 23) ? (month = 7, day += 8) : (month = 8, day -= 22);
                    break;
                case 11:
                case 12:
                    tempp= (day < 22) ? (month -= 3, day += 9) : (month -= 2, day -= 21);
                    break;
                default:
                    break;
            }
        }
        if(format===null || format===undefined)
          return `${week[d]} ${day} ${months[month - 1]} ${year}`
        if(format==="y/m/d")
          return `${year}/${month}/${day}`;
        if(format==="d/m/y")
          return `${day}/${month}/${year}`;
      }
    

    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }

    handleChangeToDate = (unix, formatted) => {
        this.setState({toDate: formatted})
    }
    handleSearchButtonClick = () => {
       let fileName=`CurrentDayNimaDetails_${this.state.toDate}.xls`;
       httpCaller.Dashboard.ExportDetailsRptTotalNimaExportWithDate(fileName,this.state.toDate); 
    }

    DatePickerInput(props) {
        return <Input {...props} style={{textAlign:"center", backgroundColor: "white"}} />;
    }

    render() {
        const FormItem = Form.Item;
        return (
            <React.Fragment>
                <Row >
                    <Col    sm={11}>
                    <div style={{fontSize:'1.6rem', fontWeight:'Bold'}}>
                    گزارش عرضه ارز نیما براساس نوع صادر کننده
                    </div>
                           
                    </Col>
                    </Row>
                    <Divider style={{backgroundColor:'#c5c5c5',height:'2px'}}></Divider>
                <Card className="gx-card">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>

                            <Col md={8} sm={24}>
                                <FormItem
                                    label=" تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <DatePicker
                                        customClass ="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleChangeToDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="toDate"
                                       preSelected={ this.state.toDate}
                                        value={this.state.toDate}
                                    />

                                   
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                            <Button type="primary" icon="download" 
                            onClick={this.handleSearchButtonClick}  >
                                {`دریافت گزارش اکسل`}</Button>
      
                           </Col>
                        </Row>


                    </Form>
                </Card>

            </React.Fragment>
        );
    }
}
export default CheckPermissionHOC(withRouter(CurrencySupplyToday), "Dashboard_Nima_Total");

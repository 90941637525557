import React, { Component } from 'react';
import { Row, Col, Card, Button, Input, Table, Form, Select, option,Divider } from 'antd';
import { withRouter } from "react-router-dom";
import httpCaller from 'services/httpService';
//import httpService from '../../../services/httpService'
import NumberFormat from 'react-number-format';

import { NotificationManager } from 'react-notifications'

import { CheckPermissionHOC } from '../../services/authorization';
import MaskedInput from 'antd-mask-input'


//this.props.history.push(`/user/view/${user.id}`);
class CurrencyCeilingRateGrid extends Component {
//btnTimeHandeler
    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,
            searchTimeResult: [],
            searchFromDate: "",
            searchToDate: "",
            selectedDate:""
        };
    }
    componentDidMount () {
        this.handleSearchButtonClick();

    }

    
componentWillMount() {
 
}
    
// changeBackColor= ()=>{

//     if(this.props.forumType =="1"){
//         var cols = document.getElementsByClassName('ant-card-body');
//      //   alert(this.props.forumType +"::"+ cols.length )
//         for(let i = 0; i < cols.length; i++) {
//             cols[i].style.removeProperty('background-color');
//         }
//     }
//     else  {
//         var cols = document.getElementsByClassName('ant-card-body');
//       //  alert(this.props.forumType +"::"+ cols.length )
//         for(let i = 0; i < cols.length; i++) {
//             cols[i].style.backgroundColor = '#d2ded2';
//         }
//     }
// }

    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }

    handleSearchButtonClick = () => {
        this.state.searchTimeResult=[];
        this.doSearch();
        //NotificationManager.warning('فیلد از تاریخ باید از فیلد تا تاریخ کوچکتر باشد');   
    }

    handelRegisterNewRate= () => {
        this.props.history.push("/setting/currencyceilingrate?forumType="+this.props.forumType);
    }
    doSearch = () => {
        const rejectedHandler = (ar) => {
            NotificationManager.warning('خطا در دریافت دادها');
        };
        var param = {
            fromDate:this.state.searchFromDate,
            toDate:this.state.searchToDate,
            forumType:parseInt(this.props.forumType) 
        }
        
        httpCaller.NimaExport.GetOldCurrencyCelling(param,
            (data) => {
                this.setState({ searchResult: data });
                if(data.length> 0 ){
                  this.btnTimeHandeler(data[0].dateTimeStr);  
                }
                
            }, rejectedHandler);
            
    }

    handleSearchFromDate = (e) => {
        this.setState({ searchFromDate: e.target.value })
    }

    handleSearchToDate = (e) => {
        this.setState({ searchToDate: e.target.value })
    }

    btnTimeHandeler = (dTime)=>{
        console.log("dTime: ", dTime)
  
       this.setState({ selectedDate: dTime });
        this.state.searchResult.map((timeObj, i) => {
         if(timeObj.dateTimeStr === dTime){
            this.state.searchTimeResult= timeObj.items;
         }
        });
        this.forceUpdate();
    }

    render() {
        const FormItem = Form.Item;
        const columns = [ {
            title: 'نوع ارز',
            dataIndex: 'currencyName',
            },
            {
                title: 'درصد فروش نسبت به خرید',
                dataIndex: 'differentPercentage',
                render: (text, row, index) => <NumberFormat value={text}  displayType={'text'} thousandSeparator={true} suffix={`درصد`} />
            },
            
            {
                title: 'سقف فروش ارز  ',
                dataIndex: 'rateSell',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={`ریال`} />
            },
            {
                title: 'سقف خرید ارز  ',
                dataIndex: 'rate',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={`ریال`} />
            },
            {
                title: 'کف فروش ارز  ',
                dataIndex: 'floorRateSell',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={`ریال`} />
            },
            {
                title: 'کف خرید ارز  ',
                dataIndex: 'floorRate',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={`ریال`} />
            }, 
            {
                title: ' تاریخ ثبت',
                dataIndex: 'dateStr',
                render: (text, row, index) => <span class="dirLtr" > {text}</span>
            }

        ];
        return (
            
            <React.Fragment >
       
                <Card style={{backgroundColor: this.props.forumColor}}>
                    <Col  md={4} xs={8} className="rightSet" >
                    <MaskedInput mask="1111/11/11" id="dealingFromDate" name="dealingFromDate" placeholder="yyyy/mm/dd"
                        style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                         placeholder="1398/01/01" value={this.state.searchFromDate}
                        onChange={this.handleSearchFromDate} />
                    </Col>
                    <Col  md={4} xs={8} className="rightSet" >
                    <MaskedInput mask="1111/11/11" id="dealingToDate" name="dealingToDate" placeholder="yyyy/mm/dd"
                        style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                        placeholder="1398/06/01" onKeyUp={this.handleSearchInputKeyUp} value={this.state.searchToDate}
                        onChange={this.handleSearchToDate} />
                    </Col>
                    <Button type="primary" htmlType="button" onClick={() => this.handleSearchButtonClick()} icon="search"   >
                    جستجو
                    </Button>

                    <Button type="primary" htmlType="button" onClick={() => this.handelRegisterNewRate()} icon="save"   >
                    ثبت نرخ جدید 
                    </Button>

                    <Divider />
                    {
                        this.state.searchResult === null ? "" :  this.state.searchResult.map((currDate, i) => { 
                            return(
                                <Button type="default"  htmlType="button" onClick={() => this.btnTimeHandeler(currDate.dateTimeStr )}  shape="round"
                                 className={currDate.dateTimeStr === this.state.selectedDate ?  "dirLtr bakBlue" :"dirLtr"  } >
                                {currDate.dateTimeStr}
                                </Button>
                            ) 
                          })
                    }

                </Card>
                {this.state.searchResult && (<Card className="gx-table-responsive withPointer">
                    <Table className="gx-table-responsive withPointer" 
                        columns={columns}
                        dataSource={ this.state.searchTimeResult}
                        pagination= {false}
                        bordered
                        // pagination={{
                        //     total: this.state.searchResult.totalCount,
                        //     pageSize: this.state.searchResult.pageSize,
                        //     current: this.state.searchResult.pageIndex,
                        //     onChange: this.handlePageChange
                        // }}
                    //onRowClick={this.GetRequestDetials}
                    />
                </Card>)}

            </React.Fragment>
        );
    }
}


export default CheckPermissionHOC(withRouter(CurrencyCeilingRateGrid), "SettingManagement_RateRange");

{/* <style>{"\
.ant-card-body{\
    background-color: #d2dfd2;\
}\
"}</style>  */}
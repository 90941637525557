import React, { Component } from 'react';
import { Input, Checkbox, Select,Row,Col,Form,TimePicker,Button, Card } from "antd";
import moment from "moment";
import {NotificationManager} from 'react-notifications'
import HttpService from "../../../services/httpService"
import {CheckPermissionHOC} from "../../../services/authorization"


class NimaOperationTime extends Component {
    constructor(props) {
        super(props);

        this.timeFormat = 'HH:mm';
        this.state={
            startTime:"0:0",
            endTime:"23:59",
            noTime : true
        }
        
    }
    componentDidMount() {
        this.loadData(true);
    }
    
    loadData = (val) => {
        HttpService.Setting.getNimaDate((data) => {
            this.setState({
                startTime:data.startTime,
                endTime:data.endTime.format(this.timeFormat),
                noTime: data.noTime.format(this.timeFormat),
                description: data.description
            });
        }, () => { }, val);
    }
    saveData = () => {
        if (this.state.description.trim() == '') {
            NotificationManager.error('ثبت توضیحات الزامی است.');
        }
        HttpService.Setting.saveNimaDate({
            startTime:this.state.startTime,
            endTime:this.state.endTime,
            noTime: this.state.noTime,
            description: this.description
        }, () => {
            NotificationManager.success('تغییرات ذخیره شده است.');
            this.loadData(false);// به منظور بررسی صحت عملبات در سرور
        });
    }


    handleTimeChange = (prop, value) => {
        if(value)
            this.setState({ [prop]: value.format(this.timeFormat) })
    }
    handleActive = (e) => {
        this.setState({ noTime: e.target.checked });
    }
    handleDescription = (e) => {
        this.setState({ description: e.target.value })
    }

    render() {
        
        return (
            <Card title='ساعت کاری نیما' headStyle={{backgroundColor:"#f18805",color:"#f6f9fb"}}>
                <div className="gx-description">
                <Row>
                    <Col xxl={8}  sm={10} xs={24}>
                        <Checkbox onChange={this.handleActive} checked={this.state.noTime}>بدون محدودیت زمانی</Checkbox>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col lg={10}  sm={12}  xs={24}>
                        <Form.Item
                            label="از ساعت"
                            labelCol={{ xs: 24, lg: 8, xl: 6 }}
                            wrapperCol={{ xs: 24, lg: 16, xl: 18 }}
                        >
                            <TimePicker disabled={this.state.noTime} value={moment(this.state.startTime, this.timeFormat)} format={this.timeFormat} onChange={(v) => this.handleTimeChange("startTime", v)}/>
                        </Form.Item>
                    </Col>
                    <Col lg={10} sm={12} xs={24}>
                        <Form.Item
                            label="تا ساعت"
                            labelCol={{ xs: 24, lg: 8, xl: 6 }}
                            wrapperCol={{ xs: 24, lg: 16, xl: 18 }}
                        >
                            <TimePicker disabled={this.state.noTime} value={moment(this.state.endTime, this.timeFormat)} format={this.timeFormat} onChange={(v) => this.handleTimeChange("endTime", v)}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                        <Col xxl={10} sm={14} xs={24}>
                                    <Form.Item
                                        label="توضیحات"
                                        labelCol={{ xs: 24, lg: 8, xl: 6 }}
                                        wrapperCol={{ xs: 24, lg: 16, xl: 18 }}
                                    >
                                        <Input.TextArea value={this.state.description} onChange={this.handleDescription} />
                                    </Form.Item>
                        </Col>
                </Row>
                </div>
                <div className="gx-card-list-footer">
                    <Button type="primary" onClick={()=> this.saveData()}>ذخیره</Button>
                </div>
                </Card>
        );
    }
}

NimaOperationTime.propTypes = {

};

export default CheckPermissionHOC(NimaOperationTime,"SettingManagement_NimaOperTime");

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, withRouter, Redirect, Link } from "react-router-dom";
import httpCaller from '../../../services/httpService';
import { connect } from 'react-redux';

import Businessmenpermission from "./Businessmenpermission";
import { HasPermission, CheckPermissionHOC } from '../../../services/authorization'


import NumberFormat from 'react-number-format';
import { Row, Col, Form, Input, Card, Table, Button, Switch, Icon, ExpandableRow, Select } from 'antd';

class BusinessmenManagment extends Component {

    constructor(props) {
        super(props)

        this.state = {
            searchResult: null,
            Businessmans: [],
            pageIndex: 1,
            pageSize: 10,
            totalItemsCount: 0,
            filter:{
                hasAccess:null,
                justSuuplyForBankSarafi:null,
                blockedByManual:null,
                blockedBySystem:null,
                supplierCategory:0,
                ignorePositionCalc: null,
            }
            
        };
        this.merchentFilterRef = {
            nationalId: React.createRef(),
            name: React.createRef(),
        };

    }
   
    componentDidMount() {
        this.handlGetBusinessmen();
    }
    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handlGetBusinessmen();
        }
    }
    handlePageIndexChanged = (pageIndex, pageSize) => {
        this.state.pageIndex = pageIndex;
        this.handlGetBusinessmen();
    }


    handlePageChange = (page) => {
        sessionStorage.setItem("TablePage", page)
        this.setState({
            pageIndex: page
        }, () => {
            this.handlGetBusinessmen();
        })
    };

    doSearch =()=>{
        this.setState({pageIndex:1},this.handlGetBusinessmen)
    }
    handlGetBusinessmen = () => {
        const successHandler = (result) => {
            this.setState({ searchResult: result });
            console.log(this.state.searchResult.items)
        };
      
        var element = document.getElementById('permisionDiv');

        if (element !== null) {
            element.parentNode.removeChild(element);
        }
        httpCaller.Merchant.GetBusinessmens(this.state.pageIndex, this.state.pageSize,
            this.merchentFilterRef.nationalId.current.input.value,
            this.merchentFilterRef.name.current.input.value,
            this.state.filter.hasAccess,
            this.state.filter.justSuuplyForBankSarafi,
            this.state.filter.blockedByManual,
            this.state.filter.blockedBySystem,
            this.state.filter.supplierCategory,
            this.state.filter.ignorePositionCalc,
             successHandler);
    }
    handleChange =  (prp, value) => {
        var f = this.state.filter;
        f[prp] = value
        this.setState({filter : f});
        console.log("filter",this.state.filter)
    }
    render() {
        const FormItem = Form.Item;
        const Option = Select.Option;
        const columns =
            [{
                title: 'نام بازرگان/ شرکت',
                dataIndex: 'fullName',
            },{
                title: 'نوع بازرگان',
                dataIndex: 'merchentKind',
            },{
                title: 'کد ملی',
                dataIndex: 'nationalId',
            },{
                title: 'مجوز عرضه ارز در نیما',
                dataIndex: 'haveAccess',
                render: (text, record) => (<Switch size ={"small"}  disabled={true} checked={record.haveAccess} />),
            }
            ,{
                title: 'محدودیت عرضه ارز به بانک ها و صرافی های بانکی',
                dataIndex: 'justSupplyForBankSarafi',
                render: (text, record) => (<Switch size ={"small"}  disabled={true} checked={record.justSupplyForBankSarafi} />),
            },
            ,{
                title: 'مسدودی دستی',
                dataIndex: 'blockedByManualStr',
            },
            ,{
                title: 'مسدودی محاسباتی',
                dataIndex: 'blockedBySystem',
                render: (text, record) => (<Switch size ={"small"}  disabled={true} checked={record.blockedBySystem} />),
            },
            ,{
                title: 'مستثنی شده از اعمال پوزیشن بازرگان',
                dataIndex: 'ignorePositionCalc',
                render: (text, record) => (<Switch size ={"small"}  disabled={true} checked={record.ignorePositionCalc} />),
            },
        ];


        return (
            <React.Fragment >
                <Card className="gx-card">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label=" کد ملی"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                >
                                    <Input ref={this.merchentFilterRef.nationalId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label=" نام بازرگان"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                >
                                    <Input ref={this.merchentFilterRef.name} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>

                        </Row>
                        <Row>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label="مجوز عرضه ارز در نیما"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                    style={{marginBottom:0}}
                                >
                                    <Select defaultValue="null" ref={this.merchentFilterRef.hasAccess}  onChange={(val)=> this.handleChange("hasAccess",val)}>
                                        <Option value="null">همه</Option>
                                        <Option value="true">دارد</Option>
                                        <Option value="false" >ندارد</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label="محدودیت عرضه ارز به بانک ها و صرافی های بانکی"
                                    labelCol={{  md: 14,  }}
                                    wrapperCol={{  md: 10,  }}
                                    style={{marginBottom:0}}
                                >
                                     <Select defaultValue="null" ref={this.merchentFilterRef.justSuuplyForBankSarafi} className="gx-ml-3 gx-mb-3" style={{width: 120}} onChange={(val)=> this.handleChange("justSuuplyForBankSarafi",val)}>
                                        <Option value="null">همه</Option>
                                        <Option value="true">دارد</Option>
                                        <Option value="false" >ندارد</Option>
                                    </Select>
                                </FormItem>
                            </Col>

                        </Row>

                        <Row>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label="مسدودی دستی"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                >
                                    <Select defaultValue="null" ref={this.merchentFilterRef.hasAccess}  onChange={(val)=> this.handleChange("blockedByManual",val)}>
                                        <Option value="null">همه</Option>
                                        <Option value="0" checked={this.state.BlockedByManual}  >مسدود نشده</Option>
                                        <Option value="1"  checked={this.state.BlockedByManual} >مسدود شده</Option>
                                        <Option value="2"  checked={this.state.BlockedByManual} >رفع همه مسدودیها</Option>  
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label="مسدودی محاسباتی"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                >
                                     <Select defaultValue="null" ref={this.merchentFilterRef.justSuuplyForBankSarafi} onChange={(val)=> this.handleChange("blockedBySystem",val)}>
                                        <Option value="null">همه</Option>
                                        <Option value="true">مسدود شده</Option>
                                        <Option value="false" >مسدود نشده</Option>
                                    </Select>
                                </FormItem>
                            </Col>

                        </Row>
                        <Row>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label="مستثنی شده از اعمال پوزیشن بازرگان"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                >
                                    <Select defaultValue="null" ref={this.merchentFilterRef.ignorePosCalc}  onChange={(val)=> this.handleChange("ignorePositionCalc",val)}>
                                        <Option value="null">همه</Option>
                                        <Option value="true">مستثنی شده</Option>
                                        <Option value="false" >مستثنی نشده</Option> 
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col  md={12} sm={24}>
                                <FormItem
                                    label="گروه بازرگان"
                                    labelCol={{  md: 8,  }}
                                    wrapperCol={{  md: 12,  }}
                                    style={{marginBottom:0}}
                                >
                                    <Select defaultValue="0"  className=""  onChange={(val)=> this.handleChange("supplierCategory",val)}>
                                        <Option value="0" checked={this.state.SupplierCategory} >همه</Option>
                                        <Option value="1" checked={this.state.SupplierCategory} >پالایشگاه</Option>
                                        <Option value="2" checked={this.state.SupplierCategory} >پتروشیمی</Option>
                                        <Option value="3" checked={this.state.SupplierCategory} >ایمیدرو</Option>
                                        <Option value="4" checked={this.state.SupplierCategory} >بانك مركزي</Option>
                                        <Option value="5" checked={this.state.SupplierCategory} >شرکت های ملی گاز</Option>
                                        <Option value="6" checked={this.state.SupplierCategory} >شرکت های ملی نفت</Option>
                                        <Option value="7" checked={this.state.SupplierCategory} >شرکت های ملی پخش فرآورده های نفتی</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col  md={10} sm={24}>
                                <Button type="primary" icon="search" htmlType="button" style={{float:"left"}} onClick={() => this.doSearch()}>
                                    جستجو
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                           
                        </Row>
                    </Form>
                </Card>

                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        defaultExpandAllRows={false}
                        expandedRowRender={(record, index) =>
                            <Businessmenpermission onChange={this.handlGetBusinessmen} nationalId={record.nationalId} fullName={record.fullName}
                             haveAccess={record.haveAccess} blockedByManual={record.blockedByManual}  blockedBySystem={record.blockedBySystem}  
                             justSupplyForBankSarafi={record.justSupplyForBankSarafi} merchentKind={record.merchentKind } 
                             ignorePositionCalc={record.ignorePositionCalc} merchantCategory={record.merchantCategory}></Businessmenpermission>}
                            expandRowByClick={true}

                        dataSource={this.state.searchResult.items}
                        onExpand={(expanded, record) => {
                           var expRows = document.getElementsByClassName("ant-table-row-expanded");
                            var i;
                            for (i = 0; i < expRows.length; i++) {
                                expRows[i].click();
                            }
                        }}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageIndexChanged
                        }}
                        rowKey="nationalId"
                    />
                </Card>)}


            </React.Fragment>
        );
    }
}

export default CheckPermissionHOC(withRouter(BusinessmenManagment), "Merchant_permission");  

import React, { Component } from 'react';
import { Row, Col, Card,Tree} from 'antd';
import {NotificationManager} from 'react-notifications'

import httpeService from '../../services/httpService'

class UserRolesPermission extends Component {
    constructor(props) {
        super(props);

        this.permissionTreeNodes = [];

        this.state = {
            checkedKeys: [],
        }
    }
    componentDidMount() {
        httpeService.Auth.getAllPermission((allPermission) => {

            this.prepareDataForTree(allPermission);
            httpeService.Auth.getUserRolesPermission({ userId: this.props.userId }, (items) => {
                this.setState({ checkedKeys: items });
            })
        })

    }

    prepareDataForTree = (items=[]) =>{
        let c1 = [];

        items.forEach((node) => {
            if (node.parent == null) {
               
                let childerenNodes = items.filter((a)=> a.parent && a.parent.key == node.key);
                let c2=[];
                
                childerenNodes.forEach((childNode) => {
                       
                        let innerChildren= items.filter((a)=> a.parent && a.parent.key == childNode.key);
                       
                        let c3= [];
                        innerChildren.forEach((innerChild) => {
                            c3.push({ key: innerChild.key, title: innerChild.description, children: null })
                        });

                        c2.push({ key: childNode.key, title: childNode.description, children: c3.length == 0 ? null : c3 })
                });
                
                c1.push({ key: node.key, title: node.description, children: c2.length == 0 ? null : c2 });
            }
        });
        console.log("c1",c1)
        this.permissionTreeNodes = c1;
    }
    renderTreeNodes = (items) => {
        return items.map((item) => {
          if (item.children) {
            return (
              <Tree.TreeNode title={item.title} key={item.key} dataRef={item}>
                {this.renderTreeNodes(item.children)}
              </Tree.TreeNode>
            );
          }
          return <Tree.TreeNode {...item} />;
        });
      };

    handleSavePermission = () => {
        httpeService.Auth.updatePermission({ roleId: this.props.roleId, permissions: this.state.checkedKeys }, () => {
            NotificationManager.success("عملیات با موفقیت انجام شده است.");

        })
    }
    render() {

        return (
            <React.Fragment>
                <Row>
                    
                    {/* <Col md={24}>
                    <span>سطح دسترسی</span>
                    </Col> */}
                    <Col xl={8}>
                        <Card className="gx-card" title=" سطح دسترسی" style={{paddingRight : 30}}>
                      
                                 <Tree
                                    checkable
                                    // onExpand={this.onExpand}
                                    // expandedKeys={this.state.expandedKeys}
                                    // autoExpandParent={this.state.autoExpandParent}
                                    onCheck={checkedKeys => console.log("clickme") } //this.setState({ checkedKeys })
                                    checkedKeys={this.state.checkedKeys}
                                    // onSelect={this.onSelect}
                                    // selectedKeys={this.state.selectedKeys}
                                    >
                                    {this.renderTreeNodes(this.permissionTreeNodes)}
                                </Tree>
                                
                                {/* <button type="button" className="btn btn-primary" style={{marginTop:"2rem"}} onClick={this.handleSavePermission}>
                                    {"ثبت"}
                                </button> */}
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
 
        );
    }
}


export default UserRolesPermission;
import React, { Component } from 'react';
import { Card, Col, Button, Table, Input,Row} from 'antd';

import httpService from '../../services/httpService'
import { HasPermission, CheckPermissionHOC } from '../../services/authorization'

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue:'',
        }
    }

    componentDidMount() {
        this.doSearch(1);
    }

    handleSearchClicked = (value) => {
        this.setState({searchValue: value },()=>{this.doSearch(1)});
    }
    handlePageIndexChanged = (selectedIndex) => {
        this.doSearch(selectedIndex)
    }
    doSearch = (pageIndex) => {

        httpService.Auth.getRoleUserCountInfo({ roleName:this.state.searchValue, pageIndex: pageIndex }, (data) => {
            this.setState({ searchResult: data});
        })
    }
    handleShowRole(roleID) {
        this.props.history.push(`/role/${roleID}`)
    }

    render() {
        const columns = [
            {
                title: 'نام نقش',
                dataIndex: 'roleName',
            },
            {
                title: 'توضیحات',
                dataIndex: 'description',
            },
            {
                title: 'تعداد کاربران فعال',
                dataIndex: 'activeUserCount',
            render: (text) => (<span style={{ textAlign: "center" }}>{text}</span>)
            },
            {
                title: 'تعداد کاربران غیر فعال',
                dataIndex: 'nonActiveUserCount',
            render: (text) => (<span style={{ textAlign: "center" }}>{text}</span>)
            }
        ]
        return (
            <React.Fragment>
                <Card>
                    <Row>
                        <Col Col md={12} lg={10}>
                            <Input.Search
                                placeholder="جستجو بر اساس نام نقش"
                                onSearch={this.handleSearchClicked}
                                enterButton
                            />
                        </Col>

                        {HasPermission("RoleManagement_CreateRole") &&
                            <Col md={12} lg={14} style={{ float: "left", textAlign: "left" }}>
                                <Button type="primary" onClick={() => this.props.history.push("/role/0")}> ایجاد نقش</Button>
                            </Col>
                        }

                    </Row>
                </Card>
                {this.state.searchResult && (<Card>
                    <Table
                        className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageIndexChanged
                        }}
                        onRowClick={(row) => this.handleShowRole(row.roleId)}
                    >
                    </Table>
                    </Card>)
                }
               
            </React.Fragment>
        );
    }
}

Roles.propTypes = {

};

export default CheckPermissionHOC(Roles, "RoleManagement_ViewRole");

import React, { Component } from 'react';
import { Card, Row, Col, Form, Divider, Select, Button, Table, TreeSelect, Input, Alert, Modal, Radio } from 'antd';
import { NotificationManager } from 'react-notifications';
import NumberFormat from 'react-number-format';

import httpService from '../../services/httpService';

class PositionCorrection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchFilter:{sarafiId:null},
            searchResult: [],
            newItem: {},
            deleteModal: { show: false, id: 0 },
            sarafies: []
        }
    }
    componentDidMount() {
        this.loadData(1, true);
        httpService.Sarafi.GetAllSarafies("", (data) => {
            this.setState({ sarafies: data });
        }, null, false);
    }
    loadData = (pageIndex, isSOCLog) => {
        httpService.Sarafi.GetPositionCorrections({ pageIndex, sarId: this.state.searchFilter.sarafiId }, (data) => {
            this.setState({ searchResult: data })
        }, null, isSOCLog);
    }
    handlePageIndexChanged = (selectedIndex) => {
        this.loadData(selectedIndex, true)
    }
    setShowModel = (isShow, id) => {
        this.setState({ deleteModal: { show: isShow, id: id } });
    }
    handleRemoveButtonClick = () => {
        httpService.Sarafi.DeletePositionCorrection(this.state.deleteModal.id, () => {
            this.setShowModel(false, 0);
            NotificationManager.success("عملیات حذف با موفقیت انجام شده است.");
            setTimeout(() => { this.loadData(1, false) }, 1000);
        });
    }
    handleTreeSelect = (value) => {
        this.setState({ newItem: { ...this.state.newItem, SarafiId: parseInt(value) } })
    }
    handleTranTypeChange = (e) => {
        this.setState({ newItem: { ...this.state.newItem, TransactionType: e.target.value } })
    }
    handlePayTypeChange = (e) => {
        this.setState({ newItem: { ...this.state.newItem, PaymentType: e.target.value } })
    }
    handleEuroAmountChange = (e) => {
        this.setState({ newItem: { ...this.state.newItem, EuroAmount: e.target.value } })
    }
    handleDescriptionChange = (e) => {
        this.setState({ newItem: { ...this.state.newItem, Description: e.target.value } })
    }
    handleSearchTreeSelect = (value) => {
        this.setState({ searchFilter: { ...this.state.searchFilter, sarafiId: (!value ? null : parseInt(value)) } })
    }
    doSearch =()=>{
        this.loadData(1,true);
    }
    submitData = () => {
        var item = this.state.newItem;
        if (!item.TransactionType) {
            NotificationManager.error("نوع تراکنش وارد نشده است.");
            return;
        }
        if (!item.PaymentType) {
            NotificationManager.error("نوع پرداخت وارد نشده است.");
            return;
        }
        if (!item.EuroAmount || item.EuroAmount == 0) {
            NotificationManager.error("مقدار وارد نشده است.");
            return;
        }
        if (!item.SarafiId) {
            NotificationManager.error("صرافی انتخاب نشده است.");
            return;
        }
        if (!item.Description || item.Description.trim() == '') {
            NotificationManager.error("پر کردن فیلد توضیحات اجباری می باشد.");
            return;
        }
        httpService.Sarafi.AddPositionCorrection(item, () => {
            NotificationManager.success("عملیات با موفقیت انجام شده است.");
            this.setState({ newItem: { ...this.state.newItem, EuroAmount: 0, Description: '' } });
            this.loadData(1, false);
        })
    }
    getDetailData = (record, index) => {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={24} >
                        <Form.Item
                            label="توضیحات"
                            labelCol={{ xs: 24, md: 4, }}
                            wrapperCol={{ xs: 24, md: 20, }}
                        >
                            <Input.TextArea rows={4} value={record.description} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col sm={24} md={16} >
                        <Button  type="danger" htmlType="button" onClick={() => { this.setShowModel(true, record.id) }} >حذف  </Button>
                    </Col>

                </Row>
            </React.Fragment>
        )
    }
    render() {
        const columns = [
            {
                title: 'کد صرافی',
                dataIndex: 'sarafiId',
            },
            {
                title: 'نام صرافی/بانک',
                dataIndex: 'sarName',
            },
            {
                title: 'نوع تراکنش',
                dataIndex: 'transactionTypeStr',
                render:(text, row, index)=><span style={{color:(row.transactionType==1 ? 'green': 'red')}}>{row.transactionTypeStr}</span>
            },
            {
                title: 'نوع پرداخت',
                dataIndex: 'paymentTypeStr',
                //render:(text, row, index)=><span style='color:red'></span>
            },
            {
                title: 'تاریخ ثبت',
                dataIndex: 'pDate',
            },
            {
                title: 'مقدار(به یورو)',
                dataIndex: 'euroAmount',
                render: (text, row, index) => <NumberFormat value={text} allowedDecimalSeparators='.' displayType={'text'} thousandSeparator={true} />
            }
        ];
        let FormItem = Form.Item;
        return (

            <React.Fragment>
                <Modal

                    visible={this.state.deleteModal.show}
                    title=""
                    type="error"
                    footer={[
                        <Button key="back" type="danger" onClick={() => this.handleRemoveButtonClick()}>حذف</Button>,
                        <Button key="submit" type="default" onClick={() => this.setShowModel(false, 0)}> انصراف</Button>,
                    ]}
                >
                    <Row>
                        <Col md={2}> </Col>
                        <Col md={20}>
                            آیا از حذف تراکنش اطمینان دارید؟
                        </Col>
                        <Col md={2}> </Col>
                    </Row>
                </Modal>
                <Card  headStyle={{backgroundColor:"#d9edf7",color:"#31708f",fontSize:'20px',fontWeight:'bolder'}} title="ثبت سند اصلاحی به منظور استفاده در محاسبات پوزیشن">
                    <Row>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="صرافی"
                                labelCol={{ xs: 24, md: 6, lg: 5 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <TreeSelect
                                    showSearch
                                    allowClear
                                    treeNodeFilterProp='title'
                                    dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                    placeholder="لطفا انتخاب کنید"
                                    style={{ width: "100%" }}
                                    onChange={this.handleTreeSelect}>
                                    {
                                        this.state.sarafies.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                    }
                                </TreeSelect>

                            </FormItem>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="مقدار(یورو)"
                                labelCol={{ xs: 24, md: 6, lg: 5 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <Input value={this.state.newItem.EuroAmount} onChange={this.handleEuroAmountChange} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="نوع تراکنش"
                                labelCol={{ xs: 24, md: 6, lg: 5 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <Radio.Group onChange={this.handleTranTypeChange} value={this.state.newItem.TransactionType}>
                                    <Radio value={1}>خرید صرافی</Radio>
                                    <Radio value={2}>فروش صرافی</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="نوع پرداخت"
                                labelCol={{ xs: 24, md: 6, lg: 5 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <Radio.Group onChange={this.handlePayTypeChange} value={this.state.newItem.PaymentType}>
                                    <Radio value={1}>اسکناس</Radio>
                                    <Radio value={2}>حواله</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={18}>
                            <FormItem
                                label="توضیحات"
                                labelCol={{ xs: 24, md: 5, lg: 4 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <Input.TextArea rows={4} onChange={this.handleDescriptionChange} value={this.state.newItem.Description} />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} sm={24}>
                            <Button type="primary" htmlType="button" onClick={this.submitData}>
                                ثبت
                            </Button>
                        </Col>
                    </Row>

                </Card>

                <Card>
                    <Row>
                        <Col md={12} sm={24}>
                                <FormItem
                                    label="صرافی"
                                    labelCol={{ xs: 24, md: 6, lg: 5 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        treeNodeFilterProp='title'
                                        dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                        placeholder="لطفا انتخاب کنید"
                                        style={{ width: "100%" }}
                                        value={this.state.searchFilter.sarafiId}
                                        onChange={this.handleSearchTreeSelect}>
                                        {
                                            this.state.sarafies.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                        }
                                    </TreeSelect>

                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                            <Button type="primary" htmlType="button" onClick={this.doSearch}>
                                جستجو
                            </Button>
                        </Col>
                            
                    </Row>
                    <Table
                        className="gx-table-responsive"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        defaultExpandAllRows={false}
                        expandedRowRender={(record, index) => this.getDetailData(record)}
                        expandRowByClick={true}
                        onExpand={(expanded, record) => {
                            var expRows = document.getElementsByClassName("ant-table-row-expanded");
                            for (let i = 0; i < expRows.length; i++) {
                                expRows[i].click();
                            }
                        }
                        }
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageIndexChanged
                        }}
                    >
                    </Table>
                </Card>
            </React.Fragment>
        );
    }
}

export default PositionCorrection;
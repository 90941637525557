import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import logo from '../assets/images/LoginLogo.png'
import { NotificationManager } from 'react-notifications';
import {Alert} from "antd";
import httpService from '../services/httpService'
import { SetAuthToken } from '../services/authorization'
class SignIn extends Component {

	constructor(props) {
		super(props)
		this.state = {
			userName: "",
			password: ""
		}
	}

	handleLogin = (e) => {
		e.preventDefault();
		if (!this.state.userName || !this.state.password) {
			NotificationManager.error("نام کاربری و یا پسورد وارد نشده است.")
		}
		else {
			httpService.Auth.login({ ...this.state }, (token) => {
				SetAuthToken(token);
				this.props.history.push("")
			});
		}


	}

	setInputValue = ({ target }) => {
		this.setState({ [target.name]: target.value })
	}

	getMessage =() => {
		return ( 
			<React.Fragment>
				در صورتی که برای اولین وارد سامانه میشود از طریق
				<Link to={{ pathname: '/Login/SendConfirmationCode' }} >تایید حساب کاربری</Link> 
				روز عبور خود را ایجاد کنید.
			</React.Fragment>
		
		)
	}

	render() {
  
		return (
			<div className="logindiv">
				<div className="limiter">
					<div className="container-login100">
						<div className="wrap-login100">


							<form class="login100-form validate-form">
								<span class="login100-form-title">
									سامانه مدیریت سنا
					</span>

								<div class="wrap-input100 validate-input">
									<input class="input100" type="text" name="userName" placeholder="نام کاربری" onChange={this.setInputValue} />
									<span class="focus-input100"></span>
									<span class="symbol-input100">
										<i class="fa fa-user" style={{ margin: "3%" }} aria-hidden="true" ></i>
									</span>
								</div>

								<div class="wrap-input100 validate-input">
									<input class="input100" type="password" name="password" placeholder="رمز عبور" onChange={this.setInputValue} />
									<span class="focus-input100"></span>
									<span class="symbol-input100">
										<i class="fa fa-lock" style={{ margin: "3%" }} aria-hidden="true"></i>
									</span>
								</div>
								<div class="container-login100-form-btn">
									<button class="login100-form-btn" onClick={this.handleLogin}>
										ورود
									</button>
								</div>
								<div style={{ margin: "10%" }}>
									<Link to={{ pathname: '/Login/SendConfirmationCode' }} style={{ color: "#3c72a8" }}>بازیابی رمز عبور/تایید حساب کاربری</Link>
								</div>

								
							</form>
							<div className="login100-pic js-tilt" data-tilt>
								<img src={logo} alt="بانک مرکزی جمهوری اسلامی ایران" />
							</div>
							<div style={{ margin: "2%" }}>
									<Alert message={(<React.Fragment>در صورتی که برای اولین وارد سامانه میشود از طریق
											<Link to={{ pathname: '/Login/SendConfirmationCode' }} >{" تایید حساب کاربری "}</Link> رمز عبور خود را ایجاد کنید.
										</React.Fragment>)} type="info" showIcon/>
								</div>
							
						</div>
						
					</div>
				</div>
			</div>




		);
	}
}

export default SignIn;
import React, {Component} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {connect} from "react-redux";
import getNavLinks from '../NavLinks'
import { HasPermission } from "../../services/authorization";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  getSubMenu = (item) => {
    const {navStyle} = this.props;
    if(item.subMenus!= null)
      var hasPermissionItems = item.subMenus.filter(a=> !a.permissionKey || HasPermission(a.permissionKey));
   
    if(hasPermissionItems != null && hasPermissionItems.length > 0){
      return (
                <SubMenu key={item.id} className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className={item.icon}/>
                    <IntlMessages id={item.textKey}/></span>}>
                    {
                      hasPermissionItems.map(sub=>{
                        return(
                          <Menu.Item style={{width: '25em', whiteSpace: 'normal', height: 'auto ', }} key={sub.id}>
                            <Link to={sub.link}><i className={sub.icon} /><IntlMessages id={sub.textKey}/></Link>
                          </Menu.Item>
                        )
                      })
                    }
                   </SubMenu>
      )
    }else{
      return(<React.Fragment></React.Fragment>)
    }
    
  }
  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const links =getNavLinks()
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
   
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}

              mode="inline"> 
            {
              
              links.map((item)=>this.getSubMenu(item))
            }

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);


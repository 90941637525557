import React,{Component} from 'react';
import { Col, Row, Button, Modal, Card } from 'antd';
import httpCaller from "../../../services/httpService";
import SimpleFieldSet from '../../utility/SimpleFieldSet'
import { withRouter } from 'react-router-dom';
import DisplayFormData from '../../utility/DisplayFormData';
import {HasPermission} from '../../../services/authorization';

class RequestDetail extends Component{

    static contextTypes = {
        router: () => true
    }
    
    constructor(props)
    {
        super(props)
        this.state = {
            RequestId : this.props.reqId,
            CurrentRequest: '',
            Applicant: '',
            Agent: '',
            AccountInfo: '',
            Hoghughi: "hidden",
            Haghighi: "visible",
            DisplayAgent: "none",
        }
        this.loadRequestDetail = this.loadRequestDetail.bind(this);
    };

    componentDidMount = () => {
        this.loadRequestDetail();
    }

    loadRequestDetail = () => {
        var data = {
            requestId : parseInt(this.state.RequestId)
        }
        httpCaller.NimaImport.GetRequestById(parseInt(this.state.RequestId),(item)=>{
            this.setState({CurrentRequest:item})
            console.log(this.state.CurrentRequest)
            if(item.customerType == 2) 
            {
                this.setState({Hoghughi:"visible"})
                this.setState({Haghighi:"hidden"})
                this.setState({DisplayAgent:"block"})
            }
            else
            {
                this.setState({Hoghughi:"hidden"})
                this.setState({Haghighi:"visible"})
                this.setState({DisplayAgent:"none"})
            }
            this.setState({Applicant: item.applicant})
            this.setState({Agent: item.applicant.agentInfo})
            this.setState({AccountInfo: item.accountInfo})
            
        }, (error) => {
        });
    }

    loadAgentInfo = () => {
        if (this.state.DisplayAgent === "none") return null;
        const _agentInfo = <SimpleFieldSet title="مشخصات نماینده متقاضی" isCollapsible>
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="نام" value={this.state.Agent.firstName} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="نام خانوادگی" value={this.state.Agent.lastName} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="تاریخ تولد" value={this.state.Agent.birthDate} />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="کدملی" value={this.state.Agent.nationalCode} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="شماره شناسنامه" value={this.state.Agent.bcertificateNumber} />
                                </Col>
                            </Row>
                        </SimpleFieldSet>
        return _agentInfo;
    }

    loadMoreInfo = () => {
        if (!HasPermission("RequestManagement_ViewMoreInfo")) return null;
        const moreInfo = <SimpleFieldSet title="مشخصات حساب مقصد" isCollapsible>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کشور مقصد" value={this.state.AccountInfo.destinationCountry} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="بانک مقصد" value={this.state.AccountInfo.destinationBank} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="حساب مقصد" value={this.state.AccountInfo.destinationAccount} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="IBAN" value={this.state.AccountInfo.iban} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد سوییفت" value={this.state.AccountInfo.swift} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="آدرس بانک" value={this.state.AccountInfo.bankAddress} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="سایر کدهای پرداختی" value={this.state.AccountInfo.otherPaymentCode} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="توضیح سایر کدها" value={this.state.AccountInfo.codeDescription} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24}>
                            <SimpleFieldSet title="مشخصات صاحب حساب" isCollapsible>
                                <Row>
                                    <Col md={8} xs={24}>
                                        <DisplayFormData lable="نام ذینفع" value={this.state.CurrentRequest.beneficiary} />
                                    </Col>
                                    <Col md={8} xs={24}>
                                        <DisplayFormData lable="تلفن ذینفع" value={this.state.CurrentRequest.beneficiaryTel} />
                                    </Col>
                                </Row>
                            </SimpleFieldSet>
                        </Col>
                    </Row>
                </SimpleFieldSet>
        return moreInfo;
    }

    render(){
        var cardTitle=(<div><span>{"مشخصات درخواست خرید ارز"}</span>
                        <Button type="default" style={{float:"left", marginBottom:"0px"}} onClick={this.context.router.history.goBack}>بازگشت</Button>
                       </div>)
        return(
            <React.Fragment>
                <Card className="gx-card" title={cardTitle}>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد درخواست" value={this.state.CurrentRequest.requestCode} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="وضعیت درخواست" value={this.state.CurrentRequest.requestStatusDesc} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نوع درخواست" value={this.state.CurrentRequest.requestTypeDesc} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد ثبت سفارش" value={this.state.CurrentRequest.sabteSefareshCode} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="تاریخ ثبت" value={this.state.CurrentRequest.requestRegistrationDateStr} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="ثبت کننده درخواست" value={this.state.CurrentRequest.sourceInsertedDesc} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="مهلت اعتبار درخواست" value={this.state.CurrentRequest.requestValidityDateStr} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="مهلت پرداخت" value={this.state.CurrentRequest.paymentDeadlineStr} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={24} xs={24}>
                            <DisplayFormData lable="علت درخواست" value={this.state.CurrentRequest.requestDetails} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نام و مبلغ ارز" value={this.state.CurrentRequest.amount + ' ' + this.state.CurrentRequest.currencyName} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نوع نرخ" value={this.state.CurrentRequest.currencyRateTypeDesc} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نوع حواله" value={this.state.CurrentRequest.transferenceTypeDesc} />
                        </Col>
                    </Row>
                    <br/>
                    {this.loadMoreInfo()}
                    <br/>
                    <Card className="gx-card" title="مشخصات متقاضی">
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="نوع متقاضی" value={this.state.CurrentRequest.customerTypeDesc} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable={this.state.CurrentRequest.customerType == 1 ? 'نام': 'نام شرکت'} value={this.state.CurrentRequest.customerType == 1 ? this.state.Applicant.firstName:this.state.Applicant.companyName} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="نام خانوادگی" value={this.state.Applicant.lastName} style={{visibility:this.state.Haghighi}}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable={this.state.CurrentRequest.customerType == 1 ? 'کد ملی': 'شناسه ملی'} value={this.state.Applicant.nationalID} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable={this.state.CurrentRequest.customerType == 1 ? 'شماره شناسنامه': 'شماره ثبت'} value={this.state.Applicant.registrationNumber} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable={this.state.CurrentRequest.customerType == 1 ? 'تاریخ تولد': 'تاریخ ثبت'} value={this.state.Applicant.shamsiBirthDate} />
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        {this.loadAgentInfo()}
                    </Card>
                </Card>
            </React.Fragment>
        )
    }
}

export default withRouter(RequestDetail)
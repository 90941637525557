import React from "react";
import {Route, Switch} from "react-router-dom";


import FullSearchExport from "./export/FullSearchExport"


import BusinessmenManagment from './merchants/BusinessmenManagment'
import BlackList from './merchants/BlackList'
import SearchResult from './import/SearchResult'
import SearchContainer from './import/SearchContainer'

import Supply from './export/Supply'

// import CurrencyCeilingRate from './export/CurrencyCeilingRate'
// import CurrencyCeilingRateList from './export/CurrencyCeilingRateList'
 
const Nima = ({match}) => (
    <Switch>
      <Route path={`${match.url}/export`} component={FullSearchExport}/>

      <Route exact path={`${match.url}/merchant`} component={BusinessmenManagment}/>
      <Route exact path={`${match.url}/BlackList`} component={BlackList}/>
      <Route exact path={`${match.url}/import`} component={SearchContainer}/>
      <Route exact path={`${match.url}/supply/:id/:demandId?`} component={Supply}/>
      {/* <Route exact path={`${match.url}/currencyceilingrate`} component={CurrencyCeilingRate}/> */}
      {/* <Route exact path={`${match.url}/currencyceilingratelist`} component={CurrencyCeilingRateList}/> */}
      <Route exact path={`${match.url}/request/:requestCode/:offerId?`} component={SearchResult}/>
      
    </Switch>
  );
  
  export default Nima;

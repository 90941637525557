import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
//import { toast, ToastContainer } from 'react-toastify';
import httpService from '../../services/httpService';
import { NotificationManager } from 'react-notifications';

import NumberFormat from 'react-number-format';
import { Row, Col, Form, Input, Button, Card, Table, Modal } from 'antd'
import DropDownSarafies from './DropDownSarafies';
// import { DatePicker } from "react-advance-jalaali-datepicker";
// import {CheckPermissionHOC} from '../../../services/authorization'
import MaskedInput from 'antd-mask-input'

class SellFromResourceBank extends Component {

  constructor(props) {
    super(props)
    this.state = {
      years: [],
      SellResourse: {
        ExchangeId: 0,

        RepDate: '',
        Euro: 0,
        EuroMarket: 0,
        Dollar: 0,
        DollarMarket: 0,
      },
      search: {
        schDate: '',
      },
      deleteModal: {
        show: false,
        id: 0
      }
    }
  }

  downloadFile = () => {
    this.handledata();
  }

  componentDidMount = () => {
    this.handledataYear();
    this.doSearch(1, true);
  }
  doSearch = (pageIndex, isSOCLog) => {
    httpService.Sana.GetSellFromResourceBank({ pageIndex, ...this.state.search }, (data) => {
      this.setState({ searchResult: data })
    }, () => { }, isSOCLog);
  }
  handlePageIndexChanged = (selectedIndex) => {
    this.doSearch(selectedIndex, true)
  }
  handledataYear = () => {
    const successHandler = (result) => {
      this.setState({ years: result });
      console.log("years", this.state.years);
    };
    httpService.Sana.GetAllYearsSellFromResource(successHandler, () => { });
  }

  handlExchangeId = (e) => {
    this.setState({ SellResourse: { ...this.state.SellResourse, ExchangeId: e } }
      , console.log("CurrencyTypeHanler ==>> this.state", this.state.SellResourse.ExchangeId)
    );
  }
  handleRepDate = (e) => {
    this.setState({ SellResourse: { ...this.state.SellResourse, RepDate: e.target.value } })
  }

  handleEuro = (e) => {
    this.setState({ SellResourse: { ...this.state.SellResourse, Euro: this.parseInt0(e.target.value) } });
  }
  handleEuroMarket = (e) => {
    this.setState({ SellResourse: { ...this.state.SellResourse, EuroMarket: this.parseInt0(e.target.value) } });
  }
  handleDollar = (e) => {
    this.setState({ SellResourse: { ...this.state.SellResourse, Dollar: this.parseInt0(e.target.value) } });
  }
  handleDollarMarket = (e) => {
    this.setState({ SellResourse: { ...this.state.SellResourse, DollarMarket: this.parseInt0(e.target.value) } });
  }
  handleRemoveButtonClick = (id) => {
    httpService.Sana.RemoveSellFromResource(id, () => {
      this.setShowModel(false, 0);
      NotificationManager.success("عملیات حذف با موفقیت انجام شده است.");
      setTimeout(() => { this.doSearch(1, false) }, 1000);
    }, () => {
      NotificationManager.error("عملیات حذف با شکست مواجه شد.");
    });
  }
  //
  parseInt0 = (paramValue) => {
    if (paramValue === null || paramValue === "") {
      return 0;
    }
    else {
      return parseInt(paramValue);
    }
  }

  handleEnterKeyUp = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitButtonClick();
    }
  }
  handleSearchEnterKeyUp = (e) => {
    if (e.key === "Enter") {
      this.handleSearchButtonClick();
    }
  }
  handleSubmitButtonClick = () => {
    if (this.parseInt0(this.state.SellResourse.ExchangeId) === 0) {
      NotificationManager.error("نام صرافی یا بانک را انتخاب نمایید.");
      return;
    }
    if (this.state.SellResourse.RepDate.length === 0) {
      NotificationManager.error("تاریخ را وارد نمایید.");
      return;
    }
    httpService.Sana.SaveSellFromResource({
      ExchangeId: this.parseInt0(this.state.SellResourse.ExchangeId),
      RepDate: this.state.SellResourse.RepDate,
      Euro: this.parseInt0(this.state.SellResourse.Euro),
      EuroMarket: this.parseInt0(this.state.SellResourse.EuroMarket),
      Dollar: this.parseInt0(this.state.SellResourse.Dollar),
      DollarMarket: this.parseInt0(this.state.SellResourse.DollarMarket),
    }, (data) => {
      this.setState(
        {
          searchResult: data,
          SellResourse: { ...this.state.SellResourse, Euro: 0, EuroMarket: 0, Dollar: 0, DollarMarket: 0 },
          search: { schDate: '' }
        });
    }, () => {
      NotificationManager.error("عملیات ذخیره اطلاعات با شکست مواجه شد.");
    });
  }
  handleSearchInputChanges = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ search: { ...this.state.search, [name]: value } });
    console.log("state", this.state)
  }
  handleSearchButtonClick = () => {
    this.doSearch(1, true);
  }
  setShowModel = (isShow, id) => {
    this.setState({ deleteModal: { show: isShow, id: id } });
  }

  render() {
    const FormItem = Form.Item;
    const columns = [
      {
        title: 'شناسه',
        dataIndex: 'id',
      },
      {
        title: 'نام صرافی/بانک',
        dataIndex: 'exchangeName',
      },
      {
        title: 'تاریخ گزارش',
        dataIndex: 'repDate',
        //  render: (text) => (<span style={{ textAlign: "center" }}>{text}</span>)
      },
      {
        title: 'فروش به اشخاص (یورو)',
        dataIndex: 'euro',
        // render: (text) => (<span style={{ textAlign: "center" }}>{text}</span>)
      },
      {
        title: 'بازار متشکل (یورو)',
        dataIndex: 'euroMarket',
      },
      {
        title: 'فروش به اشخاص (دلار)',
        dataIndex: 'dollar',
      },
      {
        title: 'بازار متشکل (دلار)',
        dataIndex: 'dollarMarket',
      }
      , {
        title: 'عملیات',
        dataIndex: '',
        render: (text, row) => {
          return (<Button type="danger" htmlType="button" onClick={() => { this.setShowModel(true, row.id) }} >حذف  </Button>)
        }
      }



    ]

    return (
      <React.Fragment>
        <Modal

          visible={this.state.deleteModal.show}
          title=""
          type="error"
          footer={[
            <Button key="back" type="danger" onClick={() => this.handleRemoveButtonClick(this.state.deleteModal.id)}>حذف</Button>,
            <Button key="submit" type="default" onClick={() => this.setShowModel(false, 0)}> انصراف</Button>,
          ]}
        >
          <Row>
            <Col md={2}> </Col>
            <Col md={20}>
              آیا از حذف تراکنش اطمینان دارید؟
            </Col>
            <Col md={2}> </Col>
          </Row>
        </Modal>
        <Card>
          <Row>
            <Col span={24}>
              {/* <a onClick={this.downloadFile} style={{paddingRight:"10px"}}>دریافت فایل</a> */}
              {this.state.years.map(year =>
                <Button type="primary" icon="download" onClick={() => window.open(httpService.Sana.GetExportSellFromResourceBank(year), '_blank')}  >{`دریافت فایل ` + year}</Button>
              )}
            </Col>
          </Row>
        </Card>

        <Card className="gx-card">
          <Form>
            <Row>
              <Col md={8} sm={24}>
                <FormItem
                  label="نام صرافی/بانک"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <DropDownSarafies onChange={this.handlExchangeId} value={this.state.SellResourse.ExchangeId} onKeyUp={this.handleEnterKeyUp} />

                </FormItem>
              </Col>

              <Col md={8} sm={24}>
                <FormItem
                  label="تاریخ گزارش"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <MaskedInput mask="1111/11/11" id="dealingFromDate" name="dealingFromDate" placeholder="yyyy/mm/dd"
                    style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                    placeholder="1398/01/01" onKeyUp={this.handleEnterKeyUp} value={this.state.SellResourse.RepDate}
                    onChange={this.handleRepDate} />
                </FormItem>
              </Col>
              <Col md={8} sm={24}>
                <FormItem
                  label="فروش به اشخاص (یورو)"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <Input value={this.state.SellResourse.Euro} onKeyUp={this.handleEnterKeyUp} onChange={this.handleEuro} />
                </FormItem>
              </Col>

            </Row>
            <Row>
              <Col md={8} sm={24}>
                <FormItem
                  label="بازار متشکل (یورو)"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <Input value={this.state.SellResourse.EuroMarket} onKeyUp={this.handleEnterKeyUp} onChange={this.handleEuroMarket} />
                </FormItem>
              </Col>
              <Col md={8} sm={24}>
                <FormItem
                  label="فروش به اشخاص (دلار)"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <Input value={this.state.SellResourse.Dollar} onKeyUp={this.handleEnterKeyUp} onChange={this.handleDollar} />
                </FormItem>
              </Col>
              <Col md={8} sm={24}>
                <FormItem
                  label="بازار متشکل (دلار)"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <Input value={this.state.SellResourse.DollarMarket} onKeyUp={this.handleEnterKeyUp} onChange={this.handleDollarMarket} />
                </FormItem>
              </Col>

            </Row>
            <Row>

              <Button type="primary" htmlType="button" onClick={this.handleSubmitButtonClick}>
                ذخیره
              </Button>
            </Row>
          </Form>
        </Card>

        {this.state.searchResult && (
          <Card>
            <Row>
              <Col md={8} sm={24}>
                <FormItem
                  label="تاریخ گزارش"
                  labelCol={{ xs: 24, md: 9, lg: 8 }}
                  wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                >
                  <MaskedInput mask="1111/11/11" id="schDate" name="schDate" placeholder="yyyy/mm/dd"
                    style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                    placeholder="1398/01/01" onKeyUp={this.handleSearchEnterKeyUp} value={this.state.search.schDate}
                    onChange={this.handleSearchInputChanges} />
                </FormItem>
              </Col>
              <Col md={8} sm={24}>
                <Button type="primary" htmlType="button" onClick={this.handleSearchButtonClick}>
                  جستجو
                </Button>
              </Col>
            </Row>
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={this.state.searchResult.items}
              bordered
              pagination={{
                total: this.state.searchResult.totalCount,
                pageSize: this.state.searchResult.pageSize,
                current: this.state.searchResult.pageIndex,
                onChange: this.handlePageIndexChanged
              }}
            //  onRowClick={(row) => this.handleShowRole(row.roleId)}
            >
            </Table>
          </Card>
        )}


      </React.Fragment>
    );
  }
}


export default SellFromResourceBank;

import React, {Component} from "react";
import {Button, Dropdown, Icon, Layout, Menu, message, Popover, Select} from 'antd';
import {connect} from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";

import languageData from "../languageData";
import UserInfo from "components/UserInfo";
import {switchLanguage, toggleCollapsedSideNav} from "../../../appRedux/actions/Setting";

import {TAB_SIZE} from "../../../constants/ThemeSetting";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";

const {Header} = Layout;

const Option = Select.Option;
const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">محصول</Menu.Item>
    <Menu.Item key="2">وبلاگ</Menu.Item>
    <Menu.Item key="3">اپلیکیشن</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info('روی مورد منو کلیک کنید');
}

function handleChange(value) {
  console.log(`selected ${value}`);
}

class AboveHeader extends Component {

  state = {
    searchText: '',
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-ml-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };


  render() {
    const {width, locale, navCollapsed} = this.props;
    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark gx-above-header-horizontal">

        <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
          <div className="gx-container">
            <div className="gx-header-horizontal-nav-flex">
              <HorizontalNav/>
            </div>
          </div>
        </div>

        <Header
          className="gx-header-horizontal-main">
          <div className="gx-container">
            <div className="gx-header-horizontal-main-flex">

              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                <i className="gx-icon-btn icon icon-menu"
                   onClick={() => {
                     if (width <= TAB_SIZE) {
                       this.props.toggleCollapsedSideNav(!navCollapsed);
                     }
                   }}
                />
              </div>
              <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
                <img alt="" src={require("assets/images/w-logo.png")}/></Link>
              <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
                <img alt="" src={require("assets/images/logo.png")}/></Link>
              <ul className="gx-header-notifications gx-mr-auto">

                <li className="gx-user-nav"><UserInfo/></li>
              </ul>
            </div>
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale, navCollapsed, width} = settings;
  return {locale, navCollapsed, width}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage})(AboveHeader);

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Table, Col, Row, Modal, Button } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
// import NotFound from '../Not-Found';
// import Modal from 'react-bootstrap/Modal';

class SaleToCbiModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            list:[],
        }
    }
    HandelHideModal = () => { this.setState({ modalShow: false }); }
    HandelShowModal = () => {
        this.setState({ modalShow: true });
        this.handledata();
        this.populateColumns();
    }

    handledata = () => {
        const successHandler = (result) => {
            var items = [...result];
            this.setState({ list: items });
        };
        const rejectedHandler = (ar) => {
        };
        httpCaller.Sarafi.GetBankSarafPositionSaleToBank(this.props.exchangeId, this.props.fromDate, this.props.toDate, successHandler, rejectedHandler);
    }

    populateColumns = () => {
        this.setState({columns : [
            {
                title: 'نوع ارز',
                dataIndex: 'currencyName',
            },{
            title: 'فروش نقدی',
            dataIndex: 'amount',
          },{
            title: 'مجموع به یورو',
            dataIndex: 'totalEuro',
          }]});
    }


    render() {

        return (
            <Row>
                <Col>
                    <a onClick={this.HandelShowModal} style={{paddingRight:"10px"}}>مشاهده جزئیات</a>
                    <Modal
                        width="200"
                        title="میزان فروش ارز به بانک مرکزی"
                        visible={this.state.modalShow}
                        onCancel={this.HandelHideModal}
                        footer={[
                            <Button key="back" onClick={this.HandelHideModal}>بازگشت</Button>,
                          ]}
                        >
                        <Row>
                            <Col span={24}>
                                <Table className="gx-table-responsive"
                                    columns={this.state.columns}
                                    dataSource={this.state.list}
                                    bordered
                                />
                            </Col>
                        </Row>        
                    </Modal>
                </Col>
            </Row>
        );
    }
}

export default SaleToCbiModal;

import React, { Component } from 'react';
import httpService from '../../services/httpService'
import NumberFormat from 'react-number-format';
import { CheckPermissionHOC, HasPermission } from '../../services/authorization'
import { Row, Col, Form, Space, Input, Button, Card, Divider, Table, InputNumber } from 'antd'
import { NotificationManager } from 'react-notifications'
import { withRouter } from 'react-router-dom';



class CurrencyCeilingRate extends Component {

    static contextTypes = {
        router: () => true
    }

    constructor(props) {
        super(props)

        this.state = {
            CurrencyRates: null,
            forumeType: "1",
        }

    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search)
        this.setState ({forumType: queryParams.get("forumType")})
        httpService.NimaExport.GetAllCurrencyRateInfo({ id: this.supplyId }, (data) => {
            this.setState({ CurrencyRates: data });
        })
    }
    


    btnSaveHandeler = () => {
        const successHandler = (result) => {
            NotificationManager.success("نرخ با موفقیت ثبت شد");

            this.props.history.push("/setting/currencyceilingratelist")
        };
        const rejectedHandler = (ar) => {

            NotificationManager.success('خطا در ذخیره دادها');
        };

        httpService.NimaExport.SaveCurrencyCellingVales(this.state.CurrencyRates, successHandler, rejectedHandler);
    }
    handleOneRate = (e, numCode) => { 
        this.state.CurrencyRates.map((currRate, i) => {
            if (currRate.numericCode === numCode) {
                // currRate.newCellingAmount=e.target.value; 
                //  console.log("e", e);
                this.state.CurrencyRates[i].newCellingAmount = e;
            }
            this.forceUpdate();
        })
    }

    handleOneRateFloor = (e, numCode) => {
        this.state.CurrencyRates.map((currRate, i) => {
            if (currRate.numericCode === numCode) {
                this.state.CurrencyRates[i].newFloorAmount = e;
            }
            this.forceUpdate();
        })
    }

    handleEuroChange = (e) => {
        this.state.CurrencyRates.map((currRate, i) => {
            currRate.newCellingAmount =(e / currRate.equalityRate) * currRate.coef;
            currRate.forumType=parseInt(this.state.forumType) ;
        });
        this.forceUpdate();
    }

    handleFloorEuroChange = (e) => {
        this.state.CurrencyRates.map((currRate, i) => {
            currRate.newFloorAmount = (e / currRate.equalityRate) * currRate.coef;
        });
        this.forceUpdate();
    }

    handleDifferentPercentageChange = (e) => {
        // this.setState({DifferentPercentageSell : e });
        this.state.CurrencyRates.map((currRate, i) => {
            currRate.differentPercentage = e;
        });
        this.forceUpdate();
    }


    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.btnSaveHandeler();
        }
    }
    handelReturnListRate = () => {
        this.props.history.push("/setting/currencyceilingratelist");
    }

    render() {
        const FormItem = Form.Item;
        // 
        var cartTitle =this.state.forumType=="1" ?(<div><span>{"سقف و کف قیمت خرید صرافی در تالار اصلی"}</span>    </div>):(<div><span>{"سقف و کف قیمت خرید صرافی در تالار دوم"}</span>    </div>);
        return (
            <React.Fragment>
                <Card title={cartTitle} > 
                    {
                        this.state.CurrencyRates === null ? "" :
                            this.state.CurrencyRates.map((currRate, i) => {
                                if (i !== 0) {
                                    return (
                                        <React.Fragment>
                                            <Col md={24} xs={24} className="rightSet">
                                                <FormItem
                                                    colon={false}
                                                    label={currRate.farsiName}
                                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                                >
                                                    <Row>
                                                        <Col md={12} xs={24}>
                                                            <label style={{color:"#ce0505"}}>سقف خرید</label>
                                                            <InputNumber style={{ width: '40%',marginRight:"2px" }}
                                                                value={parseInt(currRate.newCellingAmount)}
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={(e) => { this.handleOneRate(e, currRate.numericCode) }}
                                                                onKeyUp={this.handleSearchInputKeyUp} />
                                                            <NumberFormat style={{marginRight:'7px'}} value={(currRate.newCellingAmount * (1 + currRate.differentPercentage / 100))} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ریال نرخ فروش`} />   
                                                        </Col>
                                                        <Col md={12} xs={24}>
                                                            <label style={{color:"#3fad09"}}>کف خرید</label>
                                                            <InputNumber style={{ width: '40%',marginRight:"2px"}}
                                                                value={parseInt(currRate.newFloorAmount)}
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={(e) => { this.handleOneRateFloor(e, currRate.numericCode) }}
                                                                onKeyUp={this.handleSearchInputKeyUp} />
                                                            <NumberFormat style={{marginRight:'7px'}} value={(currRate.newFloorAmount * (1 + currRate.differentPercentage / 100))} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ریال نرخ فروش`} />
                                                        </Col>
                                                    </Row>
                                                </FormItem>
                                            </Col>
                                        </React.Fragment>
                                    )
                                }
                                else {
                                    return (
                                        <React.Fragment>
                                            <Row>
                                                <Col md={8} xs={24} >
                                                <FormItem
                                                    colon={false}
                                                    label= {"سقف خرید "+ currRate.farsiName}
                                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                                >
                                                    <InputNumber style={{ width: '40%' }}
                                                        value={parseInt(currRate.newCellingAmount)}
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        onChange={(e) => { this.handleEuroChange(e) }}
                                                        onKeyUp={this.handleSearchInputKeyUp}
                                                    />
                                                    <NumberFormat style={{marginRight:'5px'}} value={(currRate.newCellingAmount * (1 + currRate.differentPercentage / 100))} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ریال نرخ فروش`} />
                                                </FormItem>
                                            </Col>
                                            <Col md={8} xs={24} >
                                                <FormItem
                                                    colon={false}
                                                    label= {"کف خرید "+ currRate.farsiName}
                                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                                >
                                                    <InputNumber style={{ width: '40%' }}
                                                        value={parseInt(currRate.newFloorAmount)}
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        onChange={(e) => { this.handleFloorEuroChange(e) }}
                                                        onKeyUp={this.handleSearchInputKeyUp}
                                                    />
                                                    <NumberFormat style={{marginRight:'5px'}} value={(currRate.newFloorAmount * (1 + currRate.differentPercentage / 100))} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ریال نرخ فروش`} />
                                                </FormItem>
                                            </Col>
                                            <Col md={8} xs={24} >
                                                    <FormItem
                                                        colon={false}
                                                        label="درصد"
                                                        labelCol={{ xs: 24, md: 7, lg: 6 }}
                                                        wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                                    >
                                                        <InputNumber style={{ width: '40%' }}
                                                            defaultValue={currRate.differentPercentage}
                                                            value={currRate.differentPercentage}
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={(e) => { this.handleDifferentPercentageChange(e) }}
                                                            onKeyUp={this.handleSearchInputKeyUp}
                                                        />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Divider />

                                        </React.Fragment>
                                    )
                                }
                            })
                    }
                    <br />
                    <Divider />
                    <Col md={24} xs={24} >
                        <Button type="primary" htmlType="button" icon="save" onClick={() => this.btnSaveHandeler()}   >
                            {this.state.forumType=="1" ?"ثبت نرخ در تالار اصلی":"ثبت نرخ در تالار دوم"}
                            </Button>

                        <Button type="primary" htmlType="button" onClick={() => this.handelReturnListRate()} icon="rollback"   >
                            برگشت
                                </Button>
                    </Col>

                </Card>
            </React.Fragment >
        );
    }
}

export default CheckPermissionHOC(withRouter(CurrencyCeilingRate), 'SettingManagement_RateRange');;

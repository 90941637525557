import React, {Component} from 'react';
import {Card, Row, Col, Table, Checkbox, Form, Input, Button, Modal, Divider} from 'antd';
import httpCaller from '../../../services/httpService';
import { HasPermission, CheckPermissionHOC } from '../../../services/authorization';
import {CloseOutlined, SearchOutlined, PlusCircleOutlined} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import DisplayFormData from '../../utility/DisplayFormData';
import {NotificationManager} from 'react-notifications';
import numeral from 'numeral';

class BlackList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            blackList: [],
            currentPage: 1,
            exceptionedOfferCode: "",
            offerInfo : [],
            Req:[],
            applicant:[],
            modalVisibility: false,
            BlackListDesc: "",
            offerIdSrch:"",
            MerchantId: "",
            MerchantName: "",
            pageSize: 10,
            deleteModal: {
                show: false,
                id: 0
            },
            removeDesc:'',
        }
    }

    componentDidMount = () => {
        this.Search(1, true);
    }

    handleEstelamOffer = () => {
        if(this.state.exceptionedOfferCode === "")
        {
            NotificationManager.warning('کد پیشنهاد وارد نشده است');
            return;
        }
        httpCaller.NimaImport.GetOfferById(this.state.exceptionedOfferCode, (result) => {
                if(result != '')
                {
                    this.setState({offerInfo:result,Req: result.request,applicant: result.request.applicant,modalVisibility:true});                
                    // this.setState({Req: result.request});
                    // this.setState({applicant: result.request.applicant});
                    // this.setState({modalVisibility:true})
                }
                else
                {
                    NotificationManager.warning('کد پیشنهاد یافت نشد');;
                }
                
        }, () => {})        
    }

    onModalOk = () => {
        if(!HasPermission("NimaBlackList_AddToExceptions"))
        {
            NotificationManager.error("شما دسترسی به این عملیات را ندارید");
            return;
        }

        if(this.state.BlackListDesc == undefined)
        {
            NotificationManager.error("وارد کردن توضیحات الزامی است");
            return;
        }
        else
        {
            var data = this.prepareData();
            httpCaller.BlackList.AddToBlackListExceptions(data,()=>{
                this.setShowModel(false, 0);
                NotificationManager.success("عملیات مستثنی کردن از لیست سیاه با موفقیت انجام شد.");
                setTimeout(() => { this.Search(1, false) }, 1000);
            },()=>{});

            this.setState({modalVisibility:false, BlackListDesc:""});
        }
    }

    prepareData = () => {
        return{
            offerId: this.state.offerInfo.offerId,
            description: this.state.BlackListDesc,
            currentPage: this.state.currentPage,
        }
    }

    Search = (idx, isSOCLog) => {
        
        var param = {
            offerId : this.state.offerIdSrch,
            merchantNationalId : this.state.MerchantId,
            merchantName : this.state.MerchantName,
            pageSize : this.state.pageSize,
            pageIndex : idx
        };
        
        httpCaller.BlackList.SearchBlackListExceptions(param,
            (result)=>{this.setState({blackList: result})},
            ()=>{}, isSOCLog)
    }

    handlePageIndexChanged = (idx) => {
        this.setState({  currentPage: idx },()=>{this.Search(idx, true);});
    } 
    
    handleSearchOfferId = (e) =>{
        this.setState({offerIdSrch: e.target.value})
    }

    handleRemoveButtonClick = (id) => {
        if(this.state.removeDesc == undefined)
        {
            NotificationManager.error("وارد کردن توضیحات الزامی است");
            return;
        }
        var param = {
            id:id,
            description: this.state.removeDesc
        }
        
        httpCaller.BlackList.RemoveFromBlackListExceptions(param, () => {
          this.setShowModel(false, 0);
          NotificationManager.success("عملیات حذف با موفقیت انجام شد.");
          setTimeout(() => { this.Search(1, false) }, 1000);
        }, () => {});
    }

    setShowModel = (isShow, id) => {
        this.setState({ deleteModal: { show: isShow, id: id } });
    }

    render(){
        const FormItem = Form.Item;
        const columns =
            [{
                title: 'کد ',
                dataIndex: 'id',
            }, {
                title: 'کد پیشنهاد',
                dataIndex: 'entityId',
            }, {
                title: 'کد صرافی',
                dataIndex: 'exchangeId',
            }, {
                title: 'نام صرافی',
                dataIndex: 'exchangeName',
            }, {
                title: 'کد/شناسه ملی بازرگان',
                dataIndex: 'nationalId',
            }, {
                title: 'نام بازرگان',
                dataIndex: 'merchantName',
            }, {
                title: 'نام ارز',
                dataIndex: 'currencyName',
            }, {
                title: 'معادل یورویی',
                dataIndex: 'euroAmount',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
            }, {
                title:'حذف از لیست',
                render: (text, row) => {
                    return (HasPermission("NimaBlackList_RemoveFromExceptions") && <Button type="danger" htmlType="button" onClick={() => { this.setShowModel(true, row.id) }} >حذف</Button>)
                  }
            }];
        
        if(!HasPermission("NimaBlackList_View")) return null;
        return(
            <React.Fragment>
                <Modal
                    width="40%"
                    visible={this.state.deleteModal.show}
                    title=""
                    type="error"
                    footer={[
                    <Button key="back" type="danger" onClick={() => this.handleRemoveButtonClick(this.state.deleteModal.id)}>حذف</Button>,
                    <Button key="submit" type="default" onClick={() => this.setShowModel(false, 0)}> انصراف</Button>,
                    ]}
                    >
                    <br/>
                    <Row>
                        <Col md={20}>
                            آیا مایل به حذف این کد پیشنهاد از لیست استثنائات لیست سیاه هستید؟
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={20}>
                            <Input.TextArea value = {this.state.removeDesc} onChange={(e)=>this.setState({removeDesc:e.target.value})} />
                        </Col>
                    </Row>
                </Modal>
                <Card headStyle={{backgroundColor:"#d9edf7",color:"#31708f",fontSize:'20px',fontWeight:'bolder'}} title="مستثنی کردن یک پیشنهاد از لیست سیاه">
                    <Row>
                        <Col md={8} sm={12}>
                            <FormItem
                                label="کد پیشنهاد"
                                labelCol={{  md: 8,  }}
                                wrapperCol={{  md: 12,  }}
                            >
                                <Input text={this.state.exceptionedOfferCode} onChange={(e)=> this.setState({exceptionedOfferCode:e.target.value})} />
                            </FormItem>
                        </Col>
                        <Col md={12} sm={12}>
                            <Button type='primary' onClick={this.handleEstelamOffer}>{<SearchOutlined style={{color:'white',fontWeight:'bold'}} />}استعلام کد پیشنهاد</Button>
                            <Modal
                                width="80%"
                                title="مشخصات پیشنهاد"
                                visible={this.state.modalVisibility}
                                onOk={this.onModalOk}
                                onCancel={()=>this.setState({modalVisibility:false})}
                                okText="افزودن به استثنائات لیست سیاه"
                                cancelText="انصراف"
                                >
                                <Row>
                                    <Col md={8} sm={8}>
                                        <DisplayFormData lable="کد پیشنهاد" value={this.state.offerInfo.offerId} />
                                    </Col>
                                    <Col md={8} sm={8}>
                                        <DisplayFormData lable="کد صرافی" value={this.state.offerInfo.exchangeId} />
                                    </Col>
                                    <Col md={8} sm={8}>
                                        <DisplayFormData lable="نام صرافی" value={this.state.offerInfo.sarafiName} />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={8} sm={8}>
                                        <DisplayFormData lable="شناسه ملی بازرگان" value={this.state.applicant && this.state.applicant.nationalCode} />
                                    </Col>
                                    <Col md={8} sm={8}>
                                        <DisplayFormData lable="نام بازرگان" value={this.state.applicant && this.state.applicant.firstName} />
                                    </Col>
                                    <Col md={8} sm={8}>
                                        <DisplayFormData lable="نام ارز" value={this.state.Req && this.state.Req.currencyName} />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col lg={16} md={16} sm={16} xs={24}>
                                        <Form.Item
                                            label="توضیحات"
                                            labelCol={{ xs: 24, lg: 4, md:4, xl: 4 }}
                                            wrapperCol={{ xs: 24, lg: 16, md:12, xl: 12 }}
                                        >
                                            <Input.TextArea value = {this.state.BlackListDesc} onChange={(e)=> this.setState({BlackListDesc:e.target.value})} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>
                        <Col md={6} sm={12}>
                            
                        </Col>
                    </Row>
                </Card>
                <Card className="gx-card" title="پیشنهادات مستثنی شده از لیست سیاه" style={{color:"maroon"}}>
                    <Row>
                        <Col md={8}>
                            <Input addonBefore="کد پیشنهاد" value={this.state.offerIdSrch} onChange={this.handleSearchOfferId} />
                        </Col>
                        <Col md={8}>
                            <Input addonBefore="شناسه ملی بازرگان" value={this.state.MerchantId} onChange={(e)=> this.setState({MerchantId: e.target.value})} />
                        </Col>
                        <Col md={8}>
                            <Input addonBefore="نام بازرگان" value={this.state.MerchantName} onChange={(e)=> this.setState({MerchantName: e.target.value})} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <Button type="primary" onClick={()=>this.Search(1, true)}>جستجو</Button>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col md={24} sm={24}>
                        <Table className="gx-table-responsive"
                            columns={columns}
                            dataSource={this.state.blackList.items}
                            bordered
                            expandedRowRender={(record, index) =>{
                                return(
                                <React.Fragment>
                                    <Row>
                                        <Col md={8} sm={8}>
                                            <DisplayFormData lable="کد پیشنهاد" value={record.entityId} />
                                        </Col>
                                        <Col md={8} sm={8}>
                                            <DisplayFormData lable="کد درخواست" value={record.requestCode} />
                                        </Col>
                                        <Col md={8} sm={8}>
                                            <DisplayFormData lable="تاریخ ثبت پیشنهاد" value={record.offerRegisterDateStr} />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={8} sm={8}>
                                            <DisplayFormData lable="مبلغ ارز" value={numeral(record.amount).format(0,0)} />
                                        </Col>
                                        <Col md={8} sm={8}>
                                            <DisplayFormData lable="توضیحات" value={record.desc} />
                                        </Col>
                                    </Row>                                    
                                </React.Fragment>)
                            }}
                            pagination={{
                                total: this.state.blackList.totalCount,
                                pageSize: this.state.blackList.pageSize,
                                current: this.state.blackList.currentPage,
                                onChange: (index) => this.handlePageIndexChanged(index)
                            }}
                        />
                        </Col>
                    </Row>
                </Card>
            </React.Fragment>
        )
    }

}

export default CheckPermissionHOC(BlackList,"BlackList_permission")

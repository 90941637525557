import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link } from "react-router-dom";
import { Alert, Button, Card, Col, Switch, Row, Input, Radio, InputNumber } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService'
import { connect } from 'react-redux';
// import NotFound from '../Not-Found';
// import Switch from "react-switch";
import { HasPermission } from '../../../services/authorization';


class ExchangeOpenPosition extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props.exchangeId);
        this.state = {
            sarafi: { traceCD: "sdsd" },

            Mode: "view",
            openPositionCeiling: 0,
            openPositionKind: 3,
        };

        this.handleEdiButton = this.handleEdiButton.bind(this);
        this.handleSaveButton = this.handleSaveButton.bind(this);
    }

    handleChangeOpenPositionCeiling = (value) => {
        console.log("value:", value)
        console.log("value:", value)
        this.setState({ openPositionCeiling: value });
    }
    handleChangeOpenPositionKind = (e) => {
        this.setState({ 
            openPositionKind: e.target.value 
            // ,openPositionCeiling:0 
        });
    }

    handleEdiButton = (e) => {
        this.setState({ Mode: "edit" });
    }
    handleCancelButton = (e) => {
        this.setState({ Mode: "view" });
        this.handledata(false);
    }
    handleSaveButton = (e) => {
        this.setState({ Mode: "view" });

        httpCaller.Sarafi.updateOpenPosition({ ...this.state }, () => {
            this.props.history.push('/ExchangeDetail')
        }, () => { })
    }

    handledata = (val) => {
        const successHandler = (result) => {
            this.setState(
                {
                    sarafi: result,
                    exchangeId: this.props.exchangeId,
                    openPositionCeiling: result.openPositionCeiling,
                    openPositionKind: result.openPositionKind
                });
        };
        const rejectedHandler = (ar) => {
        };

        httpCaller.Sarafi.getSarafiOpenPosition(this.props.exchangeId, successHandler, rejectedHandler, val);
    }

    getButton = () => {
        if (!HasPermission("ExchangeManagement_OpenPositionCategory")) {
            return null
        };
        return (
            <Row>
                <Col lg={4} md={4} sm={12} xs={24}>
                    <Button type="primary" style={{ display: this.state.Mode === "edit" ? "block" : "none" }} onClick={this.handleSaveButton}>ثبت</Button>
                    <Button type="primary" style={{ display: this.state.Mode === "view" ? "block" : "none" }} onClick={this.handleEdiButton}>ویرایش وضعیت</Button>
                </Col>
                <Col lg={4} md={4} sm={12} xs={24}>
                    <Button type="primary" style={{ display: this.state.Mode === "edit" ? "block" : "none" }} onClick={this.handleCancelButton}>انصراف</Button>
                </Col>
            </Row>
        );
    }
    componentDidMount() {

        this.handledata(true);

    }


    render() {
        return (
            <Row>
                <Col span={24}>
                    <Card title="نوع سقف وضعیت باز ارزی - نقد" style={{ paddingRight: "10px" }}>
                        
                            <Row >
                                <label>نوع سقف: </label>
                                {/* <Switch onChange={this.handleChangeOpenPositionKind} disabled={this.state.Mode === "view" ? true : false} checked={this.state.OpenPositionKind} /> */}
                                <Radio.Group onChange={this.handleChangeOpenPositionKind} value={this.state.openPositionKind} disabled={this.state.Mode === "view" ? true : false} buttonStyle="solid" size={'large'} >
                                    <Radio.Button value={1}>بدون سقف</Radio.Button>
                                    <Radio.Button value={2}>سقف ثابت</Radio.Button>
                                    <Radio.Button value={3}>سقف محاسبه ای</Radio.Button>
                                </Radio.Group>
                            </Row>
                            {/* <Col lg={4} md={4} sm={8} xs={24}>
                            </Col> */}
                            <Row>         
                            <br />
                            </Row>
                            {this.state.openPositionKind !== 2 ? "" :
                             <Row >
                       
                                    <label>سقف وضعیت باز: </label>
                              
                                    <InputNumber onChange={this.handleChangeOpenPositionCeiling} readOnly={this.state.Mode === "view" ? true : false} value={this.state.openPositionCeiling}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/(,*)/g, '')}
                                        style={{ width: '22%' }} />
                                        <span> ریال </span>
                                    
                            </Row>}
                        
                        <br />
                        <br />
                        {this.getButton()}
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ExchangeOpenPosition;

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import httpCaller from '../../services/httpService';
import NumberFormat from 'react-number-format';
import { Row, Col, Form,Divider, Input, Button, Card, Table, TreeSelect, Alert } from 'antd'
import {NotificationManager} from 'react-notifications'
import MaskedInput from 'antd-mask-input' ;

class ShowExchangePosition extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,
            searchFilter: {
                sarafiId: 0,
                dateDay: ""
            },
            sarafies: []
        };
        this.searchFilterRef = {
            sarafiId: React.createRef(),
            dateDay: React.createRef()
        }
    }

    componentDidMount = () => {
        console.log("componentDidMount call : ");
       httpCaller.Reports.GetLastCalcStartPositionDate( (data)=>{
        this.setState({ searchFilter: { ...this.state.searchFilter, dateDay: data  } }
            , () => { this.doSearch(1) }
            );
    });

       httpCaller.Sarafi.GetAllSarafies("", (data) => {
        this.setState({ sarafies: data });
    }, null, false);
    }
    
    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }
    handleDateDay =(e)=>{
        console.log("dateDay: e.target.value :",e);
        this.setState({ searchFilter: { ...this.state.searchFilter, dateDay: e.target.value  } });
    }
    doSearch = (pageIndex) => {
        
       // this.setQueryParameter();

        var param={
            pageIndex:pageIndex,
            pageSize:10, 
            sarafiId: parseInt(this.state.searchFilter.sarafiId),
            dateDay : this.state.searchFilter.dateDay
        }
        console.log("ShowExchangePosition call result after:")
        httpCaller.Reports.ShowExchangePosition(param, (data)=>{
            this.setState({searchResult:data})
        });
    }
    handleSearchButtonClick = () => {
        var sarafiId= 0,_dateDay="";
        sarafiId = this.state.searchFilter.sarafiId;
        _dateDay = this.state.searchFilter.dateDay
     //  var sarafiIdStr= this.state.searchFilter.sarafiId ;// this.searchFilterRef.sarafiId.current.input.value;
        //var dateDayStr = this.searchFilterRef.dateDay.current.input.value;
        console.log("sarafiIdStr:",sarafiId);
        // if(sarafiIdStr.trim() != ""){
        //     sarafiId = Number.parseInt(sarafiIdStr.trim())
        // }


        this.setState({ searchFilter: 
            { sarafiId: sarafiId ,dateDay: _dateDay} }
            ,
            () => { this.doSearch(1) })
    }
    handlePageChange = (page) => {
        this.doSearch(page);
    };
    handleSearchTreeSelect = (value) => {
        this.setState({ searchFilter: { ...this.state.searchFilter, sarafiId: (!value ? null : parseInt(value)) } })
    };




    render() {
        const FormItem = Form.Item;
        const columns= [{
            title: 'تاریخ محاسبه',
            dataIndex: 'createDateP',
          //  render: (text, row, index) => <span > {text.substr(0,4)+'/' +text.substr(4,2)+'/' +text.substr(6,2)}</span>
        }, {
            title: 'کد صرافی',
            dataIndex: 'sarafiId',
            style: { background:'red' , fontSize:  '25px'},
            // props: {
            //     style: { background:'red' , fontSize:  '25px'}
            //   },
        }, {
            title: 'نام صرافی/بانک',
            dataIndex: 'sarName',
        }, {
            title: 'وضعیت باز اسکناس',
            dataIndex: 'cash',
            render: (text, row, index) => <NumberFormat style={{fontWeight:'800',  fontSize:  '18px'}} value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'وضعیت باز حواله',
            dataIndex: 'havale',
            render: (text, row, index) => <NumberFormat style={{fontWeight:'800', fontSize:  '18px'}} value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        },{
            title: 'وضعیت باز ارزی کل',
            dataIndex: 'havale',
            render: (text, row, index) => <NumberFormat style={{fontWeight:'800', fontSize:  '20px', color:((row.cash + row.havale)>0 ? 'green': 'red')}} value={row.cash + row.havale} decimalScale={0} displayType={'text'} thousandSeparator={true} /> ,
            // props: {
            //     style: { background:'red' }
            //   },
        }
        , {
            title: 'خرید (آماده معامله)',
            dataIndex: 'kharidReadyToDeal',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (منتظر صدور حواله)',
            dataIndex: 'kharidMontazerSodorHavaleh',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (آماده واریز ریال)',
            dataIndex: 'kharidAmadehVarizRial',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
            
        }, {
            title: 'خرید (آماده تایید صرافی)',
            dataIndex: 'kharidAmadehTaeidSarafi',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (منتظر تایید عرضه کننده)',
            dataIndex: 'kharidMontazerTaeidArzekonandeh',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (مختومه)',
            dataIndex: 'kharidFinal',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (انصراف صرافی)',
            dataIndex: 'kharidEnserafSarafi',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (انصراف بازرگان)',
            dataIndex: 'kharidEnserafBazargan',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (حواله سنا)',
            dataIndex: 'kharidHavalehSana',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'خرید (اسکناس سنا)',
            dataIndex: 'kharidCashSana',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (منتظر پرداخت ریال)',
            dataIndex: 'foroushMontazerRial',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (منتظر صدور حواله)',
            dataIndex: 'foroushMontazerSodorHavaleh',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (آماده تایید حواله)',
            dataIndex: 'foroushAmadehTaeidHavaleh',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (منتظر تایید نهایی)',
            dataIndex: 'foroushMontazerFinalTaeid',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (مختومه)',
            dataIndex: 'foroushFinal',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (انصراف صراف)',
            dataIndex: 'foroushEnserafSarafi',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (انصراف بازرگان)',
            dataIndex: 'foroushEnserafBazargan',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (حواله سنا)',
            dataIndex: 'foroushHavalehSana',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'فروش (اسکناس سنا)',
            dataIndex: 'foroushCashSana',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'اصلاحیه حواله',
            dataIndex: 'havaleCorrection',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }, {
            title: 'اصلاحیه اسکناس',
            dataIndex: 'cashCorrection',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
        }
    ];
        return (
            <React.Fragment>
                <Row >
                    <Col sm={11}>
                        <div style={{ fontSize: '1.6rem', fontWeight: 'Bold' }}>موجودی شروع دوره صرافی ها در تاریخ 1400/11/30 </div>
                    </Col>
                </Row>
                <Divider style={{ backgroundColor: '#c5c5c5', height: '2px' }}></Divider>
                <Card className="gx-card">
                <Row >
                        <Col md={24}>
                            <Alert message="کاربر گرامی اعداد منفی که در ستون های (وضعیت باز اسکناس، وضعیت باز حواله و وضعیت باز ارزی کل) مشاهده می فرماید صرفا جهت اطلاع شما می باشد و اعداد منفی در پوزیشن صرافی صفر لحاظ می گردد  " type="warning" showIcon />
                            <br />
                        </Col>
                    </Row>
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد صرافی"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        treeNodeFilterProp='title'
                                        dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                        placeholder="لطفا انتخاب کنید"
                                        style={{ width: "100%" }}
                                        value={this.state.searchFilter.sarafiId}
                                        onChange={this.handleSearchTreeSelect}>
                                        {
                                            this.state.sarafies.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                        }
                                    </TreeSelect>

                                    {/* <Input ref={this.searchFilterRef.sarafiId} onKeyUp={this.handleSearchInputKeyUp} /> */}
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="تاریخ محاسبه"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                <MaskedInput mask="1111/11/11" placeholder="yyyy/mm/dd" style={{ direction: 'ltr', textAlign: 'center' }} 
                                size="11"  placeholder="1400/01/01"  value={this.state.searchFilter.dateDay} onChange={this.handleDateDay}   onKeyUp={this.handleSearchInputKeyUp} />

                                    {/* <Input ref={this.searchFilterRef.dateDay} onKeyUp={this.handleSearchInputKeyUp} /> */}
                                </FormItem>
                            </Col>

                        </Row>
                        <Row>

                            <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                جستجو
                            </Button>
                        </Row>
                    </Form>
                </Card>
                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageChange
                        }}
                        //onRowClick = {this.handleTableRowClicked}
                    />
                </Card>)}
            </React.Fragment>

        );
    }
}

export default withRouter(ShowExchangePosition);

import React, { Component } from 'react';
import httpCaller from '../../../services/httpService'
import { Button , Row, Col, Card, Input} from 'antd';
import {NotificationManager} from 'react-notifications'
import { withRouter } from 'react-router-dom';

class SetPasswordByUser extends Component
{
    constructor(props){
        super(props);
        console.log(this.props.match.params["Id"]);
        this.state = {
            
            UserId: this.props.match.params["Id"],
            UserName: '',
            Password:'',
            ReEnterPassword:'',
            ValidateReEnterPassword: false,
            ValidatePassword: false,
            currentUser: null,
            isValid: false,
            thresholdLength: 8,
        }

        this.prepareData = this.prepareData.bind(this);
        this.calcStrength = this.calcStrength.bind(this);
        this.savePassword = this.savePassword.bind(this);
        this.validInput = this.validInput.bind(this);
    }

    componentDidMount = () => {
        document.getElementById("passwordInput").focus()
        this.getCurrentUser();
    }

    handlePassword = (e) => {
        
        this.setState({Password: e.target.value})
        if(e.target.value.length < this.state.thresholdLength && e.target.value.length > 0 )
        {
            this.setState({ValidatePassword: false})
        }
        else{
            this.setState({ValidatePassword: true})
        }
    }

    handleReEnterPassword = (e) => {
        this.setState({ReEnterPassword: e.target.value})
        if(e.target.value !== this.state.Password){
            this.setState({ValidateReEnterPassword: false})
        }
        else{
            this.setState({ValidateReEnterPassword: true})
        }
    }

    prepareData = () => {
        var pass = document.getElementById("passwordInput").value;
        this.setState({Password: pass})
        var user = this.state.currentUser;
        user.password = pass;
        this.setState({currentUser: user});
    }


    getCurrentUser = () =>{
        httpCaller.AppUser.GetAppUserByIdAnonymous(parseInt(this.state.UserId),(item) => {
            
            this.setState({currentUser: item})
            this.setState({UserName: item.userName})

        }, () => {}, true, false);
    }

    savePassword = (e) => {
        if(this.validInput()){
            this.prepareData();
            httpCaller.Auth.SavePassword(JSON.stringify(this.state.currentUser),
                            (result) => {
                                NotificationManager.success("عملیات رمز عبور با موفقیت انجام شد، شما به صورت خودکار به صفحه ورود هدایت میشوید، لطفا وارد سامانه شوید.")
                                setTimeout(() => {
                                    this.props.history.push('/login')
                                }, 6000)
                                
                            },
                            ()=>{
                                console.log("ERROR");
                                NotificationManager.error("عملیات رمز عبور با شکست مواجه شد، در صورت بروز مجدد خطا با مدیر سامانه تماس حاصل نمایید.")
                            });
        }
        else
            return;
    }

    validInput = () => {
        
        if (this.state.Password.length < this.state.thresholdLength)
        {
            NotificationManager.error(`حداقل طول پسورد ${this.state.thresholdLength} کاراکتر می باشد`);
            return false;
        }
        else if (this.state.Password.length >= this.state.thresholdLength)
        {
            if (!this.calcStrength())
                return false;
        }

        var alertMessage = ''
        if(this.state.ReEnterPassword === "")
            alertMessage = 'تکرار پسورد به درستی وارد نشده است'
        if (this.state.Password !== "" && this.state.ReEnterPassword !== "" && this.state.Password !== this.state.ReEnterPassword)
        {
            alertMessage = 'تکرار پسورد با مقدار ورودی پسورد تطابق ندارد'
            this.setState({ReEnterPassword: ''})
        }

        if(alertMessage !== '')
        {
            NotificationManager.error(alertMessage);
            return false;
        }
        else{
            return true;
        }
    }

    handleChange = (e) => {
        console.log("handleChange" + e.target.value)
        this.setState({Password: e.target.value})
    }

    Cancel = () => {
        this.props.history.push(`/`);
    }

    calcStrength = () => {
        var alphabet = 0;
        var num = 0;
        var symb = 0;

        const alphabets = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
        const numbers = "0123456789".split("");
        const symbols = [" ","!","#","$","%","&","'","(",")","*","+","-",".","/",":",";","<","=",">","?","@","[","\\","]","^","_","`","{","|","}","~"];

        var str = this.state.Password;
        alphabets.forEach(element => {
            if (str.includes(element))
            {
              alphabet = str.indexOf(element) >= 0 ? 1 : 0
              return;
            }
        });
        numbers.forEach(element => {
          if (str.includes(element))
          {
              num = str.indexOf(element) >= 0 ? 1 : 0
              return;
          }
        });
        symbols.forEach(element => {
          if (str.includes(element))
          {
              symb = str.indexOf(element) >= 0 ? 1 : 0
              return;
          }
        });
        
        var rate = ""
        rate = alphabet.toString() + num.toString() + symb.toString();
        console.log("rate:"+rate);
        if(rate.replace("0","").length < 3)
        {
            NotificationManager.error("پسورد باید شامل حداقل یک عدد، یک حرف و یک کاراکتر خاص باشد")
            return false;
        }
        else
        {
            return true;
        }
    }

    render(){
        return(
            <Row>
                <Col span={6}></Col>
                <Col span={12}>
                    <Card title='تنظیمات رمز عبور' 
                        style={{textAlign:"center",marginTop:"20%"}} 
                        headStyle={{backgroundColor:"#520be1",color:"#f6f9fb"}} 
                        bodyStyle={{backgroundColor:"#e1e6f0"}}>
                        <Row>
                            <Col span={4}></Col>
                            <Col span={16}>
                                <Input addonBefore='نام کاربری' value={this.state.UserName} style={{backgroundColor:"whiteSmoke"}} id="txtUserName" readOnly/>
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={4}></Col>
                            <Col span={16}>
                                <Input addonBefore='رمز عبور' type='password'  value={this.state.Password} onChange={this.handleChange} id="passwordInput" />
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={4}></Col>
                            <Col span={16}>
                            <Input addonBefore='تکرار رمز عبور' type='password'  value={this.state.ReEnterPassword} onChange={this.handleReEnterPassword} />
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                        <br/><br/>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" onClick={this.savePassword}>ثبت</Button>
                                <Button type="default" onClick={this.Cancel}>بازگشت</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6}></Col>
            </Row>
        )
    }
}

export default withRouter(SetPasswordByUser);

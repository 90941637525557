import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link } from "react-router-dom";
import {Alert, Button,Card,Checkbox, Col, Switch, Row, Form, Input} from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService'
import { connect } from 'react-redux';
// import NotFound from '../Not-Found';
// import Switch from "react-switch";
import {HasPermission} from '../../../services/authorization';

class ExchangeLicense extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sarafi: { traceCD: "sdsd" },
            Mode: "view",
            checkedBuy: true,
            checkedSell: true,
            accessImportExportTalars: 0,
            allowedTalars:{
                importTalar1:false,
                importTalar2:false,
                exportTalar1:false,
                exportTalar2:false,
            },
            description: '',
        };

        // this.handleEdiButton = this.handleEdiButton.bind(this);
        // this.handleSaveButton = this.handleSaveButton.bind(this);
    }

    handleChangeBuy = (val, a) => {
        this.setState({ checkedBuy: val });
    }
    handleChangeSell = (val) => {

        this.setState({ checkedSell: val });
    }
    // handleChangeAllowSecondRate   = (val) => {
    //     // this.setState({ allowSecondRate: val });
    //     this.setState({ allowedTalars: val });
    // }
    handleChangeDesc = (e) => {
        this.setState({description: e.target.value})
    }

    handleEdiButton = (e) => {
        this.setState({ Mode: "edit" });
    }
    handleCancelButton = (e) => {
        this.setState({ Mode: "view" });
        this.handledata(false);
    }
    handleSaveButton = (e) => {
        this.setState({accessImportExportTalars:7})
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                var talars = 0;
                if (this.state.allowedTalars.importTalar1) talars = talars + 1;
                if (this.state.allowedTalars.exportTalar1) talars = talars + 2;
                if (this.state.allowedTalars.importTalar2) talars = talars + 4;
                if (this.state.allowedTalars.exportTalar2) talars = talars + 8;
                
                
                this.setState({ description: values.description });
                this.setState({ Mode: "view" });

                console.log({...this.state})

                httpCaller.Sarafi.updatePermission({ ...this.state }, () => {
                //this.props.history.push('/ExchangeDetail');
                this.handledata(false);
                    }, () => { })
            }
        });
    }

    changeImport1 = (e) => {
        this.setState({accessImportExportTalars: (e.target.checked ? this.state.accessImportExportTalars+ parseInt(e.target.value) :
                                                                    this.state.accessImportExportTalars - parseInt(e.target.value))});
        this.setState({
            allowedTalars:{
                ...this.state.allowedTalars, 
                importTalar1: e.target.checked
            }
        });
    }

    changeExport1 = (e) => {
        this.setState({accessImportExportTalars: (e.target.checked ? this.state.accessImportExportTalars+ parseInt(e.target.value) :
            this.state.accessImportExportTalars - parseInt(e.target.value))});
        this.setState({
            allowedTalars:{
                ...this.state.allowedTalars, 
                exportTalar1: e.target.checked
            }
        })
    }

    changeImport2 = (e) => {
        this.setState({accessImportExportTalars: (e.target.checked ? this.state.accessImportExportTalars+ parseInt(e.target.value) :
            this.state.accessImportExportTalars - parseInt(e.target.value))});
        this.setState({
            allowedTalars:{
                ...this.state.allowedTalars, 
                importTalar2: e.target.checked
            }
        })
    }

    changeExport2 = (e) => {
        this.setState({accessImportExportTalars: (e.target.checked ? this.state.accessImportExportTalars+ parseInt(e.target.value) :
            this.state.accessImportExportTalars - parseInt(e.target.value))});
        this.setState({
            allowedTalars:{
                ...this.state.allowedTalars, 
                exportTalar2: e.target.checked
            }
        })
    }

    prepareAccessTalars= () => {
        var talars = 0;
        if (this.state.allowedTalars.importTalar1) talars = talars + 1;
        if (this.state.allowedTalars.exportTalar1) talars = talars + 2;
        if (this.state.allowedTalars.importTalar2) talars = talars + 4;
        if (this.state.allowedTalars.exportTalar2) talars = talars + 8;
        return talars;
    }

    handledata = (val) => {
        var IsSOCLog = val;
        const successHandler = (result) => {

            this.setState(
                {
                    sarafi: result,
                    exchangeId: this.props.exchangeId,
                    checkedBuy: result.isActiveKharid,
                    checkedSell: result.isActiveForoush,
                    accessImportExportTalars :parseInt(result.accessImportExportTalars),
                    allowedTalars :result.allowedTalars
                });
        };
        const rejectedHandler = (ar) => {
        };

        httpCaller.Sarafi.getSarafPermission(this.props.exchangeId, successHandler, rejectedHandler, val);
    }

     getButton = ()=> {
        if(!HasPermission("ExchangeManagement_EditAccess"))
        {
            return null
        };
        return (
            <Row>
                <Col lg={4} md={4} sm={12} xs={24}>
                    <Button type="primary" style={{display:this.state.Mode === "edit" ? "block" : "none"}} onClick={this.handleSaveButton}>ثبت</Button>
                    
                    <Button type="primary" style={{display:this.state.Mode === "view" ? "block" : "none"}} onClick={this.handleEdiButton}>ویرایش مجوزها و تالارها</Button>
                </Col>
                <Col lg={4} md={4} sm={12} xs={24}>
                    <Button type="primary" style={{display:this.state.Mode === "edit" ? "block" : "none"}} onClick={this.handleCancelButton}>انصراف</Button>
                </Col>
            </Row>
        );
    }
    componentDidMount() {

        this.handledata(true);

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col span={24}>
                    <Card title="مجوزهای صرافی" style={{paddingRight:"10px"}}>
                        <Row>
                            <Col lg={8} md={8}  xs={24}>
                                <label>مجوز خرید </label>
                                <Switch onChange={this.handleChangeBuy} disabled={this.state.Mode === "view" ? true : false} checked={this.state.checkedBuy} />
                            </Col>

                            <Col lg={8} md={8}  xs={24}>
                                <label>مجوز فروش </label>
                                <Switch onChange={this.handleChangeSell} disabled={this.state.Mode === "view" ? true : false} checked={this.state.checkedSell} />
                            </Col>
                            {/* <Col lg={8} md={8}  xs={24}>

                            <label>مجوز معامله در تالار دوم </label>
                                <Switch onChange={this.handleChangeAllowSecondRate} disabled={this.state.Mode === "view" ? true : false} checked={this.state.allowSecondRate} />
                            </Col> */}
                            {/* onChange={this.changeSellChequeStatus} */}
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6} xs={12}>
                                <Checkbox value='1' checked={this.state.allowedTalars.importTalar1} onChange={this.changeImport1} 
                                    disabled={this.state.Mode === "view" ? true : false}>واردات تالار اصلی</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox value='2' checked={this.state.allowedTalars.exportTalar1} onChange={this.changeExport1}
                                    disabled={this.state.Mode === "view" ? true : false}>صادرات تالار اصلی</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox value='4' checked={this.state.allowedTalars.importTalar2} onChange={this.changeImport2}
                                    disabled={this.state.Mode === "view" ? true : false}>واردات تالار دوم</Checkbox>
                            </Col>
                            <Col md={6} xs={12}>
                                <Checkbox value='8' checked={this.state.allowedTalars.exportTalar2} onChange={this.changeExport2}
                                    disabled={this.state.Mode === "view" ? true : false}>صادرات تالار دوم</Checkbox>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{display:this.state.Mode === "edit" ? "block" : "none"}}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Form.Item
                                label="توضیحات"
                                labelCol={{ xs: 24, lg: 8, md:8, xl: 6 }}
                                wrapperCol={{ xs: 24, lg: 16, md:12, xl: 12 }}
                            >
                                {getFieldDecorator('Description', {
                                initialValue: this.state.description,
                                rules: [{ required: true, message: 'لطفا توضیحات را وارد نمایید!' }],
                                })(
                                    <Input.TextArea rows={4} onChange={this.handleChangeDesc} />
                                )}
                                
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        {this.getButton()}
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Form.create()(ExchangeLicense);

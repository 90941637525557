import React, {Component} from 'react';
import {Table, Row, Col, Card, Button, Input } from 'antd';
import DisplayFormData from '../utility/DisplayFormData';
import httpCaller from "../../services/httpService";
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';

class PaymentTasviehDetail extends Component
{
    constructor(props){
        super(props);
        this.state={
            detailsInfo: [],
            paymentId:this.props.paymentId
        }
    }

    componentDidMount = () => {
        httpCaller.HesabTasvieh.GetDigitalChequePaymentById(this.state.paymentId, (data)=> {
            this.setState({detailsInfo: data});
        })
    }

    render(){

        return(
            <Card className="gx-card">
                <Row>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="کد معامله" value={this.state.detailsInfo.entityId} />
                    </Col>
                    <Col md={8} xs={24}>
                        <Input addonBefore="مبلغ حواله" addonAfter="ریال" value={numeral(this.state.detailsInfo.amount).format(0,0)} readOnly/>
                    </Col>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="وضعیت حواله" value={this.state.detailsInfo.digitalChequeStatusDesc} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="کد پیگیری" value={this.state.detailsInfo.trackingCode} />
                    </Col>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="شماره سریال چک" value={this.state.detailsInfo.chequeId} />
                    </Col>
                    <Col md={8} xs={24}>
                        <DisplayFormData lable="شماره رسانه" value={this.state.detailsInfo.mediaId} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={24} xs={24}>
                        <DisplayFormData lable="حساب مبدا" value={this.state.detailsInfo.sourceAccountNumber} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={24} xs={24}>
                        <DisplayFormData lable="حساب مقصد" value={this.state.detailsInfo.destinationAccountNumber} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={12} xs={24}>
                        <DisplayFormData lable="تاریخ ثبت حواله الکترونیک" value={this.state.detailsInfo.creationDateStr} />
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default withRouter(PaymentTasviehDetail)
import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link } from "react-router-dom";

import httpCaller from '../../../services/httpService'
import { connect } from 'react-redux';

import { HasPermission, CheckPermissionHOC } from '../../../services/authorization'
import { Row, Col, Form, Input, Card, Table, Button, Switch, Icon, ExpandableRow, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { T } from 'antd/lib/upload/utils';
import { NotificationManager } from 'react-notifications'



class Businessmenpermission extends Component {
    constructor(props) {
        super(props)
        this.state = {
            NationalId: this.props.nationalId,
            HaveAccess: this.props.haveAccess,
            FullName: this.props.fullName,
            BlockedByManual: this.props.blockedByManual,
            BlockedBySystem: this.props.blockedBySystem,
            JustSupplyForBankSarafi: this.props.justSupplyForBankSarafi,
            Description: '',
            SupplierCategory:this.props.merchantCategory,
            IgnorePosCalc:  this.props.ignorePositionCalc,
        };
        // console.log("this.state.JustSupplyForBankSarafi",this.state.JustSupplyForBankSarafi);
        // console.log("this.state.IgnorePosCalc",this.state.IgnorePosCalc);
        // console.log("this.props.ignorePositionCalc",this.props.ignorePositionCalc);
      
    }
    handleChangeInputDesc = (e) => {

        this.setState({ Description: e.target.value })
    }
    handleChangeHaveAccess = (val) => {
        this.setState({ HaveAccess: val });
    }
    handleChangeJustSupplyForBank = (val) => {
        this.setState({ JustSupplyForBankSarafi: val } );
       
    }

  
    handlUpdateAccess = () => {
        const successHandler = (result) => {
            this.props.onChange();
            NotificationManager.success('عملیات با موفقیت انجام شده است.');
        };
        if (this.state.Description.length === 0) {
            NotificationManager.error('توضیحات برای تغییرات نوشته نشده است');
        }
        else {
            httpCaller.Merchant.UpdateAccessBusinessmen(this.state.NationalId, this.state.HaveAccess, this.state.JustSupplyForBankSarafi, this.state.BlockedByManual, this.state.IgnorePosCalc, this.state.Description, successHandler);
        }
    }
    handlUpdateInfo = () => {
        const successHandler = (result) => {
            this.props.onChange();
            NotificationManager.success('عملیات با موفقیت انجام شده است.');
        };
       
        httpCaller.Merchant.UpdateMerchantInfo(this.state.NationalId, this.state.SupplierCategory, successHandler);
    }
    handlclose = () => {
        var element = document.getElementById('permisionDiv');
        element.parentNode.removeChild(element);
    }

    handleChangeBlockedByManual = (value) => {

        this.setState({ BlockedByManual: value });
    }
    handleChangeSupplierCategory = val =>{
        this.setState({SupplierCategory:parseInt(val)});
    }

    handleChangeMerchantPos = (val) =>{
        
        this.setState({ IgnorePosCalc: val });
        // console.log( "val:",val, "this.state.IgnorePosCalc:", this.state.IgnorePosCalc);
    }

    componentDidMount() {
    }


    render() {
        const FormItem = Form.Item;
        const Option = Select.Option;
        return (

            <React.Fragment >
                <div id={"expDiv" + this.state.NationalId}>
                    <Card title='مشخصات'  size='small' headStyle={{ backgroundColor: "rgb(53, 126, 191)", color: "#f6f9fb" }}>
                    <Row>
                            <Col sm={24} md={12}   >
                                <FormItem
                                    label=" کد ملی"
                                    labelCol={{ xs: 12, md: 7, }}
                                    wrapperCol={{ xs: 12, md: 15, }}
                                    style= {{marginBottom:0}}
                                >
                                    <text>{this.state.NationalId}</text>
                                </FormItem>
                            </Col>

                            <Col sm={24} md={12}  >
                                <FormItem
                                    label=" نام بازرگان"
                                    labelCol={{ xs: 24, md: 8, }}
                                    wrapperCol={{ xs: 24, md: 16, }}
                                    style= {{marginBottom:0}}
                                >
                                    <text>{this.state.FullName}</text>
                                </FormItem>
                            </Col>

                           
                        </Row>
                        <Row>
                        <Col sm={24} md={12} >
                                <FormItem
                                    label="نوع بازرگان"
                                    labelCol={{ xs: 24, md: 8, }}
                                    wrapperCol={{ xs: 24, md: 16, }}
                                    style= {{marginBottom:0}}
                                >
                                    <text>{this.props.merchentKind}</text>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12}>
                            <FormItem
                                    label="گروه بازرگان"
                                    labelCol={{ xs: 24, md: 8, }}
                                    wrapperCol={{ xs: 24, md: 16 }}
                                    style= {{marginBottom:0}}
                                >
                                    <Select size={"default"} defaultValue={String(this.state.SupplierCategory)} 
                                    className="gx-ml-3 gx-mb-3" style={{ width: 200 }}
                                        onChange={(val) => this.handleChangeSupplierCategory(val)}>
                                        <Option value="0" checked={this.state.SupplierCategory} >سایر</Option>
                                        <Option value="1" checked={this.state.SupplierCategory} >پالایشگاه</Option>
                                        <Option value="2" checked={this.state.SupplierCategory} >پتروشیمی</Option>
                                        <Option value="3" checked={this.state.SupplierCategory} >ایمیدرو</Option>
                                        <Option value="4" checked={this.state.SupplierCategory} >بانك مركزي</Option>
                                        <Option value="5" checked={this.state.SupplierCategory} >شرکت های ملی گاز</Option>
                                        <Option value="6" checked={this.state.SupplierCategory} >شرکت های ملی نفت</Option>
                                        <Option value="7" checked={this.state.SupplierCategory} >شرکت های ملی پخش فرآورده های نفتی</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} sm={24} >
                                <Button type="primary" onClick={this.handlUpdateInfo}>ثبت</Button>
                            </Col>
                        </Row>
                    
                    </Card>
                    <Card title='مجوزها و دسترسی ها' size='small' headStyle={{ backgroundColor: "rgb(234, 141, 60)", color: "#f6f9fb" }}>
                        <Row>
                            <Col sm={24} md={12}>
                                <FormItem
                                    label="مجوز عرضه ارز در نیما"
                                    labelCol={{ xs: 24, md: 8, }}
                                    wrapperCol={{ xs: 24, md: 16, }}
                                    style= {{marginBottom:0}}
                                >
                                    <Switch size={"default"} onChange={this.handleChangeHaveAccess} checked={this.state.HaveAccess} />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12}>
                                <FormItem
                                    label="محدودیت عرضه ارز به بانک ها و صرافی های بانکی"
                                    labelCol={{ xs: 24, md: 19, }}
                                    wrapperCol={{ xs: 24, md: 5, }}
                                    style= {{marginBottom:0}}
                                >
                                    <Switch size={"default"} onChange={this.handleChangeJustSupplyForBank} checked={this.state.JustSupplyForBankSarafi} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={12}>
                                <FormItem
                                    label="مستثنی کردن از اعمال پوزیشن بازرگان"
                                    labelCol={{ xs: 24, md: 19, }}
                                    wrapperCol={{ xs: 24, md: 5, }}
                                    style= {{marginBottom:0}}
                                >
                                    <Switch size={"default"} onChange={this.handleChangeMerchantPos} checked={this.state.IgnorePosCalc} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={12}   >
                                <FormItem
                                    label="مسدودی محاسباتی"
                                    labelCol={{ xs: 24, md: 8, }}
                                    wrapperCol={{ xs: 24, md: 16, }}
                                    style= {{marginBottom:0}}
                                >
                                    <Switch size={"default"} checked={this.state.BlockedBySystem} />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12}    >
                                <FormItem
                                    label="مسدودی دستی"
                                    labelCol={{ xs: 24, md: 8, }}
                                    wrapperCol={{ xs: 24, md: 16, }}
                                    style= {{marginBottom:0}}
                                >
                                    <Select size={"default"} defaultValue={String(this.state.BlockedByManual)} ref={this.state.BlockedByManual}
                                        className="gx-ml-3 gx-mb-3" style={{ width: 130 }}
                                        onChange={(val) => this.handleChangeBlockedByManual(val)}>
                                        <Option value="0" checked={this.state.BlockedByManual}  >مسدود نشده</Option>
                                        <Option value="1" checked={this.state.BlockedByManual} >مسدود شده</Option>
                                        <Option value="2" checked={this.state.BlockedByManual} >رفع همه مسدودیها</Option>
                                    </Select>
                                </FormItem>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={24} md={16} >
                                <FormItem
                                    label="توضیحات"
                                    labelCol={{ xs: 24, md: 4, }}
                                    wrapperCol={{ xs: 24, md: 20, }}
                                    style= {{marginBottom:0}}
                                >
                                    <Input.TextArea rows={2} onChange={this.handleChangeInputDesc} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} sm={24} >
                                <Button type="primary" onClick={this.handlUpdateAccess}>ثبت</Button>
                            </Col>
                        </Row>

                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

export default CheckPermissionHOC(withRouter(Businessmenpermission), "Merchant_permission");   

import React, { Component } from 'react';
import { Tabs } from 'antd'
import CurrencyCeilingRateGrid from './CurrencyCeilingRateGrid'


import { CheckPermissionHOC, HasPermission } from '../../services/authorization'

class CurrencyCeilingRateList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            forumType: "talar1"
        };
    }


    loadSearchRequest = () => {
        this.setState({ forumType: "talar1" })
    }

    loadSearchOffer = () => {
        this.setState({ forumType: "talar2" })
    }


    handleSelect = (key) => {
          if(key=="1"){
            
            var cols = document.getElementsByClassName('ant-card-body');
            for(let i = 0; i < cols.length; i++) {
              cols[i].style.removeProperty('background-color');
             // cols[i].style.removeProperty('backgroundColor');
            }
          }
          else  {
            var cols = document.getElementsByClassName('ant-card-body');
            for(let i = 0; i < cols.length; i++) {
              cols[i].style.backgroundColor = '#d2ded2';
              //cols[i].style.backgroundcolor= '#d2ded2';
            }
          }
         // this.forceUpdate();
      }

    render() {

        const TabPane = Tabs.TabPane;

        if (!HasPermission("SettingManagement_RateRange")) return null;
        return (

            <div  >
                <Tabs   type="card" defaultActiveKey={1}>
                    <TabPane tab="سقف و کف قیمت خرید صرافی در تالار اول" key="1">
                        <CurrencyCeilingRateGrid  forumColor="#fff"  forumType="1" />

                    </TabPane>
                    <TabPane  tab="سقف و کف قیمت خرید صرافی در تالار دوم" key="2">
                        <CurrencyCeilingRateGrid  forumColor="#d2ded2" forumType="2" />

                    </TabPane>
                </Tabs>
            </div>

        );
    }
}

export default CurrencyCeilingRateList;

                        {/* <style>{"\
        .ant-card-body{\
            background-color: #d2dfd2;\
        }\
      "}</style> */}
import React, { Component } from 'react';
import {Row, Col, Card, Button, Input, Table, Form, Select, option } from 'antd';
import { withRouter } from "react-router-dom";
import httpCaller from '../../services/httpService';
import NumberFormat from 'react-number-format';

import {NotificationManager} from 'react-notifications'
import { DatePicker } from "react-advance-jalaali-datepicker";
import {CheckPermissionHOC} from '../../services/authorization';

class Invalidpayments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,
                fromDate: "",
                toDate: ""
        };

       
    }

    componentDidMount = () => {
      
    }

    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }
    handleChangeFromDate = (unix, formatted) => {
        this.setState({fromDate: formatted})
    }
    handleChangeToDate = (unix, formatted) => {
        this.setState({toDate: formatted})
    }
    handleSearchButtonClick = () => {
        if( this.state.fromDate <= this.state.toDate ){
            this.doSearch(1);
        }
        else { NotificationManager.warning('فیلد از تاریخ باید از فیلد تا تاریخ کوچکتر باشد');   }     
    
    }

    doSearch = (pageIndex) => {
        const rejectedHandler = (ar) => {
            NotificationManager.warning('خطا در دریافت دادها');
        };
        var param = {
            pageIndex: pageIndex,
            pageSize: 10,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            
        }
console.log("param", param)
        httpCaller.HesabTasvieh.GetInvalidPaymentIdentifier(param,
            (data) => {
                console.log("return server date: " , data)
                this.setState({ searchResult: data })
            }, rejectedHandler);
    }


    handlePageChange = (page) => {
        this.doSearch(page);
    };



    DatePickerInput(props) {
        return <Input {...props} style={{textAlign:"center", backgroundColor: "white"}} />;
    }

    render() {
        const FormItem = Form.Item;
        const columns = [{
            title: 'شناسه',
            dataIndex: 'id',
        }, {
            title: 'نوع',
            dataIndex: 'typeStr',
        }, {
            title: 'کد تراکنش',
            dataIndex: 'transactionId',
           
        }, {
            title: 'مبلغ ',
            dataIndex: 'amount',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={`ریال`} />
        }, {
            title: ' حساب مبدا',
            dataIndex: 'sourceAccount',

        }, {
            title: 'شناسه واریز',
            dataIndex: 'identifier',
        }, {
            title: 'تاریخ تراکنش',
            dataIndex: 'transactionDateStr',
        }, {
            title: 'نوع وضعیت',
            dataIndex: 'transactionStatusType',
        },
        , {
            title: ' نوع تراکنش',
            dataIndex: 'transactionType',
        }, {
            title: 'توضیحات',
            dataIndex: 'description',
        }
    
    
    ];
        return (
            <React.Fragment>
                <Card className="gx-card">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="از تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <DatePicker
                                        customClass ="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleChangeFromDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="fromDate"
                                       // preSelected={ this.state.fromDate}
                                        value={this.state.fromDate}
                                    />
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                <FormItem
                                    label="تا تاریخ"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <DatePicker
                                        customClass ="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleChangeToDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="toDate"
                                       // preSelected={ this.state.toDate}
                                        value={this.state.toDate}
                                    />

                                   
                                </FormItem>
                            </Col>
                            <Col md={8} sm={24}>
                                    <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                       جستجو
                                      </Button>
                           </Col>
                        </Row>


                    </Form>
                </Card>
                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageChange
                        }}
                        //onRowClick={this.GetRequestDetials}
                    />
                </Card>)}
            </React.Fragment>
        );
    }
}


export default CheckPermissionHOC(withRouter(Invalidpayments), "Invalidpayments_ViewRole");

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import httpCaller from '../../../services/httpService';
import NumberFormat from 'react-number-format';
import { Row, Col, Form, Input, Button, Card,Select, Table } from 'antd'
import {NotificationManager} from 'react-notifications'


class SearchRequest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,
            searchFilter: {
                requestCode: 0,
                nationalId: "",
                sabteSefareshCode: 0,
                currencyRateType:255
            }
        };
        this.searchFilterRef = {
            requestCode: React.createRef(),
            nationalId: React.createRef(),
            sabteSefareshCode: React.createRef()
        }
    }

    componentDidMount = () => {
        this.loadEntitiesBaseOnQuery();
    }

    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {

            this.handleSearchButtonClick();
        }
    }
    handleChangeStatusRate = (val) => {
        this.setState({ searchFilter: { ...this.state.searchFilter, currencyRateType:this.parseInt0(val) } } );
    }
    handleSearchButtonClick = () => {
        this.setState({
            searchFilter:
            {
                requestCode: this.searchFilterRef.requestCode.current.input.value,
                nationalId: this.searchFilterRef.nationalId.current.input.value,
                sabteSefareshCode: this.searchFilterRef.sabteSefareshCode.current.input.value,
                currencyRateType: this.state.searchFilter.currencyRateType
            }
        }
        ,
        () => {this.doSearch(1) }
        )
    }

    doSearch = (pageIndex) => {
        
        this.setQueryParameter();

        const rejectedHandler = (ar) => {
            NotificationManager.warning('خطا در دریافت دادها');
        };
        var param = {
            pageIndex: pageIndex,
            pageSize: 10,
            sabteSefareshCode: parseInt(this.state.searchFilter.sabteSefareshCode),
            requestCode: parseInt(this.state.searchFilter.requestCode),
            nationalId: this.state.searchFilter.nationalId,
            currencyRateType: this.state.searchFilter.currencyRateType
        }

        httpCaller.NimaImport.SearchRequest(param,
            (data) => {
                this.setState({ searchResult: data })
            }, rejectedHandler);
    }
    parseInt0 = ( paramValue)=>{
        if(paramValue === null || paramValue === ""){
            return 0;
        }
        else    {
            return parseInt(paramValue) ;
        }
    }

    handlePageChange = (page) => {
        this.doSearch(page);
    };

    GetRequestDetials = (row) => {
        this.props.history.push(`/nima/request/${row.requestCode}`); //new
    }

    loadEntitiesBaseOnQuery(){
        let searchParams = new URLSearchParams(this.props.location.search); 
        let hasReqId = searchParams.has("reqId");
        let hasNationalId = searchParams.has("nationalId");
        let hasSabtesefaresh = searchParams.has("sabtesefaresh");
 
        if(hasReqId){
            this.searchFilterRef.requestCode.current.setValue(searchParams.get("reqId"));
        }
 
        if(hasNationalId){
            this.searchFilterRef.nationalId.current.setValue(searchParams.get("nationalId"));
        }

        if(hasSabtesefaresh){
            this.searchFilterRef.sabteSefareshCode.current.setValue(searchParams.get("sabtesefaresh"));
        }
       
        if(hasReqId || hasNationalId || hasSabtesefaresh){
            setTimeout(() => {
                this.handleSearchButtonClick();
            }, 500);
        }

    }

    setQueryParameter = ()=>{
       
        let pathname = this.props.location.pathname; 
        let searchParams = new URLSearchParams(); 
        searchParams.set("type", 1);
       
        if(this.state.searchFilter.requestCode.trim() !=""){
            searchParams.set("reqId", this.state.searchFilter.requestCode);
        }

        if(this.state.searchFilter.nationalId.trim() !=""){
            searchParams.set("nationalId", this.state.searchFilter.nationalId);
        }

        if(this.state.searchFilter.sabteSefareshCode.trim() !=""){
            searchParams.set("sabtesefaresh", this.state.searchFilter.sabteSefareshCode);
        }
       
        this.props.history.push({
                 pathname: pathname,
                 search: searchParams.toString()
           });

    }

    render() {
        const FormItem = Form.Item;
        const columns = [{
            title: 'کد درخواست',
            dataIndex: 'requestCode',
        }, {
            title: 'کد ثبت سفارش',
            dataIndex: 'sabteSefareshCode',
        }, {
            title: 'مبلغ',
            dataIndex: 'amount',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ${row.currencyName}`} />
        }, {
            title: 'تاریخ درخواست',
            dataIndex: 'requestRegistrationDatePersian',
        }, {
            title: 'تاریخ اعتبار',
            dataIndex: 'requestValidityDatePersian',

        }, {
            title: 'ثبت کننده درخواست',
            dataIndex: 'sourceInsertedDesc',
        }, {
            title: 'نام متقاضی',
            dataIndex: 'applicantName',
        }];
        return (
            <React.Fragment>
                <Card className="gx-card">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد درخواست"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.searchFilterRef.requestCode} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد ثبت سفارش"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.searchFilterRef.sabteSefareshCode} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد ملی"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.searchFilterRef.nationalId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="نوع نرخ "
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Select defaultValue="255" onChange={this.handleChangeStatusRate} > 
                                        <Select.Option value="255">همه گزینه ها</Select.Option>
                                        <Select.Option value="0">مصوب</Select.Option>
                                        <Select.Option value="1">آزاد</Select.Option>
                                        <Select.Option value="2">تعیین شده توسط بانک مرکزی</Select.Option>
                                        <Select.Option value="9">تالار دوم</Select.Option>
                                    </Select> 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>

                            <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                جستجو
                            </Button>
                        </Row>
                    </Form>
                </Card>
                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageChange
                        }}
                        onRowClick={this.GetRequestDetials}
                    />
                </Card>)}
            </React.Fragment>
        );
    }
}

export default withRouter(SearchRequest);

import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import httpCaller from '../../../services/httpService';
import NumberFormat from 'react-number-format';
import { Row, Col, Form, Input, Button, Card, Table } from 'antd'
import {NotificationManager} from 'react-notifications'

class SearchOffer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResult: null,
            searchFilter: {
                offerId: 0,
                exchangeId: 0
            }
        };
        this.searchFilterRef = {
            offerId: React.createRef(),
            exchangeId: React.createRef()
        }
    }

    componentDidMount = () => {
        this.loadEntitiesBaseOnQuery();
    }
    
    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }

    doSearch = (pageIndex) => {
        
        this.setQueryParameter();

        var param={
            pageIndex:pageIndex,
            pageSize:10, 
            offerId: parseInt(this.state.searchFilter.offerId),
            exchangeId : parseInt(this.state.searchFilter.exchangeId)
        }

        httpCaller.NimaImport.SearchOffer(param, (data)=>{
            this.setState({searchResult:data})
        });
    }
    handleSearchButtonClick = () => {
        var offerId= 0,exchangeId=0;
        var offerIdStr= this.searchFilterRef.offerId.current.input.value;
        var exchangeIdStr = this.searchFilterRef.exchangeId.current.input.value;

        if(offerIdStr.trim() != ""){
            offerId = Number.parseInt(offerIdStr.trim())
        }
        if(exchangeIdStr.trim() != ""){
            exchangeId = Number.parseInt(exchangeIdStr.trim())
        }

        //Commented By Dajer
        // if(!!offerId || !exchangeId){
        //     NotificationManager.error("خطا در نوع مقدار داده های ورودی");
        // }

        this.setState({ searchFilter: 
            { offerId: offerId, exchangeId: exchangeId } }
            ,
            () => { this.doSearch(1) })
    }
    handlePageChange = (page) => {
        this.doSearch(page);
    };

    handleTableRowClicked = (row) => {
        if(!!row.offerId){
            this.props.history.push(`/nima/request/${row.requestCode}/${row.offerId}`)
        }
        else{
            this.props.history.push(`/nima/request/${row.requestCode}`)
        }
    }

    loadEntitiesBaseOnQuery(){
        let searchParams = new URLSearchParams(this.props.location.search); 
        let hasOfferId = searchParams.has("offerId");
        let hasExchangeId = searchParams.has("exchangeId");
 
        if(hasOfferId){
            this.searchFilterRef.offerId.current.setValue(searchParams.get("offerId"));
        }
 
        if(hasExchangeId){
            this.searchFilterRef.exchangeId.current.setValue(searchParams.get("exchangeId"));
        }
       
        if(hasOfferId ||hasExchangeId){
            setTimeout(() => {
                this.handleSearchButtonClick();
            }, 500);
        }

    }

    setQueryParameter = ()=>{
       
        let pathname = this.props.location.pathname; 
        let searchParams = new URLSearchParams(); 
        searchParams.set("type", 2);
       
        if(this.searchFilterRef.offerId.current.input.value.trim() !=""){
            searchParams.set("offerId", this.state.searchFilter.offerId);
        }

        if(this.searchFilterRef.exchangeId.current.input.value.trim() != ""){
            searchParams.set("exchangeId", this.state.searchFilter.exchangeId);
        }
       
        this.props.history.push({
                 pathname: pathname,
                 search: searchParams.toString()
           });

    }

    render() {
        const FormItem = Form.Item;
        const columns= [{
            title: 'کد پیشنهاد',
            dataIndex: 'offerId',
        }, {
            title: 'کد درخواست',
            dataIndex: 'requestCode',
        }, {
            title: 'کد صرافی',
            dataIndex: 'exchangeId',
        }, {
            title: 'مبلغ',
            dataIndex: 'amount',
            render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ${row.currencyName}`} />
        }, {
            title: 'وضعیت',
            dataIndex: 'offerStatusStr',
            
        }, {
            title: 'تاریخ ثبت پیشنهاد',
            dataIndex: 'offerRegisterDateStr',
        }];
        return (
            <React.Fragment>
                <Card className="gx-card">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد پیشنهاد"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.searchFilterRef.offerId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد صرافی"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input ref={this.searchFilterRef.exchangeId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>

                        </Row>
                        <Row>

                            <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                جستجو
                            </Button>
                        </Row>
                    </Form>
                </Card>
                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageChange
                        }}
                        onRowClick = {this.handleTableRowClicked}
                    />
                </Card>)}
            </React.Fragment>

        );
    }
}

export default withRouter(SearchOffer);

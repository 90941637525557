import React, { Component } from 'react';
import { Card, Row, Col, Checkbox, Select, Form, Modal, TreeSelect, Button, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import httpCaller from '../../services/httpService';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications'
import numeral from 'numeral';
const { confirm } = Modal;
 
class ExceptionalCurUseBySarafiList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Buy: false,
            Sale: false,
            Cash: false,
            Havale: false,
            sarafiId: this.props.location?.state?.sarafiId == undefined ? 0 : this.props.location.state.sarafiId,
            ExceptionalCurUseBySarafiId: null,
            children: [],
            searchResult: [],
            pageSize: 10,
            pageIndex: 1,
            sarafies: []
        }
    }
   

    componentDidMount = () => {
    
        this.Search(1,true);
        httpCaller.Sarafi.GetAllSarafies("", (data) => {
            this.setState({ sarafies: data });
        }, null, false);      
        
    }

    handleSearchTreeSelect = (value) => {
        // this.setState({ searchFilter: { ...this.state.searchFilter, sarafiId: (!value ? null : parseInt(value)) } })
        this.setState({ sarafiId: (!value ? null : parseInt(value)) })
    }

    handleSearchButtonClick = () => {
        if (this.state.sarafiId == 0 || this.state.sarafiId == undefined) {
            NotificationManager.warning(`لطفا صرافی را انتخاب نمایید`);
        }
        else {
            this.Search(1, true);
        }
    }

    handlePageChange = (page) => {
        this.Search(page, true);
    };

    handleDetailButtonClick=(id) => {
        this.props.history.push({ pathname: '/NewExceptionalCurUseBySarafi', state: { id:id, sarafiId: this.state.sarafiId }, })
      }


    handleDeleteButtonClick = () => {
        var id = this.state.ExceptionalCurUseBySarafiId;
        console.log(id);
        httpCaller.Sana.DeleteExceptionalCurUseBySarafi(id, () => {
            this.Search(1, true);
            this.props.action(1)
        }, () => { })
    }

    showDeleteConfirm = () => {
        console.log();
        confirm({
            title: 'آیا از حذف مطمئن هستید؟',
            content:
                'پس از حذف دسترسی به این مستثنی وجود نخواهد داشت',
            onOk: () => {
                this.handleDeleteButtonClick();
                console.log("sdfsdfsd");
            },
            onCancel: () => { },
        });
    }


    Search = (pageIdx, isSOCLog) => {
        this.setState({ pageIndex: pageIdx });
        var data = this.prepareData(pageIdx);
        httpCaller.Sana.SearchExceptionalCurUseBySarafi(data, (result) => {
            this.setState({ searchResult: result });
        }, () => { }, isSOCLog)
        
    }

    prepareData = (pageIdx) => {
        return {
            SarafiId: this.state.sarafiId,
            pageIndex: parseInt(pageIdx),
            pageSize: parseInt(this.state.pageSize),
        }
    }


   

    render() {
      const columns = [        
        {
            title: 'نام صرافی', 
            dataIndex: 'sarName',
            width: '450px',
        },
        {
            title: ' کد سرفصل',
            dataIndex: 'currencyUseId',
        },
        {
            title: ' نام سرفصل', 
            dataIndex: 'currencyUseName',
            width: '650px',
        },
        // {
        //     title: 'خرید نقدی',
        //     dataIndex: 'buyCash',
        //     render: (text) => (
        //         <span style={{ fontSize: '20px' }}>{text === true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />}</span>)
        // },
        // {
        //     title: 'فروش نقدی',
        //     dataIndex: 'sellCash',
        //     render: (text) => (
        //         <span style={{ fontSize: '20px' }}>{text === true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />}</span>)
        // },
        // {
        //     title: 'خرید حواله',
        //     dataIndex: 'buyHavaleh',
        //     render: (text) => (
        //         <span style={{ fontSize: '20px' }}>{text === true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />}</span>)
        // },
        // {
        //     title: 'فروش حواله',
        //     dataIndex: 'sellHavaleh',
        //     render: (text) => (
        //         <span style={{ fontSize: '20px' }}>{text === true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />}</span>)
        // },
        // {
        //     title: 'توضیحات',
        //     dataIndex: 'description',
        // },
        {
            title: 'سقف خرید (یورو)',
            dataIndex: 'allowedBuy',
            render: (text) => (
                <span >{text === -1 ? "بدون سقف" : text}</span>)
        },
        // {
        //     title: 'توضیحات سقف خرید',
        //     dataIndex: 'descriptionallowedBuy',
        // },
        // {
        //     title: 'عملیات',
        //     dataIndex: 'id',
        //     render: (text) => (
        //         <div>
        //             <span style={{ fontSize: '20px' }}><Button type="primary" onClick={this.handleEditButtonClick}><EditOutlined /></Button></span>
        //             <span style={{ fontSize: '20px' }}><Button type="danger" onClick={this.showDeleteConfirm}><DeleteOutlined /></Button></span>

        //         </div>)

        // },
        ];

        const FormItem = Form.Item;

        // const plainOptions = [
        //     {label: 'خرید نقدی', value: 1},
        //     {label: 'فروش نقدی', value: 2},
        //     {label: 'خرید حواله', value: 4},
        //     {label: 'فروش حواله', value: 8}];

        // const CheckboxGroup = Checkbox.Group;
        return (
            <Row>
                <Col md={24} xs={24}>
                    <Card className="gx-card" >
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="صرافی"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}>
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        treeNodeFilterProp='title'
                                        dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                        placeholder="لطفا انتخاب کنید"
                                        style={{ width: "100%" }}
                                        value={this.state.sarafiId}
                                        onChange={this.handleSearchTreeSelect}>
                                        {
                                            this.state.sarafies.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                        }
                                    </TreeSelect>
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                    جستجو
                                </Button>
                                <Button type="primary" icon="add" htmlType="button" onClick={() => {
                                    if (this.state.sarafiId == 0 || this.state.sarafiId == undefined) {
                                        NotificationManager.warning(`لطفا صرافی را انتخاب نمایید`);
                                    }
                                    else {
                                        this.props.history.push({ pathname: '/NewExceptionalCurUseBySarafi', state: { id:null, sarafiId: this.state.sarafiId }, })
                                    }
                                }
                                }>
                                    ایجاد مستثنی جدید
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                    {this.state.searchResult && (<Card className="gx-card" >
                        <Table className="gx-table-responsive withPointer"
                            rowKey="id"
                            // onRow={(record, rowIndex) => {
                            //     return {
                            //         onClick: event => {
                            //             this.setState({ ExceptionalCurUseBySarafiId: record.id });
                                        
                            //         }, // click row
                            //     };
                            // }}
                            onRow={(record) => {
                                return {
                                  onClick : () => {
                                   // this.setState({ ExceptionalCurUseBySarafiId: record.id });
                                     this.handleDetailButtonClick(record.id)
                                }
                                };
                              }}
                            columns={columns}
                            dataSource={this.state.searchResult.items}
                            bordered
                            // expandedRowRender={(row) => <ExceptionalCurUseBySarafiDetail action={this.Search} ExceptionalCurUseBySarafiId={row.id}></ExceptionalCurUseBySarafiDetail>}
                            // expandRowByClick={true}
                            pagination={{
                                total: this.state.searchResult.totalCount,
                                pageSize: this.state.searchResult.pageSize,
                                current: this.state.searchResult.pageIndex,
                                onChange: (index) => this.handlePageChange(index)
                            }}
                        />
                    </Card>)}
                </Col>
            </Row>
        )
    }
}

export default ExceptionalCurUseBySarafiList

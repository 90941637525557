import React, { Component } from 'react';
import {Row, Col, Card, Button, Input, Table, Form, Select, option } from 'antd';
import NumberFormat from 'react-number-format';
import {NotificationManager} from 'react-notifications';
import httpCaller from "../../services/httpService";
import {withRouter} from 'react-router-dom';
import {HasPermission} from '../../services/authorization';
import PaymentTasviehDetail from '../HesabTasvieh/PaymentTasviehDetail';
import { DatePicker } from "react-advance-jalaali-datepicker";
import {CheckPermissionHOC} from '../../services/authorization';

class SearchDigitalChequePayments extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            searchResult: null,
            creationDate: '',
            entityId: 0,
            digitalChequeStatus: 1,
            mediaId: 0,
            trackingCode: ''
        }
    }

    componentDidMount = () => {
        this.setState({entityId:''})
        this.setState({mediaId:''})
    }

    handleSearchInputKeyUp = (e) => {
        if (e.key === "Enter") {
            this.handleSearchButtonClick();
        }
    }

    search = (pageIndex) => {

        var data = this.prepareRequest(pageIndex);

        httpCaller.HesabTasvieh.DigitalChequePayments(data, (result) => {
                 this.setState({searchResult: result});
             }, () =>{});
    }

    prepareRequest(pageIndex) {
        return {
            pageIndex : pageIndex,
            pageSize: 10, 
            havaleCreationDate: this.state.creationDate,
            entityId : parseInt(this.state.entityId === '' ? 0 : this.state.entityId),
            type: 2,
            digitalChequeStatus: parseInt(this.state.digitalChequeStatus),
            mediaId: parseInt(this.state.mediaId === '' ? 0 : this.state.mediaId),
            trackingCode: this.state.trackingCode,
        }
    }

    handlePageChange = (selectedIndex) => {
        this.search(selectedIndex);
    }

    handleSearchButtonClick = () => {

        this.search(1);
    }

    handleChangeEntity = (e) =>{
        const reg = /^[0-9\b]+$/;
        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({entityId:e.target.value})
        }
    }

    handleChangeStatus = (val) => {
        this.setState({digitalChequeStatus: val})
    }

    handleChangeTrackingCode = (e) => {
        const reg = /^[0-9\b]+$/;
        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({trackingCode: e.target.value})
        }
    }

    handleChangeMediaId = (e) => {
        const reg = /^[0-9\b]+$/;
        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({mediaId: e.target.value})
        }
    }

    handleChangeCreationDate = (unix, formatted) => {
        this.setState({creationDate: formatted})
    }

    DatePickerInput(props) {
        return <Input {...props} style={{textAlign:"center", backgroundColor: "white"}} />;
    }

    render(){
        const FormItem = Form.Item;
        const columns = [{
            title: 'کد',
            dataIndex: 'id',
        }, {
            title: 'کد پیگیری',
            dataIndex: 'trackingCode',
        }, {
            title: 'کد ثبت حواله',
            dataIndex: 'chequeId',
        }, {
            title: 'شماره رسانه',
            dataIndex: 'mediaId',
        }, {
            title: 'کد معامله',
            dataIndex: 'entityId',
        }, {
            title: 'تاریخ ثبت حواله',
            dataIndex: 'creationDateStr',

        }];

        return (
            <React.Fragment>
                <Card className="gx-card">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد معامله"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input value={this.state.entityId} onChange={this.handleChangeEntity}  />
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="وضعیت حواله"
                                    labelCol={{ xs: 24, md: 12, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 12, lg: 15 }}
                                >
                                    <Select defaultValue="1" onChange={this.handleChangeStatus} > 
                                        <Select.Option value="1">موفق</Select.Option>
                                        <Select.Option value="2">ناموفق</Select.Option>
                                    </Select>                                  
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="کد پیگیری"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input value={this.state.trackingCode} onChange={this.handleChangeTrackingCode} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="شماره رسانه"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <Input value={this.state.mediaId} onChange={this.handleChangeMediaId} onKeyUp={this.handleSearchInputKeyUp} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={24}>
                                <FormItem
                                    label="تاریخ ثبت حواله"
                                    labelCol={{ xs: 24, md: 7, lg: 6 }}
                                    wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                                >
                                    <DatePicker
                                        customClass ="customDatePicker"
                                        inputComponent={this.DatePickerInput}
                                        format="jYYYY/jMM/jDD"
                                        onChange={this.handleChangeCreationDate}
                                        onKeyUp={this.handleSearchInputKeyUp}
                                        id="datefrom"
                                        preSelected={this.state.creationDate}
                                        value={this.state.creationDate}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={24} sm={24}>
                                <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearchButtonClick}>
                                    جستجو
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                {this.state.searchResult && (<Card>
                    <Table className="gx-table-responsive withPointer"
                        rowKey="id"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        expandedRowRender={(row)=><PaymentTasviehDetail paymentId = {row.id}></PaymentTasviehDetail>}
                        expandRowByClick = {true}
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: this.handlePageChange
                        }}
                    />
                </Card>)}
            </React.Fragment>
        );
    }
}

export default CheckPermissionHOC(withRouter(SearchDigitalChequePayments), "Invalidpayments_ViewRole");
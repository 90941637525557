import React, { Component, useReducer } from 'react';
import { withRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Button, Col, Row, Card,Tabs } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
import { connect } from 'react-redux';
// import NotFound from '../Not-Found';
import ExchangeLicenseHistory from './ExchangeLicenseHistory';
import ExchangeInfo from './ExchangeInfo';
import ExchangePosition from './ExchangePosition';
import {HasPermission} from '../../../services/authorization'
import {CheckPermissionHOC} from '../../../services/authorization';

class ExchangeDetail extends Component {

  constructor(props) {
    super(props)
    let id = this.props.match.params["code"];
    this.state = {
      Id: id
    };
  }

  backBorwser = () => {
    window.history.back();
  }

  componentDidMount = () => {
    
  }

  handleSelect = (key) => {
    switch(key) {
      case "2":
        //this.forceUpdate();
    }
      
  }

  render() {
    let exchangeManagementView = HasPermission("ExchangeManagement_View");
    const TabPane = Tabs.TabPane;
    
    return (
      exchangeManagementView && <React.Fragment>
        <Row>
          <Col span={24}>
              <Tabs  type="card" onChange={this.handleSelect}>
                <TabPane tab="مشخصات صرافی" key="1">
                  <ExchangeInfo exchangeId={this.state.Id} />
                </TabPane>
                <TabPane tab="تاریخچه تغییر مجوزهای صرافی" key="2">
                  <ExchangeLicenseHistory update={true} exchangeId={this.state.Id} />
                </TabPane>
                {HasPermission ("ExchangeManagement_ViewBalance") &&
                  <TabPane tab="تراز صرافی" key="3">
                    <ExchangePosition exchangeId={this.state.Id} />
                  </TabPane>
                }
            </Tabs>            
          </Col>
        </Row>
        <br/>
        <Row style={{direction:"ltr",paddingLeft:"18px"}}>
          <Col>
            <Button type="default" onClick={this.backBorwser}>بازگشت</Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

//Commented By Dajer
//export default  CheckPermissionHOC (ExchangeDetail , "ExchangeManagement_View");
export default withRouter(ExchangeDetail)


import React,{Component, Fragment} from 'react'
import { Redirect, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import {Form, Card, Row, Col, List, Table, Button} from 'antd'
import createHistory from 'history/createBrowserHistory'
import {FilePdfOutlined} from '@ant-design/icons'

class DownloadFile extends Component{
    constructor(props)
    {
        super(props)
        this.state = {
        }
    }

    componentDidMount(){

    }

    handleDownload = () => {
        
        fetch(process.env.PUBLIC_URL + '/Help_Tasvieh.pdf')
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
                    a.href = url;
                    a.target = '_blank'
                    a.download = 'Help_Tasvieh'
					a.click();
				});
		    });
    }

    render(){
        return (
            <Row>
                <Col span={8}/>
                <Col span={8}>
                    <Card title="فهرست مستندات"style={{textAlign:"center",marginTop:"20%"}} 
                        headStyle={{backgroundColor:"#038fdd",color:"#f6f9fb"}} 
                        bodyStyle={{backgroundColor:"#e1e6f0"}}>
                        <Row>
                            <Col span={8}>
                                <FilePdfOutlined style={{fontSize:'30px', color:'red'}} />
                            </Col>
                            <Col span={8}>
                                <a href = {process.env.PUBLIC_URL + '/Help_Tasvieh.pdf'} target='_blank'>حساب تسویه ریالی</a>
                            </Col>
                            <Col span={4}>
                                <Button type="primary" shape="circle" icon="download" onClick={this.handleDownload} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}/>
            </Row>
        );
    }
}

export default DownloadFile
import React, { Component } from "react";
import { Card, Row, Col, Form, Divider, Select, Button, Table, TreeSelect, Input, Alert } from 'antd';
import MaskedInput from 'antd-mask-input';
import httpCaller from '../../services/httpService';
import NumberFormat from 'react-number-format';
import moment from 'moment-jalaali'
//import DropdownTreeSelect from 'react-dropdown-tree-select';
import numeral from 'numeral'
import { FileExcelTwoTone, SearchOutlined, WindowsFilled } from '@ant-design/icons';
//import 'react-dropdown-tree-select/dist/styles.css';
import DisplayFormData from '../utility/DisplayFormData';
import { isNull, trimStart } from "lodash";
import { CheckPermissionHOC } from '../../services/authorization'
import SimpleFieldSet from '../utility/SimpleFieldSet';


class CheckSanaRialiPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: "",
            toDate: "",
            paymentType: -1,
            paymentDate: "",
            amount: 0,
            paymentStatus: -1,
            paymentValidity: -1,
            children: [],
            selectedSarafies: '',
            srchResult: { items: [] },
            pageSize: 10,
            pageIndex: 1,
        }
    }

    componentDidMount() {
        httpCaller.Sarafi.GetAllSarafies("", (data) => {
            this.setState({ children: data })
        }, (err) => { }, false);

    }

    handleChangePaymentType = (val) => {
        this.setState({ paymentType: val })
    }

    handleChangeStatus = (val) => {
        this.setState({ paymentStatus: val })
    }

    handleChangePaymentValidity = (val) => {
        this.setState({ paymentValidity: val })
    }

    handleTreeChange = (value) => {

    }

    handleTreeSelect = (value) => {
        console.log("SAR", value)
        var temp = this.state.selectedSarafies;
        this.setState({ selectedSarafies: value })
        // this.setState({ selectedSarafies: `${temp},${value}` });
    }

    handleSearchButtonClick = () => {
        this.fetchData(1);
    }

    handlePageChange = (page) => {
        this.fetchData(page);
    };

    fetchData = (pageIndex) => {
        var param = {
            pageIndex: pageIndex,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            payType: this.state.paymentType == "-1" ? '' : this.state.paymentType,
            paymentStatus: this.state.paymentStatus == "-1" ? '' : this.state.paymentStatus,
            paymentValidity: this.state.paymentValidity == "-1" ? '' : this.state.paymentValidity,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
        };
        httpCaller.Reports.GetRilaliPaymentStatus(param, (res) => { this.setState({ srchResult: res }) }, (err) => { console.log(err) })
    }

    handleFromDate = (e) => {
        this.setState({ fromDate: e.target.value })
    }

    handleToDate = (e) => {
        this.setState({ toDate: e.target.value })
    }

    handleSrchTree = (val) => {
        httpCaller.Sarafi.GetAllSarafies(val, (data) => {
            this.setState({ children: data })
        }, (err) => { }, false);
    }

    handleExportExcel = () => {
        var param = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            payType: this.state.paymentType == "-1" ? '' : this.state.paymentType,
            paymentStatus: this.state.paymentStatus == "-1" ? '' : this.state.paymentStatus,
            paymentValidity: this.state.paymentValidity == "-1" ? '' : this.state.paymentValidity,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
        };

        var nd = new Date();
        var jt = moment.jConvert.toJalaali(nd.getFullYear(), nd.getMonth() + 1, nd.getDate());

        var fileName = `RialiPaymentStatus_${jt.jy}${('0'+jt.jm).slice(-2)}${('0'+jt.jd).slice(-2)}.xls`
        httpCaller.Reports.GetRilaliPaymentStatusExcel(fileName, param);
    }

    render() {
        const FormItem = Form.Item;

        const columns =
            [{
                title: 'کد پیگیری سنا',
                dataIndex: 'sanaTrackingCode',
            }
                , {
                title: 'کد صرافی',
                dataIndex: 'sarCode',
            }
                , {
                title: 'نام صرافی',
                dataIndex: 'sarName',
            },
            {
                title: 'نام بانک حساب صرافی',
                dataIndex: 'paymentBank',
            }
                , {
                title: 'تاریخ معامله',
                dataIndex: 'pDate',
            }
                , {
                title: 'نوع تراکنش',
                dataIndex: 'transactionTypeStr',
            }
                , {
                title: 'نام ارز',
                dataIndex: 'currencyName',
            }
                , {
                title: 'نرخ ارز',
                dataIndex: 'currencyRate',
            }
                , {
                title: 'مبلغ ریالی معامله',
                dataIndex: 'rialiAmount',
                render: (text) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
            }
                , {
                title: 'پرداخت های تراکنش',
                className: "nestedHeader",
                children: [
                    {
                        title: 'ابزار پرداخت',
                        dataIndex: 'transPaymentTypeStr',
                    },
                    {
                        title: 'مبلغ پرداخت',
                        dataIndex: 'paymentAmount',
                        render: (text) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                    },
                    {
                        title: 'وضعیت صحت پرداخت',
                        dataIndex: 'paymentValidityStr',
                    },
                    {
                        title: 'نتیجه تطبیق اطلاعات پرداخت کننده',
                        dataIndex: 'paymentStatusStr',
                    }
                ],
            }
            ];



        return (
            <React.Fragment>
                <Row >
                    <Col sm={11}>
                        <div style={{ fontSize: '1.6rem', fontWeight: 'Bold' }}>وضعيت صحت​ سنجی هر پرداخت</div>
                    </Col>
                </Row>
                <Divider style={{ backgroundColor: '#c5c5c5', height: '2px' }}></Divider>
                <Card className="gx-card">
                    <Row >
                        <Col md={11}>
                            <Alert message="تنها پرداختهایی که از طریق پایانه فروش (POS)، ساتنا و پایا انجام شده، مورد بررسی قرار گرفته است." type="warning" showIcon />
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} sm={24}>
                            <FormItem
                                label=" تاریخ پرداخت از"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <MaskedInput mask="1111/11/11" id="fromDate" name="fromDate" placeholder="yyyy/mm/dd" style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                                    placeholder="1400/01/01" value={this.state.fromDate} onChange={this.handleFromDate} />
                            </FormItem>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                                label=" تاریخ پرداخت تا"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <MaskedInput mask="1111/11/11" id="toDate" name="toDate" placeholder="yyyy/mm/dd" style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                                    placeholder="1400/01/01" value={this.state.toDate} onChange={this.handleToDate} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="وضعیت بررسی صحت پرداخت"
                                labelCol={{ xs: 24, md: 24, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 24, lg: 15 }}
                            >
                                <Select defaultValue="-1" onChange={this.handleChangePaymentValidity} style={{ width: '100%' }}>
                                    <Select.Option value="-1">همه وضعیت ها</Select.Option>
                                    <Select.Option value="1">بررسی نشده</Select.Option>
                                    <Select.Option value="2">تایید نشده</Select.Option>
                                    <Select.Option value="3">تایید شده</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="وضعیت تطبیق اطلاعات پرداخت کننده"
                                labelCol={{ xs: 24, md: 24, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 24, lg: 15 }}
                            >
                                <Select defaultValue="-1" onChange={this.handleChangeStatus} style={{ width: '100%' }}>
                                    <Select.Option value="-1">همه وضعیت ها</Select.Option>
                                    <Select.Option value="1">بررسی نشده</Select.Option>
                                    <Select.Option value="2">عدم تطابق</Select.Option>
                                    <Select.Option value="3">تطبیق شده</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="ابزار پرداخت"
                                labelCol={{ xs: 24, md: 12, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 12, lg: 15 }}
                            >
                                <Select defaultValue="-1" onChange={this.handleChangePaymentType} style={{ width: '100%' }}>
                                    <Select.Option value="-1">همه موارد</Select.Option>
                                    <Select.Option value="1">ساتنا</Select.Option>
                                    <Select.Option value="2">پایا</Select.Option>
                                    <Select.Option value="3">انتقال درون بانکی</Select.Option>
                                    <Select.Option value="4">کارت به کارت</Select.Option>
                                    <Select.Option value="5">چک</Select.Option>
                                    <Select.Option value="6">واریز به حساب</Select.Option>
                                    <Select.Option value="7">POS</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                                label="صرافی"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <TreeSelect
                                    showSearch
                                    allowClear
                                    multiple
                                    treeNodeFilterProp='title'
                                    treeCheckable
                                    dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                    placeholder="لطفا انتخاب کنید"
                                    style={{ width: "100%" }}
                                    onChange={this.handleTreeSelect}
                                >
                                    {
                                        this.state.children.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                    }

                                </TreeSelect>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={24}>
                            <Button type="primary" htmlType="button" onClick={() => this.handleSearchButtonClick()}>
                                جستجو
                            </Button>
                            <Button htmlType="button" type="default" style={{ borderColor: "green", color: "green" }} onClick={() => this.handleExportExcel()}
                                icon={<FileExcelTwoTone twoToneColor="#52c41a" />}>
                                انتقال به فایل اکسل
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={24}>
                            تاریخ آخرین پرداخت ریالی بررسی شده: {this.state.srchResult.lastCheckedDate}
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <SimpleFieldSet title='بررسی صحت پرداخت ها در یک نگاه' isCollapsible> 
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تعداد بررسی نشده" value={numeral(this.state.srchResult.notCheckedValidityCount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تعداد تایید نشده" value={numeral(this.state.srchResult.notApprovedCount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تعداد تایید شده" value={numeral(this.state.srchResult.approvedCount).format(0, 0)} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="بررسی نشده(به ریال)" value={numeral(this.state.srchResult.notCheckedValidityAmount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تایید نشده(به ریال)" value={numeral(this.state.srchResult.notApprovedAmount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تایید شده(به ریال)" value={numeral(this.state.srchResult.approvedAmount).format(0, 0)} />
                            </Col>
                        </Row>
                        <br />
                    </SimpleFieldSet>
                    <br/>
                    <SimpleFieldSet style={{color:"Red"}} title='بررسی تطبیق اطلاعات پرداخت ها در یک نگاه' isCollapsible> 
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تعداد بررسی نشده" value={numeral(this.state.srchResult.notCheckedCount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تعداد عدم تطابق" value={numeral(this.state.srchResult.notMatchedCount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تعداد تطبیق شده" value={numeral(this.state.srchResult.matchedCount).format(0, 0)} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="بررسی نشده(به ریال)" value={numeral(this.state.srchResult.notCheckedAmount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="عدم تطابق(به ریال)" value={numeral(this.state.srchResult.notMatchedAmount).format(0, 0)} />
                            </Col>
                            <Col md={8} xs={24}>
                                <DisplayFormData lable="تطبیق شده(به ریال)" value={numeral(this.state.srchResult.matchedAmount).format(0, 0)} />
                            </Col>
                        </Row>
                    </SimpleFieldSet>
                    <br/>
                    <Table className="gx-table-responsive"
                        columns={columns}
                        dataSource={this.state.srchResult.items.items}
                        bordered
                        pagination={true}
                        expandRowByClick={false}
                        rowKey="sanaTrackingCode"
                        pagination={{
                            total: this.state.srchResult.items.totalCount,
                            pageSize: this.state.srchResult.items.pageSize,
                            current: this.state.srchResult.items.pageIndex,
                            onChange: (index) => this.handlePageChange(index)
                        }}
                    />
                </Card>
            </React.Fragment>
        )
    }
}

export default CheckPermissionHOC(CheckSanaRialiPayments, 'ReportsManagement_SanaRialiValidation')
//export default CheckSanaRialiPayments;

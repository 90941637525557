import React, { Component } from 'react';
import { Row, Col} from 'antd'
import {HasPermission} from '../../services/authorization';
import Shahker from "./SettingElements/Shahkar"
import NimaOperationTime from "./SettingElements/NimaOperationTime"
import PolicyCoefficients from "./SettingElements/PolicyCoefficients"
import {CheckPermissionHOC} from '../../services/authorization';

class SanaSetting extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const PolicyCoefficientPermission = HasPermission("SettingManagement_PolicyCoef");
        return (
            <React.Fragment>
                <Row>
                    <Col span={24}>
                        <Shahker />
                        <NimaOperationTime />
                        {PolicyCoefficientPermission && <PolicyCoefficients />}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

SanaSetting.propTypes = {

};

export default CheckPermissionHOC(SanaSetting,"SettingManagement");
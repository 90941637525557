import React, { Component } from 'react';
import { Tabs, Row } from 'antd'


import Rpt1NimaExport from './Rpt1NimaExport'
import httpService from '../../../services/httpService'
//import { withRouter } from 'react-router-dom';

class Report1 extends Component {
    constructor(props) {
        super(props);
       
        this.defOrderedCur = [
            94, 1, 14, 85, 43
        ];
        this.state = {
            tabData: [],
        }
    }
    componentDidMount() {
        httpService.Dashboard.GetRpt1Currency(data => {
            let tab = [];
            for (let el in this.defOrderedCur) {
                var s = data.find(a => a.code == this.defOrderedCur[el]);
                if (s) { tab.push(s); }
            }
            for (let a in data) {
                if (!tab.some(b => b.code == data[a].code)) { tab.push(data[a]); }
            }
            let activeTab = this.props.match.params["curCode"];
            if(!activeTab){
                activeTab = (tab.length > 0 ? tab[0].code : '-1')
                activeTab= activeTab.toString();
            }
            this.setState({ tabData: tab,activeTab:activeTab});
        });
    }
   
    onTabClick =(tab) =>{
        this.setState({ activeTab:tab});
    }
    render() {
        const TabPane = Tabs.TabPane;
        return (
            //style={{ width: 2000, overflow: 'scroll' }}  style={{ width: 3000, overflow: 'scroll' }} 
            <React.Fragment>

                <Tabs type="card" activeKey={this.state.activeTab}  defaultActiveKey={this.state.activeTab} onTabClick={this.onTabClick}>
                    {this.state.tabData.map(cur =>

                        <TabPane tab={cur.name} key={cur.code} >
                            <Rpt1NimaExport curName={cur.name} curCode={cur.code} />
                        </TabPane>

                    )}
                </Tabs>

            </React.Fragment>
        );
    }
}

export default Report1;

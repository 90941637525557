import React, { Component } from 'react';
import {Table, Row, Col, Input} from 'antd';
import httpCaller from "../../../services/httpService";
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import numeral from 'numeral'
//import '../../../../styles/ui/'

class PaymentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            entityId: this.props.entityId,
            currentEntity: this.props.currentEntity,
            Sum: 0,
        }
    }

    getData = (val) => {
        if (this.state.currentEntity === 'offer') {
            httpCaller.NimaImport.GetOfferPayments(parseInt(val), (result) => {
                this.setState({ data: result });
                var sumConfirmedAmount = 0;
                if (result.length > 0) {
                    result.filter(s => s.status === 1).map(item =>
                        sumConfirmedAmount = sumConfirmedAmount + item.amount)
                    this.setState({ Sum: sumConfirmedAmount });
                    this.props.showPayment("block");
                }
                else {
                    this.props.showPayment("none");
                }
            }, () => { }, false);
        }
        else if (this.state.currentEntity === 'dealing') {
            httpCaller.NimaExport.GetDealingPayment({ dealingId: val }, (result) => {
                this.setState({ data: result });
                var sumConfirmedAmount = 0;
                if (result.length > 0) {
                    result.map(item =>
                        sumConfirmedAmount = sumConfirmedAmount + item.amount)
                    this.setState({ Sum: sumConfirmedAmount });
                    this.props.showPayment("block");
                }
                else {
                    this.props.showPayment("none");
                }
            }, () => { }, false);
        }
    }

    componentDidMount = () => {
        this.getData(this.state.entityId)
    }

    componentWillReceiveProps = (newProps) => {
        if (this.state.currentEntity === 'offer') {
            if (this.props.entityId !== newProps.entityId) {
                this.getData(newProps.entityId);
            }
        }
        else if (this.state.currentEntity === 'dealing') {
        }
    }

    render() {
        const columns =
            [{
                title: 'کد پرداخت',
                dataIndex: 'paymentCode',
            }, {
                title: 'روش پرداخت',
                dataIndex: 'paymentMethod',
            }, {
                title: 'تاریخ پرداخت',
                dataIndex: 'paymentDateStr',
            }, {
                title: 'مبلغ پرداختی (ریال)',
                dataIndex: 'amount',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} />
            }, {
                title: 'وضعیت پرداخت',
                dataIndex: 'paymentStatus',

            },
                , {
                title: 'توضیحات',
                dataIndex: 'description',
            }];

        return (
            <React.Fragment>
                <Table className="gx-table-responsive"
                    columns={columns}
                    dataSource={this.state.data}
                    bordered
                    pagination={false}
                    footer={(ob) => {
                        return (
                                <Row style={{direction:"rtl"}}>
                                    <Col span={16}>
                                        <Input className="sumpayment" addonBefore="جمع پرداخت های تاییدشده (ریال)" value={numeral(this.state.Sum).format(0, 0)} readOnly/>
                                    </Col>
                                </Row>
                                
                            )
                    }}
                />
            </React.Fragment>
        )
    }
}
PaymentComponent.propTypes = {
    currentEntity: PropTypes.oneOf('dealing', 'offer'),
};
export default PaymentComponent
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Button, Card, Table, Row, Col, Switch, Modal, Input, Tooltip, Divider,Checkbox } from 'antd';
import httpCaller from '../../services/httpService';
import { NotificationManager } from 'react-notifications';
import { HasPermission } from '../../services/authorization';
import { CloseOutlined } from '@ant-design/icons'
import Title from 'antd/lib/skeleton/Title';

class ProfileBox extends Component {

    constructor(props) {

        super(props)
        this.state = {
            Mode: "View",
            BtnEditCaption: "ویرایش",
            BtnCancelCaption: "بازگشت",
            UserId: 0,
            UserType: 2,
            UserTypeText: '',
            UserName: '',
            FirstName: '',
            LastName: '',
            NationalId: '',
            DisplayName: '',
            SarafiId: '',
            SarafiName: '',
            Email: '',
            Password: '',
            Token: '',
            Tell: '',
            MobileNumber: '',
            CreationDate: '',
            LastModifiedDate: '',
            Enabled: false,
            IsActive: false,
            Description: '',
            SelectedItem: {},
            show: false,
            showReset: false,
            NewPassword: '',
            ShowCancelButton: "none",
            roles: [],
            model: {
                roles: []
            },
            pageIndex: 1,
            pageSize: 10,
            selectedPageIndex: 1,
            totalItemsCount: 0,
            samplePasswordShow: false,
        }

        this.handleRemoveUser = this.handleRemoveUser.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getInfoForResetPassword = this.getInfoForResetPassword.bind(this);
        //  this.resetPassword = this.resetPassword.bind(this);
        this.handleResetPass = this.handleResetPass.bind(this);
        this.handleNationalId = this.handleNationalId.bind(this);
        this.handleDisplayName = this.handleDisplayName.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.hideModal = this.hideModal.bind(this);
        //    this.GeneratePassword = this.GeneratePassword.bind(this);
      


    }

    handleEditButton = (e) => {
        if (this.state.Mode === "View") {
            this.setState({ Mode: "Edit" });
            this.setState({
                BtnEditCaption: "ثبت",
                BtnCancelCaption: "انصراف",
                //ShowCancelButton: "block"
            })

        }
        else {
            this.EditData();
            this.handleCancelButton();
        }
    }

    prepareData() {
        return {
            id: parseInt(this.props.UserId),
            firstName: this.state.FirstName,
            lastName: this.state.LastName,
            sarafiId: parseInt(this.state.SarafiId),
            nationalId: this.state.NationalId,
            displayName: this.state.DisplayName,
            email: this.state.Email,
            enabled: this.state.Enabled,
            description: this.state.Description,
            mobileNumber: this.state.MobileNumber,
            workPhonNumber: this.state.Tell
        }
    }

    EditData() {

        const requestdata = this.prepareData();
        console.log(requestdata)
        const successHandler = (result) => {
            this.loadData(false);
            NotificationManager.success('اطلاعات کاربر با موفقیت بروزرسانی شد')
        };
        const rejectedHandler = (ar) => {
        };

        httpCaller.AppUser.UpdateAppUser(requestdata, successHandler, rejectedHandler);
    }

    handleReturnButton = () => {
        if (this.state.Mode === "View")
            this.props.history.push('/user');
        else
            this.handleCancelButton();

    }

    handleCancelButton = (e) => {
        this.setState({ Mode: "View" });
        this.setState({
            BtnEditCaption: "ویرایش",
            BtnCancelCaption: "بازگشت",
        });
        this.loadData(false);
    }

    componentDidMount() {
        this.setState({ UserId: this.props.UserId });
        this.loadData(true);
        this.getData();
    }

    loadData(val) {

        const successHandler = (result) => {
            console.log(result);
            this.setState({
                UserName: result.userName,
                FirstName: result.firstName,
                LastName: result.lastName,
                NationalId: result.nationalId,
                DisplayName: result.displayName,
                SarafiId: result.sarafiId,
                SarafiName: result.sarafiName,
                Tell: result.workPhonNumber,
                MobileNumber: result.mobileNumber,
                UserTypeText: this.handleUserTypeText(result.userType),
                Email: result.email,
                Enabled: result.enabled,
                IsActive: result.isActive,
                Description: result.description,
                CreationDate: result.creationDateString,
                LastModifiedDate: result.lastDateModifiedStr,
                UserCreator: result.userCreator,
                LockoutEnabled: result.lockoutEnabled,
                LockoutEnd: result.lockoutEnd,
                AuthenticatedByLDAP : result.authenticatedByLdap
            })
        };
        const rejectedHandler = (ar) => {
            console.log("rejectedHandler");
        };

        httpCaller.AppUser.GetAppUserById(this.props.UserId, successHandler, rejectedHandler, false, val);
    }

    handleChange = (val) => {
        this.setState({ Enabled: val });
    }

    handleUserName = (e) => {
        this.setState({ UserName: e.target.value })
    }

    handleLastName = (e) => {
        this.setState({ LastName: e.target.value })
    }

    handleName(e) {
        this.setState({ FirstName: e.target.value })
    }

    handleNationalId(e) {
        this.setState({ NationalId: e.target.value })
    }

    handleDisplayName(e) {
        this.setState({ DisplayName: e.target.value })
    }
    // handelNewPassword = (e)=>{
    //     this.setState({NewPassword:e.target.value})
    // }

    leaveEmailInput = (e) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(e.target.value)) {
            this.setState({ Email: e.target.value })
            if (e.target.value.trim() === "") {
                this.setState({ isEmailValid: false })
            }
            else {
                this.setState({ isEmailValid: true })
            }
        }
        else {
            NotificationManager.error("فرمت ایمیل صحیح نمی باشد");
        }
    }

    // leaveResetPasswoodInput = (e) => {

    // }

    handleEmail = (e) => {

        this.setState({ Email: e.target.value })
    }

    handleTel = (e) => {

        this.setState({ Tell: e.target.value })
    }

    handleDesc = (e) => {
        this.setState({ Description: e.target.value })
    }

    handleResetPass(e) {
        this.setState({ showReset: true })
        // if(this.state.Email === null)
        //     NotificationManager.error("برای کاربر موردنظر ایمیلی ثبت نشده است، لطفا ابتدا ایمیل کاربر را تکمیل نمایید");
        // else{
        // this.resetPassword();
        //}
    }

    getInfoForResetPassword() {
        return {
            email: this.state.Email,
            id: Number.parseInt(this.props.UserId),
        }
    }

    hideModal() {
        this.setState({ show: false });
        this.setState({ showReset: false });
    }


    ConfirmGeneratePassword = () => {
        let re = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        if (re.test(this.profileBoxRef.inputNewPassword.current.input.value)) {
            var data = this.GatherData();
            httpCaller.Auth.SavePassword(JSON.stringify(data),
                (result) => {
                    NotificationManager.success("عملیات رمز عبور با موفقیت انجام شد.")
                },
                () => {
                    NotificationManager.error("عملیات رمز عبور با شکست مواجه شد، در صورت بروز مجدد خطا با مدیر سامانه تماس حاصل نمایید.")
                });
        }
        else {
            this.setState({ samplePasswordShow: true });
            NotificationManager.error("فرمت پسورد باید ترکیب حروف، علائم، عدد و حداقل هشت کارکتر باشد");
        }
    }




    handleUserTypeChanged = (e) => {
        this.setState({ UserType: e.target.value })
    }

    handleUserTypeText(e) {
        switch (e.value) {
            case 'CBIAdmin':
                return 'کاربر ارشد';
            case 'CBIUser':
                return 'کاربر';
            case 'SarafiAdmin':
                return 'مدیر صرافی';
            case 'SarafiUser':
                return 'کاربر صرافی';
            case 'NezaratOstan':
                return 'ناظر استانی';
            default:
                return 'کاربر';
        }
    }
    handleMobile = (e) => {

        const reg = /^[0-9\b]+$/;

        if (e.target.value.trim() === '' || reg.test(e.target.value)) {
            this.setState({ MobileNumber: e.target.value })
        }
    }

    handleRemoveUser = (e) => {
        var uId = parseInt(this.state.UserId)
        const confirm = Modal.confirm;
        confirm({
            title: "آیا از حذف این کاربر اطمینان دارید؟",
            onOk() {
                httpCaller.Auth.softDeleteUser({ userId: uId }, () => {
                    NotificationManager.success("عملیات حذف با موفقیت انجام شد");
                }
                )
            },
            onCancel() {

            },
        });
    }

    loadRolesForUser = () => {
        this.setState({ show: true });
    }

    handleRemoveRoleForUser = (roleId) => {
        httpCaller.Auth.removeUserFromRole({ roleId, userId: this.props.UserId }, () => {
            this.getData(1);
        });
    }

    getData = () => {
        var data = {
            userId: this.props.UserId,
            pageSize: this.state.pageSize,
            pageIndex: this.state.pageIndex
        };
        httpCaller.Auth.getRolesForUser(data, (pagedEntity) => {
            this.setState({ model: { ...this.state.model, roles: pagedEntity.items } });
            this.setState({ roles: this.state.model.roles })
        });
    }


    render() {
        let canAddUser = HasPermission("AccountManagement_AddUser");
        let canDeleteUser = HasPermission("AccountManagement_DeleteUser");
        let canResetPassword = HasPermission("AccountManagement_ResetPassword");
        let canDeleteRole4User = HasPermission("AccountManagement_DeleteRole");

        const columns = [
            {
                title: 'نام نقش',
                dataIndex: 'roleName',
            },
            {
                title: 'توضیحات',
                dataIndex: 'description',
            },
            canDeleteRole4User && {
                title: 'عملیات',
                dataIndex: 'action',
                render: (text, row) => (<Tooltip placeholder="top" title="حذف کاربر از نقش">
                    <a onClick={() => this.handleRemoveUserFromRole(row.userId, row.userName)}>
                        {<CloseOutlined style={{ color: "red", fontSize: "2rem", cursor: "pointer" }} />}
                    </a>
                </Tooltip>)
            }
        ]

        return (
            <React.Fragment>
                <Card title="اطلاعات کاربر" style={{ textAlign: "center" }}>
                    <Row>
                        <Col md={8} xs={24}>
                            <Input addonBefore="نام کاربری" value={this.state.UserName} onChange={this.handleUserName} readOnly />
                        </Col>
                        <Col md={8} xs={24} style={{ display: this.state.showSarafi }}>
                            <Input addonBefore="وضعیت حساب کاربر" value={this.state.IsActive === true ? 'تایید شده' : 'تایید نشده'} readOnly />
                        </Col>
                        <Col md={8} xs={24} style={{ display: this.state.showSarafi }}>
                            <Input addonBefore="تاریخ ایجاد کاربر" value={this.state.CreationDate} readOnly />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={16} xs={24}>
                            <Input addonBefore="تاریخ آخرین تغییر پروفایل" value={this.state.LastModifiedDate} readOnly />
                        </Col>
                    </Row>
                    <br />
                    <Divider type="horizontal" />
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <Input addonBefore="نام" value={this.state.FirstName} onChange={this.handleName} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                        <Col md={8} xs={24}>
                            <Input addonBefore="نام خانوادگی" value={this.state.LastName} onChange={this.handleLastName} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                        <Col md={8} xs={24}>
                            <Input addonBefore="نام نمایشی" value={this.state.DisplayName} onChange={this.handleDisplayName} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <Input addonBefore="شناسه ملی" value={this.state.NationalId} onChange={this.handleNationalId} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                        <Col md={8} xs={24}>
                            <Input addonBefore="ایمیل" value={this.state.Email} onChange={this.handleEmail} onBlur={this.leaveEmailInput} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                        <Col md={8} xs={24}>
                            <Input addonBefore="شماره ثابت" value={this.state.Tell} onChange={this.handleTel} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <Input addonBefore="شماره همراه" value={this.state.MobileNumber} onChange={this.handleMobile} readOnly={this.state.Mode === "View" ? true : false} />
                        </Col>
                        <Col md={8} xs={24}>
                            <label>اهراز هویت با Active Directory(برای کاربران بانک) </label>
                            <Switch  disabled checked={this.state.AuthenticatedByLDAP} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={24} xs={24}>
                            <Input.TextArea rows={4} addonBefore="توضیحات" value={this.state.Description} readOnly={this.state.Mode === "View" ? true : false} onChange={this.handleDesc} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={4} xs={24}>
                            <label>فعال </label>
                            <Switch onChange={this.handleChange} disabled={this.state.Mode === "View" ? true : false} checked={this.state.Enabled} />
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row>
                        <Col md={6} xs={24}>
                        </Col>
                        <Col md={12} xs={24}>
                            {
                                canAddUser && (<Button type="primary" onClick={this.handleEditButton}>{this.state.BtnEditCaption}</Button>)
                            }
                            {
                                canDeleteUser && <Button type="danger" onClick={this.handleRemoveUser}>حذف کاربر</Button>
                            }
                            <Button type="default" onClick={this.handleReturnButton}>{this.state.BtnCancelCaption}</Button>
                        </Col>
                        <Col md={6} xs={24}></Col>
                    </Row>
                </Card>
            </React.Fragment>
        )
    }
}

export default ProfileBox;

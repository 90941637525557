import React, { Component } from 'react';
import httpCaller from '../../../services/httpService';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { Alert, Tabs, Tab, Button, Modal } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';

//import NotFound from '../Not-Found';


class DropDownCurrency extends Component {

  constructor(props) {

    super(props)
    this.state = {
      Currencies: [],
      isProcessing: false
    }
  }

  handledata() {


    const successHandler = (result) => { 

      this.setState({ Currencies: result })
    //  alert("Succeed");
    };

    const rejectedHandler = (ar) => {
      //this.setState({ isProcessing: false });
    //  alert("ERROR");
    };

    httpCaller.NimaImport.GetCurrencies(successHandler,rejectedHandler, false);
  

  }
  componentDidMount() {
    this.handledata();
  }

  render() {
      
    return (
        <select ref={this.props.ref}  onChange={(e) => this.props.onChange(e.target.value)}  class="form-control col-6 ant-input" >
        
            {this.state.Currencies.map(info => (
            <option key={info.currencyId} value={info.currencyId} >{info.farsiNm}</option>
            ))}

        </select>
    )
  }

}
export default DropDownCurrency;



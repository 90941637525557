import React, { Component } from "react";
import RequestDetail from "./RequestDetail";
import {Row, Col, Button, Tabs,Card} from 'antd'
import SelectedOffer from "./SelectedOffer";
import OtherOffersList from "./OtherOffersList";
import httpCaller from "../../../services/httpService";
import { withRouter, Link } from 'react-router-dom';

class SearchResult extends Component {
    constructor(props) {

        super(props);
        //let param = this.props.match.params["param"];
        let requestCode = this.props.match.params["requestCode"];
        let offerId = this.props.match.params["offerId"];
        this.state = {
            ReqId: requestCode,//param.includes("Request") ? param.substring(7, param.length) : param.substring(5, param.length),
            OfferId: offerId,
            SearchType: offerId === '' ? "Request" : "offer",//param.includes("Request") ? "request" : "offer",
            HasSelectedOffer: 'true',
            HasOtherOffers: 'true',
            tabActive: 1
        }
    }

    setActive = (val) => {
        this.setState({ tabActive: val })
    }

    hasSelectedOffer = (val) => {
        this.setState({ HasSelectedOffer: val })
    }

    hasOtherOffers = (val) => {

        this.setState({ HasOtherOffers: val })
    }

    handleSelectedOffer = () => {

        if (this.state.HasSelectedOffer === 'true') {
            return (<SelectedOffer reqId={this.state.ReqId} getDataProp={this.hasSelectedOffer}></SelectedOffer>)
        }
        else
        {
            return(
                <Card>
                     <Row style={{textAlign:"center"}}>
                    <Col span={24} >
                        <br/>
                        <br/>
                        <label>پیشنهاد معامله شده ای روی این درخواست وجود ندارد</label>
                    </Col>
                </Row>
                </Card>
               )
        }
    }

    handleOtherOffers = () => {

        if (this.state.HasOtherOffers === 'true') {
            return (<OtherOffersList reqId={this.state.ReqId} offerId={this.state.OfferId} SetActive={this.setActive} getOtherDataProp={this.hasOtherOffers} searchType={this.state.SearchType}></OtherOffersList>);
        }
        else
        {
            return(
            <Card>
                <Row style={{textAlign:"center"}}>
                    <Col span={24} >
                        <br/>
                        <br/>
                        <label>داده ای برای نمایش وجود ندارد</label>
                    </Col>
                </Row>
            </Card>)
        }
    }

    ReturnBack = () => {
        this.props.history.push(`/nima/import`);
    }

    render() {
        const TabPane = Tabs.TabPane;
        return (
            <React.Fragment>
                <RequestDetail reqId={this.state.ReqId}></RequestDetail>
                <Tabs type="card">
                    <TabPane tab="پیشنهاد معامله شده" key="1">{this.handleSelectedOffer()}</TabPane>
                    <TabPane tab="سایر پیشنهادات" key="2">
                        {this.handleOtherOffers()}
                    </TabPane>
                </Tabs>
            </React.Fragment>
        )
    }
}

export default withRouter(SearchResult)
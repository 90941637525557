import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class DisplayFormData extends Component {
    constructor(props) {
        super(props)
        this.size = this.props.size ? this.props.size : 'sm';
        this.id = this.props.id;
        this.type = this.props.type ? this.props.type : 'text';
    }
    render() {
        return (
            <Input addonBefore={this.props.lable}  value={this.props.value} type={this.type} id={this.id} readOnly/>
        );
    }
}

DisplayFormData.propTypes = {
    size: PropTypes.oneOf('sm', 'lg'),
    type: PropTypes.oneOf('password', 'text'),
    id: PropTypes.string,
    lable: PropTypes.string,
    value: PropTypes.string,
};

export default DisplayFormData;
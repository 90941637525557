import React, { Component } from 'react';
import { Row, Col, Alert, Input,Button, Select, Card, Table, data } from 'antd';
//import { toast, ToastContainer } from 'react-toastify';
import httpCaller from '../../../services/httpService';
//import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import ExchangeDetail from './ExchangeDetail';
// import NotFound from '../Not-Found';
import {HasPermission} from '../../../services/authorization';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import { setWith } from 'lodash';


class SarafiManagment extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: "",
      list: [],
      columns: [],
      currentPage: 1,
      //accessToSecondRate:255,
      allowedTalars:3,
      pageSize: 10,
      totalnumber: 0,
      show: false,
      SearchCount: 0,
      totalpage: 1
    }
  }
  prepareRequest = () => {

    return {
      SarafiData: this.state.data,
      //accessToSecondRate:this.state.accessToSecondRate,
      allowedTalars: this.state.allowedTalars,
      currentPage: this.state.currentPage,
      pageSize: this.state.pageSize
    }
  }
  componentDidMount() {
       
  this.setState({columns : [{
    title: 'کد صرافی',
    dataIndex: 'code',
  }, {
    title: 'نام صرافی',
    dataIndex: 'name',
  }, {
    title: 'مجوز خرید',
    dataIndex: 'isActive_Kharid',
    // render: (text) => (
    //   <span>{text == true ? "دارد" : "ندارد"}</span>
    // ) 
    render: (text) => (
      <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
    
  }, {
    title: 'مجوز فروش',
    dataIndex: 'isActive_Foroush',
    render: (text) => (
      <span style={{fontSize:'20px'}}>{text === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} />}</span>)
  }, {
    title: 'نوع فعالیت',
    dataIndex: 'licenseSTName',
  }, {
    title: 'وضعیت مجوز',
    dataIndex: 'lastLicenseTypeName',
  }]});

  this.loadEntitiesBaseOnQuery();
  }

  componentWillMount() {
//window.location.href
    this.handleSearch(false);
  }
  parseInt0 = (paramValue) => {
    if (paramValue === null || paramValue === "") {
      return 0;
    }
    else {
      return parseInt(paramValue);
    }
  }
  handledetail = () => {
    let filterlist = [];
    filterlist.push(this.state.data)
  }
  handlePageChange = (page) => {
    this.setState({  currentPage: page },()=>{this.handledata(true);});
    
  };

  loadEntitiesBaseOnQuery(){
    let searchParams = new URLSearchParams(this.props.location.search); 
    let currentPageHas = searchParams.has("currentPage");
    let dataHas = searchParams.has("data");

    if(currentPageHas){
        this.setState({currentPage : searchParams.get("currentPage")});
    }

    if(dataHas){
        this.setState( {data :searchParams.get("data") });
    }
   
    if(currentPageHas ||dataHas){
        setTimeout(() => {
            this.handledata(false);
        }, 500);
    }
}

setQueryParameter = ()=>{
  let pathname = this.props.location.pathname; 
  let searchParams = new URLSearchParams(); 
 
  if(this.state.currentPage !=""){
      searchParams.set("currentPage", this.state.currentPage);
  }

  if(this.state.data.trim() != ""){
      searchParams.set("data", this.state.data);
  }
 
  this.props.history.push({
           pathname: pathname,
           search: searchParams.toString()
     });

}
handleChangeStatusRate = (val) => {
  //this.setState({ accessToSecondRate: this.parseInt0(val)  
  this.setState({ allowedTalars: this.parseInt0(val)  });
 
}
  handledata = (val) => {
    const requestData = this.prepareRequest();
    const successHandler = (result) => {
      console.log("successHandler result:", result);
      var items = [...result.items];
      this.setState({ totalnumber: result.totalCount, totalpage: result.totalPages })
      this.setState({ list: items })

      if (this.state.list.length == 0 && this.state.currentPage == 0) {
        this.setState({ show: false, SearchCount: this.state.SearchCount + 1  })
     
      }
      else {
        this.setState({ show: true, SearchCount: this.state.SearchCount + 1 });
        }
    };

    const rejectedHandler = (ar) => {
    };
    this.setQueryParameter(); 
    httpCaller.Sarafi.GetSarafiList((requestData), successHandler, rejectedHandler, val);

  }

  handleSearchInputKeyUp = (e) => {
    if (e.key === "Enter") {
        this.handleSearch(true);
    }
}

  handleSearch = () => {

    this.setState({ currentPage: 0 }, function () {
      this.handledata(true)
    })
    //e.preventDefault();
  }
  handleManageData = (e) => {

    this.setState({ data: e.target.value });


    e.preventDefault();
  }
  GetsarafiDetail(code, name) {
    this.props.history.push(`/Exchange/ExchangeDetail/${code}`);
  }

  render() {
    if(!HasPermission ("ExchangeManagement_view")) return null;

    return (
      <React.Fragment>
        <Row justify="right">
          <Col span={24}>
          <Alert
            message="راهنمایی:"
            description="جهت جستجو از نام صرافی و یا کد صرافی استفاده نمایید."
            type="info"
          />
          </Col>
        </Row>
        <br/>
        <Row justify="start">
          <Col  md={2} style={{marginTop:8 +'px'}} >
            <label >نام یا کد صرافی:</label>
          </Col>
          <Col  md={3} >
          <Input
            placeholder="نام یا کد صرافی"
            onChange={this.handleManageData}
            onSearch={this.handleSearch}
            onKeyUp={this.handleSearchInputKeyUp}
      
            value={this.state.data}
          />
          </Col>

          <Col  md={5} style={{marginTop:8 +'px', textAlign:'end'}}>
            <label>فیلتر بر اساس نوع تالار معاملاتی:</label>
          </Col>
          <Col  md={2} >
                                   <Select defaultValue="3" onChange={this.handleChangeStatusRate}  > 
                                        {/* <Select.Option value="255">همه گزینه ها</Select.Option>
                                        <Select.Option value="0">بدون مجوز</Select.Option>
                                        <Select.Option value="1">مجوز دار</Select.Option> */}
                                        <Select.Option value="3">بدون فیلتر</Select.Option>
                                        <Select.Option value="1">تالار اول</Select.Option>
                                        <Select.Option value="2">تالار دوم</Select.Option>
                                        
                                    </Select> 
          </Col >
          <Col md={2} >
          <Button type="primary" icon="search" htmlType="button" onClick={this.handleSearch}>
                                جستجو
                            </Button>
          </Col>
          

        </Row>

        <Row style={{ visibility: this.state.show ? 'visible' : 'hidden'}}>
          <Col span={24}>
            <Card>
              <Table className="gx-table-responsive withPointer"
                onRow={(record) => {
                  return {
                    onClick : () => {this.GetsarafiDetail(record.code, record.name)}
                  };
                }}
                stripped
                columns={this.state.columns}
                dataSource={this.state.list}
                bordered
                pagination={{
                  total: this.state.totalnumber,
                  pageSize: this.state.pageSize,
                  current: this.state.currentPage,
                  onChange: this.handlePageChange
                }}
                title={() => 'نتیجه جستجو'}
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default withRouter(SarafiManagment)



import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import SendConfirmationCode from "./loginOperations/SendConfirmationCode"
import EnterConfirmationCode from "./loginOperations/EnterConfirmationCode"
import SetPasswordByUser from "./loginOperations/SetPasswordByUser"

const Login = ({match}) => (
    <Switch>
      <Route path={`${match.url}/SendConfirmationCode`} component={SendConfirmationCode}/>
      <Route path={`${match.url}/EnterConfirmationCode/:userId`} component={EnterConfirmationCode}/>
      <Route path={`${match.url}/SetPasswordByUser/:Id`} component={SetPasswordByUser}/>
    </Switch>
  );
  
  export default Login; 
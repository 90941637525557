import React, { Component } from 'react';
import RoleDefine from './RoleDefine'
import RoleMember from './RoleMember'
import RolePermission from './RolePermission'
import { CheckPermissionHOC,HasPermission } from '../../services/authorization';
import { Row, Col, Card,Tabs} from 'antd'


class Role extends Component {
    constructor(props) {
        super(props);

        let id = Number.parseInt(this.props.match.params["roleId"]);
        this.state = {
            roleId: id,
            activeTab: 1,
            users: []
        }

    }

    render() {
        var getTabs = () => {
            const TabPane = Tabs.TabPane;
            return (
                <Tabs type="card">
                   {HasPermission("RoleManagement_AssignUserToRole") && <TabPane tab="کاربران" key="1">
                        <RoleMember  {...this.props} setUsers={(users) => { this.setState({ users }) }} roleId={this.state.roleId} />
                   </TabPane>
                   }
                   {HasPermission("RoleManagement_AssignPermissionToRole") && <TabPane tab="دسترسی" key="2">
                        <RolePermission  {...this.props} roleId={this.state.roleId} />
                    </TabPane>
                    }
              </Tabs>
            )
        };



        return (
            <React.Fragment>
                        <RoleDefine {...this.props} hasUsers={(this.state.users.length > 0)} roleId={this.state.roleId} />
                        {this.state.roleId > 0 ? getTabs() : ""}
            </React.Fragment>
        );
    }
}



export default CheckPermissionHOC(Role,'RoleManagement_ViewRole');
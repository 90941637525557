import React, { Component } from "react";
import { Card, Row, Col, Form, Divider, Select, Button, Table, TreeSelect, Input, Alert } from 'antd';
import MaskedInput from 'antd-mask-input';
import httpCaller from '../../services/httpService';
import moment from 'moment-jalaali'
import { isNull, trimStart } from "lodash";
import { FileExcelTwoTone, SearchOutlined } from '@ant-design/icons';
import { CheckPermissionHOC } from '../../services/authorization'


class RialiPaymentsValidityReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: "",
            toDate: "",
            paymentDate: "",
            amount: 0,
            children: [],
            selectedSarafies: '',
            srchResult: { items: [] },
            pageSize: 10,
            pageIndex: 1,
        }
    }

    componentDidMount() {
        httpCaller.Sarafi.GetAllSarafies("", (data) => {
            this.setState({ children: data })
        }, (err) => { }, false);

    }



    handleTreeChange = (value) => {

    }

    handleTreeSelect = (value) => {
        this.setState({ selectedSarafies: value })
    }

    handleSearchButtonClick = () => {
        this.fetchData(1);
    }

    handlePageChange = (page) => {
        this.fetchData(page);
    };

    fetchData = (pageIndex) => {
        var param = {
            pageIndex: pageIndex,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
        };
        httpCaller.Reports.GetRialiPaymentValidityReport(param, (res) => { this.setState({ srchResult: res }) }, (err) => { console.log(err) })
    }

    handleFromDate = (e) => {
        this.setState({ fromDate: e.target.value })
    }

    handleToDate = (e) => {
        this.setState({ toDate: e.target.value })
    }

    handleSrchTree = (val) => {
        httpCaller.Sarafi.GetAllSarafies(val, (data) => {
            this.setState({ children: data })
        }, (err) => { }, false);
    }

    handleExportExcel = () => {
        var param = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            sarafiIds: trimStart(this.state.selectedSarafies, ','),
        };
        var nd = new Date();
        var jt = moment.jConvert.toJalaali(nd.getFullYear(), nd.getMonth() + 1, nd.getDate());

        var fileName = `RialiValidity_${jt.jy}${('0' + jt.jm).slice(-2)}${('0' + jt.jd).slice(-2)}.xls`
        httpCaller.Reports.GetRialiPaymentValidityReportExcel(fileName, param);
    }

    render() {
        const FormItem = Form.Item;

        const columns =
            [{
                title: 'کد  صرافی',
                dataIndex: 'sarafiId',

            }
                , {
                title: 'نام صرافی',
                dataIndex: 'sarName',
                width: '200px',
            }

                , {
                title: ' تعداد کل',
                children: [

                    {
                        title: 'ساتنا',
                        dataIndex: 'total.satna',
                    },
                    {
                        title: 'پایا',
                        dataIndex: 'total.paya',
                    },
                    {
                        title: 'انتقال درون بانکی',
                        dataIndex: 'total.inBankTransfer',
                    },
                    {
                        title: 'کارت به کارت',
                        dataIndex: 'total.shetab',
                    },
                    {
                        title: 'چک',
                        dataIndex: 'total.cheque',
                    },
                    {
                        title: 'واریز به حساب',
                        dataIndex: 'total.deposit',
                    },
                    {
                        title: 'POS',
                        dataIndex: 'total.pos',
                    },
                    {
                        title: 'نقدی',
                        dataIndex: 'cache',
                    },
                ],
            }
                , {
                title: 'بررسی شده',
                children: [
                    {
                        title: ' تایید شده(درصد)',
                        children: [
                            {
                                title: 'ساتنا',
                                dataIndex: 'approvedPercent.satna',
                            },
                            {
                                title: 'پایا',
                                dataIndex: 'approvedPercent.paya',
                            },
                            {
                                title: 'انتقال درون بانکی',
                                dataIndex: 'approvedPercent.inBankTransfer',
                            },
                            {
                                title: 'کارت به کارت',
                                dataIndex: 'approvedPercent.shetab',
                            },
                            {
                                title: 'چک',
                                dataIndex: 'approvedPercent.cheque',
                            },
                            {
                                title: 'واریز به حساب',
                                dataIndex: 'approvedPercent.deposit',
                            },
                            {
                                title: 'POS',
                                dataIndex: 'approvedPercent.pos',
                            }
                        ],
                    },
                    {
                        title: 'تایید نشده(درصد)',
                        children: [
                            {
                                title: 'ساتنا',
                                dataIndex: 'notApprovedPercent.satna',
                            },
                            {
                                title: 'پایا',
                                dataIndex: 'notApprovedPercent.paya',
                            },
                            {
                                title: 'انتقال درون بانکی',
                                dataIndex: 'notApprovedPercent.inBankTransfer',
                            },
                            {
                                title: 'کارت به کارت',
                                dataIndex: 'notApprovedPercent.shetab',
                            },
                            {
                                title: 'چک',
                                dataIndex: 'notApprovedPercent.cheque',
                            },
                            {
                                title: 'واریز به حساب',
                                dataIndex: 'notApprovedPercent.deposit',
                            },
                            {
                                title: 'POS',
                                dataIndex: 'notApprovedPercent.pos',
                            }
                        ],
                    }
                ],
            },
            {
                title: 'تعداد بررسی نشده ',
                children: [
                    {
                        title: 'ساتنا',
                        dataIndex: 'notChecked.satna',
                    },
                    {
                        title: 'پایا',
                        dataIndex: 'notChecked.paya',
                    },
                    {
                        title: 'انتقال درون بانکی',
                        dataIndex: 'notChecked.inBankTransfer',
                    },
                    {
                        title: 'کارت به کارت',
                        dataIndex: 'notChecked.shetab',
                    },
                    {
                        title: 'چک',
                        dataIndex: 'notChecked.cheque',
                    },
                    {
                        title: 'واریز به حساب',
                        dataIndex: 'notChecked.deposit',
                    },
                    {
                        title: 'POS',
                        dataIndex: 'notChecked.pos',
                    }
                ],
            },
            {
                title: 'نامشخص',
                children: [
                    {
                        title: 'ساتنا',
                        dataIndex: 'unknown.satna',
                    },
                    {
                        title: 'پایا',
                        dataIndex: 'unknown.paya',
                    },
                    {
                        title: 'انتقال درون بانکی',
                        dataIndex: 'unknown.inBankTransfer',
                    },
                    {
                        title: 'کارت به کارت',
                        dataIndex: 'unknown.shetab',
                    },
                    {
                        title: 'چک',
                        dataIndex: 'unknown.cheque',
                    },
                    {
                        title: 'واریز به حساب',
                        dataIndex: 'unknown.deposit',
                    },
                    {
                        title: 'POS',
                        dataIndex: 'unknown.pos',
                    }
                ],
            }


            ];



        return (
            <React.Fragment>
                <Row >
                    <Col sm={11}>
                        <div style={{ fontSize: '1.6rem', fontWeight: 'Bold' }}>آمار صحت ​سنجی تراکنش​های ريالی سنا</div>
                    </Col>
                </Row>
                <Divider style={{ backgroundColor: '#c5c5c5', height: '2px' }}></Divider>
                <Card className="gx-card">

                    <Row>
                        <Col md={8} sm={24}>
                            <FormItem
                                label=" تاریخ پرداخت از"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <MaskedInput mask="1111/11/11" id="fromDate" name="fromDate" placeholder="yyyy/mm/dd" style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                                    placeholder="1400/01/01" value={this.state.fromDate} onChange={this.handleFromDate} />
                            </FormItem>
                        </Col>
                        <Col md={8} sm={24}>
                            <FormItem
                                label=" تاریخ پرداخت تا"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <MaskedInput mask="1111/11/11" id="toDate" name="toDate" placeholder="yyyy/mm/dd" style={{ direction: 'ltr', textAlign: 'center' }} size="11"
                                    placeholder="1400/01/01" value={this.state.toDate} onChange={this.handleToDate} />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} sm={24}>
                            <FormItem
                                label="صرافی"
                                labelCol={{ xs: 24, md: 7, lg: 6 }}
                                wrapperCol={{ xs: 24, md: 15, lg: 15 }}
                            >
                                <TreeSelect
                                    showSearch
                                    allowClear
                                    multiple
                                    treeNodeFilterProp='title'
                                    treeCheckable
                                    dropdownStyle={{ overflow: "scroll", maxHeight: "400px" }}
                                    placeholder="لطفا انتخاب کنید"
                                    style={{ width: "100%" }}
                                    onChange={this.handleTreeSelect}
                                // onSelect={this.handleTreeSelect}

                                >
                                    {
                                        this.state.children.map(child => <TreeSelect.TreeNode value={child.id} title={child.sarName}></TreeSelect.TreeNode>)
                                    }

                                </TreeSelect>

                            </FormItem>
                        </Col>
                        <Col md={8} sm={24}></Col>
                    </Row>
                    <Row>
                        <Col md={8} sm={24}>
                            <Button type="primary" htmlType="button" onClick={() => this.handleSearchButtonClick()}>
                                جستجو
                            </Button>
                            <Button htmlType="button" type="default" style={{ borderColor: "green", color: "green" }} onClick={() => this.handleExportExcel()}
                                icon={<FileExcelTwoTone twoToneColor="#52c41a" />}>
                                انتقال به فایل اکسل
                            </Button>
                        </Col>
                    </Row>

                </Card>
                <Card>

                    <br />
                    <Table className="gx-table-responsive"
                        columns={columns}
                        dataSource={this.state.srchResult.items}
                        bordered
                        pagination={true}
                        expandRowByClick={false}
                        rowKey="sanaTrackingCode"
                        pagination={{
                            total: this.state.srchResult.totalCount,
                            pageSize: this.state.srchResult.pageSize,
                            current: this.state.srchResult.pageIndex,
                            onChange: (index) => this.handlePageChange(index)
                        }}
                    />
                </Card>
            </React.Fragment>
        )
    }
}

export default CheckPermissionHOC(RialiPaymentsValidityReport, 'ReportsManagement_SanaRialiValidation')
//export default statisticsRialiPayments;

import React, { Component } from 'react';
import { Radio, Table, Card, Row, Col, Divider, Button ,Tooltip } from "antd";
import numeral from 'numeral';
import IconWithTextCard from "components/Metrics/IconWithTextCard";

import httpService from '../../../services/httpService';
//import excell from '../assets/images/excell.png';
import excell from '../../../../src/assets/images/excell.png'
// import { Space } from 'antd';

class DashboardExportTotalReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplyRptDashbord_totalEuroAmount: 0,
            supplyRptDashbord_supplyArzs: [],
            supplyRptDashbord_supplyArzAndSuppliers: [],

            demandRptDashbord_totalEuroAmount: 0,
            demandRptDashbord_demandDealingsArzs: [],
            demandRptDashbord_demandDealingsArzAndSarafiNames: [],

            dealingRptDashbord_totalEuroAmount: 0,
            dealingRptDashbord_demandDealingsArzs: [],
            dealingRptDashbord_demandDealingsArzAndSarafiNames: []
        }

    }
    componentDidMount() {
        httpService.Dashboard.GetRptTotalNimaExport((data) => {

            this.setState({
                supplyRptDashbord_totalEuroAmount: data.supplyRptDashbord.totalEuroAmount,
                supplyRptDashbord_supplyArzs: data.supplyRptDashbord.supplyArzs,
                supplyRptDashbord_supplyArzAndSuppliers: data.supplyRptDashbord.supplyArzAndSuppliers,

                demandRptDashbord_totalEuroAmount: data.demandRptDashbord.totalEuroAmount,
                demandRptDashbord_demandDealingsArzs: data.demandRptDashbord.demandDealingsArzs,
                demandRptDashbord_demandDealingsArzAndSarafiNames: data.demandRptDashbord.demandDealingsArzAndSarafiNames,

                dealingRptDashbord_totalEuroAmount: data.dealingRptDashbord.totalEuroAmount,
                dealingRptDashbord_demandDealingsArzs: data.dealingRptDashbord.demandDealingsArzs,
                dealingRptDashbord_demandDealingsArzAndSarafiNames: data.dealingRptDashbord.demandDealingsArzAndSarafiNames
            });
        })

    }
    handleChange = () => {

    }
    handleShowDetails = (row) => {
        if(row.currencyCode){
            this.props.history.push(`/main/dashboard/nimadetails/${row.currencyCode}`)
        }else{
            this.props.history.push(`/main/dashboard/nimadetails`)
        }
        
    }
    render() {

        const SupplyArzs = [
            {
                title: ' نوع ارز',
                dataIndex: 'currencyName',
                width: '20%',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'مبلغ',
                dataIndex: 'sumAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.sumAmount - b.sumAmount,
            }];

        const SupplyArzAndSuppliers = [
            {
                title: 'عرضه کننده',
                dataIndex: 'supplier',
                width: '20%',
                sorter: (a, b) => a.supplier.length - b.supplier.length,
            },
            {
                title: ' نوع ارز',
                dataIndex: 'currencyName',
                width: '10%',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'مبلغ',
                dataIndex: 'sumAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.sumAmount - b.sumAmount,
            }];

        const DemDemandDealingsArzs = [
            {
                title: ' نوع ارز',
                dataIndex: 'currencyName',
                width: '20%',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
           
            {
                title: 'مبلغ',
                dataIndex: 'sumAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.sumAmount - b.sumAmount,
            },
            {
                title: 'معادل یورویی',
                dataIndex: 'sumEuroAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.sumEuroAmount - b.sumEuroAmount,
            },
            {
                title: 'میانگین موزون نرخ',
                dataIndex: 'weightedAverage',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.weightedAverage - b.weightedAverage,
            }];

        const DemDemandDealingsArzAndSarafiNames = [
            {
                title: 'نام صرافی/بانک',
                dataIndex: 'sarafiName',
                width: '20%',
                sorter: (a, b) => a.sarafiName.length - b.sarafiName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: ' نوع ارز',
                dataIndex: 'currencyName',
                width: '20%',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'مبلغ',
                dataIndex: 'sumAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.sumAmount - b.sumAmount,
            },
            {
                title: 'معادل یورویی',
                dataIndex: 'sumEuroAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.sumEuroAmount - b.sumEuroAmount,
            },
            {
                title: 'میانگین موزون نرخ',
                dataIndex: 'weightedAverage',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.weightedAverage - b.weightedAverage,
            }];

        const DelDemandDealingsArzs = [
            {
                title: ' نوع ارز',
                dataIndex: 'currencyName',
                width: '20%',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            
            {
                title: 'مبلغ',
                dataIndex: 'sumAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.sumAmount - b.sumAmount,
            },
            {
                title: 'معادل یورویی',
                dataIndex: 'sumEuroAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.sumEuroAmount - b.sumEuroAmount,
            },
            {
                title: 'میانگین موزون نرخ',
                dataIndex: 'weightedAverage',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.weightedAverage - b.weightedAverage,
            }];

        const DelDemandDealingsArzAndSarafiNames = [
            {
                title: 'نام صرافی/بانک',
                dataIndex: 'sarafiName',
                width: '20%',
                sorter: (a, b) => a.sarafiName.length - b.sarafiName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: ' نوع ارز',
                dataIndex: 'currencyName',
                width: '20%',
                sorter: (a, b) => a.currencyName.length - b.currencyName.length,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'مبلغ',
                dataIndex: 'sumAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                sorter: (a, b) => a.sumAmount - b.sumAmount,
            },
            {
                title: 'معادل یورویی',
                dataIndex: 'sumEuroAmount',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.sumEuroAmount - b.sumEuroAmount,
            },
            {
                title: 'میانگین موزون نرخ',
                dataIndex: 'weightedAverage',
                width: '10%',
                render: (text, row, index) => numeral(text).format('0,0'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.weightedAverage - b.weightedAverage,
            }];

        return (
            <React.Fragment >
                <Row >
                    <Col    sm={11}>
                    <div style={{fontSize:'1.6rem', fontWeight:'Bold'}}>
                        گزارش عرضه ارز نیما در روز جاری 
                    </div>
                           
                    </Col>
                    <Col sm={11} >
                        <Button type="primary" icon="download" onClick={() => window.open(httpService.Dashboard.ExportDetailsRptTotalNimaExport(), '_blank')}  >{`گزارش روز جاری براساس نوع صادر کننده`}</Button>
                        <Button style={{float:'left'}} type='primary' onClick={this.handleShowDetails}>مشاهده ریز تراکنش های روز جاری</Button> 
                    </Col>
                    <Col sm={2}>
                    <Tooltip placement="leftTop" title={"خروجی به اکسل"}>
                    {/* <Button type="danger"  size={'large'} icon="file-excel" shape="circle"  onClick={() => window.open(httpService.Dashboard.ExportRptTotalNimaExport(), '_blank')}  /> */}

                    <li>
                        <span  onClick={() => window.open(httpService.Dashboard.ExportRptTotalNimaExport(), '_blank')}
                         className={`gx-pointer 'active'}`}>
                       <img src={excell}  />
                      </span>
                    </li>
                    </Tooltip>
                   

                    
                    </Col>

                </Row>
                
                <Divider style={{backgroundColor:'#c5c5c5',height:'2px'}}></Divider>
                
                <Row>
                    <Col xl={8} lg={12} md={12}  xs={24} className="gx-col-full">
                        <IconWithTextCard icon="orders" iconColor="geekblue" title={`${numeral(this.state.supplyRptDashbord_totalEuroAmount).format('0,0')} یورو`} subTitle="مجموع عرضه" />
                    </Col>
                    <Col xl={8} lg={12} md={12}   xs={24} className="gx-col-full">
                        <IconWithTextCard icon="revenue-new" iconColor="primary" title={`${numeral(this.state.demandRptDashbord_totalEuroAmount).format('0,0')} یورو`} subTitle="مجموع تقاضا" />
                    </Col>
                    <Col xl={8} lg={12} md={12}  xs={24} className="gx-col-full">
                        <IconWithTextCard icon="ellipse-v" iconColor="geekblue" title={`${numeral(this.state.dealingRptDashbord_totalEuroAmount).format('0,0')} یورو`} subTitle="مجموع معامله" />
                    </Col>
                </Row>
                {/* <Divider style={{backgroundColor:'#c5c5c5'}}></Divider> */}
                <Row >

                    <Col  xl={8}  xs={24}   >
                        <Card title="مجموع عرضه ها بر مبنای نوع ارز"
                            headStyle={{ backgroundColor: '#b5d1ff' }}
                            bodyStyle={{padding:'12px'}}
                        >
                            <Table className="gx-table-responsive withPointer"
                                pagination={false}
                                columns={SupplyArzs}
                                dataSource={this.state.supplyRptDashbord_supplyArzs}
                                onChange={this.handleChange}
                                onRowClick={this.handleShowDetails} />


                        </Card>
                    </Col>
                    <Col xl={8}  xs={24}  >
                        <Card title="مجموع تقاضاهای صرافی ها/بانک ها  بر مبنای نوع ارز"
                            headStyle={{ backgroundColor: '#fa8c16' }} 
                            bodyStyle={{padding:'12px'}}>
                            <Table className="gx-table-responsive withPointer"
                                pagination={false}
                                columns={DemDemandDealingsArzs}
                                dataSource={this.state.demandRptDashbord_demandDealingsArzs}
                                onChange={this.handleChange}
                                onRowClick={this.handleShowDetails} />
                        </Card>
                    </Col>
                    <Col xl={8}  xs={24} >
                        <Card title="مجموع معاملات بر مبنای نوع ارز"
                            headStyle={{ backgroundColor: '#20c997' }}
                            bodyStyle={{padding:'12px'}}
                        >
                            <Table className="gx-table-responsive withPointer"
                                pagination={false}
                                columns={DelDemandDealingsArzs}
                                dataSource={this.state.dealingRptDashbord_demandDealingsArzs}
                                onChange={this.handleChange}
                                onRowClick={this.handleShowDetails} />
                        </Card>
                    </Col> 
                </Row>
                {/* <Divider style={{backgroundColor:'#c5c5c5'}}></Divider> */}
                <Row >

                    <Col   xl={8}  xs={24} >
                        <Card title="مجموع عرضه ها بر مبنای عرضه کننده"
                            headStyle={{ backgroundColor: '#b5d1ff' }}
                            bodyStyle={{padding:'12px'}}
                        >
                            <Table className="gx-table-responsive"
                                pagination={false}
                                columns={SupplyArzAndSuppliers}
                                dataSource={this.state.supplyRptDashbord_supplyArzAndSuppliers}
                                onChange={this.handleChange} />
                        </Card>
                    </Col>
                    <Col xl={8}  xs={24}  >
                        <Card title="مجموع تقاضاهای صرافی ها/بانک ها بر مبنای صرافی/بانک"
                            headStyle={{ backgroundColor: '#fa8c16' }}
                                bodyStyle={{padding:'12px'}} >

                            <Table className="gx-table-responsive"
                                pagination={false}
                                columns={DemDemandDealingsArzAndSarafiNames}
                                dataSource={this.state.demandRptDashbord_demandDealingsArzAndSarafiNames}
                                onChange={this.handleChange} />
                        </Card>
                    </Col>
                    <Col xl={8}  xs={24}  >
                        <Card title="مجموع معاملات صرافی ها/بانک ها بر مبنای صرافی/بانک"
                            headStyle={{ backgroundColor: '#20c997' }}
                            bodyStyle={{padding:'12px'}}
                        >
                            <Table className="gx-table-responsive"
                                pagination={false}
                                columns={DelDemandDealingsArzAndSarafiNames}
                                dataSource={this.state.dealingRptDashbord_demandDealingsArzAndSarafiNames}
                                onChange={this.handleChange} />
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default DashboardExportTotalReport;
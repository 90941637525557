import React from 'react'
import { Button, Card, Table, Row, Col, Switch, Modal, Input, Tooltip, Divider, Select} from 'antd';
import { Component } from 'react';
import httpCaller from '../../services/httpService';
import {CloseOutlined} from '@ant-design/icons'
import {HasPermission} from '../../services/authorization';
import {NotificationManager} from 'react-notifications'
import {PlusCircleOutlined} from '@ant-design/icons'

class UserRoles extends Component{

    constructor(props)
    {
        super(props);
        this.state = {
            roles: [],
            model: {
                roles: []
            },
            pageIndex: 1,
            pageSize: 10,
            allRoles: [],
            selectedUserIdToAdd: 0,
            selectedUserTextToAdd: '',
            selectedRoleId: 0,
            totalCount : 0,
        }
    }

    componentDidMount = () => {
        this.getData(1, true);
        this.createSelectItems();
    }

    createSelectItems = () => {

        httpCaller.Auth.getRolesInfo({ userId:0}, (data) => {
            this.setState({allRoles: data});
        })
    }

    handleChange = (value) => {
        this.setState({
            selectedUserIdToAdd: value,
        });
    }

    getData = (pageIndex, isSOCLog) => {
        var data = {
            userId: parseInt(this.props.UserId),
            pageSize: 10,
            pageIndex: pageIndex
        };
        httpCaller.Auth.getRolesForUser(data, (pagedEntity) => {
            this.setState({ model: { ...this.state.model, roles: pagedEntity.items } });
            this.setState({ roles: this.state.model.roles , totalCount: pagedEntity.totalCount , pageIndex:pagedEntity.pageIndex })
        }, () => { }, isSOCLog);
    }

    //handlePageIndexChanged = (index) => {
      //  this.getData(index, true);
   // }

    handleRemoveUserConfirmation = () => {
        
        this.setState({ modalVisible: false })
        var uId = parseInt(this.props.UserId);
        httpCaller.Auth.removeUserFromRole({ roleId: parseInt(this.state.selectedRoleId), userId: uId}, () => {
            NotificationManager.success("عملیات حذف با موفقیت انجام شد");
            this.setState({
                selectedUserIdToAdd: 0,
            });
            
        });

        this.getData(1, false);
    }

    handleAddRole = () => {
        if (this.state.selectedUserIdToAdd > 0) {
            httpCaller.Auth.addUserToRole({ roleId: this.state.selectedUserIdToAdd, userId: this.props.UserId }, () => {
                NotificationManager.success(` نقش موردنظر با موفقیت اضافه شد.`)
                this.setState({
                    selectedUserIdToAdd: 0,
                });
                this.getData(1, false);
            })
        }
    }

    render(){
        let canDeleteRole4User = HasPermission("AccountManagement_DeleteRole");
        const Option = Select.Option;

        const  columns =[
            {
                title:'کد نقش',
                dataIndex:'roleId',
            },
            {
                title:'نام نقش',
                dataIndex:'roleName',
            },
            {
                title:'توضیحات',
                dataIndex:'description',
            },
            canDeleteRole4User && {
                title:'عملیات',
                dataIndex:'action',
                render:(text, row)=>(<Tooltip placeholder="top" title="حذف کاربر از نقش">
                     <a onClick={() => this.setState({ modalVisible: true, selectedRoleId: row.roleId })}>
                        {<CloseOutlined style={{ color: "red", fontSize: "2rem", cursor: "pointer" }}/>}
                    </a>
                </Tooltip>)
            }
        ]

        return(
            <React.Fragment>
                <Card>
                    <Row>
                        <Col sm={24} lg={20} xl={16} xxl={12}>
                            <Input.Group compact className="gx-mb-3">
                            <Select onChange={this.handleChange} 
                                    style={{width: "40%"}}
                                    placeholder='نقش موردنظر را انتخاب کنید'>
                                {this.state.allRoles.map(info => (
                                <Option value={info.roleId} >{info.roleName}</Option>
                                ))}
                            </Select>
                                <Button style={{backgroundColor: '#68d268'}} onClick={this.handleAddRole} ><PlusCircleOutlined /></Button>
                            </Input.Group>
                        </Col>
                    </Row> 
                    <Row>
                        <Col span={24}>
                            <Table className="gx-table-responsive withPointer"
                                columns={columns}
                                dataSource={this.state.roles}
                                bordered
                                pagination={{
                                    total:this.state.totalCount,
                                    pageSize: this.state.pageSize,
                                    current: this.state.pageIndex,
                                    onChange: (index) => this.getData(index, true)
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
                <Modal
                    visible={this.state.modalVisible}
                    footer={[
                        <Button key="submit" type="danger" onClick={this.handleRemoveUserConfirmation}>
                            حذف کاربر از نقش
                    </Button>,
                        <Button key="back" onClick={() => { this.setState({ modalVisible: false, selectedRoleId: 0 }) }}>
                            بازگشت
                    </Button>
                    ]}
                >
                    <Row >
                        <Col span={24} >
                            <span>{"آیا از حذف این نقش برای این کاربر اطمینان دارید؟"}</span>
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        )
    }
}

export default UserRoles

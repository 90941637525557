import React, { Component } from 'react';
import httpCaller from '../../services/httpService'
import { NotificationManager } from 'react-notifications'
import { Row, Col, Card, Table, Switch, Select, Input, Button, Form,Checkbox } from 'antd'
import { HasPermission,CheckPermissionHOC } from '../../services/authorization'

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchResult: {},
            searchType: "0",
            searchValue: ""
        }
        this.searchValueRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData(1);
    }

    fetchData = (pageIndex) => {
        var param = {
            pageIndex: pageIndex,
            pageSize: 10,
            searchType: this.state.searchType ,
            searchContain: this.state.searchValue ,
        };
        httpCaller.User.GetUsers(param, (pagedEntity) => {
            this.setState({ searchResult: pagedEntity });
        });

    }

    handleSearchTypeChanged = (value) => {
        if(this.searchValueRef.current){
            this.searchValueRef.current.input.value = "";
        }

        this.setState({ searchType: value });
    }
    handleSearchTextChanged = (value) => {
        this.setState({ searchValue: this.searchValueRef.current.input.value });
    }
    handleUserTypeChanged = (value) => {
         this.setState({ searchValue: value });
    }
    handleSearch = (e) => {

        this.fetchData(1);
    }
    handleShowUser = (user) => {
        if (!HasPermission("SysAdmin") && user.isDeleted) {
            NotificationManager.error("کاربر در سامانه حذف شده است.")
            return;
        }

        this.props.history.push(`/user/view/${user.id}`);
    }

    render() {
        let isSysAdmin = HasPermission("SysAdmin");

        const Option = Select.Option;
        const columns = [

            {
                title: "نام کاربری",
                dataIndex: "userName",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: "نام",
                dataIndex: "firstName",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: "نام خانوادگی",
                dataIndex: "lastName",
                render: (text, row) => (row.isDeleted ? <del>{text}</del> : text)
            },
            {
                title: "تایید شده",
                dataIndex: "isActive",
                render: (text, row) => (<Switch checked={row.isActive} disabled={true} />),
            },
            {
                title: "فعال",
                dataIndex: "isEnable",
                render: (text, row) => (<Switch checked={row.isEnable} disabled={true} />),
            }
        ]
        return (

            <React.Fragment>
                <Card>
                    <Row>
                        <Col sm={24} lg={20} xl={16} xxl={12}>
                            <Input.Group compact className="gx-mb-3">
                                <Select  defaultValue="0" style={{ width: '30%' }} onChange={this.handleSearchTypeChanged} >
                                    <Option value="0" >همه ی موارد</Option>
                                    {/* <Option value="1" >کد یا نام بانک/صرافی</Option> */}
                                    <Option value="2" >نام کاربری</Option>
                                    <Option value="3" >نام و نام خانوادگی</Option>
                                    {/* <Option value="4" >نوع کاربر</Option> */}
                                </Select>
                                {
                                    (() => {
                                        if (this.state.searchType == "4") {
                                            return <Select  style={{ width: '40%' }} defaultValue="" onChange={this.handleUserTypeChanged}>
                                                <Option value="">[انتخاب کنید]</Option>
                                                <Option value="CBIAdmin">کاربر ارشد</Option>
                                                <Option value="CBIUser">کاربر</Option>
                                                <Option value="SarafiAdmin">مدیر صرافی</Option>
                                                <Option value="SarafiUser">کاربر صرافی</Option>
                                                <Option value="NezaratOstan">ناظر استانی</Option>
                                            </Select>
                                        }
                                        else {
                                            return (<Input style={{ width: '40%' }} ref={this.searchValueRef}  placeholder="جستجو" onChange={this.handleSearchTextChanged} />)
                                        }
                                    })()
                                }
                                <Button type="primary" icon="search" onClick={this.handleSearch} >جستجو</Button>
                            </Input.Group>
                        </Col>
                    </Row>
                </Card>
                {this.state.searchResult.items && <Card>
                    <Table className="gx-table-responsive withPointer"
                        columns={columns}
                        dataSource={this.state.searchResult.items}
                        bordered
                        pagination={{
                            total: this.state.searchResult.totalCount,
                            pageSize: this.state.searchResult.pageSize,
                            current: this.state.searchResult.pageIndex,
                            onChange: (index) => this.fetchData(index),
                        }}
                        onRowClick= {(row) => !isSysAdmin && row.isDeleted ? {} : this.handleShowUser(row)}
                    />

                </Card>
                }
            </React.Fragment>
        );
    }
}

export default  CheckPermissionHOC(Users,'AccountManagement_SearchUser');

import React, { Component } from 'react';
import jwt_decode from 'jwt-decode'


var _currentAppUser = { Permissions: [], UserInfo: null };
var __checkPermissionOnClient__ = true;

function CheckPermissionHOC(PermissionableComponent, requiredPermission) {

    

    return (props) => {
        let hasPermission = HasPermission(requiredPermission);
        console.log("hasPermission:",hasPermission);
        return (hasPermission ? 
        <PermissionableComponent  {...props} /> : 
        <React.Fragment></React.Fragment>)
    }
    
}

function IsUserAuthenticated() {
    return window.sessionStorage.getItem('auth_token') != null;
}

function SetAuthToken(token) {
    window.sessionStorage.setItem('auth_token', token);
}

function HasPermission(permission) {

    if(!__checkPermissionOnClient__) return true;

    let allPermission = _currentAppUser.Permissions;
    if (allPermission.length == 0) {
        let token = window.sessionStorage.getItem('auth_token')
        if (!token)
            return false;
       
        var decoded = jwt_decode(token);
        if (decoded.permission) {
            allPermission = decoded.permission
                .split(',')
                .map(v => v.toLowerCase());
        }
    }
    return ( allPermission.includes("sysadmin") ||
        allPermission.includes(permission.toLowerCase())
    );
}

function Logout() {
    window.sessionStorage.removeItem("auth_token");
    _currentAppUser = { Permissions: [], UserInfo: null };
}

function GetAuthToken() {
    return window.sessionStorage.getItem("auth_token");
}

function GetAuthUserInfo() {
    var userInfo = _currentAppUser.UserInfo;
    if (userInfo == null) {
        let token = window.sessionStorage.getItem('auth_token')
        var decoded = "";
        if (token) {
            decoded = jwt_decode(token);
        }
        userInfo = {
            fullName: decoded.fullName,
            lastLogingDate: decoded.lastLogingDate
        }
    }

    return userInfo;
}
 window.SuppressPermission = () =>{__checkPermissionOnClient__ = !__checkPermissionOnClient__; return __checkPermissionOnClient__}

 export {
    CheckPermissionHOC,
    IsUserAuthenticated,
    SetAuthToken,
    GetAuthToken,
    HasPermission,
    Logout,
    GetAuthUserInfo
}
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import SarafiManagement from "./SarafiManagement/SarafiManagment"
import ExchangeDetail from "./SarafiManagement/ExchangeDetail"

const Exchange = ({match}) => (
    <Switch>
      <Route path={`${match.url}/sarafi`} component={SarafiManagement}/>
      <Route path={`${match.url}/ExchangeDetail/:code`} component={ExchangeDetail}/>
    </Switch>
  );
  
  export default Exchange; 
import React, { Component } from 'react';
import DisplayFormData from '../../utility/DisplayFormData'
import SimpleFieldSet from '../../utility/SimpleFieldSet'
import httpService from '../../../services/httpService'
import NumberFormat from 'react-number-format';
import Demand from './Demand'
import numeral from 'numeral'
import { CheckPermissionHOC, HasPermission } from '../../../services/authorization'
import { Row, Col, Button, Table, Card } from 'antd'
import SupplyCottages from './SupplyCottages';

class Supply extends Component {

    static contextTypes = {
        router: () => true
    }
    
    constructor(props) {
        super(props)
        this.supplyId = props.match.params["id"];
        this.demandId = props.match.params["demandId"];
        this.state = {
            expandedRows: [],
            supply: {
                supplyAccount:{}
            }
        }
    }
    componentDidMount() {
        httpService.NimaExport.GetSupplyDetails({ id: this.supplyId }, (data) => {
            this.setState({ supply: data });
        })
    }

    loadDemandDetail = (record) => {
        return <Demand demandId={record.demandId} updateDealing={this.updateDealing }></Demand>
    }
    updateDealing = (deal) => {
        let demands = [...this.state.supply.demands]

        demands = demands.map((a) => {
            if (a.dealingId == deal.dealingId) {
                a.dealingStatusText = deal.dealingStatusText;
            }
            return a;
        })
        this.setState({ supply: { ...this.state.supply, demands } })
    };
    render() {

        const columns =
            [{
                title: 'کد تقاضا',
                dataIndex: 'demandId',
            }, {
                title: 'نام صرافی',
                dataIndex: 'sarafiName',
            }, {
                title: 'قیمت واحد',
                dataIndex: 'demandCurrencyUnitPrice',
            }, {
                title: 'کد معامله',
                dataIndex: 'dealingId',
            }, {
                title: 'مبلغ ارز',
                dataIndex: 'dealingAmount',
                render: (text, row, index) => <NumberFormat value={text} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix={` ${row.currencyName}`} />
            },
                , {
                title: 'وضعیت معامله',
                dataIndex: 'dealingStatusText',
            }]

        var cartTitle=(<div><span>{"مشخصات عرضه ارز"}</span>
                        <Button type="default" style={{float:"left", marginBottom:"0px"}} onClick={this.context.router.history.goBack}>بازگشت</Button>
                       </div>)

        return (
            <React.Fragment>
                <Card title={cartTitle}>

                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="کد عرضه" value={this.state.supply.supplyId} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="زمان ثبت عرضه" value={this.state.supply.supplyRegisterDateStr} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="مهلت ثبت تقاضا" value={this.state.supply.insertDemandDeadlineStr} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="مهلت انتخاب تقاضا" value={this.state.supply.demandSelectDeadlineStr} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نوع ارز" value={this.state.supply.currencyName} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="مبلغ ارز" value={numeral(this.state.supply.amount).format("0,0")} />
                        </Col>

                        <Col md={8} xs={24}>
                            <DisplayFormData lable={" قیمت واحد" + (this.state.supply.currencyName ? this.state.supply.currencyName : "")} value={numeral(this.state.supply.supplyCurrencyUnitPrice).format("0,0")} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="ضریب نرخ ارز" value={this.state.supply.currencyCoefficient} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نوع نرخ" value={this.state.supply.currencyRateTypeText} />
                        </Col>
                        {this.state.supply.paymentTerms != 0 && <Col md={8} xs={24}>
                            <DisplayFormData lable="زمان پرداخت ریال" value={this.state.supply.paymentTermsText} />
                        </Col>}
                    </Row>
                    <br />
                    <Row>
                        <Col xs={24}>
                            <DisplayFormData lable="شرایط پرداخت ریال" value={this.state.supply.paymentCondition} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={24}>
                            <DisplayFormData lable="شرایط انجام معامله" value={this.state.supply.dealRules} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="نام کاربر ثبت کننده" value={this.state.supply.userName} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="شماره تماس" value={this.state.supply.userMobileNumber} />
                        </Col>
                        <Col md={8} xs={24}>
                            <DisplayFormData lable="حذف منطقی"  value={(this.state.supply.supplyIsDeleted === true ? "بله" : "خیر" )} />
                        </Col>
                    </Row>
                    <br />
                    {
                        HasPermission("Nima_ViewExchangeAccount") &&(<SimpleFieldSet title="مشخصات حساب مبدا" isCollapsible>
                                <Row>
                                    <Col md={8} xs={24}>
                                        <DisplayFormData lable="کشور" value={this.state.supply.supplyAccount.originCountry} />
                                    </Col>
                                    <Col md={8} xs={24}>
                                        <DisplayFormData lable="بانک" value={this.state.supply.supplyAccount.originBank} />
                                    </Col>
                                </Row>
                            </SimpleFieldSet>)
                    }

                </Card>
                <Card title={`مشخصات عرضه کننده ${this.state.supply.supplierType == 1 ? 'حقیقی' : 'حقوقی'}`}>
                    {this.state.supply.supplierType == 1 ?
                        (<React.Fragment>
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="نام" value={this.state.supply.haghighiFirstName} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="نام خانوادگی" value={this.state.supply.haghighiLastName} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="تاریخ تولد" value={this.state.supply.haghighiBirthDay} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="کد ملی" value={this.state.supply.haghighiNationalId} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="شماره شناسنامه" value={this.state.supply.haghighiCertificateNumber} />
                                </Col>

                            </Row>
                        </React.Fragment>
                        )
                        :
                        (<React.Fragment>
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="نام شرکت" value={this.state.supply.hoghoghiCompanyName} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="شناسه ملی" value={this.state.supply.hoghoghiNationalId} />
                                </Col>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="شماره ثبت" value={this.state.supply.hoghoghiRegistrationNumber} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={8} xs={24}>
                                    <DisplayFormData lable="تاریخ ثبت" value={this.state.supply.hoghoghiRegistrationDate} />
                                </Col>
                            </Row>
                        </React.Fragment>
                        )
                    }
                </Card>
                {/* <SupplyCottages supplyId={this.supplyId} /> */}
                {
                    this.state.supply.demands && (
                        <Card title="مشخصات تقاضا/معامله">

                            <Table className="gx-table-responsive withPointer"
                                columns={columns}
                                dataSource={this.state.supply.demands}
                                rowClassName={(row, index) => parseInt(row.demandId).toString().trim() === parseInt(this.demandId).toString().trim() ? "desiredEntity" : ''}
                                bordered
                                pagination={false}
                                onRowClick={this.handleShowSupply}
                                expandedRowRender={this.loadDemandDetail}
                                expandRowByClick={true}
                                defaultExpandedRowKeys={this.demandId}
                                rowKey="demandId"
                            />
                        </Card>
                    )
                }

            </React.Fragment >
        );
    }
}

export default CheckPermissionHOC(Supply, 'NimaExport_ViewDealing');;